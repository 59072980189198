// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  urlServer : "http://200.2.192.234:5000",
  //urlServer : "https://api.inexoos.com/dep",
  //urlServer : "http://127.0.0.1:5000",
  //urlSnd    : "https://api.napsis.cl",
  urlSnd    : "https://api-testing.napsis.cl"
};
