import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PromedioAsignaturaService {
  
  constructor(private http: HttpClient) { }

  getData(recurso,params:any) : Promise<any> {
    let url: string = `${recurso}`;
    return this.http.get(url,{params: params}).toPromise();
  }
  getDataPost(recurso,params:any) : Promise<any> {
    let url: string = `${recurso}`;
    return this.http.post(url,params).toPromise();
  }

}
