import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { urlServer } from '../url.service';

@Injectable()
export class AsistenciaSndService{
    private url:string= urlServer;

    constructor(
        public http: HttpClient
    ){}

    asistenciaMensualSostenedor(cod_sostenedor:number,ano_escolar:number): Observable<any>{
        return this.http.get(this.url+'/sostenedor/'+cod_sostenedor+'/asistencia/ano_escolar/'+ano_escolar+'/tipo/1');
    }

    asistenciaMensualEstablecimiento(cod_sostenedor:number,ano_escolar:number): Observable<any> {
        return this.http.get(this.url+'/sostenedor/'+cod_sostenedor+'/asistencia/ano_escolar/'+ano_escolar+'/tipo/2');
    }

    asistenciaMensualAlumnosPrioritarios(cod_sostenedor:number,ano_escolar:number): Observable<any> {
        return this.http.get(this.url+'/sostenedor/'+cod_sostenedor+'/asistencia/ano_escolar/'+ano_escolar+'/tipo/3');
    }

    totalAsistenciaMatricula(cod_sostenedor:number,ano_escolar:number): Observable<any> {
        return this.http.get(this.url+'/sostenedor/'+cod_sostenedor+'/asistencia/ano_escolar/'+ano_escolar+'/tipo/4');
    }

    asistenciaMensualAlumnoIntegrado(cod_sostenedor:number,ano_escolar:number): Observable<any> {
        return this.http.get(this.url+'/sostenedor/'+cod_sostenedor+'/asistencia/ano_escolar/'+ano_escolar+'/tipo/5');
    }

    asistenciaMensualAlumnoVulnerable(cod_sostenedor:number,ano_escolar:number): Observable<any> {
        return this.http.get(this.url+'/sostenedor/'+cod_sostenedor+'/asistencia/ano_escolar/'+ano_escolar+'/tipo/6');
    }

    asistenciaMensualAlumnoPreferente(cod_sostenedor:number,ano_escolar:number): Observable<any> {
        return this.http.get(this.url+'/sostenedor/'+cod_sostenedor+'/asistencia/ano_escolar/'+ano_escolar+'/tipo/7');
    }


    asistenciaMensualSnd(json_param:string): Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this.http.post(this.url+'/asistencia_mensual', json_param, {headers: headers});
    }

}
