import { Subscription } from 'rxjs/Subscription';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { PuenteService } from '../../services/puente.service';
import { urlServer } from '../../services/url.service';
import { ListaAgnosService } from '../../services/shared/listaagnos.service';
import { GraficasService } from '../../services/shared/graficas.service';
import { DatatablesService } from '../../services/shared/datatables.service';
import { HttpPuenteService } from '../../services/shared/httppuente.service';
import { ExcelService } from '../../services/excel.service';
import { Chart } from 'angular-highcharts';
import * as Highcharts from "highcharts";
declare const $: any;

@Component({
  selector: 'app-alumnos-preferentes',
  templateUrl: './alumnos-preferentes.component.html',
  styleUrls: ['./alumnos-preferentes.component.scss']
})
export class AlumnosPreferentesComponent implements OnInit, OnDestroy {

  chart: Chart;
  Puente: Subscription;
  ActualAnoEscolar: any;
  mdPerfil: any;
  linkParams: any;
  titulo_excel:string = 'Ejemplo';
  SlcAnoEscolar = [];
  ListAnoEscolar = [];
  dataTable = [];
  dataTableExcel = [];
  dataTableExcelPorcAlumnos = [];
  dataTableExcelCantAlumnos = [];
  dataTableExcelPorcAlumnosNoPref = [];
  dataTableExcelCantAlumnosNoPref = [];
  rsData = [];
  rowHeadTablePorcAlumnos = [];
  rowBodyTablePorcAlumnos = [];
  rowHeadTablePorcAlumnosNoPref = [];
  rowBodyTablePorcAlumnosNoPref = [];
  colBodyTable = [];  
  rowHeadTableAlumnos = [];
  rowBodyTableAlumnos= [];
  rowFootTableAlumnos = [];
  rowHeadTableAlumnosNoPref = [];
  rowBodyTableAlumnosNoPref= [];
  rowFootTableAlumnosNoPref= [];
  listNivelesPorcAlumnos = [];
  listNivelesCantAlumnos = [];
  listNivelesPorcAlumnosNoPref = [];
  listNivelesCantAlumnosNoPref= [];
  alumnosPref=false;
  alumnosPrefCol=false;
  alumnosPrefNivel=false;
  public colegio:boolean = false;
  public loading:boolean = true;
  public api_error:boolean = false;

  constructor(private puente: PuenteService, private IGservice: HttpPuenteService,private excelService:ExcelService,private Agnos: ListaAgnosService, private DataTables: DatatablesService, private graphService: GraficasService) {

    this.Puente = this.puente.getData().subscribe(data => {
      this.graphService.removeActiveTab();
      if (this.alumnosPref) {
        this.alumnos_pref();
      }
      if (this.alumnosPrefCol) {
        this.alumnos_pref_colegio();
      }
      if (this.alumnosPrefNivel) {
        this.alumnos_pref_nivel();
      }
    });
   }

   ngOnDestroy(): void {
    this.Puente.unsubscribe();
  }
   ngOnInit() {
    this.graphService.tabs = ["pill1", "tab1", "pill2", "tab2", "pill3", "tab3"];
    this.ActualAnoEscolar =this.Agnos.ActualAnoEscolar;
    this.ListAnoEscolar=this.SlcAnoEscolar=this.Agnos.listAnosEscolares(this.ActualAnoEscolar);
    this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));
    if(this.mdPerfil.nivel==3 || this.mdPerfil.nivel==5){
      this.colegio=true;
    }
    else{
      this.colegio=false;
    }
    if (sessionStorage.getItem('link_param')) {
      this.alumnosPref = false;
      this.linkParams = sessionStorage.getItem('link_param');
      sessionStorage.removeItem('link_param');
    }
    switch (this.linkParams) {
      case 'pill2':
        this.alumnosPrefCol = true;
        this.alumnos_pref_colegio();
        break;
      case 'pill3':
        this.alumnosPrefNivel=true;
        this.alumnos_pref_nivel();
        break;
      default:
        this.alumnosPref = true;
        this.alumnos_pref();
    }
    this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));
    this.sl2_style();
  }

    alumnosPreferentesItem(items: any) {
    this.graphService.removeActiveTab();
    this.ActualAnoEscolar = items.value;
    if (this.alumnosPref) {
      this.alumnos_pref();
    } else if (this.alumnosPrefCol) {
      this.alumnos_pref_colegio();
    } else if (this.alumnosPrefNivel) {
      this.loading = true;
      this.alumnos_pref_nivel();
    } else {
      $.notify(
        {icon: 'notifications', message: 'Opción ingresada no es correcta'}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause',placement: {from: 'bottom',align: 'right'}}
      );
    }
  }

  alumnos_pref() {
    this.api_error = false;
    this.graphService.removeActiveTab();

    $("#pill1").parent().addClass("active");
    $("#tab1").parent().addClass("active");

    this.alumnosPref = true;
    this.alumnosPrefCol = false;
    this.alumnosPrefNivel = false;


    this.rsData = [];
    this.loading = false;

    let recurso: string;
    let idSelect: string;
    let jsonData: any;
    let list: any;
    let params: any;

    if (localStorage.getItem('select_colegio')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_colegio'));
        idSelect = jsonData.id
        this.colegio=true;
    } 
    else if (localStorage.getItem('select_sost')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_sost'));
        idSelect = jsonData.id
        this.colegio=false;
    } 
    else if (localStorage.getItem('select_slep')) {
        list = JSON.parse(localStorage.getItem('ListSost'));
        jsonData = JSON.parse(localStorage.getItem('select_slep'));
        idSelect = jsonData.id
        this.colegio=false;
    }

    let alumnos_preferentes_post = urlServer + '/alumnos_preferentes';

    
    if (localStorage.getItem('select_colegio')) {
      recurso = urlServer + '/colegioselector/' + idSelect;
    } 
    else if (localStorage.getItem('select_sost')) {
      recurso = urlServer + '/rbd/sostenedor/' + idSelect;
    } 
    else if (localStorage.getItem('select_slep')) {
      recurso = urlServer + '/rbd/slep/' + idSelect;
    } 
    else {
      recurso = null
    }

    let arrCodColegios = [];
    let arrIdColegios = [];
    if(recurso){
      this.IGservice.getData(recurso).then((data) => {
          Object.keys(data.data).forEach(key => {
              arrIdColegios.push(key);
              arrCodColegios.push(data.data[key]);
          });

          let ListCodColegios = arrCodColegios.toString();
          let ListIdColegios  = arrIdColegios.toString();

          params = {
              "grupo_colegios": ListCodColegios,
              "ano_escolar": this.ActualAnoEscolar,
              "tipo": "1"
          }

          this.IGservice.getDataPost(alumnos_preferentes_post,params)
          .then((data) => {
            this.graficoConsolidado(data);
          })
          .catch((err) => {
            $.notify(
              { icon: 'notifications', message: err.message }, 
              { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
            );
          });

      })
      .catch((err) => {
        $.notify(
          {icon: 'notifications', message: err.message}, 
          {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
        );
      });
    }
    else{
      $.notify(
        {icon: 'notifications', message: 'Revise selección en el menú por favor.'}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
      );
    }
  }

  alumnos_pref_colegio() {
    this.api_error = false;
    this.graphService.removeActiveTab();

    $("#pill2").parent().addClass("active");
    $("#tab2").parent().addClass("active");

    this.alumnosPref = false;
    this.alumnosPrefCol = true;
    this.alumnosPrefNivel = false;  
    this.rsData = [];

    let recurso: string;
    let idSelect: string;
    let data:string;
    let jsonData: any;
    let list: any;
    let params: any;
    let rsTable = [];
    let AnoEscolar = [];

    if (!this.ActualAnoEscolar) {
      let currentTime = new Date()
      this.ActualAnoEscolar = currentTime.getFullYear();
    }

    let codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));

    if (this.SlcAnoEscolar.length==0) {
      Object.keys(this.ListAnoEscolar).forEach(key => {
        let xano = {};
        xano["ano_escolar"] = this.ListAnoEscolar[key].ano_escolar;
        this.SlcAnoEscolar.push(xano);
      });
    }

    this.SlcAnoEscolar = this.graphService.eliminarDuplicateInArray(this.SlcAnoEscolar);
    this.loading = true;

    if (localStorage.getItem('select_colegio')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_colegio'));
        idSelect = jsonData.id
    } else if (localStorage.getItem('select_sost')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_sost'));
        idSelect = jsonData.id
    } else if (localStorage.getItem('select_slep')) {
        list = JSON.parse(localStorage.getItem('ListSost'));
        jsonData = JSON.parse(localStorage.getItem('select_slep'));
        idSelect = jsonData.id
    }
    let tipo = "";
    let alumnos_preferentes_post = urlServer + '/alumnos_preferentes';

    if (localStorage.getItem('select_sost')) {
      recurso = urlServer + '/rbd/sostenedor/' + idSelect;
      tipo = "3";
    } 
    else if (localStorage.getItem('select_slep')) {
      recurso = urlServer + '/rbd/slep/' + idSelect;
      tipo = "2";
    }
     else {
      recurso = null
    }

    let arrCodColegios = [];
    let arrIdColegios = [];
    if(recurso){
      this.IGservice.getData(recurso).then((data) => {
          Object.keys(data.data).forEach(key => {
              arrIdColegios.push(key);
              arrCodColegios.push(data.data[key]);
          });

          let ListCodColegios = arrCodColegios.toString();
          let ListIdColegios  = arrIdColegios.toString();

          params = {
              "grupo_colegios": ListCodColegios,
              "ano_escolar": this.ActualAnoEscolar,
              "tipo": tipo
          }

          this.IGservice.getDataPost(alumnos_preferentes_post,params)
          .then((result) => {
            if( result.success && result.data.xkeys) {
              let jsonData = result.data;
              this.rsData = jsonData.datos;
              this.graphBarra(this.rsData, this.ActualAnoEscolar, jsonData.colors, jsonData.xkeys);
              this.tablaAlumnosPreferentesCol(jsonData.labels);
              this.loading = false;
            } else {
              $.notify(
                {icon: 'notifications', message: "No hay datos para imprimir"}, 
                {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
              });
              setTimeout(() => { this.loading = false}, 700);
            }
          })
          .catch((err) => {
            $.notify(
              { icon: 'notifications', message: err.message }, 
              { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
            );
          });
      })
      .catch((err) => {
        $.notify(
          {icon: 'notifications', message: err.message}, 
          {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
        );
      });
    }
    else{
      $.notify(
        {icon: 'notifications', message: 'Revise selección en el menú por favor.'}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
      );
    }
  }

  alumnos_pref_nivel() {
    this.api_error = false;
    this.loading = true;
    this.graphService.removeActiveTab();
    $("#pill3").parent().addClass("active");
    $("#tab3").parent().addClass("active");
    this.alumnosPref = false;
    this.alumnosPrefCol = false;
    this.alumnosPrefNivel = true;   
    let recurso: string;
    let idSelect: string;
    let data:string;
    let jsonData: any;
    let list: any;
    let params: any;
    let rsTable = [];
    let AnoEscolar = [];
    let niveles = [];
    let rsData2:string;

    if (!this.ActualAnoEscolar) {
      this.ActualAnoEscolar =this.Agnos.ActualAnoEscolar;
      this.ListAnoEscolar=this.SlcAnoEscolar=this.Agnos.listAnosEscolares(this.ActualAnoEscolar);
      let currentTime = new Date()
    }

    let codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));
    if (this.SlcAnoEscolar.length==0) {
      Object.keys(this.ListAnoEscolar).forEach(key => {
        let xano = {};
        xano["ano_escolar"] = this.ListAnoEscolar[key].ano_escolar;
        this.SlcAnoEscolar.push(xano);
      });
    }
    this.SlcAnoEscolar = this.graphService.eliminarDuplicateInArray(this.SlcAnoEscolar);

    if (localStorage.getItem('select_colegio')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_colegio'));
        idSelect = jsonData.id
        this.colegio= true;
    } 
    else if (localStorage.getItem('select_sost')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_sost'));
        idSelect = jsonData.id
        this.colegio= false;
    } 
    else if (localStorage.getItem('select_slep')) {
        list = JSON.parse(localStorage.getItem('ListSost'));
        jsonData = JSON.parse(localStorage.getItem('select_slep'));
        idSelect = jsonData.id
        this.colegio= false;
    }

    let alumnos_preferentes_post = urlServer + '/alumnos_preferentes';
    let tipo = "";
// Definir valores de tipo con Danny para la api
    if (localStorage.getItem('select_colegio')) {
      recurso = urlServer + '/colegioselector/' + idSelect;
      tipo = "5";
    } 
    else if (localStorage.getItem('select_sost')) {
      recurso = urlServer + '/rbd/sostenedor/' + idSelect;
      tipo = "5";
    } else if (localStorage.getItem('select_slep')) {
      recurso = urlServer + '/rbd/slep/' + idSelect;
      tipo = "4";
    } else {
      recurso = null
    }

    let arrCodColegios = [];
    let arrIdColegios = [];
    if(recurso){
      this.IGservice.getData(recurso).then((data) => {
          Object.keys(data.data).forEach(key => {
              arrIdColegios.push(key);
              arrCodColegios.push(data.data[key]);
          });

          let ListCodColegios = arrCodColegios.toString();
          let ListIdColegios  = arrIdColegios.toString();

          params = {
              "grupo_colegios": ListCodColegios,
              "ano_escolar": this.ActualAnoEscolar,
              "tipo": tipo
          }

          this.IGservice.getDataPost(alumnos_preferentes_post,params)
          .then((result) => {
            if( result.success && result.data.xkeys) {
              jsonData = result.data;
              rsData2 = jsonData.datos;
              niveles = jsonData.labels.niveles;
              rsTable = jsonData.labels;
              if(localStorage.getItem('ListProfilesPers'))
              { 
                let nivelesvalores=null;              
               nivelesvalores=this.Agnos.nivelespersonalizado(rsData2,jsonData.xkeys);
               this.graphBarra(nivelesvalores[1], this.ActualAnoEscolar, jsonData.colors, nivelesvalores[0]);
               this.tablaAlumnosPreferentesNiveles(rsTable, nivelesvalores[0]);
               this.loading = false;
              }
               else{  
              this.graphBarra(rsData2, this.ActualAnoEscolar, jsonData.colors, jsonData.xkeys);
              this.tablaAlumnosPreferentesNiveles(rsTable, niveles);
              this.loading = false;
               }
            } else {
              $.notify(
                {icon: 'notifications', message: "No hay datos para imprimir"}, 
                {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
              });
              setTimeout(() => { this.loading = false}, 700);
            }
          })
          .catch((err) => {
            $.notify(
              { icon: 'notifications', message: err.message }, 
              { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
            );
          });

      })
      .catch((err) => {
        $.notify(
          {icon: 'notifications', message: err.message}, 
          {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
        );
      });
    }
    else{
      $.notify(
        {icon: 'notifications', message: 'Revise selección en el menú por favor.'}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
      );
    }
  }

  graficoConsolidado(result) {
    
    if( result.success ) {
      let jsonData = result.data;
      var obj = {};
      obj["matricula"] = jsonData.datos[1][1];
      obj["disponible"] = jsonData.datos[0][1];
      this.rsData[this.ActualAnoEscolar] = obj
      this.graphPie(this.rsData, this.ActualAnoEscolar);
      this.loading = false;
    } else {
      $.notify(
        {icon: 'notifications', message: "No hay datos para imprimir"}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
      });
      setTimeout(() => { this.loading = false}, 700);
    }
  }

  graphPie(rsData, anoEscolar) {
    this.loading = true;
    if (rsData.length>0) {
      this.chart = new Chart({
        lang: {
          downloadPNG: "Descargar a PNG",
          downloadJPEG: "Descargar a JPEG",
          downloadSVG: "Descargar a SVG",
          downloadPDF: "Descargar a PDF",
          loading: "Cargando",
          printChart: "Imprimir",
          contextButtonTitle: 'Opciones gráfico'
        },
        chart: {
          type: 'pie'
        },
        title: {
          text: 'Alumnos preferentes ' + anoEscolar
        },
        credits: {
          enabled: false
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.y}</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.y}'
            }
          }
        },
        series: [{
          name: 'Alumnos preferentes',
          data: [{
            name: 'Preferentes',
            y: rsData[anoEscolar]["disponible"],
            sliced: true,
            selected: true,
            color: '#1EA559'

          }, {
            name: 'No Preferentes',
            y: rsData[anoEscolar]["matricula"],
            color: '#FB3948'
          }]
        }]
      });

      this.dataTable = [];
      this.dataTableExcel =[];
      let codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));

      for (var k in rsData) {
        if (parseInt(k)>0) {
          this.dataTable.push({
            anoEscolar: k,
            disponible: rsData[k]["disponible"] + '%',
            matricula: rsData[k]["matricula"] + '%',
          });

          this.titulo_excel = `Alumnos_preferentes_periodo_${this.ActualAnoEscolar}`;
          this.dataTableExcel.push({
            Año_escolar: k,
            Disponible: rsData[k]["disponible"] + '%',
            Matriculados: rsData[k]["matricula"] + '%',
          });
        }
      };
      this.loading = false;
    } else {
      $.notify({
        icon: 'notifications',
        message: "No hay datos para imprimir"
      },
      {
        type: 'warning',
        timer: 2000,
        delay: 2000,
        mouse_over: 'pause',
        placement: {
          from: 'bottom',
          align: 'right'
        }
      });
      this.loading = false;
    }
  }

  graphBarra(rsData, anoEscolar, colors, categoria, tipo = 'consolidado') {
    this.loading = true;
    let linesEscalonadas = 0;
    var rotacionDataLabels = -90;
    if (tipo == 'comparativo') {
      rotacionDataLabels = null;
    }

    this.chart = new Chart({
      lang: {
        downloadPNG: "Descargar a PNG",
        downloadJPEG: "Descargar a JPEG",
        downloadSVG: "Descargar a SVG",
        downloadPDF: "Descargar a PDF",
        loading: "Cargando",
        printChart: "Imprimir",
        contextButtonTitle: 'Opciones gráfico'
      },
      chart: {
        type: 'column'
      },
      title: {
        text: `Alumnos preferentes ${anoEscolar}`
      },
      xAxis: {
        categories: categoria,
        min: null,
        max: null,
        title: {
          text: null
        },
        labels: {
          staggerLines:linesEscalonadas
        }
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: "Porcentaje de alumnos preferentes"
        },
        labels: {
          overflow: "justify",
          format: '{value}'
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        valueSuffix: '%',
        formatter: function() {
          return "" + this.x + "<br/>" + this.series.name + " : <strong>" + Highcharts.numberFormat(this.y, 0, ",", ".")+"%</strong>";
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            shadow: true
          }
        },
        series: {
          stacking: 'normal',
          dataLabels: {
            rotation: null,//rotacionDataLabels,
            formatter: function() {
              return this.y + "%";
              /*if (tipo == 'consolidado') {
                return this.series.name + ": <strong>" + this.y + "%</strong> ";
              } else {
                return "% uso: <strong>" + this.y + "%</strong> ";
              }*/
            },
            enabled: true,
            verticalAlign: 'middle',
            align: 'center',
            inside: true,
          }
        }
      },
      series: rsData,
      colors: colors
    });
    this.loading = false;
  }

  tablaAlumnosPreferentesCol(rsTable) {
    if (rsTable['0']) {
      let codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));
      this.titulo_excel = `Alumnos Preferentes`;
      this.dataTable = [];
      this.dataTableExcel=[];

      Object.keys(rsTable).forEach(key => {
        if (key != 'colegios') {
          if (key != 'colegios') {
            let total_preferentes:number;
            let total_nopreferentes:number;
            let cod_colegio:number;
            let nom_colegio:string;
            let porc_preferentes:number;
            let porc_nopreferentes:number;
            let cant_matricula_disp:number;
            let cant_matricula_disp_sin_formato:number;
  
            total_preferentes = parseInt(rsTable[key].total_preferentes);
            total_nopreferentes = parseInt(rsTable[key].total_no_preferentes);
            cod_colegio = parseInt(rsTable[key].cod_colegio);
            nom_colegio  = rsTable[key].nom_colegio?rsTable[key].nom_colegio:rsTable[key].comuna;
            porc_preferentes = parseInt(rsTable[key].porc_preferentes);
            porc_nopreferentes = parseInt(rsTable[key].porc_no_preferentes);
            cant_matricula_disp = this.DataTables.formatNumber(total_nopreferentes - total_preferentes);
            cant_matricula_disp_sin_formato = total_nopreferentes - total_preferentes;
  
            this.dataTable.push({
              colegio: nom_colegio,
              total_preferentes: this.DataTables.formatNumber(total_preferentes),
              porc_preferentes: porc_preferentes + '%',
              total_nopreferentes: this.DataTables.formatNumber(total_nopreferentes),
              porc_nopreferentes: porc_nopreferentes + '%',
            });
  
            this.dataTableExcel.push({
              Agrupacion: nom_colegio,
              Total_preferentes: total_preferentes,
              Porc_preferentes: porc_preferentes + '%',
              Total_no_preferentes: total_nopreferentes,
              Porc_no_preferentes: porc_nopreferentes + '%',
            });
          }
        }
      });
    
    } else {
      $.notify(
        {icon: 'notifications', message: "No hay datos para imprimir"}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
      });
    }
  }

  tablaAlumnosPreferentesNiveles(rsTable, niveles) {
    this.loading = true;
    this.rowHeadTablePorcAlumnos = [];
    this.rowBodyTablePorcAlumnos = [];
    this.rowHeadTablePorcAlumnosNoPref = [];
    this.rowBodyTablePorcAlumnosNoPref = [];
    this.colBodyTable = [];   //General 
    this.rowHeadTableAlumnos = [];
    this.rowBodyTableAlumnos = [];
    this.rowHeadTableAlumnosNoPref = [];
    this.rowBodyTableAlumnosNoPref = [];

    this.listNivelesPorcAlumnos = [];
    this.listNivelesPorcAlumnosNoPref = [];
    this.listNivelesCantAlumnos = [];
    this.listNivelesCantAlumnosNoPref = [];
    this.rowFootTableAlumnos= [];
    this.dataTableExcelPorcAlumnos = [];
    this.dataTableExcelPorcAlumnosNoPref = [];
    this.dataTableExcelCantAlumnos = [];
    this.dataTableExcelCantAlumnosNoPref = [];

    let tHeadPorcAlumnosPref = [];
    let tHeadPorcAlumnosNoPref = [];
    let tHeadAlumnos  = [];
    let tFootAlumnos  = [];
    let tHeadAlumnosNoPref  = [];
    let tFootAlumnosNoPref  = [];

    let totalNivelesAlumnos = [];
    let totalNivelesAlumnosNoPref = [];
    let arrNivelCol = []; //General 
    

    if (niveles) {
      tHeadPorcAlumnosPref.push('Agrupación');
      tHeadPorcAlumnosNoPref.push('Agrupación');
      tHeadAlumnos.push('Agrupación');
      tFootAlumnos.push('Total');
      tHeadAlumnosNoPref.push('Agrupación');
      tFootAlumnosNoPref.push('Total');

      Object.keys(niveles).forEach(key => {
        tHeadPorcAlumnosPref.push(niveles[key]);
        tHeadPorcAlumnosNoPref.push(niveles[key]);
        tHeadAlumnos.push(niveles[key]);
        tHeadAlumnosNoPref.push(niveles[key]);
        totalNivelesAlumnos[niveles[key]] = totalNivelesAlumnos[niveles[key]] = 0;
        totalNivelesAlumnosNoPref[niveles[key]] = totalNivelesAlumnosNoPref[niveles[key]] = 0;
        this.listNivelesPorcAlumnos.push(niveles[key]);
        this.listNivelesPorcAlumnosNoPref.push(niveles[key]);
        this.listNivelesCantAlumnos.push(niveles[key]);
        this.listNivelesCantAlumnosNoPref.push(niveles[key]);
        arrNivelCol[niveles[key]] = '';
      });
      this.listNivelesCantAlumnos.push('Total');
      this.listNivelesCantAlumnosNoPref.push('Total');
      tHeadAlumnos.push('Total');
      tHeadAlumnosNoPref.push('Total');
      totalNivelesAlumnos['Total'] = totalNivelesAlumnos['Total']=0; 
      totalNivelesAlumnosNoPref['Total'] = totalNivelesAlumnosNoPref['Total']=0; 
      this.rowHeadTablePorcAlumnos = tHeadPorcAlumnosPref;
      this.rowHeadTablePorcAlumnosNoPref = tHeadPorcAlumnosNoPref;
      this.rowHeadTableAlumnos  = tHeadAlumnos;
      this.rowHeadTableAlumnosNoPref  = tHeadAlumnosNoPref;
      let arrColegio = [];
      let arrColegioNoPref = [];
      let arrColegioPorcPref = [];
      let arrColegioPorcNoPref = [];

      Object.keys(rsTable).forEach(key => {
        if (key != 'niveles') {
          let porcAlumnosCol = [];
          let porcAlumnosColNoPref = [];
          let AlumnosCol = [];
          let AlumnosColNoPref = [];
          let totalPrefColegio = 0;
          let totalNoPrefColegio = 0;
          porcAlumnosCol = arrNivelCol.slice();
          porcAlumnosColNoPref = arrNivelCol.slice();
          AlumnosCol = arrNivelCol.slice();
          AlumnosColNoPref = arrNivelCol.slice();
          let colegio = rsTable[key]['nom_colegio']?rsTable[key]['nom_colegio']:rsTable[key]['comuna'];
          arrColegio.push({rbd:parseInt(key),colegio:colegio});
          Object.keys(rsTable[key]['grados']).forEach(nivel => {
            if(this.DataTables.buscarNivel(nivel,niveles)){
            let cant_alu_pref:number;
            let cant_alu_no_pref:number;
            let porcentajepref:number;
            let porcentajenopref:number;
            cant_alu_pref     = parseInt(rsTable[key]['grados'][nivel]['total_preferentes']);
            cant_alu_no_pref = parseInt(rsTable[key]['grados'][nivel]['total_no_preferentes']);
            porcentajepref   = parseInt(rsTable[key]['grados'][nivel]['porc_preferentes']);
            porcentajenopref   = parseInt(rsTable[key]['grados'][nivel]['porc_no_preferentes']);
            totalPrefColegio = totalPrefColegio + cant_alu_pref;
            totalNoPrefColegio = totalNoPrefColegio + cant_alu_no_pref;
            porcAlumnosCol[nivel] = porcentajepref+'%';
            porcAlumnosColNoPref[nivel] = porcentajenopref+'%';
            AlumnosCol[nivel] = this.DataTables.formatNumber(cant_alu_pref);
            AlumnosColNoPref[nivel] = this.DataTables.formatNumber(cant_alu_no_pref);

            totalNivelesAlumnos[nivel] = cant_alu_pref + totalNivelesAlumnos[nivel];
            totalNivelesAlumnosNoPref[nivel] = cant_alu_no_pref + totalNivelesAlumnosNoPref[nivel];
            }
          });

          AlumnosCol['Total'] = this.DataTables.formatNumber(totalPrefColegio);
          AlumnosColNoPref['Total'] = this.DataTables.formatNumber(totalNoPrefColegio);
          totalNivelesAlumnos['Total'] = totalPrefColegio + totalNivelesAlumnos['Total'];
          totalNivelesAlumnosNoPref['Total'] = totalNoPrefColegio + totalNivelesAlumnosNoPref['Total'];
          this.rowBodyTablePorcAlumnos[parseInt(key)] = porcAlumnosCol;
          this.rowBodyTablePorcAlumnosNoPref[parseInt(key)] = porcAlumnosColNoPref;
          this.rowBodyTableAlumnos[parseInt(key)]  = AlumnosCol;
          this.rowBodyTableAlumnosNoPref[parseInt(key)]  = AlumnosColNoPref;
        }

      });
      this.colBodyTable =  arrColegio;
      this.rowFootTableAlumnos  = totalNivelesAlumnos;
      this.rowFootTableAlumnosNoPref  = totalNivelesAlumnosNoPref;

      Object.keys(this.colBodyTable).forEach(key=>{
        let rbd  = this.colBodyTable[key].rbd;
        let rowExcelCantAlumnos = [];
        let rowExcelCantAlumnosNoPref = [];
        let rowExcelPorcPref = [];
        let rowExcelPorcNoPref = [];
        rowExcelPorcNoPref['Agrupacion'] =rowExcelPorcPref['Agrupacion'] = rowExcelCantAlumnos['Agrupacion'] =rowExcelCantAlumnosNoPref['Agrupacion'] = this.colBodyTable[key].colegio;
        Object.keys(this.listNivelesPorcAlumnos).forEach(idx=>{
          let rowHead = this.listNivelesPorcAlumnos[idx];
          let porcentajepref = this.rowBodyTablePorcAlumnos[rbd][rowHead];
          let index = " "+rowHead;
          rowExcelPorcPref[index] = porcentajepref;
        });
        this.dataTableExcelPorcAlumnos[key] = rowExcelPorcPref;

        Object.keys(this.listNivelesPorcAlumnosNoPref).forEach(idx=>{
          let rowHead = this.listNivelesPorcAlumnosNoPref[idx];
          let porcentajenopref = this.rowBodyTablePorcAlumnosNoPref[rbd][rowHead];
          let index = " "+rowHead;
          rowExcelPorcNoPref[index] = porcentajenopref;
        });
        this.dataTableExcelPorcAlumnosNoPref[key] = rowExcelPorcNoPref;

        //rowExcelCantAlumnos['Establecimiento'] =  this.colBodyTable[key].colegio;
        Object.keys(this.listNivelesCantAlumnos).forEach(idx=>{
          let rowHead  = this.listNivelesCantAlumnos[idx];
          let cantidad = this.rowBodyTableAlumnos[rbd][rowHead];
          let index = " "+rowHead;
          if (cantidad) {
            rowExcelCantAlumnos[index] = parseInt(cantidad.replace('.',''));
          } else {
            rowExcelCantAlumnos[index] = '';
          }
        });
        this.dataTableExcelCantAlumnos[key] = rowExcelCantAlumnos;
      
      let rowalumnospref = [];
      rowalumnospref['Agrupacion'] = 'Total';
      Object.keys(this.rowFootTableAlumnos).forEach(idx=>{
        let total = parseInt(this.rowFootTableAlumnos[idx]);
        let index = " "+idx;
        rowalumnospref[index] = total;
      });
      this.dataTableExcelCantAlumnos.push(rowalumnospref);

     // rowExcelCantAlumnosNoPref['Establecimiento'] =  this.colBodyTable[key].colegio; 
      Object.keys(this.listNivelesCantAlumnosNoPref).forEach(idx=>{
        let rowHead  = this.listNivelesCantAlumnosNoPref[idx];
        let cantidad = this.rowBodyTableAlumnosNoPref[rbd][rowHead];
        let index = " "+rowHead;
        if (cantidad) {
          rowExcelCantAlumnosNoPref[index] = parseInt(cantidad.replace('.',''));
        } else {
          rowExcelCantAlumnosNoPref[index] = '';
        }
      });
      this.dataTableExcelCantAlumnosNoPref[key] = rowExcelCantAlumnosNoPref;
    });

    let rowalumnosnopref = [];
    rowalumnosnopref['Agrupacion'] = 'Total';
    Object.keys(this.rowFootTableAlumnosNoPref).forEach(idx=>{
      let total = parseInt(this.rowFootTableAlumnosNoPref[idx]);
      let index = " "+idx;
      rowalumnosnopref[index] = total;
    });
    this.dataTableExcelCantAlumnosNoPref.push(rowalumnosnopref);



    } else {
      $.notify(
        {icon: 'notifications', message: "No hay datos para imprimir"}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
      });
    }
    this.loading = false;
  }

  //Exporta json a excel
  exportAsXLSX(opcion:number=1):void {
    let titulo = '';
    switch (opcion) {
      case 1:
        this.excelService.exportAsExcelFile(this.dataTableExcel, this.titulo_excel);
        break;
      case 2:
        titulo = `Alumnos_preferentes_porcentaje_periodo_${ this.ActualAnoEscolar }`;
        this.excelService.exportAsExcelFile(this.dataTableExcelCantAlumnos, titulo);
        break;
      case 3:
        titulo = `Alumnos_preferentes_nivel_periodo_${ this.ActualAnoEscolar }`;
        this.excelService.exportAsExcelFile(this.dataTableExcelPorcAlumnos, titulo);
        break;
        case 4:
        titulo = `Alumnos_nopreferentes_nivel_periodo_${ this.ActualAnoEscolar }`;
        this.excelService.exportAsExcelFile(this.dataTableExcelCantAlumnosNoPref, titulo);
        break;
        case 5:
        titulo = `Alumnos_nopreferentes_nivel_porcentaje_periodo_${ this.ActualAnoEscolar }`;
        this.excelService.exportAsExcelFile(this.dataTableExcelPorcAlumnosNoPref, this.titulo_excel);
        break;
      default:
        $.notify(
          {icon: 'notifications', message: 'Si usted llego aquí es que ha modificado las opciones permitidas ..'}, 
          {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
        );
    }
  }

  sl2_style() {
    $(".c-btn").css("box-shadow", "none");
    $(".c-btn").css("border-top", "none");
    $(".c-btn").css("border-left", "none");
    $(".c-btn").css("border-right", "none");
    $(".c-btn").css("color", "#989898");
    $(".c-btn").css("background-color", "#ededee");
    $(".sl2-material > div >  .dropdown-list").css("position", "absolute");
  }

}
