import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portal-padapo',
  templateUrl: './portal-padapo.component.html',
  styleUrls: ['./portal-padapo.component.scss']
})
export class PortalPadapoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
