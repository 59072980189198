/**
 * Creado por Angris Salazar
 * Servicio para proveer solicitudes a los distintos componentes hacia las APIs
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//para rango notas y simce
import { urlServer } from '../url.service';
//para sidebar
import 'rxjs/add/operator/toPromise';

// para Dashboard
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class HttpPuenteService {

  private jsonData = new Subject<any>(); //Para Dashboard
  constructor(private http: HttpClient) {}

  getData(recurso) : Promise<any> {
    let url: string = `${recurso}`;
    //console.log('getdata',recurso);
    return this.http.get(url).toPromise();
  }
  //tomado para rango notas y simce
  listadoGradosAsignaturasColegios(recurso,json_param:string): Observable<any>{
    //console.log('listado',recurso,json_param);
    let url:string= urlServer;
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this.http.post(url+recurso, json_param, {headers: headers});
}

// tomado para indicadores academicos
  getDataindicadoresacademicos(recurso,params:any) : Promise<any> {
    let url: string = `${recurso}`;
    return this.http.get(url,{params: params}).toPromise();
  }
  getDataPost(recurso, params) : Promise<any> {
    
    let url: string = `${recurso}`;
    return this.http.post(url, params).toPromise();
  }
  // tomado para sidebar 
  getDataSelect(recurso,token) : Promise<any> {
    //console.log('dataselect',recurso);
    let url: string = `${recurso}`;
    return this.http.get(url).toPromise();
  }

  // tomado para dashboard y cambiando nombre de funciones para diferenciar con los awaits
  async getDataasync(recurso,params:any) : Promise<any> {
   // console.log('dataasync',recurso);
    let url: string = `${recurso}`;
    const response  = await this.http.get(url,{params: params}).toPromise();
    return response;
  }

  async getDataPostasync(recurso,params:any) : Promise<any> {
    //console.log('postasync',recurso);
    let url: string = `${recurso}`;
    const response  = await this.http.post(url, params).toPromise();
    return response;
  }

  setRs(data: any[], tipo:string) {
   // console.log('setrs');
    let variables = [];
    variables.push(tipo);
    variables.push(data);
    this.jsonData.next(variables);
  }

  getRs(): Observable<any> {
   // console.log('getrs');
    return this.jsonData.asObservable();
  }

  /***
   * Para perfiles de colegio y personalizado obtener el id del sostenedor para los indicadores 
   */
  idsostParent(value){
    let id_sost=null;
    let arrayidparent = JSON.parse(localStorage.getItem('ListParentCole'));
    Object.keys(arrayidparent).forEach(keyparent => {      
      if(arrayidparent[keyparent].id_col==value){
        id_sost=arrayidparent[keyparent].id_sost;
      }  
    })
    return id_sost;
  }

  formatName(name){
    let newName='';
    if(name.length>20){
      let pieces=name.split(' ');
      newName=pieces[0]+' '+pieces[2];     
    }
    else{
      newName=name;
    }
    return newName;

  }
 
}
