import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Sanitizer } from '@angular/core';


@Component({
  selector: 'app-imprimir',
  template: `
        <div class="container" >
          <div class="main-content">
            <div class="container-fluid">
              <div class="row" [innerHTML]="html_content">
                
              </div>  
            </div>
          </div>
        </div>
          `,
          styleUrls: ['./imprimir.component.scss']

})
export class ImprimirComponent implements OnInit {

  html_content: any;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.html_content = this.sanitizer.bypassSecurityTrustHtml(localStorage.getItem("print"));
    localStorage.removeItem("print");

    window.print();
  }

}
