import { Subscription } from 'rxjs/Subscription';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { PuenteService } from '../../services/puente.service';
import { urlServer } from '../../services/url.service';
import { HttpPuenteService } from '../../services/shared/httppuente.service';
import { DatatablesService } from '../../services/shared/datatables.service';
import { ListaAgnosService } from '../../services/shared/listaagnos.service';
import { ExcelService } from '../../services/excel.service';
declare const $: any;

@Component({
  selector: 'app-libro-clases',
  templateUrl: './libro-clases.component.html',
  styleUrls: ['./libro-clases.component.scss']
})
export class LibroClasesComponent implements OnInit, OnDestroy {
  Puente: Subscription;
  ActualAnoEscolar: any;
  titulo_excel:string = '';
  public loading:boolean = true;
  public datos:boolean = false;
  public api_error:boolean = false;
  dataTable      = [];
  dataTableTotales = [];
  dataTableExcel = [];
  SlcAnoEscolar  = [];
  ListAnoEscolar = [];

  constructor(private puente: PuenteService, private IGservice: HttpPuenteService,private DataTables: DatatablesService, private Agnos: ListaAgnosService, private excelService:ExcelService) { 
    
    this.ActualAnoEscolar = this.Agnos.ActualAnoEscolar;  
    this.Puente = this.puente.getData().subscribe(data => {
      this.libro_clases();      
      });
  }
  ngOnDestroy(): void {
    this.Puente.unsubscribe();
  }
  ngOnInit() {   
    
    this.getlistAnosEscolares(this.ActualAnoEscolar);
  }
  public getlistAnosEscolares ( agno : number) {
    this.SlcAnoEscolar=this.Agnos.listAnosEscolares(agno);
    this.libro_clases();
  }

  libro_clases(){    
    
    let recurso='';
    let idSelect: string;
    let jsonData: any;
    let params: any;
    this.titulo_excel = '';
    this.dataTable = [];
    this.dataTableExcel = [];
    this.dataTableTotales = [];
    this.api_error = false;
    this.datos= false;
    this.loading = true;
    let sum_total_asignaturas:number=0;
            let sum_calificaciones:number=0;
            let sum_notas_ingresadas:number=0;
            let sum_total_atrasos:number=0;    
            let sum_total_anotaciones:number=0;  
            let sum_total_atrasos_dia:number=0; 

    if (localStorage.getItem('select_colegio')) {
      jsonData = JSON.parse(localStorage.getItem('select_colegio'));
      idSelect = jsonData.id
    } 
    else if (localStorage.getItem('select_sost')) {     
      jsonData = JSON.parse(localStorage.getItem('select_sost'));
      idSelect = jsonData.id
    } 
    else if (localStorage.getItem('select_slep')) {     
      jsonData = JSON.parse(localStorage.getItem('select_slep'));
      idSelect = jsonData.id
    }

    let libroclases_post = urlServer + '/libroclases';
    let tipo = "";
    if (localStorage.getItem('select_colegio')) {
      recurso = urlServer + '/colegioselector/' + idSelect;
      this.titulo_excel = 'Libro_clase_comuna';
      tipo = "2";
    } 
    else if (localStorage.getItem('select_sost')) {
      recurso = urlServer + '/rbd/sostenedor/' + idSelect; 
      this.titulo_excel = 'Libro_clase_comuna';
      tipo = "2";
    } 
    else if(localStorage.getItem('select_slep')) {
      recurso = urlServer + '/rbd/slep/' + idSelect;
      this.titulo_excel = 'Libro_clase_sostenedor';
      tipo = "1";      
    } 
    else {
      recurso = null
    }    
    
    if(recurso ){

      let arrCodColegios = [];
      let arrIdColegios = [];
        this.IGservice.getData(recurso).then((data) => {
        Object.keys(data.data).forEach(key => {
            arrIdColegios.push(key);
            arrCodColegios.push(data.data[key]);
        });
        let ListCodColegios = arrCodColegios.toString();
        params = {
            "grupo_colegios": ListCodColegios,
            "ano_escolar": this.ActualAnoEscolar,
            "tipo": tipo
        }
        this.IGservice.getDataPost(libroclases_post,params)
        .then((data) => {          
            let jsonData = data.data;
            let totalesLibroClases = [];
            
          Object.keys(jsonData).forEach(indice => {   
            let total_asignaturas:number;
            total_asignaturas=parseInt(jsonData[indice]["total_asignaturas"]);
            sum_total_asignaturas     = sum_total_asignaturas + total_asignaturas;
            sum_calificaciones = sum_calificaciones +parseInt(jsonData[indice]["calificaciones"]);
            sum_notas_ingresadas   = sum_notas_ingresadas + parseInt(jsonData[indice]["notas_ingresadas"]);
            sum_total_atrasos   = sum_total_atrasos + parseInt(jsonData[indice]["total_atrasos"]);  
            sum_total_anotaciones   = sum_total_anotaciones + parseInt(jsonData[indice]["total_anotaciones"]); 
            sum_total_atrasos_dia   = sum_total_atrasos_dia + parseInt(jsonData[indice]["total_atrasos_dia"]); 
            this.dataTable.push({
              agrupacion: jsonData[indice]["nom_colegio"],
              total_asignaturas:jsonData[indice]["total_asignaturas"],
              calificaciones:jsonData[indice]["calificaciones"],
              moda_calif_asignaturas:jsonData[indice]["moda_calc"],              
              notas_ingresadas:jsonData[indice]["notas_ingresadas"],
              total_atrasos:jsonData[indice]["total_atrasos"],
              total_anotaciones:jsonData[indice]["total_anotaciones"],
              porcentaje_asistencia:jsonData[indice]["total_cursos_asistencia"]+'%',
              total_atrasos_dia:jsonData[indice]["total_atrasos_dia"]
            });
            this.dataTableExcel.push({
              agrupacion: jsonData[indice]["nom_colegio"],
              total_asignaturas:jsonData[indice]["total_asignaturas"],
              calificaciones:jsonData[indice]["calificaciones"],
              moda_calif_asignaturas:jsonData[indice]["moda_calc"],              
              notas_ingresadas:jsonData[indice]["notas_ingresadas"],
              total_atrasos:jsonData[indice]["total_atrasos"],
              total_anotaciones:jsonData[indice]["total_anotaciones"],
              porcentaje_asistencia:jsonData[indice]["total_cursos_asistencia"]+'%',
              total_atrasos_dia:jsonData[indice]["total_atrasos_dia"]
            });
            
          });
          let total={
            agrupacion:'Total',
            total_asignaturas:sum_total_asignaturas,
            calificaciones:sum_calificaciones,
            moda_calif_asignaturas:'-',              
            notas_ingresadas:sum_notas_ingresadas,
            total_atrasos:sum_total_atrasos,
            total_anotaciones:sum_total_anotaciones,
            porcentaje_asistencia:'-',
            total_atrasos_dia:sum_total_atrasos_dia
          };
          
          
          if(this.dataTable.length==0){  
            this.loading = false;          
            $.notify(
              { icon: 'notifications', message: "No hay datos para imprimir" }, 
              { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
            );            
          }
          else{
            this.dataTableExcel.push(total);
            this.dataTableTotales.push(total);
            this.loading = false;
            this.datos = true;
          }
        })
        .catch((err) => {
          this.loading = false; 
          $.notify(
            { icon: 'notifications', message: "No hay datos para imprimir" }, 
            { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
          );
        });
    })
    .catch((err) => {
      this.loading = false; 
      $.notify(
        {icon: 'notifications', message: err.message}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
      );
    });
    }
    else{
      $.notify(
        {icon: 'notifications', message: 'Revise selección en el menú por favor.'}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
      );
    }
  }
  //Exporta json a excel
  exportAsXLSX(opcion:number=1):void {
    let titulo = '';
    this.excelService.exportAsExcelFile(this.dataTableExcel, this.titulo_excel);    
  }
}
