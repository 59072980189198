//AS
import { ListaAgnosService } from './services/shared/listaagnos.service';
import { DatatablesService } from './services/shared/datatables.service';
import { HttpPuenteService } from './services/shared/httppuente.service';
import { GraficasService } from './services/shared/graficas.service';
//import { SidebarService } from './services/sidebar.service';
//import { DashboardService } from './services/dashboard.service';
//import { AsistenciaService } from './services/asistencia.service';
//import { RangoNotasService } from './services/rango-notas.service';
//import { EvolucionMatriculaService } from './services/evolucion-matricula.service';
//import { CapacidadUsoService } from './services/capacidad-uso.service';
//import { IndicadoresGestionService } from './services/indicadores-gestion.service';
//import { IndicadoresAcademicosService } from './services/indicadores-academicos.service';
//import { AlumnosRetiradosService } from './services/alumnos-retirados.service';
//import { MantenedorUsuarioService } from './services/mantenedor-usuario.service';
//AS

import { DashboardServiceSnd } from './services/dashboardsnd.service';

import { PromedioAsignaturaService } from './services/promedio-asignatura.service';

import { ApiEvolucionMatriculaService } from './services/sostenedor/evolucion-matricula.service';

import { NotificationService } from './services/notification.service';
import { PuenteService } from './services/puente.service';

import { AuthService } from './services/auth.service';
import { ExcelService } from './services/excel.service';
import { PrincipalComponent } from './system/principal/principal.component';
import { SystemModule } from './system/system.module';
import { LoginComponent } from './login/login.component';
import { NgModule, } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { APP_BASE_HREF } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { routing, appRoutingProviders, } from './app.routing';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule
} from '@angular/material';


import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { SidebarComponent } from './system/sidebar/sidebar.component';
import { NavbarModule } from './system/shared/navbar/navbar.module';
import { FooterModule } from './system/shared/footer/footer.module';
import { FixedpluginModule } from './system/shared/fixedplugin/fixedplugin.module';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { SpinnerModule } from './system/shared/spinner/spinner.module';

import { ValidationFormsComponent } from './validationforms/validationforms.component';
import { FieldErrorDisplayComponent } from './validationforms/field-error-display/field-error-display.component';

import { CookieService } from 'ngx-cookie-service';
import { ErrorPageComponent } from './error-page/error-page.component';
import { HttpServiceProvider } from './services/http.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PanelComponent } from './panel/panel.component';

import { ImprimirComponent } from './imprimir/imprimir.component';





@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule
  ],
  declarations: [ErrorPageComponent, ImprimirComponent]
})
export class MaterialModule { }


@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    MaterialModule,
    MatNativeDateModule,
    SystemModule,
    RouterModule,
    routing,   
    NavbarModule,
    FooterModule,
    FixedpluginModule,
    AngularMultiSelectModule,
    SpinnerModule

  ],
  declarations: [
    AppComponent,
    PrincipalComponent,
    LoginComponent,
    SidebarComponent,
    ValidationFormsComponent,
    FieldErrorDisplayComponent, 
    PanelComponent
  ],
  providers: [
    appRoutingProviders,
    AuthService,    
    CookieService,
    PuenteService,    
    ApiEvolucionMatriculaService,    
    NotificationService,    
    PromedioAsignaturaService,        
    DashboardServiceSnd,
    ExcelService,
    //AS
    ListaAgnosService,
    DatatablesService,
    HttpPuenteService,
    GraficasService,
    //SidebarService,
    //DashboardService,
    //AsistenciaService,
    // IndicadoresGestionService,
    //EvolucionMatriculaService,
    //AlumnosRetiradosService,
    //RangoNotasService,
    //MantenedorUsuarioService,
    //IndicadoresAcademicosService,
    //CapacidadUsoService,
    //AS
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpServiceProvider,
      multi: true
    }
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }

