import { MantenedorUsuarioComponent } from './mantenedor-usuario/mantenedor-usuario.component';
import { PrincipalComponent } from './principal/principal.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardsndComponent } from './dashboardsnd/dashboardsnd.component';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { IndicadoresAcademicosComponent } from './indicadores-academicos/indicadores-academicos.component';
import { PromedioAsignaturaComponent } from './indicadores-academicos/promedio-asignatura.component';
import { RangoNotasComponent } from './indicadores-academicos/rango-notas.component';
//AS
import { ResultadoSimceComponent } from './indicadores-academicos/resultado-simce.component';
//AS

import { IndicadoresGestionComponent } from './indicadores-gestion/indicadores-gestion.component';
import { EvolucionMatriculaComponent } from './indicadores-gestion/evolucion-matricula.component';
import { CapacidadUsoComponent } from './indicadores-gestion/capacidad-uso.component';
import { AsistenciaComponent } from './indicadores-gestion/asistencia.component';
import { AlumnosRetiradosComponent} from './indicadores-gestion/alumnos-retirados.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';

// this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil')))); 
// this.redirecPerfil = JSON.parse(atob(localStorage.getItem(btoa('redirecPerfil'))));

// AS
import { AlumnosPrioritariosComponent } from './indicadores-gestion/alumnos-prioritarios.component';
import { AlumnosPreferentesComponent } from './indicadores-gestion/alumnos-preferentes.component';
import { IndicadoresUsoComponent } from './indicadores-uso/indicadores-uso.component';
import { AdministracionComponent } from './indicadores-uso/administracion.component';
import { LibroClasesComponent } from './indicadores-uso/libro-clases.component';
import { PlanificacionComponent } from './indicadores-uso/planificacion.component';
import { BibliotecaComponent } from './indicadores-uso/biblioteca.component';
import { RecaudacionComponent } from './indicadores-uso/recaudacion.component';
import { VisitasSistemaComponent } from './indicadores-uso/visitas-sistema.component';
import { PortalPadapoComponent } from './indicadores-uso/portal-padapo.component';
import { PromedioPeriodoComponent } from './indicadores-academicos/promedio-periodo.component';
// AS

const SystemRoutes: Routes = [
  {
    path: 'system',
    component: PrincipalComponent,
    children: [
      { path: '', redirectTo: 'principal', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'dashboardsnd', component: DashboardsndComponent },

      { path: 'indicadores-academicos', component: IndicadoresAcademicosComponent },
      { path: 'indicadores-academicos/promedio-asignatura', component: PromedioPeriodoComponent },
      //{ path: 'indicadores-academicos/promedio-periodo', component: PromedioPeriodoComponent },
      { path: 'indicadores-academicos/rango-notas', component: RangoNotasComponent },
      { path: 'indicadores-academicos/resultado-simce', component: ResultadoSimceComponent },
      { path: 'indicadores-gestion', component: IndicadoresGestionComponent },
      { path: 'indicadores-gestion/evolucion-matricula', component: EvolucionMatriculaComponent },
      { path: 'indicadores-gestion/asistencia', component: AsistenciaComponent },
      { path: 'indicadores-gestion/alumnos-retirados', component: AlumnosRetiradosComponent },
      { path: 'indicadores-gestion/capacidad-uso', component: CapacidadUsoComponent },
// AS
      { path: 'indicadores-gestion/alumnos-prioritarios', component: AlumnosPrioritariosComponent },
      { path: 'indicadores-gestion/alumnos-preferentes', component: AlumnosPreferentesComponent },
      { path: 'indicadores-uso', component: IndicadoresUsoComponent },
      { path: 'indicadores-uso/administracion', component: AdministracionComponent },
      { path: 'indicadores-uso/biblioteca', component: BibliotecaComponent },
      { path: 'indicadores-uso/libro-clases', component: LibroClasesComponent },
      { path: 'indicadores-uso/planificacion', component: PlanificacionComponent },
      { path: 'indicadores-uso/portal-padapo', component: PortalPadapoComponent },
      { path: 'indicadores-uso/recaudacion', component: RecaudacionComponent },
// AS      
      { path: 'notificaciones', component: NotificacionesComponent },
      { path: 'mantenedor-usuario', component: MantenedorUsuarioComponent },

      { path: '**', component: DashboardComponent}
    ]
  }
];
@NgModule({
  imports:[RouterModule.forChild(SystemRoutes)],
  exports:[RouterModule]
})

export class SystemRoutingModule {}

