import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
//AS
import { HttpPuenteService } from './../../services/shared/httppuente.service';
import { CookieService } from 'ngx-cookie-service';
// import { SidebarService } from './../../services/sidebar.service';
//AS
import { FormBuilder, Validators, FormGroup, AbstractControl, ValidationErrors } from '@angular/forms';
import { urlServer } from './../../services/url.service';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-mantenedor-usuario',
  templateUrl: './mantenedor-usuario.component.html',
  styleUrls: ['./mantenedor-usuario.component.scss']
})
export class MantenedorUsuarioComponent implements OnInit {
  userRegistered = {id: null, run: '', nombre: '', apellido_paterno: '', apellido_materno: '', sexo: '', correo: ''};
  settings = {'dep': {}, 'slep': {}, 'sostenedor': {}, 'colegio': {}};
  profiles = { 'dep': [], 'slep': [], 'sostenedor': [], 'colegio': []};
  profilesSelected = { 'dep': [], 'slep': [], 'sostenedor': [], 'colegio': [], 'colegiopersonalizado': [], 'nivel': [], 'asignatura': []};
  parentProfileSelected = null;
  userProfileLogged = '';
  userIdProfileLogged = '';
  addProfileFormWithErr: Boolean = false;
  runValid: Boolean = false;
  submitAttempt: Boolean = false;
  profileDisable: Boolean = true;
  userDisable: Boolean = true;
  registerForm: FormGroup;
  userProfiles: Array<any>;
  userProfilesShow= [];
  userAccounts: Array<any>;
  userAccountSelected: Array<any>;
  mdPerfil:any;

  // AS
  settingsPerfil= {};
  settingsCuenta= {};
  ListSlep = [];    
  ListSost = [];
  ListCole = [];
  //ListColePersonalizado = [];
  ListNiveles= [];
  ListNivelColegio= [];
  ListAsignaturas= [];
  ListAsignaturaNivelColegio= [];
  token: any
  public urlR_tmp: any;

  // AS constructor(private sdServ: SidebarService, private auth: AuthService, private formBuilder: FormBuilder, ) {
    constructor(private sdServ: HttpPuenteService,private cookieService: CookieService, private auth: AuthService, private formBuilder: FormBuilder) {
    this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));
    this.getPerfilInfo({}, 'slep');
    this.getUsuario();
    this.userAccounts = [];
    this.userProfiles = [];
    this.userProfileLogged = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));
    this.userIdProfileLogged = localStorage.getItem(btoa('id_usuario'));
    this.findProfileByUserLogged();
    this.cleanUserProfile();
  }

  ngOnInit() {
   // console.log(localStorage);
    this.setUpSettings(true);
    this.sl2_style();
  }

  /*
  * Settings de los "angular2-multiselect"
  * @params: enable -> Boolean -> Si se activa o no el selector en la vista.
  * return none
  */
  setUpSettings(enable) {


    try {
   
     // this.nom_user = atob(localStorage.getItem(btoa('nombre')));
      this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));
      const token = this.cookieService.get(btoa('token'));
      //console.log('perfilmantenedor',this.mdPerfil);
      //Analisamos el perfil
      switch (this.mdPerfil.nivel) {
        case 0:
                    
                    this.urlR_tmp = urlServer + "/" + this.mdPerfil.tipo_nivel + "/" + this.mdPerfil.codigo
                    this.sdServ.getDataSelect(this.urlR_tmp, this.token)
                        .then((data) => {
                            this.ListSlep = [];
                            Object.keys(data.data).forEach(key => {
                                let nombre = this.addslashes(data.data[key].nombre);
                                let option = '{ "id": ' + data.data[key].id + ', "itemName": "' + nombre + '" }'
                                this.ListSlep.push(JSON.parse(option))
                            });
                            localStorage.setItem('ListSlep', JSON.stringify(this.ListSlep))
                            setTimeout(() => {
                                this.sl2_style();
                            }, 1000);                            
                            
                        })
                        .catch((err) => {
                            $.notify(
                                { icon: 'notifications', message: err.message}, 
                                { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'} }
                            );
                        });

                    break;

                case 1:
                    
                    this.urlR_tmp = urlServer + "/" + this.mdPerfil.tipo_nivel + "/" + this.mdPerfil.codigo 
                    this.sdServ.getDataSelect(this.urlR_tmp, this.token)
                        .then((data) => {
                            //console.log('data',data);
                            //this.nombre_slep = data.nom_slep;
                            this.ListSlep = [];
                            let nombre = this.addslashes(data.nom_slep);
                            let option = '{ "id": ' + data.id + ', "itemName": "' + nombre + '" }'
                            this.ListSlep.push(JSON.parse(option))
                            
                            localStorage.setItem('ListSlep', JSON.stringify(this.ListSlep))
                            localStorage.setItem('select_slep', JSON.stringify(JSON.parse(option)))
                            
                            setTimeout(() => {
                                this.sl2_style();
                            }, 1000);                            

                        })
                        .catch((err) => {
                            $.notify(
                                { icon: 'notifications', message: err.message }, 
                                { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
                            );
                        });
                    
                    this.urlR_tmp = urlServer + "/" + this.mdPerfil.tipo_nivel + "/" + this.mdPerfil.codigo + "/tree"
                    
                    this.sdServ.getDataSelect(this.urlR_tmp, this.token)
                        .then((data) => {
                            //console.log('url',this.urlR_tmp,data);
                            this.ListSost = [];
                            
                            Object.keys(data.data).forEach(key => {
                                   this.ListCole = [];
                                  // this.ListColePersonalizado = [];
                                   Object.keys(data.data[key].sostenedores).forEach(idSos => {                                    
                                        let nombre = this.addslashes(data.data[key].sostenedores[idSos].nom_sostenedor);
                                        let option = '{ "id": ' + idSos + ', "itemName": "' + nombre + '" }'
                                        this.ListSost.push(JSON.parse(option))
                                        // AS generar listado de colegios                                     
                                        Object.keys(data.data[key].sostenedores[idSos].colegios).forEach(keycolegio => {
                                            let nombre = this.addslashes(data.data[key].sostenedores[idSos].colegios[keycolegio].nom_colegio);
                                            let option = '{ "id": ' + data.data[key].sostenedores[idSos].colegios[keycolegio].id + ', "itemName": "' + nombre + '" }'
                                                this.ListCole.push(JSON.parse(option))
                                                //this.ListColePersonalizado.push(JSON.parse(option))
                                        });
                                        localStorage.setItem('ListCole', JSON.stringify(this.ListCole));
                                   });
                            });
                            localStorage.setItem('ListSost', JSON.stringify(this.ListSost))
                            setTimeout(() => {
                                this.sl2_style();
                            }, 1000);
                        })
                        .catch((err) => {
                            $.notify(
                                { icon: 'notifications', message: err.message }, 
                                { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
                            );
                        });                    
                    break;

                case 2:
                    let Urlsostenedorusuario = urlServer + "/sostenedores/" + localStorage.getItem(btoa('id_usuario')) + "/tree";
                    this.sdServ.getDataSelect(Urlsostenedorusuario, this.token).then((data) => {
                        this.ListSost = [];          
                            this.ListCole = []; 
                            Object.keys(data.sostenedores).forEach(idSos => {           
                                 let nombre = this.addslashes(data.sostenedores[idSos].nom_sostenedor);
                                 let option = '{ "id": ' + data.sostenedores[idSos].id_sostenedor + ', "itemName": "' + nombre + '" }'
                                 this.ListSost.push(JSON.parse(option))  
                            });
                            localStorage.setItem('ListSost', JSON.stringify(this.ListSost));
                            Object.keys(data.colegios).forEach(keycolegio => {
                              let nombre = this.addslashes(data.colegios[keycolegio].colegio);
                              let option = '{ "id": ' + data.colegios[keycolegio].id_colegio + ', "itemName": "' + nombre + '" }'
                              this.ListCole.push(JSON.parse(option))
                          });
                          localStorage.setItem('ListCole', JSON.stringify(this.ListCole));
                        setTimeout(() => {
                            this.sl2_style();
                        }, 1000);
                    })
                    .catch((err) => {
                        $.notify(
                            { icon: 'notifications', message: err.message }, 
                            { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
                        );
                    });                    
                  break;

                case 3:
                    
                  let Urlcolegiosusuario = urlServer + "/colegiosperfiles/" + localStorage.getItem(btoa('id_usuario')) + "/tree";
                  this.sdServ.getDataSelect(Urlcolegiosusuario, this.token)
                      .then((data) => {
                          
                          this.ListCole = [];
                          Object.keys(data.colegios).forEach(keycolegio => {
                              let nombre = this.addslashes(data.colegios[keycolegio].nom_colegio);
                              let option = '{ "id": ' + data.colegios[keycolegio].id + ', "itemName": "' + nombre + '" }'
                              this.ListCole.push(JSON.parse(option))
                          });
                          localStorage.setItem('ListCole', JSON.stringify(this.ListCole));                         
                          
                          setTimeout(() => {
                              this.sl2_style();
                          }, 1000);
                          
                      })
                      .catch((err) => {
                          $.notify(
                              { icon: 'notifications', message: err.message }, 
                              { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
                          );
                      });
                    break;
                    case 5: // AS Para perfil personalizado                        
                        let Urlcolegiosusuariopersonalizado = urlServer + "/colegiosperfiles/" + localStorage.getItem(btoa('id_usuario')) + "/tree";
                        this.sdServ.getDataSelect(Urlcolegiosusuariopersonalizado, this.token)
                        .then((data) => {
                            this.ListCole = [];
                            Object.keys(data.colegios).forEach(keycolegio => {
                                let nombre = this.addslashes(data.colegios[keycolegio].nom_colegio);
                                let option = '{ "id": ' + data.colegios[keycolegio].id + ', "itemName": "' + nombre + '" }'
                                this.ListCole.push(JSON.parse(option))
                            });
                            localStorage.setItem('ListCole', JSON.stringify(this.ListCole));                         
                            //console.log(this.ListCole);
                           // console.log(localStorage.getItem('ListCole'));
                            setTimeout(() => {
                                this.sl2_style();
                            }, 1000);
                            
                        })
                        .catch((err) => {
                        
                            $.notify(
                                { icon: 'notifications', message: err.message }, 
                                { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
                            );
                        });
                    break;
                      }

                      if (JSON.parse(localStorage.getItem('ListSlep'))) {
                        this.ListSlep = JSON.parse(localStorage.getItem('ListSlep'));
                    }
        
                    if (JSON.parse(localStorage.getItem('ListSost'))) {
                        this.ListSost = JSON.parse(localStorage.getItem('ListSost'));
                    }
        
                    if (JSON.parse(localStorage.getItem('ListCole'))) {
                        this.ListCole = JSON.parse(localStorage.getItem('ListCole'));
                    }

    this.settings['slep'] = {
      singleSelection: true,
      text: 'Seleccionar slep',
      selectAllText: 'Marcar todo',
      unSelectAllText: 'Desmarcar todo',
      searchPlaceholderText: 'Buscar',
      enableSearchFilter: true,
      badgeShowLimit: 3,
      classes: 'sl2-material',
      disabled: enable
    };
    this.settingsPerfil = {
      singleSelection: false,
      text: 'Seleccionar',
      selectAllText: 'Marcar todo',
      unSelectAllText: 'Desmarcar todo',
      searchPlaceholderText: 'Buscar',
      enableSearchFilter: true,
      badgeShowLimit: 3,
      classes: 'sl2-material',
      disabled: enable
    };
    this.settings['colegio'] = {
      singleSelection: true,
      text: 'Seleccionar colegio',
      selectAllText: 'Marcar todo',
      unSelectAllText: 'Desmarcar todo',
      searchPlaceholderText: 'Buscar',
      enableSearchFilter: true,
      badgeShowLimit: 3,
      classes: 'sl2-material',
      disabled: enable
    };
    this.settingsCuenta = {
      singleSelection: true,
      text: 'Seleccionar',
      selectAllText: 'Marcar todo',
      unSelectAllText: 'Desmarcar todo',
      searchPlaceholderText: 'Buscar',
      enableSearchFilter: true,
      badgeShowLimit: 3,
      classes: 'sl2-material',
      disabled: enable
    };


      } catch (error) {
        localStorage.setItem("code_error","501");
        localStorage.setItem("code_mensaje","Datos no coinciden con el sistema, por favor inicie nuevamente");        
    }




  }

  /*
  * Estilos de los "angular2-multiselect"
  * @params: none
  * return none
  */
  sl2_style() {
    $( '.c-btn' ).css('box-shadow', 'none');
    $( '.c-btn' ).css('border-top', 'none');
    $( '.c-btn' ).css('border-left', 'none');
    $( '.c-btn' ).css('border-right', 'none');
    $( '.c-btn' ).css('color', '#989898');
    $( '.dropdown-list' ).css('position', 'relative');
  }

  activarPerfiles(item: any){
    //console.log('itemcuenta',item);
    this.profileDisable = false;
  }

  /*
  * Búsca en el api la info del perfil seleccionado
  * @param: item -> any -> Perfil seleccionado
  * @param: tipo -> any -> Tipo de perfil seleccionado
  * return none
  */
  getPerfilInfo(item: any, tipo: any) {
    let url = '';
    let params: any;
    let jsonData: any;
    let niveles = [];
    let arrIdColegios = [];
    let arrIdNiveles = [];   
    
    switch (tipo) {
      case 'slep':
        url = urlServer + '/dep/' + this.mdPerfil.codigo ;
        break;
      case 'sostenedor':
        url = urlServer + '/slep/' + item.id + '/tree';
        break;
      case 'colegio':
        url = urlServer + '/sostenedor/' + item.id + '/tree';
        break;
      case 'director':
        url = urlServer + '/colegio/' + item.id;
        break;
        // AS
        case 'niveles':
          this.ListNiveles= [];
          this.ListNivelColegio= [];
          
          Object.keys(this.profilesSelected['colegiopersonalizado']).forEach(key => {            
            arrIdColegios.push(this.profilesSelected['colegiopersonalizado'][key].id);
          });
          let ListIdColegios  = arrIdColegios.toString();
          
          url = urlServer + '/colegios/niveles-colegios';
          params = {
            "grupo_colegios": ListIdColegios,
            "ano_escolar":  (new Date).getFullYear() //2014,//
        }
        
        this.sdServ.getDataPost(url,params)
        .then((result) => {
          if( result.success ) {
            jsonData = result.data;
            //console.log(url,params,'personalizadoniveles',niveles)
           // this.ListNiveles = []; 
                            Object.keys(jsonData).forEach(Nivel => {           
                                 let nombre = this.addslashes(jsonData[Nivel].grado);
                                 let option = '{ "id": ' + jsonData[Nivel].id + ', "itemName": "' + nombre + '" }';
                                 // Cargar vector con los niveles y su respectivo colegio para generar registro y borrado correcto
                                 //let enlacecolegio= '{ "id": ' + jsonData[Nivel].id + ', "id_colegio": "' + jsonData[Nivel].id_colegio + '" }';
                                 this.ListNivelColegio.push({'id':jsonData[Nivel].id,'colegio':jsonData[Nivel].id_colegio});                                
                                 arrIdNiveles.push(jsonData[Nivel].id);
                                 this.ListNiveles.push(JSON.parse(option));   
                            });
                            //Asignaturas
                            this.ListAsignaturas= [];
                            this.ListAsignaturaNivelColegio= [];                                                    
                            let ListIdNiveles  = arrIdNiveles.toString();                            
                            url = urlServer + '/colegios/asignaturas-niveles';
                            params = {
                              "grupo_niveles": ListIdNiveles,
                              "ano_escolar": (new Date).getFullYear() //2014//
                          }
                          
                          this.sdServ.getDataPost(url,params)
                          .then((result) => {
                            if( result.success ) {
                              jsonData = result.data;
                              
                                              Object.keys(jsonData).forEach(Asignatura => {           
                                                  let nombre = this.addslashes(jsonData[Asignatura].nombre);
                                                  let option = '{ "id": ' + jsonData[Asignatura].id + ', "itemName": "' + nombre + '" }';
                                                  // Cargar vector con los niveles y su respectivo colegio para generar registro y borrado correcto
                                                  //let enlaceasignaturanivelcolegio= '{ "id": ' + jsonData[Asignatura].id + ', "id_colegio": "' + jsonData[Asignatura].id_colegio + '", "id_nivel": "' + jsonData[Asignatura].id_grado + '" }';
                                                  this.ListAsignaturaNivelColegio.push({'id':jsonData[Asignatura].id,'colegio':jsonData[Asignatura].id_colegio,'nivel':jsonData[Asignatura].id_grado}) ;
                                                  this.ListAsignaturas.push(JSON.parse(option));  
                                              });
                            } 
                          })
                          .catch((err) => {
                            $.notify(
                              { icon: 'notifications', message: err.message }, 
                              { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
                            );
                          });
                  //
                            
          } 
        })
        .catch((err) => {
          $.notify(
            { icon: 'notifications', message: err.message }, 
            { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
          );
        });

       
        break;

        case 'asignaturas':
          this.ListAsignaturas= [];
          this.ListAsignaturaNivelColegio= [];
          Object.keys(this.profilesSelected['nivel']).forEach(key => {            
            arrIdNiveles.push(this.profilesSelected['nivel'][key].id);
          });
          let ListIdNiveles  = arrIdNiveles.toString();
          
          url = urlServer + '/colegios/asignaturas-niveles';
          params = {
            "grupo_niveles": ListIdNiveles,
            "ano_escolar": (new Date).getFullYear()
        }
        
        this.sdServ.getDataPost(url,params)
        .then((result) => {
          if( result.success ) {
            jsonData = result.data;
            
                            Object.keys(jsonData).forEach(Asignatura => {           
                                 let nombre = this.addslashes(jsonData[Asignatura].nombre);
                                 let option = '{ "id": ' + jsonData[Asignatura].id + ', "itemName": "' + nombre + '" }';
                                 // Cargar vector con los niveles y su respectivo colegio para generar registro y borrado correcto
                                 //let enlaceasignaturanivelcolegio= '{ "id": ' + jsonData[Asignatura].id + ', "id_colegio": "' + jsonData[Asignatura].id_colegio + '", "id_nivel": "' + jsonData[Asignatura].id_grado + '" }';
                                 this.ListAsignaturaNivelColegio.push({'id':jsonData[Asignatura].id,'colegio':jsonData[Asignatura].id_colegio,'nivel':jsonData[Asignatura].id_grado}) ;
                                 this.ListAsignaturas.push(JSON.parse(option));  
                            });
          } 
        })
        .catch((err) => {
          $.notify(
            { icon: 'notifications', message: err.message }, 
            { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
          );
        });

       
        break;
        // AS
      }

    /*  this.sdServ.getDataSelect(url, '').then((res) => {
        let data = null;
        this.profiles[tipo] = [];

        switch (tipo) {
          case 'slep':
            data = res.data;
            break;
          case 'sostenedor':
            data = res.data['slep']['sostenedores'];
            break;
          case 'colegio':
            data = res.data['sostenedor']['colegios'];
            break;
          case 'director':
            data = res;
            //this.profiles['colegio'].push({id: data.id, itemName: data.nom_colegio});
            break;
        };

        this.cleanProfile(tipo);

        Object.keys(data).forEach(key => {
          const element = data[key];
          switch (tipo) {
            case 'slep':
              this.profiles[tipo].push({id: element.id, itemName: element.nombre});
              break;
            case 'sostenedor':
              this.profiles[tipo].push({id: element.id, itemName: element.nom_sostenedor});
              break;
            case 'colegio':
              this.profiles[tipo].push({id: element.id, itemName: element.nom_colegio});
              break;
          };
        });
      })
      .catch((err) => {
      });*/
  }

  /*
  * Limpia el arreglo de perfiles dependiendo del tipo
  * @param: item -> any -> Perfil seleccionado
  * return none
  */
  cleanProfile() {
    let tipo=this.parentProfileSelected;
    switch (tipo) {
      case 'slep':
      case 'sostenedor':
        this.profilesSelected[tipo] = [];
        break;
      case 'colegio':
        this.profilesSelected[tipo] = [];
        break;
        case 'personalizado':
          this.profilesSelected['colegiopersonalizado'] = [];
          this.profilesSelected['asignatura']=[];
          this.profilesSelected['nivel']=[];
        break;
        default:
          this.profilesSelected['sostenedor'] = [];
          this.profilesSelected['colegio'] = [];
          this.profilesSelected['colegiopersonalizado'] = [];
          this.profilesSelected['asignatura']=[];
          this.profilesSelected['nivel']=[];


    };
  }

 /**
  * Permite organizar las etiqutetas para niveles y asignaturas para el guardado  
  * @param nivelesasignaturas 
  * @param asignaturasnivelescolegios 
  * @param tipo 
  */
  colegiosnivelesasignaturas(nivelesasignaturas,asignaturasnivelescolegios,tipo){
    let nivelesasociados=[]
    
      Object.keys(nivelesasignaturas).forEach(id => {
      Object.keys(asignaturasnivelescolegios).forEach(nivelcolegio => {
        //console.log('comparar',niveles[nivel].id,nivelescolegios[nivelcolegio].nivel)
        if(nivelesasignaturas[id]==asignaturasnivelescolegios[nivelcolegio].id){
         // niveles[nivel]=nivel
            if(tipo=='nivel'){
               nivelesasociados.push(tipo+'-'+asignaturasnivelescolegios[nivelcolegio].colegio)
            }
            else if(tipo=='asignatura'){
              nivelesasociados.push(tipo+'-'+asignaturasnivelescolegios[nivelcolegio].colegio+'-'+asignaturasnivelescolegios[nivelcolegio].nivel)
            }        
         // console.log('igual',niveles[nivel].id,nivelescolegios[nivelcolegio].nivel)
        }
      });
 
    });
  return nivelesasociados;
  }

  /*
  * Crea el perfil de la persona
  * @param: none
  * return none
  */
  addProfile() {
    const tipo = this.parentProfileSelected;
    let id_perfil = null; // Default DEP
    let params = {};
    let codigo_nivel = [];
    let codigo_nivel_colegio = [];
    let codigo_nivel_nivel = [];
    let codigo_nivel_asignatura = [];
    let jsonData: any;
    let idSelect: string;

    this.addProfileFormWithErr = false;
    // AS se debe validar de acuerdo al perfil activo los prerrequisitos 
    // para el perfil a guardar
    if (!this.userAccountSelected || !this.userAccountSelected.length) {
      this.formErrMessage('Debe seleccionar una cuenta');
    } 
    else {
      if (tipo) {
        switch (tipo) {
          case 'dep':
            id_perfil = 1;
            codigo_nivel = [];
          break;
          case 'slep':
           // if (!this.profilesSelected['slep'].length) {
              if (localStorage.getItem('select_slep')) {
                jsonData = JSON.parse(localStorage.getItem('select_slep'));
                idSelect = jsonData.id
                id_perfil = 2;              
                codigo_nivel = [idSelect];
              } 
              else {
              this.formErrMessage('Debe seleccionar un slep');
            }
          break;
          case 'sostenedor':
            // AS validar que se ha seleccionado el radio y una opcion del listado de comunas
           /* if (!this.profilesSelected['slep'].length) {
              this.formErrMessage('Debe seleccionar un slep');
            } else*/ if (!this.profilesSelected['sostenedor'].length) {
              this.formErrMessage('Debe seleccionar al menos una comuna');
            } else {
              id_perfil = 3;
              codigo_nivel = this.perfilesArrayIds(this.profilesSelected['sostenedor']);
              //console.log('bef',codigo_nivel);
            }
          break;
          case 'colegio':
           /* if (!this.profilesSelected['slep'].length) {
              this.formErrMessage('Debe seleccionar un slep');
            } else if (!this.profilesSelected['sostenedor'].length) {
              this.formErrMessage('Debe seleccionar un sostenedor');
            } else*/ 
            if (!this.profilesSelected['colegio'].length) {
              this.formErrMessage('Debe seleccionar al menos un colegio');
            } else {
              id_perfil = 4;
              codigo_nivel = this.perfilesArrayIds(this.profilesSelected['colegio']);
            }
          break;

          // AS
          case 'personalizado':
             if (!this.profilesSelected['colegiopersonalizado'].length) {
              this.formErrMessage('Debe seleccionar al menos un colegio');
            } 
            else {
              id_perfil = 6;
              //armar 3 vectores para colegios,grados,asignaturas
              
              codigo_nivel_colegio = this.perfilesArrayIds(this.profilesSelected['colegiopersonalizado']);              
              codigo_nivel_nivel = this.profilesSelected['nivel'].length>0?this.perfilesArrayIds(this.profilesSelected['nivel']):this.perfilesArrayIds(this.ListNiveles);
              codigo_nivel_asignatura = this.profilesSelected['asignatura'].length>0?this.perfilesArrayIds(this.profilesSelected['asignatura']):this.perfilesArrayIds(this.ListAsignaturas);
              }
          break;
          // AS
        };
      } else {
        this.formErrMessage('Debe seleccionar un perfil');
      }
    }

    if (!this.addProfileFormWithErr) {
   // AS diferenciar parametros para el guardado de perfil personalizado
      if(tipo=='personalizado'){
        params = {
          id_perfil: id_perfil,
          id_usuario: this.userAccountSelected[0]['id'],
          codigo_nivel_colegio: codigo_nivel_colegio.length ? codigo_nivel_colegio : 0,
          codigo_nivel_nivel: codigo_nivel_nivel.length ? codigo_nivel_nivel : 0,
          codigo_nivel_asignatura: codigo_nivel_asignatura.length ? codigo_nivel_asignatura : 0,
          niveles_colegios: this.colegiosnivelesasignaturas(codigo_nivel_nivel,this.ListNivelColegio,'nivel'),//this.ListNivelColegio.length ? this.ListNivelColegio: 0,
          asignaturas_niveles_colegio: this.colegiosnivelesasignaturas(codigo_nivel_asignatura,this.ListAsignaturaNivelColegio,'asignatura'),//this.ListAsignaturaNivelColegio.length ? this.ListAsignaturaNivelColegio : 0,
          tipo_nivel: tipo
        };
      }
      else{
       params = {
        id_perfil: id_perfil,
        id_usuario: this.userAccountSelected[0]['id'],
        codigo_nivel: codigo_nivel.length ? codigo_nivel : 0,
        tipo_nivel: tipo
      };
    }
      this.auth.registerProfile(params).then((res) => {
        //console.log('resp',res)
        this.getProfiles(this.userRegistered.id);
        $.notify({
          icon: 'notifications',
          message: res.message
        }, {
          type: 'success',
          timer: 2000,
          delay: 2000,
          mouse_over: 'pause',
          placement: {
              from: 'bottom',
              align: 'right'
          }
        });
      }, (err) => {
        this.formErrMessage(err.error.message);
      });
    }
    
  }

  /*
  * Trae los perfiles del usuario
  * @param: id -> int -> id de la persona
  * return none
  */
  getProfiles(id) {
    this.userProfilesShow=[];
    this.auth.getProfiles(id).then((res) => {
      const respuesta = res;
      if (typeof respuesta.usuario !== 'undefined') {
        this.userProfiles = respuesta.usuario;        
      }
                Object.keys(this.userProfiles).forEach(key => {                  
                  if (this.userProfiles[key].nivel<=5){
                    this.userProfilesShow.push(this.userProfiles[key]);
                  }
                });
    }, (err) => {

    });
    
  }

  /*
  * Trae del api la info del usuario dependiendo del rut colocado
  * @param: none
  * return none
  */
  getUsuario() {
    if (this.registerForm) {
        const run = this.registerForm['controls'].run.value;
        this.runValid = this.auth.checkRut(run);
        if (this.runValid) {
            this.userAccounts = [];
            this.userAccountSelected = [];
            this.auth.getPersonaByRun({run: this.auth.formatRut(run), id_usuario: this.userIdProfileLogged}).then((res) => {       
            this.userRegistered = res.datos;
            const user = this.userRegistered;            
            if (this.userRegistered.run) {
              
                this.registerForm = this.formBuilder.group({
                run: [run, Validators.compose([Validators.required])],
                nombre: [user.nombre, Validators.compose([Validators.minLength(3), Validators.required])],
                apellido_paterno: [user.apellido_paterno, Validators.compose([Validators.minLength(3), Validators.required])],
                apellido_materno: [user.apellido_materno, Validators.compose([Validators.minLength(3), Validators.required])],
                sexo: [user.sexo, Validators.compose([Validators.required])],
                correo: ['', Validators.compose([this.emailOrEmpty])]
                });
                this.userAccounts = [];
                if (Object.keys(this.userRegistered['usuarios']).length !== 0) {
                   this.userAccounts = this.userRegistered['usuarios'];
                }
                
                this.setUpSettings(false);
                this.getProfiles(user.id);
                this.parentProfileSelected=null
                this.cleanProfile();
                //this.profileDisable = false;                
                
            }
            else {
                 this.registerForm = this.formBuilder.group({
                 run: [run, Validators.compose([Validators.required])],
                 nombre: ['', Validators.compose([Validators.minLength(3), Validators.required])],
                 apellido_paterno: ['', Validators.compose([Validators.minLength(3), Validators.required])],
                 apellido_materno: ['', Validators.compose([Validators.minLength(3), Validators.required])],
                 sexo: ['', Validators.compose([Validators.required])],
                 correo: ['', Validators.compose([this.emailOrEmpty])]
                 });
                 
                 this.formErrMessage(res.message);
            }
        });
      } 
      else {
            this.setUpSettings(true);
            //this.profileDisable = true;
            this.formErrMessage('El run ingresado es invalido');
      }
    }
  }

  mailChanged(item){
    if(item.length>15){
    this.userDisable = false;
     }
    else{
      this.userDisable = true;
    }
  }

  /*
  * Crear o actualiza el usuario agregado en el formulario
  * @param: none
  * return none
  */
  addOrUpdateUser() {
    if (this.runValid) {
      this.userDisable = true;
      this.validations();
      this.submitAttempt = true;
      if (this.registerForm.valid) {
        const params = {
          id: this.userRegistered.id ? this.userRegistered.id : 0,
          run: this.auth.formatRut(this.registerForm.value['run']),
          nombre: this.registerForm.value['nombre'],
          apellido_paterno: this.registerForm.value['apellido_paterno'],
          apellido_materno: this.registerForm.value['apellido_materno'],
          sexo: this.registerForm.value['sexo'],
          username: this.registerForm.value['correo']
        };
        this.auth.registerPerson(params).then((res) => {
          const respuesta = res;
          this.userRegistered = respuesta.datos;
          this.userAccounts = this.userRegistered['usuarios'];
          
          this.userRegistered['email'] = '';
          this.setUpSettings(false);
         // this.profileDisable = false;
          this.getProfiles(this.userRegistered.id);
          this.formSuccessMessage(respuesta.message);
          this.userDisable = false;
        }, (err) => {
          this.formErrMessage(err.error.message);
          this.userDisable = false;
        });
      }
    } else {
      this.formErrMessage('El run ingresado es invalido');
    }
  }

  /*
  * Borra el perfil del listado
  * @param: profile -> object -> Perfil seleccionado.
  * return none
  */
  removeUserProfile(profile) {
    this.auth.deleteProfile(profile).then((res) => {
      const respuesta = res;
      this.formSuccessMessage(respuesta.message);
      this.getProfiles(this.userRegistered.id);
    }, (err) => {
      this.formErrMessage(err.message);
    });
  }

  /*
  * Validaciones del cuestionario de creación de usuario.
  * @param: none
  * return none
  */
  validations() {
    if (!this.registerForm.controls.nombre.valid) {
       this.formErrMessage('El nombre es requerido y debe ser mayor a 3 caracteres');
    }
    else if (!this.registerForm.controls.apellido_paterno.valid) {
         this.formErrMessage('El apellido paterno es requerido y debe ser mayor a 3 caracteres');
         }
         else if (!this.registerForm.controls.apellido_materno.valid) {
                 this.formErrMessage('El apellido materno es requerido y debe ser mayor a 3 caracteres');
              }
              else if (!this.registerForm.controls.sexo.valid) {
                      this.formErrMessage('El sexo es requerido');
                   }
    else if (!this.registerForm.controls.correo.valid) {
            this.formErrMessage('El correo es invalido');
    }
  }

  /*
  * Mostrar mensaje de error
  * @param: message -> string -> Mensaje a mostrar
  * return none
  */
  formErrMessage(message) {
    this.addProfileFormWithErr = true;
    $.notify({
      icon: 'notifications',
      message: message
    }, {
      type: 'danger',
      timer: 2000,
      delay: 2000,
      mouse_over: 'pause',
      placement: {
          from: 'bottom',
          align: 'right'
      }
    });
  }

  /*
  * Mostrar mensaje de cambio exitoso
  * @param: message -> string -> Mensaje a mostrar
  * return none
  */
  formSuccessMessage(message) {
    this.addProfileFormWithErr = true;
    $.notify({
      icon: 'notifications',
      message: message
    }, {
      type: 'success',
      timer: 2000,
      delay: 2000,
      mouse_over: 'pause',
      placement: {
          from: 'bottom',
          align: 'right'
      }
    });
  }

  /*
  * Busca el perfil del usuario dependiendo del usuario logeado
  * @param: none
  * return none
  */
  findProfileByUserLogged() {
    const codigo = this.userProfileLogged['codigo'];
    switch (this.userProfileLogged['tipo_nivel']) {
      case 'slep':
        this.getPerfilInfo({id: codigo}, 'sostenedor');
        break;
      case 'sostenedor':
        this.getPerfilInfo({id: codigo}, 'colegio');
        break;
      case 'colegio':
        this.getPerfilInfo({id: codigo}, 'director');
        break;
    }
  }

  /*
  * Limpia la información del usuario búscado o agregado
  * @param: none
  * return none
  */
  cleanUserProfile() {
    this.setUpSettings(true);
    this.registerForm = this.formBuilder.group({
      run: ['', Validators.compose([Validators.required])],
      nombre: ['', Validators.compose([Validators.minLength(3), Validators.required])],
      apellido_paterno: ['', Validators.compose([Validators.minLength(3), Validators.required])],
      apellido_materno: ['', Validators.compose([Validators.minLength(3), Validators.required])],
      sexo: ['', Validators.compose([Validators.required])],
      correo: ['', Validators.compose([this.emailOrEmpty])]
    });
    this.userRegistered = {id: null, run: '', nombre: '', apellido_paterno: '', apellido_materno: '', sexo: '', correo: ''};
    this.parentProfileSelected = null;
    this.profileDisable = true;
    this.profilesSelected = { 'dep': [], 'slep': [], 'sostenedor': [], 'colegio': [], 'colegiopersonalizado': [], 'nivel': [], 'asignatura': []};
    this.userAccounts = [];
    this.userAccountSelected = [];
    this.userProfiles = [];
  }

  /*
  * Recorre el arreglo de objetos y devuelve un arreglo de ids
  * @param: profiles -> array -> Arreglo de objetos de perfiles
  * return array
  */
  private perfilesArrayIds(perfiles) {
    const array = [];
    Object.keys(perfiles).forEach(key => {
      array.push(perfiles[key].id);
    });
    return array;
  }

  /*
  * Validador de email que acepta nulos o vacios
  * @param: control -> AbstractControl -> Item a validar
  * return none
  */
  emailOrEmpty(control: AbstractControl): ValidationErrors | null {
    return control.value === '' ? null : Validators.email(control);
  }

  addslashes(str) {
    str = str.replace(/\\/g, '\\\\');
    str = str.replace(/\'/g, '\\\'');
    str = str.replace(/\'/g, '\\\' ');
    str = str.replace(/\"/g, '\\"');
    str = str.replace(/\0/g, '\\0');
    return str;
}

stripslashes(str) {
    str = str.replace(/\\'/g, '\'');
    str = str.replace(/\\"/g, '"');
    str = str.replace(/\\0/g, '\0');
    str = str.replace(/\\\\/g, '\\');
    return str;
}

onItemSelect(item: any, tipo: any) {
  //console.log(item,tipo);
}

}
