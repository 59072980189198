import { DashboardServiceSnd } from '../../services/dashboardsnd.service';
import { urlServer } from '../../services/url.service';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { TableData } from '../md/md-table/md-table.component';
import { LegendItem, ChartType } from '../md/md-chart/md-chart.component';

import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { PuenteService } from '../../services/puente.service';
import { Chart } from 'angular-highcharts';
import { CapacidadSndService } from '../../services/api-snd/capacidad.service';
import { EvolucionMatriculaSndService } from '../../services/api-snd/evolucion-matricula.service';
import { AlumnosRetiradosSndService } from '../../services/api-snd/alumnos-retirados.service';
import { AsistenciaSndService } from '../../services/api-snd/asistencia.service';

// import Highcharts = require('highcharts');
// import jsPDF = require('jspdf');
// import html2canvas = require('html2canvas');

import * as Highcharts from "highcharts";
import * as jsPDF from "jspdf";
import * as html2canvas from "html2canvas";

declare const require: any;
declare const $: any;

@Component({
    selector: 'app-dashboardsnd',
    templateUrl: './dashboardsnd.component.html',
    styleUrls: ['./dashboardsnd.component.scss'],
    providers:[CapacidadSndService, EvolucionMatriculaSndService, AlumnosRetiradosSndService, AsistenciaSndService]
})
export class DashboardsndComponent implements OnInit, OnDestroy {
    color = 'red';
    mode = 'indeterminate';
    value = 50;

    spinner = false;
    mdPerfil: any;
    Puente: Subscription;

    params: any;
    carga: any;
    SlcAnoEsoclar: any;
    ActualAnoEscolar: any;
    data_puente: any;
    chartCapacidadUso: Chart;
    chartAsisInas: Chart;

    rs_capacidad_uso: any;
    rs_asistencia: any;
    rs_matricula: any;
    rs_retirados: any;

    total_matriculados: any;
    total_retirados: any;
    MES_NOM_COMPLETO = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    mes_actual:any;
    rsData = [];
    public loading:boolean = true;
    
    public err_dashboard:boolean=false;
    public err_matriculados:boolean=false;
    public err_retirados:boolean=false;
    public err_asist:boolean=false;
    public err_uso:boolean=false;


    constructor(private route: ActivatedRoute, private puente: PuenteService, private DBservice: DashboardServiceSnd, private router: Router, private CapacidadSndService:CapacidadSndService,private EvolucionMatriculaSndService:EvolucionMatriculaSndService, private AlumnosRetiradosSndService:AlumnosRetiradosSndService, private AsistenciaSndService:AsistenciaSndService) {
        
        this.Puente = this.puente.getData().subscribe(data => {
            //AS
            this.cargaDashboardsnd();
           
        });
    }


    public ngOnInit() {

        this.cargaDashboardsnd();
        //AS
       /*  console.log('sostdashbsnd');
        this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));
        
        this.err_dashboard = false;
        if (!this.ActualAnoEscolar) {
            let currentTime = new Date()
            this.ActualAnoEscolar = currentTime.getFullYear();
        }
        
        this.rs_capacidad_uso = true;
        this.graphCapacidadUso();

        this.rs_asistencia = true;
        this.graphAsisInasis();

        this.rs_matricula = true;
        this.calcMatricula();

        this.rs_retirados = true;
        this.calcRetirados();
        this.loading = false;
        this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil')))); */
        //AS
    }

    ngOnDestroy(){
        this.Puente.unsubscribe();
    }
    //AS
    cargaDashboardsnd() {

        this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));
        
        this.err_dashboard = false;
        if (!this.ActualAnoEscolar) {
            let currentTime = new Date()
            this.ActualAnoEscolar = currentTime.getFullYear();
        }

        this.rs_capacidad_uso = true;
        this.graphCapacidadUso();

        this.rs_asistencia = true;
        this.graphAsisInasis();

        this.rs_matricula = true;
        this.calcMatricula();

        this.rs_retirados = true;
        this.calcRetirados();
        this.loading = false;
        this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));
    }
    //AS
    
    Gpdf() {
        if (this.ActualAnoEscolar) {
            this.spinner = true;
            var divWidth = $(".highcharts-container").width() - 20;
            $("#printArea").css("width","1100px")
            document.body.style.width = "1920px";
            document.body.style.height = "1080px";
            
            $('#graphClass1').highcharts().setSize("520", false);
            $('#graphClass2').highcharts().setSize("520", false);
         
            setTimeout(() => {
                var Doc = new jsPDF('l', 'pt', "A4");
                html2canvas(document.querySelector("#printArea"), {
                onclone: function (document) {
                    
                }
                }).then(canvas => {
                var imgData = canvas.toDataURL('image/png');
                var width = Doc.internal.pageSize.width;
                var height = Doc.internal.pageSize.height;
                Doc.addImage(imgData, 'PNG', 0, 0);
                Doc.save('Inicio.pdf')
                
                document.body.removeAttribute("style");
                $('#graphClass1').highcharts().setSize(divWidth, false);
                $('#graphClass2').highcharts().setSize(divWidth, false);
                $("#printArea").css("width","100%");
                });
                this.spinner = false;
            }, 1000);
        } else {
            $.notify(
                {icon: 'notifications', message: "No hay datos para imprimir"}, 
                {type: 'warning',timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
            );
        }
    }

    graphCapacidadUso() {
        this.loading = true;
        this.err_uso = false;
        let codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));
        this.CapacidadSndService.capacidadTotalSostenedor(codSostenedor,this.ActualAnoEscolar).subscribe(
            result => {
                if( result.success ) {
                    let jsonData = result.data;
                    var obj = {};
                    obj["matricula"] = jsonData.datos[1][1];
                    obj["disponible"] = jsonData.datos[0][1];
                    this.rsData[this.ActualAnoEscolar] = obj
                    this.capacidadUsoGraphPie(this.rsData, this.ActualAnoEscolar);
                    this.loading = false;

                } else {
                    $.notify(
                        {icon: 'notifications', message: "No hay datos para imprimir"}, 
                        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
                    });
                    setTimeout(() => { this.loading = false}, 700);
                }
            },
            error => {
                setTimeout(() => { this.loading = false}, 700);
                this.err_uso = true;
            }
        );
    }   

    graphAsisInasis() {
        this.err_asist = false;
        let codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));
        this.AsistenciaSndService.asistenciaMensualSostenedor(codSostenedor,this.ActualAnoEscolar).subscribe(
            result => {
              if( result.success ) {
                let jsonData = result.data;
                this.buildGraphBarra(jsonData.datos, this.ActualAnoEscolar, jsonData.colors, jsonData.xkeys);
              } else {
                $.notify(
                  {icon: 'notifications', message: "No hay datos para imprimir"}, 
                  {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
                });
                setTimeout(() => { this.loading = false}, 700);
              }
            },
            error => {
                setTimeout(() => { this.loading = false}, 700);
                this.err_asist = true;
            }
        );
    }
   

    calcMatricula() {
        this.total_matriculados = 0;
        let AnoEscolar = [];
        this.rsData = [];
        this.err_matriculados = false;
        let codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));

        this.EvolucionMatriculaSndService.evolucionMatriculaSostenedor(codSostenedor,this.ActualAnoEscolar).subscribe(
            result => {
                if( result.success ) {
                    let jsonData = result.data;
                    this.rsData = jsonData.datos;
                    AnoEscolar  = jsonData.xkeys;
                    Object.keys(AnoEscolar).forEach(key => {
                        if (AnoEscolar[key] == this.ActualAnoEscolar) {
                            this.total_matriculados = this.formatNumber(this.rsData[0].data[key]);
                        }
                    });
                } else {
                    $.notify(
                        {icon: 'notifications', message: result.message}, 
                        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right'}}
                    );
                    setTimeout(() => { this.loading = false}, 700);
                }
            },
            error => {
                setTimeout(() => { this.loading = false}, 700);
                this.err_matriculados = true;
            }
        );
    }
   

    calcRetirados() {
        this.total_retirados = 0;
        this.err_retirados = false;
        let dia = new Date();
        let nro_mes = dia.getMonth();
        this.mes_actual = this.MES_NOM_COMPLETO[nro_mes]

        let cod_sostenedor = parseInt(localStorage.getItem('cod_sostenedor'));
        this.AlumnosRetiradosSndService.alumnosRetiradosSostenedor(cod_sostenedor,this.ActualAnoEscolar).subscribe(
            result => {
              if( result.success ) {
                let data = result.data;
                this.rsData = data.datos;
                let rowTotal = this.rsData[0].data;
                this.total_retirados = rowTotal[nro_mes];
              }else {
                    $.notify(
                        {icon: 'notifications', message: "No hay datos para imprimir"}, 
                        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
                    });
                    setTimeout(() => { this.loading = false}, 700);
                }
            },
            error => {
                setTimeout(() => { this.loading = false}, 700);
                this.err_retirados = true;
            }
        );
        // console.log(this.err_retirados);
    }

    link(link: any, tab: any) {
        sessionStorage.setItem('link_param', tab);
        this.router.navigate([link]);
    }
    formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }



    /* FUNCIONES DE GRAFICOS */
    capacidadUsoGraphPie(rsData, anoEscolar) {

        this.chartCapacidadUso = new Chart({
            lang: {
                downloadPNG: "Descargar a PNG",
                downloadJPEG: "Descargar a JPEG",
                downloadSVG: "Descargar a SVG",
                downloadPDF: "Descargar a PDF",
                loading: "Cargando",
                printChart: "Imprimir",
                contextButtonTitle: 'Opciones gráfico'
            },
            chart: {
                type: 'pie',
            },
            title: {
                text: 'Capacidad de uso ' + anoEscolar
            },
            credits: {
                enabled: false
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            exporting: {
                'enabled': true,
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                    }
                }
            },
            series: [{
                name: 'Capacidad de uso',
                data: [{
                    name: 'Disponibles',
                    y: rsData[anoEscolar]["disponible"],
                    sliced: true,
                    selected: true,
                    color: '#1EA559'

                }, {
                    name: 'Matriculados',
                    y: rsData[anoEscolar]["matricula"],
                    color: '#FB3948'
                }]
            }]
        });
    }

    buildGraphBarra(rsData, anoEscolar, colors, categoria) {
 
        this.chartAsisInas = new Chart({
        lang: {
            downloadPNG: "Descargar a PNG",
            downloadJPEG: "Descargar a JPEG",
            downloadSVG: "Descargar a SVG",
            downloadPDF: "Descargar a PDF",
            loading: "Cargando",
            printChart: "Imprimir",
            contextButtonTitle: 'Opciones gráfico'
        },
        chart: {
            type: 'column'
        },
        title: {
            text: 'Asistencia mensual ' + anoEscolar
        },
        xAxis: {
            categories: categoria,
            min: null,
            max: null,
            title: {
            text: null
            },
                
        },
        yAxis: {
            min: 0,
            max: 100,
            title: {
            text: "Porcentaje de asistencia"
            },
            labels: {
            overflow: "justify",
            format: '{value}'
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            valueSuffix: '%',
            formatter: function() {
            return "" + this.x + "<br/>" + this.series.name + " : <strong>" + Highcharts.numberFormat(this.y, 0, ",", ".")+"%</strong>";
            }
        },
        plotOptions: {
            bar: {
            dataLabels: {
                enabled: true,
                shadow: true
            }
            },
            series: {
            stacking: 'normal',
            dataLabels: {
                rotation: null,
                formatter: function() {
                return this.y + "%";
                },
        
                enabled: true,
                verticalAlign: 'middle',
                align: 'center',
                inside: true
                    
            }
            }
        },
        series: rsData,
        colors: colors
        });
    }    
    

}
