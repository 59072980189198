import { Subscription } from 'rxjs/Subscription';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as Chartist from 'chartist';
import { DateAdapter } from '@angular/material';
import { Chart } from 'angular-highcharts';
import { PuenteService } from '../../services/puente.service';

import { urlServer } from '../../services/url.service';
import { ApiEvolucionMatriculaService } from '../../services/sostenedor/evolucion-matricula.service';
import { EvolucionMatriculaSndService } from '../../services/api-snd/evolucion-matricula.service';
import { CapacidadSndService } from '../../services/api-snd/capacidad.service';
import * as Highcharts from "highcharts";
import * as jsPDF from "jspdf";
import * as html2canvas from "html2canvas";
import {ExcelService} from '../../services/excel.service';
//AS
//import { ListaAgnosService } from '../../services/shared/listaagnos.service';
//import { DatatablesService } from '../../services/shared/datatables.service';
import { HttpPuenteService } from '../../services/shared/httppuente.service';
import { GraficasService } from '../../services/shared/graficas.service';
// import { RangoNotasService } from './../../services/rango-notas.service';
//import { CapacidadUsoService } from '../../services/capacidad-uso.service';
//AS
declare const require: any;
declare const $: any;

@Component({
  selector: 'app-capacidad-uso',
  templateUrl: './capacidad-uso.component.html',
  styleUrls: ['./capacidad-uso.component.scss'],
  providers:[ApiEvolucionMatriculaService, EvolucionMatriculaSndService, CapacidadSndService]
})

export class CapacidadUsoComponent implements OnInit, OnDestroy {
  chart: Chart;
  //MES_NOM_COMPLETO = ["", "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  //MES_NOMBRES = ["", "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
  
  dataTable = [];
  capacUso = false;
  capacUsoCol   = false;
  capacUsoNivel = false;
  capacUsoComparativo = false;
  spinner = false;
  color = 'red';
  mode = 'indeterminate';
  value = 50;
  data_puente: any;
  mdPerfil: any;
  Puente: Subscription;
  ActualAnoEscolar: any;
  SlcAnoEsoclar = [];
  ListAnoEscolar = [];
  rsData = [];
  rowHeadTablePorcUso = [];
  rowBodyTablePorcUso = [];
  dataTableExcel = [];
  dataTableExcelPorcUso = [];
  dataTableExcelCapUso = [];
  titulo_excel:string = 'Ejemplo';
  colBodyTable = [];
  rowHeadTableCapUso = [];
  rowBodyTableCapUso = [];
  rowFootTableCapUso = [];
  listNivelesPorcUso = [];
  listNivelesCapUso = [];
  linkParams: any;
  datos= [];

  public colegio:boolean = false;
  public loading:boolean = true;
  public api_error:boolean = false;
  public dataCapacidadUsoSnd:boolean = false; 

  // AS constructor(private puente: PuenteService, private IGservice: CapacidadUsoService, private apiEvolucionMatricula: ApiEvolucionMatriculaService, private EvolucionMatriculaSndService:EvolucionMatriculaSndService, private CapacidadSndService:CapacidadSndService, private excelService:ExcelService, private IAservice: RangoNotasService) {
    constructor(private puente: PuenteService, private IGservice: HttpPuenteService, private apiEvolucionMatricula: ApiEvolucionMatriculaService, private EvolucionMatriculaSndService:EvolucionMatriculaSndService, private CapacidadSndService:CapacidadSndService, private excelService:ExcelService, private IAservice: HttpPuenteService, private graphService: GraficasService) { 
    this.Puente = this.puente.getData().subscribe(data => {
      this.graphService.removeActiveTab();
      if (this.capacUso) {
        this.capac_uso();
      }
      if (this.capacUsoCol) {
        this.capac_uso_colegio();
      }
      if (this.capacUsoNivel) {
        this.capac_uso_nivel();
      }
    });
  }

  ngOnDestroy(): void {
    this.Puente.unsubscribe();
  }

  ngOnInit() {
    this.graphService.tabs = ["pill4", "tab4", "tab40", "pill5", "tab5", "tab50", "pill6", "tab6", "pill7", "tab7"];
    this.ActualAnoEscolar = new Date().getFullYear();
    this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));
    if(this.mdPerfil.nivel==3 || this.mdPerfil.nivel==5){
      this.colegio=true;
    }
    else{
      this.colegio=false;
    }
    this.graphService.removeActiveTab();
    this.listAnosEscolaresSostenedor();

    if (sessionStorage.getItem('link_param')) {
      this.capacUso = false;
      this.linkParams = sessionStorage.getItem('link_param');
      sessionStorage.removeItem('link_param');
    }

    switch (this.linkParams) {
      case 'pill5':
        this.capacUsoCol = true;
        this.capac_uso_colegio();
        break;
      case 'pill6':
        this.capacUsoNivel=true;
        this.capac_uso_nivel();
        break;
      default:
        this.capacUso = true;
        this.capac_uso();
    }
    this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));
    this.sl2_style();
  }

  recargarCapacidadUsoItem(items: any) {
    this.graphService.removeActiveTab();
    this.ActualAnoEscolar = items.value;
    if (this.capacUso) {
      this.capac_uso();
    } else if (this.capacUsoCol) {
      this.capac_uso_colegio();
    } else if (this.capacUsoNivel) {
      this.loading = true;
      this.capac_uso_nivel();
    } else if (this.capacUsoComparativo) {
      this.loading = true;
      this.capac_uso_comparativo();
    } else {
      $.notify(
        {icon: 'notifications', message: 'Opción ingresada no es correcta'}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause',placement: {from: 'bottom',align: 'right'}}
      );
    }
  }

  recargarGraficoCapacidad(items: any) {
    this.recalculaGraphPie(this.rsData, items.value);
  }

  Gpdf() {
    this.loading = true;
    if (this.rsData.length != 0) {
      this.spinner = true;
      var divWidth = $(".highcharts-container").width() - 20;;
      var originalBodyWidth = document.body.offsetWidth;
      var originalBodyHeight = document.body.offsetHeight;
      document.body.style.width = "1920px";
      document.body.style.height = "1080px";

      $('.graphClass').highcharts().setSize("1100", false);
      $('.table').css("width","1100px");
      setTimeout(() => {
        var Doc = new jsPDF('l', 'pt', "A4");
        html2canvas(document.querySelector(".page1"), {
          onclone: function (document) {
            document.querySelector(".page1").classList.remove("col-md-8");
          }
        }).then(canvas => {
          var imgData = canvas.toDataURL('image/png');
          var width = Doc.internal.pageSize.width;
          var height = Doc.internal.pageSize.height;
          Doc.addImage(imgData, 'PNG', 0, 0);
          Doc.addPage();
          html2canvas(document.querySelector(".page2"), {
            onclone: function (document) {
              document.querySelector(".page2").style.width = "100%";
            }
          }).then(canvas => {
            var imgData = canvas.toDataURL('image/png');
            var width = Doc.internal.pageSize.width;
            var height = Doc.internal.pageSize.height;
            Doc.addImage(imgData, 'PNG', 0, 0);
            Doc.save('Indicadores de gestión.pdf')
            document.body.removeAttribute("style");
            $('.graphClass').highcharts().setSize(divWidth, false);
            $('.table').css("width","100%");
            this.spinner = false;
          });
        });
      }, 1000);
      this.loading = false;
    } else {
      $.notify(
        {icon: 'notifications', message: "No hay datos para imprimir" }, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom',align: 'right'}}
      );
      setTimeout(() => { this.loading = false}, 700);
    }
  }

  capac_uso() {
    this.api_error = false;
    this.graphService.removeActiveTab();

    $("#pill4").parent().addClass("active");
    $("#tab4").parent().addClass("active");
    $("#tab40").parent().addClass("active");

    this.capacUso = true;
    this.capacUsoCol = false;
    this.capacUsoNivel = false;
    this.capacUsoComparativo = false;


    this.rsData = [];
    this.dataCapacidadUsoSnd = true;
    this.loading = false;

    let recurso: string;
    let idSelect: string;
    let jsonData: any;
    let list: any;
    let params: any;
    let AnoEscolar = [];
    let porc_matricula: any;
    let porc_disponible: any;

    if (localStorage.getItem('select_colegio')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_colegio'));
        idSelect = jsonData.id
        this.colegio=true;
    } 
    else if (localStorage.getItem('select_sost')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_sost'));
        idSelect = jsonData.id
        this.colegio=false;
    } 
    else if (localStorage.getItem('select_slep')) {
        list = JSON.parse(localStorage.getItem('ListSost'));
        jsonData = JSON.parse(localStorage.getItem('select_slep'));
        idSelect = jsonData.id
        this.colegio=false;
    }

    let capacidad_uso_post = urlServer + '/capacidad_uso';

    if (localStorage.getItem('select_colegio')) {
      recurso = urlServer + '/colegioselector/' + idSelect;
    } 
    else if (localStorage.getItem('select_sost')) {
      recurso = urlServer + '/rbd/sostenedor/' + idSelect;
    }
     else if (localStorage.getItem('select_slep')) {
      recurso = urlServer + '/rbd/slep/' + idSelect;
    } 
    else {
      recurso = null
    }

    let arrCodColegios = [];
    let arrIdColegios = [];
    if(recurso){
      this.IAservice.getData(recurso).then((data) => {
          Object.keys(data.data).forEach(key => {
              arrIdColegios.push(key);
              arrCodColegios.push(data.data[key]);
          });

          let ListCodColegios = arrCodColegios.toString();
          let ListIdColegios  = arrIdColegios.toString();

          params = {
              "grupo_colegios": ListCodColegios,
              "ano_escolar": this.ActualAnoEscolar,
              "tipo": "2"
          }

          this.IGservice.getDataPost(capacidad_uso_post,params)
          .then((data) => {
            this.graficoConsolidado(data);
          })
          .catch((err) => {
            $.notify(
              { icon: 'notifications', message: err.message }, 
              { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
            );
          });

      })
      .catch((err) => {
        $.notify(
          {icon: 'notifications', message: err.message}, 
          {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
        );
      });
    }
  }

  graficoConsolidado(result) {
    if( result.success ) {
      let jsonData = result.data;
      var obj = {};
      obj["matricula"] = jsonData.datos[1][1];
      obj["disponible"] = jsonData.datos[0][1];
      this.rsData[this.ActualAnoEscolar] = obj
      this.recalculaGraphPie(this.rsData, this.ActualAnoEscolar);
      this.loading = false;
    } else {
      $.notify(
        {icon: 'notifications', message: "No hay datos para imprimir"}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
      });
      setTimeout(() => { this.loading = false}, 700);
    }
  }

  capac_uso_comparativo(){
    this.api_error = false;
    this.graphService.removeActiveTab();
    $("#pill7").parent().addClass("active");
    $("#tab7").parent().addClass("active");
    this.capacUso = false;
    this.capacUsoCol = false
    this.capacUsoNivel = false;
    this.capacUsoComparativo = true;
    this.rsData = [];

    let recurso: string;
    let idSelect: string;
    let jsonData: any;
    let AnoEscolar = [];

    if (!this.ActualAnoEscolar) {
      this.SlcAnoEsoclar = [];
    }

    this.dataTable = [];
    let colores = ['#4572A7', '#AA4643', '#89A54E', '#80699B', '#3D96AE', '#DB843D', '#92A8CD', '#A47D7C', '#B5CA92'];
    let porc_matricula: any;
    let porc_disponible: any;
    this.dataCapacidadUsoSnd = true;
    this.loading = false;

    jsonData = JSON.parse(localStorage.getItem('select_slep'));
    idSelect = jsonData.id
    recurso = urlServer + '/slep/' + idSelect + '/capacidad-uso-comparativa';

    let matriculadoSle = 0;
    let capacidadSle   = 0;
    let rsTable = [];
    this.IGservice.getData(recurso)
      .then((data) => {
        Object.keys(data.data).forEach(key => {
          if (!$.isEmptyObject(data.data[key])) {
            Object.keys(data.data[key]).forEach(anoEscolar => {
              AnoEscolar.push(anoEscolar);
              if (!this.ActualAnoEscolar) {
                var xano = {};
                xano["ano_escolar"] = anoEscolar;
                this.SlcAnoEsoclar.push(xano);
                if (this.ActualAnoEscolar) {
                  if (this.ActualAnoEscolar < anoEscolar) {
                    this.ActualAnoEscolar = anoEscolar;
                  }
                } else {
                  this.ActualAnoEscolar = anoEscolar;
                }
              }

              if (this.ActualAnoEscolar == anoEscolar) {
                Object.keys(data.data[key][anoEscolar]).forEach(zona => {
                  let valor = [];
                  let matriculados = data.data[key][anoEscolar][zona].matriculados;
                  let capacidad = data.data[key][anoEscolar][zona].capacidad;

                  matriculadoSle += matriculados;
                  capacidadSle += capacidad;
                  porc_matricula = (100 * matriculados) / capacidad;
                  porc_matricula = Math.round(porc_matricula * 10) / 10;
                  porc_disponible = 100 - porc_matricula
                  porc_disponible = Math.round(porc_disponible * 10) / 10;
                  valor.push(porc_matricula);

                  let obj = {
                    name: zona,
                    data: valor,
                    visible:true
                  };
                  this.rsData.push(obj);

                  this.dataTable.push({
                    zona: zona,
                    cant_uso: this.formatNumber(matriculados),
                    porcentaje_uso: porc_matricula + '%',
                    disponible: capacidad,
                    porcentaje_disponible: porc_disponible + '%',
                  });

                });
              } 
              
            });
          }
        });

        if (this.rsData) {
          //Agregar al SLE
          let valor = [];
          porc_matricula = (100 * matriculadoSle) / capacidadSle;
          porc_matricula = Math.round(porc_matricula * 10) / 10;
          porc_disponible = 100 - porc_matricula
          porc_disponible = Math.round(porc_disponible * 10) / 10;
          valor.push(porc_matricula);

          let obj = {
            name: 'SLE',
            data: valor,
            color: "#FF8D33",
            visible:true
          };
          this.rsData.push(obj);
          this.recalcularGraphBarra(this.rsData, this.ActualAnoEscolar, colores, [''], 'comparativo');
        }

      })
      .catch((err) => {
        $.notify(
          {icon: 'notifications',message: err.message }, 
          {type: 'warning',timer: 2000, delay: 2000,mouse_over: 'pause', placement: {from: 'bottom',align: 'right'}}
        );
      });
  }

  capac_uso_colegio() {
    this.api_error = false;
    this.graphService.removeActiveTab();

    $("#pill5").parent().addClass("active");
    $("#tab5").parent().addClass("active");
    $("#tab50").parent().addClass("active");

    this.capacUso = false;
    this.capacUsoCol = true;
    this.capacUsoNivel = false;
    this.capacUsoComparativo = false;
    this.rsData = [];

    let recurso: string;
    let idSelect: string;
    let data:string;
    let jsonData: any;
    let list: any;
    let params: any;
    let rsTable = [];
    let AnoEscolar = [];

    if (!this.ActualAnoEscolar) {
      let currentTime = new Date()
      this.ActualAnoEscolar = currentTime.getFullYear();
    }

    let codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));

    if (this.SlcAnoEsoclar.length==0) {
      Object.keys(this.ListAnoEscolar).forEach(key => {
        let xano = {};
        xano["ano_escolar"] = this.ListAnoEscolar[key].ano_escolar;
        this.SlcAnoEsoclar.push(xano);
      });
    }

    this.SlcAnoEsoclar = this.eliminarDuplicateInArray(this.SlcAnoEsoclar);
    this.loading = true;

    if (localStorage.getItem('select_colegio')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_colegio'));
        idSelect = jsonData.id
        this.colegio=true;
    } 
    else if (localStorage.getItem('select_sost')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_sost'));
        idSelect = jsonData.id
        this.colegio= false;
    } 
    else if (localStorage.getItem('select_slep')) {
        list = JSON.parse(localStorage.getItem('ListSost'));
        jsonData = JSON.parse(localStorage.getItem('select_slep'));
        idSelect = jsonData.id
        this.colegio= false;
    }
    let tipo = "";
    let capacidad_uso_post = urlServer + '/capacidad_uso';

    if (localStorage.getItem('select_colegio')) {
      recurso = urlServer + '/colegioselector/' + idSelect;
      tipo = "4";
    } 
    else if (localStorage.getItem('select_sost')) {
      recurso = urlServer + '/rbd/sostenedor/' + idSelect;
      tipo = "4";
    } 
    else if (localStorage.getItem('select_slep')) {
      recurso = urlServer + '/rbd/slep/' + idSelect;
      tipo = "3";
    } 
    else {
      recurso = null
    }

    let arrCodColegios = [];
    let arrIdColegios = [];
    if(recurso){
      this.IAservice.getData(recurso).then((data) => {
          Object.keys(data.data).forEach(key => {
              arrIdColegios.push(key);
              arrCodColegios.push(data.data[key]);
          });

          let ListCodColegios = arrCodColegios.toString();
          let ListIdColegios  = arrIdColegios.toString();

          params = {
              "grupo_colegios": ListCodColegios,
              "ano_escolar": this.ActualAnoEscolar,
              "tipo": tipo
          }

          this.IGservice.getDataPost(capacidad_uso_post,params)
          .then((result) => {
            if( result.success ) {
              let jsonData = result.data;
              this.rsData = jsonData.datos;
              this.recalcularGraphBarra(this.rsData, this.ActualAnoEscolar, jsonData.colors, jsonData.xkeys);
              this.recalcularTablaCapacidadUsoCol(jsonData.labels);
              this.loading = false;
            } else {
              $.notify(
                {icon: 'notifications', message: "No hay datos para imprimir"}, 
                {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
              });
              setTimeout(() => { this.loading = false}, 700);
            }
          })
          .catch((err) => {
            $.notify(
              { icon: 'notifications', message: err.message }, 
              { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
            );
          });
      })
      .catch((err) => {
        $.notify(
          {icon: 'notifications', message: err.message}, 
          {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
        );
      });
    }

    // this.CapacidadSndService.capacidadTotalEstablecimiento(codSostenedor,this.ActualAnoEscolar).subscribe(
    //   result => {
    //     if( result.success ) {
    //       let jsonData = result.data;
    //       this.rsData = jsonData.datos;
    //       this.recalcularGraphBarra(this.rsData, this.ActualAnoEscolar, jsonData.colors, jsonData.xkeys);
    //       this.recalcularTablaCapacidadUsoCol(jsonData.labels);
    //       this.loading = false;
    //     } else {
    //       $.notify(
    //         {icon: 'notifications', message: "No hay datos para imprimir"}, 
    //         {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
    //       });
    //       setTimeout(() => { this.loading = false}, 700);
    //     }
    //   },
    //   error => {
    //     setTimeout(() => { this.loading = false}, 700);
    //     this.api_error = true;
    //   }
    // );
  }

  capac_uso_nivel() {
    this.api_error = false;
    this.loading = true;
    this.graphService.removeActiveTab();
    $("#pill6").parent().addClass("active");
    $("#tab6").parent().addClass("active");
    this.capacUso = false;
    this.capacUsoCol = false;
    this.capacUsoNivel = true;
    this.capacUsoComparativo = false;

    let recurso: string;
    let idSelect: string;
    let data:string;
    let jsonData: any;
    let list: any;
    let params: any;
    let rsTable = [];
    let AnoEscolar = [];
    let niveles = [];
    let rsData2:string;

    if (!this.ActualAnoEscolar) {
      this.listAnosEscolaresSostenedor();
      let currentTime = new Date()
      this.ActualAnoEscolar = currentTime.getFullYear();
    }

    let codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));
    if (this.SlcAnoEsoclar.length==0) {
      Object.keys(this.ListAnoEscolar).forEach(key => {
        let xano = {};
        xano["ano_escolar"] = this.ListAnoEscolar[key].ano_escolar;
        this.SlcAnoEsoclar.push(xano);
      });
    }
    this.SlcAnoEsoclar = this.eliminarDuplicateInArray(this.SlcAnoEsoclar);

    if (localStorage.getItem('select_colegio')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_colegio'));
        idSelect = jsonData.id
        this.colegio=true;
    } 
    else if (localStorage.getItem('select_sost')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_sost'));
        idSelect = jsonData.id
        this.colegio= false;
    } 
    else if (localStorage.getItem('select_slep')) {
        list = JSON.parse(localStorage.getItem('ListSost'));
        jsonData = JSON.parse(localStorage.getItem('select_slep'));
        idSelect = jsonData.id
        this.colegio= false;
    }

    let capacidad_uso_post = urlServer + '/capacidad_uso';
    let tipo = "";

    if (localStorage.getItem('select_colegio')) {
      recurso = urlServer + '/colegioselector/' + idSelect;
      tipo = "6";
    } 
    else if (localStorage.getItem('select_sost')) {
      recurso = urlServer + '/rbd/sostenedor/' + idSelect;
      tipo = "6";
    } else if (localStorage.getItem('select_slep')) {
      recurso = urlServer + '/rbd/slep/' + idSelect;
      tipo = "5";
    } else {
      recurso = null
    }

    let arrCodColegios = [];
    let arrIdColegios = [];
    if(recurso){
      this.IAservice.getData(recurso).then((data) => {
          Object.keys(data.data).forEach(key => {
              arrIdColegios.push(key);
              arrCodColegios.push(data.data[key]);
          });

          let ListCodColegios = arrCodColegios.toString();
          let ListIdColegios  = arrIdColegios.toString();

          params = {
              "grupo_colegios": ListCodColegios,
              "ano_escolar": this.ActualAnoEscolar,
              "tipo": tipo
          }

          this.IGservice.getDataPost(capacidad_uso_post,params)
          .then((result) => {
            if( result.success ) {
              jsonData = result.data;
              rsData2 = jsonData.datos;
              niveles = jsonData.labels.niveles;
              rsTable = jsonData.labels;

              this.recalcularGraphBarra(rsData2, this.ActualAnoEscolar, jsonData.colors, jsonData.xkeys);
              this.recalcularTablaCapacidadUsoNiveles(rsTable, niveles);
              this.loading = false;
            } else {
              $.notify(
                {icon: 'notifications', message: "No hay datos para imprimir"}, 
                {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
              });
              setTimeout(() => { this.loading = false}, 700);
            }
          })
          .catch((err) => {
            $.notify(
              { icon: 'notifications', message: err.message }, 
              { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
            );
          });

      })
      .catch((err) => {
        $.notify(
          {icon: 'notifications', message: err.message}, 
          {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
        );
      });
    }
    else{
      $.notify(
        {icon: 'notifications', message: 'Revise selección en el menú por favor.'}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
      );
    }
    // this.CapacidadSndService.capacidadTotalGrado(codSostenedor,this.ActualAnoEscolar).subscribe(
    //   result => {
    //     if( result.success ) {
    //       jsonData = result.data;
    //       rsData2 = jsonData.datos;
    //       niveles = jsonData.labels.niveles;
    //       rsTable = jsonData.labels;

    //       this.recalcularGraphBarra(rsData2, this.ActualAnoEscolar, jsonData.colors, jsonData.xkeys);
    //       this.recalcularTablaCapacidadUsoNiveles(rsTable, niveles);
    //       this.loading = false;
    //     } else {
    //       $.notify(
    //         {icon: 'notifications', message: "No hay datos para imprimir"}, 
    //         {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
    //       });
    //       setTimeout(() => { this.loading = false}, 700);
    //     }
    //   },
    //   error => {
    //     setTimeout(() => { this.loading = false}, 700);
    //     this.api_error = true;
    //   }
    // );
  }

  recalcularTablaCapacidadUsoNiveles(rsTable, niveles) {
    this.loading = true;
    this.rowHeadTablePorcUso = [];
    this.rowBodyTablePorcUso = [];
    this.colBodyTable = [];
    this.rowHeadTableCapUso = [];
    this.rowBodyTableCapUso = [];
    this.listNivelesPorcUso = [];
    this.listNivelesCapUso = [];
    this.rowFootTableCapUso = [];
    this.dataTableExcelPorcUso = [];

    let tHeadPorcUso = [];
    let tHeadCapUso  = [];
    let tFootCapUso  = [];
    let totalNivelesCapUso = [];
    let arrNivelCol = [];

    if (niveles) {
      
      tHeadPorcUso.push('Establecimiento');
      tHeadCapUso.push('Establecimiento');
      tFootCapUso.push('Total');

      Object.keys(niveles).forEach(key => {
        tHeadPorcUso.push(niveles[key]);
        tHeadCapUso.push(niveles[key]);
        totalNivelesCapUso[niveles[key]] = 0;
        this.listNivelesPorcUso.push(niveles[key]);
        this.listNivelesCapUso.push(niveles[key]);
        arrNivelCol[niveles[key]] = '';
      });
      this.listNivelesCapUso.push('Total');
      
      tHeadCapUso.push('Total');
      totalNivelesCapUso['Total'] = 0; 
      this.rowHeadTablePorcUso = tHeadPorcUso;
      this.rowHeadTableCapUso  = tHeadCapUso;
      let arrColegio = [];

      Object.keys(rsTable).forEach(key => {
        if (key != 'niveles') {
          let porcUsoCol = [];
          let cantUsoCol = [];
          let totalColegio = 0;
          porcUsoCol = arrNivelCol.slice();
          cantUsoCol = arrNivelCol.slice();
          arrColegio.push({rbd:parseInt(key),colegio:rsTable[key]['nom_colegio']});

          Object.keys(rsTable[key]['grados']).forEach(nivel => {
            console.log(nivel);
            let cant_alu:number;
            let nro_vacantes:number;
            let porcentaje:number;
            cant_alu     = parseInt(rsTable[key]['grados'][nivel]['cantidad_alumnos']);
            nro_vacantes = parseInt(rsTable[key]['grados'][nivel]['nro_vacantes']);
            porcentaje   = Math.round((cant_alu*100)/nro_vacantes);
            totalColegio = totalColegio + cant_alu;
            porcUsoCol[nivel] = porcentaje+'%';
            cantUsoCol[nivel] = this.formatNumber(cant_alu);
            totalNivelesCapUso[nivel] = cant_alu + totalNivelesCapUso[nivel];
          });

          cantUsoCol['Total'] = this.formatNumber(totalColegio);
          totalNivelesCapUso['Total'] = totalColegio + totalNivelesCapUso['Total'];
          this.rowBodyTablePorcUso[parseInt(key)] = porcUsoCol;
          this.rowBodyTableCapUso[parseInt(key)]  = cantUsoCol;
        }

      });
      this.colBodyTable =  arrColegio;
     // console.log(this.rowBodyTableCapUso, arrColegio);
      this.rowFootTableCapUso  = totalNivelesCapUso;

      Object.keys(this.colBodyTable).forEach(key=>{
        let rbd  = this.colBodyTable[key].rbd;
        let rowExcel1 = [];
        rowExcel1['Establecimiento'] =  this.colBodyTable[key].colegio;
        Object.keys(this.listNivelesPorcUso).forEach(idx=>{
          let rowHead = this.listNivelesPorcUso[idx];
          let porcentaje = this.rowBodyTablePorcUso[rbd][rowHead];
          let index = " "+rowHead;
          rowExcel1[index] = porcentaje;
        });
        this.dataTableExcelPorcUso[key] = rowExcel1;

        let rowExcel2 = [];
        rowExcel2['Establecimiento'] =  this.colBodyTable[key].colegio;
        Object.keys(this.listNivelesCapUso).forEach(idx=>{
          let rowHead  = this.listNivelesCapUso[idx];
          let cantidad = this.rowBodyTableCapUso[rbd][rowHead];
          let index = " "+rowHead;
          if (cantidad) {
            rowExcel2[index] = parseInt(cantidad.replace('.',''));
          } else {
            rowExcel2[index] = '';
          }
        });
        this.dataTableExcelCapUso[key] = rowExcel2;
      });

      let row = [];
      row['Establecimiento'] = 'Total';
      Object.keys(this.rowFootTableCapUso).forEach(idx=>{
        let total = parseInt(this.rowFootTableCapUso[idx]);
        let index = " "+idx;
        row[index] = total;
      });
      this.dataTableExcelCapUso.push(row);

    } else {
      $.notify(
        {icon: 'notifications', message: "No hay datos para imprimir"}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
      });
    }
    this.loading = false;
  }

  listAnosEscolaresSostenedor() {
    this.graphService.removeActiveTab();
    this.ListAnoEscolar = []

    let rsData = [];
    let recurso: string;
    let idSelect: string;
    let jsonData: any;
    let AnoEscolar = [];
    let codSostenedor: number;
    let opcion: number;
    let data: string;
    let currentTime = new Date()
    let ano_actual = currentTime.getFullYear();

    for (var _i = ano_actual; _i >= 2005; _i--) {
        this.SlcAnoEsoclar.push({ano_escolar: _i});
        this.ListAnoEscolar.push({ano_escolar: _i});
    }
    /*if (localStorage.getItem('cod_sostenedor')) {

      let currentTime = new Date()
      let ano_actual = currentTime.getFullYear();

      codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));
      this.loading = true;
      this.EvolucionMatriculaSndService.evolucionMatriculaSostenedor(codSostenedor,ano_actual).subscribe(
        result => {
          if( result.success ) {
            let jsonData = result.data;
            this.rsData = jsonData.datos;
            AnoEscolar  = jsonData.xkeys;
            let anoEsta = AnoEscolar.indexOf(ano_actual.toString());
            if (anoEsta == -1) {
              AnoEscolar.push(ano_actual.toString());
            }
            AnoEscolar = this.eliminarDuplicateInArray(AnoEscolar);

            let arrAnoEscolar = [];
            Object.keys(AnoEscolar).forEach(key => {
              arrAnoEscolar.push(parseInt(AnoEscolar[key]));
            });
            arrAnoEscolar.sort(function(a, b){return b - a});
            
            Object.keys(arrAnoEscolar).forEach(key => {
              var xano = {};
              xano["ano_escolar"] =  parseInt(arrAnoEscolar[key]);
              this.ListAnoEscolar.push(xano);
              this.SlcAnoEsoclar.push(xano);
              AnoEscolar.push(this.ListAnoEscolar[key].ano_escolar);
            });

            this.SlcAnoEsoclar = this.eliminarDuplicateInArray(this.SlcAnoEsoclar);
            this.loading = false;
          } else {
            $.notify(
              { icon: 'notifications', message: "No hay datos para imprimir" }, 
              {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
            );
            setTimeout(() => { this.loading = false}, 700);
          }
        },
        error => {
          setTimeout(() => { this.loading = false}, 700);
          this.api_error = true;
        }
      );
    }*/
  }

  recalcularTablaCapacidadUsoCol(rsTable) {
    if (rsTable['0']) {
      let codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));
      this.titulo_excel = 'Capacidad_uso_total_por_establecimientos_sostenedor_'+codSostenedor;
      this.dataTable = [];
      this.dataTableExcel=[];

      Object.keys(rsTable).forEach(key => {
        if (key != 'colegios') {
          let matriculados:number;
          let nro_vacantes:number;
          let cod_colegio:number;
          let nom_colegio:string;
          let porc_uso:number;
          let porc_matricula_disp:number;
          let cant_matricula_disp:number;
          let cant_matricula_disp_sin_formato:number;

          matriculados = parseInt(rsTable[key].matriculados);
          nro_vacantes = parseInt(rsTable[key].nro_vacantes);
          cod_colegio = parseInt(rsTable[key].cod_colegio);
          nom_colegio  = rsTable[key].nom_colegio;
          porc_uso = Math.round((matriculados*100)/(nro_vacantes));
          porc_matricula_disp = 100 - porc_uso;
          cant_matricula_disp = this.formatNumber(nro_vacantes - matriculados);
          cant_matricula_disp_sin_formato = nro_vacantes - matriculados;

          this.dataTable.push({
            colegio: nom_colegio,
            cant_uso: this.formatNumber(matriculados),
            porcentaje_uso: porc_uso + '%',
            disponible: cant_matricula_disp,
            porcentaje_disponible: porc_matricula_disp + '%',
          });

          this.dataTableExcel.push({
            Establecimiento: nom_colegio,
            Cantidad_en_uso: matriculados,
            Porcentaje_uso: porc_uso + '%',
            Cantidad_matricula_disponible: cant_matricula_disp_sin_formato,
            Porcentaje_matricula_disponible: porc_matricula_disp + '%',
          });
        }
      });
    
    } else {
      $.notify(
        {icon: 'notifications', message: "No hay datos para imprimir"}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
      });
    }
  }

  recalcularGraphBarra2(rsData, anoEscolar) {
    this.loading = true;
    this.chart = new Chart({
      lang: {
        downloadPNG: "Descargar a PNG",
        downloadJPEG: "Descargar a JPEG",
        downloadSVG: "Descargar a SVG",
        downloadPDF: "Descargar a PDF",
        loading: "Cargando",
        printChart: "Imprimir",
        contextButtonTitle: 'Opciones gráfico'
      },
      chart: {
        type: 'column'
      },
      title: {
        text: 'Capacidad de uso ' + anoEscolar
      },
      xAxis: {
        categories: [''],
        min: null,
        max: null,
        title: {
          text: null
        }
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: "Porcentaje de capacidad de uso"
        },
        labels: {
          overflow: "justify",
          format: '{value}'
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        valueSuffix: '%',
        formatter: function() {
          return "" + this.x + "<br/>" + this.series.name + " : <strong>" + Highcharts.numberFormat(this.y, 0, ",", ".")+"%</strong>";
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            shadow: true
          }
        },
        series: {
          stacking: null,
          dataLabels: {
            rotation: null,
            formatter: function() {
              return "<strong>" + this.y + "%</strong> ";
            },
            enabled: true,
            verticalAlign: 'middle',
            align: 'center',
            inside: true,
          }
        }
      },
      series: rsData,
      colors: ['#4572A7', '#AA4643', '#89A54E', '#80699B', '#3D96AE', '#DB843D', '#92A8CD', '#A47D7C', '#B5CA92']
    });
    this.loading = false;
  }

  recalcularGraphBarra(rsData, anoEscolar, colors, categoria, tipo = 'consolidado') {
    this.loading = true;
    var rotacionDataLabels = -90;
    if (tipo == 'comparativo') {
      rotacionDataLabels = null;
    }

    this.chart = new Chart({
      lang: {
        downloadPNG: "Descargar a PNG",
        downloadJPEG: "Descargar a JPEG",
        downloadSVG: "Descargar a SVG",
        downloadPDF: "Descargar a PDF",
        loading: "Cargando",
        printChart: "Imprimir",
        contextButtonTitle: 'Opciones gráfico'
      },
      chart: {
        type: 'column'
      },
      title: {
        text: 'Capacidad de uso ' + anoEscolar
      },
      xAxis: {
        categories: categoria,
        min: null,
        max: null,
        title: {
          text: null
        }
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: "Porcentaje de capacidad de uso"
        },
        labels: {
          overflow: "justify",
          format: '{value}'
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        valueSuffix: '%',
        formatter: function() {
          return "" + this.x + "<br/>" + this.series.name + " : <strong>" + Highcharts.numberFormat(this.y, 0, ",", ".")+"%</strong>";
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            shadow: true
          }
        },
        series: {
          stacking: null,
          dataLabels: {
            rotation: rotacionDataLabels,
            formatter: function() {
              if (tipo == 'consolidado') {
                return this.series.name + ": <strong>" + this.y + "%</strong> ";
              } else {
                return "% uso: <strong>" + this.y + "%</strong> ";
              }
            },
            enabled: true,
            verticalAlign: 'middle',
            align: 'center',
            inside: true,
          }
        }
      },
      series: rsData,
      colors: colors
    });
    this.loading = false;
  }

  recalculaGraphPie(rsData, anoEscolar) {
    this.loading = true;
    if (rsData.length>0) {
      this.chart = new Chart({
        lang: {
          downloadPNG: "Descargar a PNG",
          downloadJPEG: "Descargar a JPEG",
          downloadSVG: "Descargar a SVG",
          downloadPDF: "Descargar a PDF",
          loading: "Cargando",
          printChart: "Imprimir",
          contextButtonTitle: 'Opciones gráfico'
        },
        chart: {
          type: 'pie'
        },
        title: {
          text: 'Capacidad de uso ' + anoEscolar
        },
        credits: {
          enabled: false
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
          }
        },
        series: [{
          name: 'Capacidad de uso',
          data: [{
            name: 'Disponibles',
            y: rsData[anoEscolar]["disponible"],
            sliced: true,
            selected: true,
            color: '#1EA559'

          }, {
            name: 'Matriculados',
            y: rsData[anoEscolar]["matricula"],
            color: '#FB3948'
          }]
        }]
      });

      this.dataTable = [];
      this.dataTableExcel =[];
      let codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));

      for (var k in rsData) {
        if (parseInt(k)>0) {
          this.dataTable.push({
            anoEscolar: k,
            disponible: rsData[k]["disponible"] + '%',
            matricula: rsData[k]["matricula"] + '%',
          });

          this.titulo_excel = "Capacidad_uso_total_sostenedor_"+codSostenedor+"_periodo_"+this.ActualAnoEscolar;
          this.dataTableExcel.push({
            Año_escolar: k,
            Disponible: rsData[k]["disponible"] + '%',
            Matriculados: rsData[k]["matricula"] + '%',
          });
        }
      };
      this.loading = false;
    } else {
      $.notify({
        icon: 'notifications',
        message: "No hay datos para imprimir"
      },
      {
        type: 'warning',
        timer: 2000,
        delay: 2000,
        mouse_over: 'pause',
        placement: {
          from: 'bottom',
          align: 'right'
        }
      });
      this.loading = false;
    }
  }

  //Exporta json a excel
  exportAsXLSX(opcion:number=1):void {
    let titulo = '';
    let codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));
    switch (opcion) {
      case 1:
        this.excelService.exportAsExcelFile(this.dataTableExcel, this.titulo_excel);
        break;
      case 2:
        titulo = 'Capacidad_uso_porcentaje_uso_nivel_periodo_'+this.ActualAnoEscolar+'_sostenedor_'+codSostenedor;
        this.excelService.exportAsExcelFile(this.dataTableExcelPorcUso, titulo);
      case 3:
        titulo = 'Capacidad_uso_nivel_periodo_'+this.ActualAnoEscolar+'_sostenedor_'+codSostenedor;
        this.excelService.exportAsExcelFile(this.dataTableExcelCapUso, titulo);
        break;
      default:
        $.notify(
          {icon: 'notifications', message: 'Si usted llego aquí es que ha modificado las opciones permitidas ..'}, 
          {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
        );
    }
  }

  eliminarDuplicateInArray(arreglo) {
    let uniqueNames = [];
    $.each(arreglo, function(i, el){
        if($.inArray(el, uniqueNames) === -1) uniqueNames.push(el);
    });
    return uniqueNames;
  }

  formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }

  numbreRand(min: number, max: number, cant: number) {
    let array = [];
    if (cant == 1) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    } else {
      for (let index = 0; index < cant; index++) {
        array.push(Math.floor(Math.random() * (max - min + 1)) + min);
      }
      return array.sort();
    }
  }

  sl2_style() {
    $(".c-btn").css("box-shadow", "none");
    $(".c-btn").css("border-top", "none");
    $(".c-btn").css("border-left", "none");
    $(".c-btn").css("border-right", "none");
    $(".c-btn").css("color", "#989898");
    $(".c-btn").css("background-color", "#ededee");
    $(".sl2-material > div >  .dropdown-list").css("position", "absolute");
  }

  /*removeActiveTab() {
    $("#pill4").parent().removeClass("active");
    $("#tab4").parent().removeClass("active");
    $("#tab40").parent().removeClass("active");
    $("#pill5").parent().removeClass("active");
    $("#tab5").parent().removeClass("active");
    $("#tab50").parent().removeClass("active");
    $("#pill6").parent().removeClass("active");
    $("#tab6").parent().removeClass("active");
    $("#pill7").parent().removeClass("active");
    $("#tab7").parent().removeClass("active");
  }*/
}
