import { Subscription } from 'rxjs/Subscription';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { PuenteService } from '../../services/puente.service';
import { urlServer } from '../../services/url.service';
import { Graph } from '../../models/graph.model';
import { ListaAgnosService } from '../../services/shared/listaagnos.service';
import { HttpPuenteService } from '../../services/shared/httppuente.service';
import { DatatablesService } from '../../services/shared/datatables.service';
import { GraficasService } from '../../services/shared/graficas.service';
import { ExcelService } from '../../services/excel.service';
declare const $: any;
@Component({
  selector: 'app-administracion',
  templateUrl: './administracion.component.html',
  styleUrls: ['./administracion.component.scss']
})
export class AdministracionComponent implements OnInit, OnDestroy {
  Puente: Subscription;
  graph:Graph;
  ActualAnoEscolar: any;
  titulo_excel:string = '';
  nombre_colegio:string = '';
  codColegio:string = '';
  adminsle=false;
  adminsost=false;
  admincoleg=false;
  datos=true;
  public loading:boolean = true;
  public api_error:boolean = false;
  SlcAnoEscolar  = [];
  dataTable      = [];
  dataTableExcel = [];

  constructor(private puente: PuenteService,private IGservice: HttpPuenteService,private DataTables: DatatablesService,private Agnos: ListaAgnosService, private httppuente: HttpPuenteService,private graphService: GraficasService, private excelService:ExcelService) { 

    this.ActualAnoEscolar = this.Agnos.ActualAnoEscolar;
    this.Puente = this.puente.getData().subscribe(data => {
    this.administracion_colegio(0);      
    });
  }
  ngOnDestroy(): void {
    this.Puente.unsubscribe();
  }
  ngOnInit() {
    this.SlcAnoEscolar=this.Agnos.listAnosEscolares(this.ActualAnoEscolar);
    this.administracion_colegio(1);
  }
  
  onClick(event,data) {  
    this.administracion_colegio(3,data.codigo,data.agrupacion);
  }  

  administracion_colegio(admin,colegio?: string,nombre_colegio?: string){
    
    let recurso='';
    let idSelect: string;
    let jsonData: any;
    let params: any;
    this.titulo_excel = '';
    this.nombre_colegio = '';
    this.dataTable = [];
    this.dataTableExcel = [];
    this.api_error = false;
    this.loading = true;
    
    if (localStorage.getItem('select_colegio')) {
      jsonData = JSON.parse(localStorage.getItem('select_colegio'));
      idSelect = jsonData.id
    } 
    else if (localStorage.getItem('select_sost')) {     
      jsonData = JSON.parse(localStorage.getItem('select_sost'));
      idSelect = jsonData.id
    } 
    else if (localStorage.getItem('select_slep')) {     
      jsonData = JSON.parse(localStorage.getItem('select_slep'));
      idSelect = jsonData.id
    }

  let administracion_colegio_post = urlServer + '/administracion';
  let tipo = "";
    if (localStorage.getItem('select_colegio')) {
      recurso = urlServer + '/colegioselector/' + idSelect;
      this.admincoleg=false;
      this.adminsle=false;
      this.adminsost=true;
      this.titulo_excel = 'Administración_comuna';
      tipo = "2";
    } 
    else if (localStorage.getItem('select_sost')) {
      recurso = urlServer + '/rbd/sostenedor/' + idSelect;
      this.admincoleg=false;
      this.adminsle=false;
      this.adminsost=true;
      this.titulo_excel = 'Administración_comuna';
      tipo = "2";
    } 
    else if(localStorage.getItem('select_slep')) {
      recurso = urlServer + '/rbd/slep/' + idSelect;
      this.admincoleg=false;
      this.adminsost=false;
      this.adminsle=true;
      this.titulo_excel = 'Administración_sostenedor';
      tipo = "1";
      
    } else {
      recurso = null
    }
    
    
    if(recurso ){
      if(!colegio){
        let arrCodColegios = [];
        let arrIdColegios = [];
      this.IGservice.getData(recurso).then((data) => {
          Object.keys(data.data).forEach(key => {
              arrIdColegios.push(key);
              arrCodColegios.push(data.data[key]);
          });

          let ListCodColegios = arrCodColegios.toString();
          params = {
              "grupo_colegios": ListCodColegios,
              "ano_escolar": this.ActualAnoEscolar,
              "tipo": tipo
          }

          this.IGservice.getDataPost(administracion_colegio_post,params)
          .then((data) => {
            
            let jsonData = data.data;
            Object.keys(jsonData).forEach(indice => {              
              this.dataTable.push({
                agrupacion: jsonData[indice]["nom_colegio"],
                habilitados:jsonData[indice]["habilitados"],
                total_cursos:jsonData[indice]["total_cursos"],
                total_asignaturas:jsonData[indice]["total_asignaturas"],
                alumnos_inscritos:jsonData[indice]["alumnos_inscritos"],
                alumnos_matriculados:jsonData[indice]["alumnos_matriculados"],
                codigo:jsonData[indice]["cod_colegio"]
              });
              this.dataTableExcel.push({
                agrupacion: jsonData[indice]["nom_colegio"],
                habilitados:jsonData[indice]["habilitados"],
                total_cursos:jsonData[indice]["total_cursos"],
                total_asignaturas:jsonData[indice]["total_asignaturas"],
                alumnos_inscritos:jsonData[indice]["alumnos_inscritos"],
                alumnos_matriculados:jsonData[indice]["alumnos_matriculados"],
              });
            });
            if(this.dataTable.length==0){    
              this.loading = false;        
              $.notify(
                { icon: 'notifications', message: "No hay datos para imprimir" }, 
                { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
              );
              
            }
            else{
              //this.dataTableExcel = this.dataTable;
              this.loading = false;
            }
          })
          .catch((err) => {
            this.loading = false;
            $.notify(
              { icon: 'notifications', message: "No hay datos para imprimir" }, 
              { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
            );
          });
      })
      .catch((err) => {
        this.loading = false;
        $.notify(
          {icon: 'notifications', message: err.message}, 
          {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
        );
      });
    }
    else{     
      if(admin==3){
        tipo = "3";
        this.adminsost=false;
            this.adminsle=false;
            this.admincoleg=true;
            this.titulo_excel = 'Administración_detalle';
            this.nombre_colegio=nombre_colegio;
      }
        params = {
            "grupo_colegios": colegio,
            "ano_escolar": this.ActualAnoEscolar,
            "tipo": tipo
        }

        this.IGservice.getDataPost(administracion_colegio_post,params)
        .then((data) => {
          this.loading = false;
          let jsonData = data.data;
          Object.keys(jsonData).forEach(indice => {
            this.dataTable.push({
              Tipo_ensenanza: jsonData[indice]["cod_param"],
              Grado:jsonData[indice]["nom_param"],
              Curso:jsonData[indice]["letra"],
              Capacidad:jsonData[indice]["nro_vacantes"],
              Jornada:jsonData[indice]["jornada"],
              Tipo_curso:jsonData[indice]["tipo_curso"],
              Prof_jefe:jsonData[indice]["run_profesor_jefe"],
              Nom_Prof_jefe:jsonData[indice]["nom_profesor_jefe"],
              Decreto_evaluacion:jsonData[indice]["nro_decreto_eval"],
              Plan_estudio:jsonData[indice]["plan_estudio"],
              Asignarturas_segun_plan:jsonData[indice]["total_asignatura_plan"],
              Asignarturas_configuradas:jsonData[indice]["total_asignaturas_conf"]

                          });
          });
          if(this.dataTable.length==0){       
            this.loading = false;     
            $.notify(
              { icon: 'notifications', message: "No hay datos para imprimir" }, 
              { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
            );
            
          }
          else{
            this.dataTableExcel = this.dataTable;
          }
         
        })
        .catch((err) => {
          this.loading = false;
          $.notify(
            { icon: 'notifications', message: "No hay datos para imprimir" }, 
            { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
          );
        }); 
    }
  }
  else{
    $.notify(
      {icon: 'notifications', message: 'Revise selección en el menú por favor.'}, 
      {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
    );
  }
  }
  //Exporta json a excel
  exportAsXLSX(opcion:number=1):void {
    let titulo = '';
    this.excelService.exportAsExcelFile(this.dataTableExcel, this.titulo_excel);    
  }

}
