import { Subscription } from 'rxjs/Subscription';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as Chartist from 'chartist';
import { DateAdapter } from '@angular/material';
import { Chart } from 'angular-highcharts';
import { PuenteService } from '../../services/puente.service';
import { urlServer } from '../../services/url.service';
import { AlumnosRetiradosSndService } from '../../services/api-snd/alumnos-retirados.service';
import { EvolucionMatriculaSndService } from '../../services/api-snd/evolucion-matricula.service';
import * as Highcharts from "highcharts";
import * as jsPDF from "jspdf";
import * as html2canvas from "html2canvas";
import {ExcelService} from '../../services/excel.service';
//AS
import { ListaAgnosService } from '../../services/shared/listaagnos.service';
import { HttpPuenteService } from '../../services/shared/httppuente.service';
//import { AlumnosRetiradosService } from '../../services/alumnos-retirados.service';
//import { RangoNotasService } from './../../services/rango-notas.service';
//AS
declare const require: any;
declare const $: any;
import * as _ from 'lodash';

@Component({
  selector: 'app-alumnos-retirados',
  templateUrl: './alumnos-retirados.component.html',
  styleUrls: ['./alumnos-retirados.component.scss'],
  providers:[AlumnosRetiradosSndService,EvolucionMatriculaSndService]
})

export class AlumnosRetiradosComponent implements OnInit, OnDestroy {
  chart: Chart;
  chartMotivos: Chart;
  //MES_NOM_COMPLETO = ["", "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  //MES_NOMBRES = ["", "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
  // AS Uso servicio Años
  MESES = [];//["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
  dataTable = [];
  dataTableExcel = [];
  titulo_excel = 'Ejemplo';
  dataTableHead = [];
  aluRetirados = false;
  aluRetiradosMotivo = false;
  aluRetiradosComparativo = false;
  spinner = false;
  color = 'red';
  mode = 'indeterminate';
  value = 50;
  data_puente: any;
  mdPerfil: any;
  Puente: Subscription;
  ActualAnoEscolar: any;
  SlcAnoEsoclar = [];
  rsData = [];
  linkParams: any;
  xkeys = [];
  ykeys = "";
  colegios = [];
  motivos = [];
  arrColegio = [];
  arrComunas = [];
  codColegio = [];
  resultados = [];
  totalesPorColegio = {};
  totalesPorMotivo = {};
  totalConsolidado = 0;
  ListAnoEscolar = [];

  public loading:boolean = true;
  public api_error:boolean = false;
  public dataAluRetiradosDep:boolean = false; 
  public dataAluRetiradosSnd:boolean = false; 

  //constructor(private puente:PuenteService, private IGservice: AlumnosRetiradosService, private alumnosRetiradosSndService:AlumnosRetiradosSndService, private EvolucionMatriculaSndService:EvolucionMatriculaSndService,  private excelService:ExcelService, private IAservice: RangoNotasService,private Agnos: ListaAgnosService) {
    constructor(private puente:PuenteService, private IGservice: HttpPuenteService, private alumnosRetiradosSndService:AlumnosRetiradosSndService, private EvolucionMatriculaSndService:EvolucionMatriculaSndService,  private excelService:ExcelService, private IAservice: HttpPuenteService,private Agnos: ListaAgnosService) {  
  this.Puente = this.puente.getData().subscribe(data => {
      this.removeActiveTab();
      if (this.aluRetirados) {
        this.alu_retirados();
      }else if (this.aluRetiradosMotivo) {
        this.alu_retirados_motivo();
      }
    });
  }

  ngOnDestroy(): void {
    this.Puente.unsubscribe();
  }

  ngOnInit() {
    this.MESES =this.Agnos.MESES;
    this.ActualAnoEscolar = this.Agnos.ActualAnoEscolar; // AS new Date().getFullYear();    
    this.listAnosEscolaresSostenedor();
    
    this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));
    this.removeActiveTab();

    if (sessionStorage.getItem('link_param')) {
      this.aluRetirados = false;
      this.aluRetiradosMotivo = false;
      this.linkParams = sessionStorage.getItem('link_param');
      sessionStorage.removeItem('link_param');
    }

    switch (this.linkParams) {
      case 'pill1':
        this.aluRetirados = true;
        this.alu_retirados();
        break;
      case 'pill2':
        this.aluRetiradosMotivo = true;
        this.alu_retirados_motivo();
        break;
      default:
        this.aluRetirados = true;
        this.alu_retirados();
    }

    this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));
    this.sl2_style();
  }

  Gpdf() {
    this.loading = true;
    if (this.rsData.length != 0) {
      this.spinner = true;
      var divWidth = $(".highcharts-container").width() - 20;;
      var originalBodyWidth = document.body.offsetWidth;
      var originalBodyHeight = document.body.offsetHeight;
      document.body.style.width = "1920px";
      document.body.style.height = "1080px";


      $('.graphClass').highcharts().setSize("1100", false);
      $('.table').css("width","1100px");
      setTimeout(() => {
        var Doc = new jsPDF('l', 'pt', "A4");
        html2canvas(document.querySelector(".page1"), {
          onclone: function (document) {
            document.querySelector(".page1").classList.remove("col-md-8");
          }
        }).then(canvas => {
          var imgData = canvas.toDataURL('image/png');
          var width = Doc.internal.pageSize.width;
          var height = Doc.internal.pageSize.height;
          Doc.addImage(imgData, 'PNG', 0, 0);
          Doc.addPage();

          html2canvas(document.querySelector(".page2"), {
            onclone: function (document) {
              document.querySelector(".page2").style.width = "100%";
            }
          }).then(canvas => {
            var imgData = canvas.toDataURL('image/png');
            var width = Doc.internal.pageSize.width;
            var height = Doc.internal.pageSize.height;
            Doc.addImage(imgData, 'PNG', 0, 0);
            Doc.save('Indicadores de gestión.pdf')
            document.body.removeAttribute("style");
            $('.graphClass').highcharts().setSize(divWidth, false);
            $('.table').css("width","100%");
            this.spinner = false;
          });
        });

      }, 1000);
      this.loading = false;
    } else {
      $.notify(
        {icon: 'notifications', message: "No hay datos para imprimir"}, 
        {type: 'warning', timer: 2000,delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
      );
      setTimeout(() => { this.loading = false}, 700);
    }
  }

  alu_retirados_motivo() {
    this.removeActiveTab();
    $("#pill2").parent().addClass("active");
    $("#tab2").parent().addClass("active");

    this.aluRetirados = false;
    this.aluRetiradosMotivo = true;
    this.aluRetiradosComparativo = false;
    this.dataTableExcel = [];
    this.dataTable = [];
    this.dataTableHead = [];
    this.rsData = [];
    this.SlcAnoEsoclar = []
    this.resultados = [];
    this.arrColegio = [];
    this.arrComunas = [];
    this.codColegio = [];
    this.motivos = [];
    this.colegios = [];

    let rsData = [];
    let recurso: string;
    let idSelect: string;
    let jsonData: any;
    let params: any;
    let AnoEscolar = [];
    let cod_sostenedor : number;
    let ArrMeses = [];
    let ArrDataAno = [];
    let list = [];
    let recurso_retirados = urlServer + '/alumnos_retirados';
    let arrCodColegios = [];
    let arrIdColegios = [];

    cod_sostenedor = parseInt(localStorage.getItem('cod_sostenedor'));

    if (this.SlcAnoEsoclar.length==0) {
      Object.keys(this.ListAnoEscolar).forEach(key => {
        let xano = {};
        xano["ano_escolar"] = this.ListAnoEscolar[key].ano_escolar;
        this.SlcAnoEsoclar.push(xano);
      });
    }

    this.SlcAnoEsoclar = this.eliminarDuplicateInArray(this.SlcAnoEsoclar);
    this.loading = true;
    this.api_error = false;

    if (localStorage.getItem('select_colegio')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_colegio'));
        idSelect = jsonData.id
    } 
    else if (localStorage.getItem('select_sost')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_sost'));
        idSelect = jsonData.id
    }
     else if (localStorage.getItem('select_slep')) {
        list = JSON.parse(localStorage.getItem('ListSost'));
        jsonData = JSON.parse(localStorage.getItem('select_slep'));
        idSelect = jsonData.id
    }

    if (localStorage.getItem('select_colegio')) {
      recurso = urlServer + '/colegioselector/' + idSelect;
    } 
    else if (localStorage.getItem('select_sost')) {
      recurso = urlServer + '/rbd/sostenedor/' + idSelect;
    } 
    else if (localStorage.getItem('select_slep')) {
      recurso = urlServer + '/rbd/slep/' + idSelect;
    } 
    else {
      recurso = null
    }

    if(recurso){
        this.IAservice.getData(recurso).then((data) => {
            Object.keys(data.data).forEach(key => {
                arrIdColegios.push(key);
                arrCodColegios.push(data.data[key]);
            });

            let ListCodColegios = arrCodColegios.toString();
            let ListIdColegios  = arrIdColegios.toString();

            params = {
                "grupo_colegios": ListCodColegios,
                "ano_escolar": this.ActualAnoEscolar,
                "tipo": "3"
            }

            this.IGservice.getDataPost(recurso_retirados, params).then((result) => {
              this.graficoDataMotivos(result, cod_sostenedor);
            })
            .catch((err) => {
              $.notify(
                {icon: 'notifications', message: err.message}, 
                {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
              );
            });
        })
        .catch((err) => {
          $.notify(
            {icon: 'notifications', message: err.message}, 
            {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
          );
        });
    }
    else{
      $.notify(
        {icon: 'notifications', message: 'Revise selección en el menú por favor.'}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
      );
    }
    
  }

  graficoDataMotivos(result, cod_sostenedor) {
    if( result.success ) {
      let data = result.data;
      this.rsData = data.datos;
      this.xkeys = data.xkeys;
      this.ykeys = data.ykeys;
      this.colegios = data.colegios;
      this.recargaGraficoRetiradosMotivo(this.rsData, this.ActualAnoEscolar);
      this.dataTableHead.push("Establecimiento");
      this.dataTableHead.push("Comuna");

      var dataobj = {};
      var motivos = {};
      var nomMotivos = {};
      var arrColegioObj = {};
      var arrComunasObj = {};

      var obj = {};
      Object.keys(this.colegios).forEach(key => {
        dataobj[this.colegios[key].order] = {};
      });

      Object.keys(this.colegios).forEach(key => {
          motivos[this.colegios[key].numero] = this.colegios[key].numero;
          nomMotivos[this.colegios[key].numero] = this.colegios[key].motivo;
          arrColegioObj[this.colegios[key].order] = this.colegios[key].nom_colegio;
          arrComunasObj[this.colegios[key].order] = this.colegios[key].comuna;
          if(dataobj[this.colegios[key].order]){
            dataobj[this.colegios[key].order][this.colegios[key].numero] = this.colegios[key].total_retirados;
          }
          if(obj[this.colegios[key].numero]!=undefined){
            obj[this.colegios[key].numero] = parseInt(obj[this.colegios[key].numero]) + parseInt(this.colegios[key].total_retirados);
          }else{
            obj[this.colegios[key].numero] = parseInt(this.colegios[key].total_retirados);
          }
      });

      Object.keys(arrColegioObj).forEach(key => {
        this.arrColegio.push(arrColegioObj[key]);
        this.arrComunas.push(arrComunasObj[key]);
        this.codColegio.push(key);
      });
      Object.keys(motivos).forEach(key => {
        this.motivos.push(motivos[key]);
        this.dataTableHead.push(nomMotivos[key]);
      });

      var total = 0;
      var totalesColegio = {};
      Object.keys(dataobj).forEach(cod_colegio => {
        var totales = parseInt(totalesColegio[cod_colegio]);
        Object.keys(dataobj[cod_colegio]).forEach(numero => {
          if(totalesColegio[cod_colegio]!=undefined){
            totalesColegio[cod_colegio] = parseInt(totalesColegio[cod_colegio]) + parseInt(dataobj[cod_colegio][numero]);
          }else{
            totalesColegio[cod_colegio] = parseInt(dataobj[cod_colegio][numero]);
          }
        });
        total = total + totalesColegio[cod_colegio];
      });

      this.totalConsolidado = total;
      this.dataTable.push(dataobj);
      this.totalesPorColegio = totalesColegio;
      this.totalesPorMotivo = obj;
      this.resultados = this.dataTable["0"];
      this.dataTableHead.push("Total");
      this.dataTableExcel =[];
      let tablaExcel = [];

      Object.keys(arrColegioObj).forEach(key => {
        let rowExcel = [];
        rowExcel['Establecimiento'] = arrColegioObj[key];
        let motivosColegio = dataobj[key];
        let totalColegio:number =0;
        Object.keys(nomMotivos).forEach(idx => {
          if (nomMotivos[idx]) {
            rowExcel[nomMotivos[idx]] = '';
            if (motivosColegio[idx]) {
              rowExcel[nomMotivos[idx]] = parseInt(motivosColegio[idx]);
              totalColegio += parseInt(motivosColegio[idx]);
            }
          }
        });
        rowExcel['Total'] = totalColegio;
        tablaExcel.push(rowExcel);
      });

      let rowExcel = [];
      let xTotal:number = 0;
      rowExcel['Establecimiento'] = 'Total';
      Object.keys(this.totalesPorMotivo).forEach(key => {
        rowExcel[nomMotivos[key]] = (parseInt(this.totalesPorMotivo[key])>0) ? parseInt(this.totalesPorMotivo[key]) : '';
        xTotal += parseInt(this.totalesPorMotivo[key]);
      });
      rowExcel['Total'] = xTotal;
      tablaExcel.push(rowExcel);

      this.titulo_excel = "Motivos_retiros_sostenedor_"+cod_sostenedor+"_periodo_"+this.ActualAnoEscolar
      this.dataTableExcel =tablaExcel;
      this.loading = false;
    } else {
      this.loading = false;
      $.notify(
        {icon: 'notifications', message: result.message}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right'}}
      );
      setTimeout(() => { this.loading = false}, 700);
    }
  }

  recargarRetiradosMotivo(items: any) {
    this.recargaGraficoRetiradosMotivo(this.rsData, items.value);
  }

  recargaGraficoRetiradosMotivo(rsData, ActualAnoEscolar) {

    let meses = [];
    let data = [];
    this.dataTable = [];

    for (var key in rsData[ActualAnoEscolar]) {
      meses.push(rsData[ActualAnoEscolar][key]["MES"]);
      data.push(rsData[ActualAnoEscolar][key]["RETIRADOS"])
    }

    this.chartMotivos = new Chart({
      lang: {
        downloadPNG: "Descargar a PNG",
        downloadJPEG: "Descargar a JPEG",
        downloadSVG: "Descargar a SVG",
        downloadPDF: "Descargar a PDF",
        loading: "Cargando",
        printChart: "Imprimir",
        contextButtonTitle: 'Opciones gráfico'
      },
      chart: {
        type: "column"
      },
      title: {
        text: ""
      },
      xAxis: {
        categories: this.xkeys,
        title: {
          text: null
        },
        labels: {
          rotation: -45
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: "Retirados"
        },
        labels: {
          overflow: "justify",
          format: '{value}'
        },
        allowDecimals: false
      },
      tooltip: {
        formatter: function() {
          return "" + this.x + "<br/>" + this.series.name + " : <strong>" + Highcharts.numberFormat(this.y, 0, ",", ".") + "</strong>";
        }
      },
      plotOptions: {
        column: {
          color: "#cb4b4b",
          events: {
            legendItemClick: function () {
              return false; 
            }
          }
        },
        series: {
          dataLabels: {
            enabled: true,
            align: 'center',
            inside: false,
            color: 'black',
            style: {"color": "contrast", "fontSize": "11px", "fontWeight": "bold", "textOutline": "0px contrast" }
          }
        },
      },
      credits: {
        enabled: false
      },
      series: rsData
    });

    let randAnoEscolar: any = this.numbreRand(2010, 2018, 2);
    let randRsData: any = this.numbreRand(1, 100, 2);

    let total = 0;
    for (var k in data) {
      total = total + data[k];
    };

    var obj = {
      anoEscolar: ActualAnoEscolar,
      retirados: data,
      total: total
    };
  }

  alu_retirados() {
    this.removeActiveTab();
    $("#pill1").parent().addClass("active");
    $("#tab1").parent().addClass("active");
    $("#tab10").parent().addClass("active");
    this.aluRetirados = true;
    this.aluRetiradosMotivo = false;
    this.aluRetiradosComparativo = false;

    let rsData = [];
    let recurso: string;
    let idSelect: string;
    let jsonData: any;
    let AnoEscolar = [];
    let ArrMeses = [];
    let ArrDataAno = [];
    let params: any;
    let list: any;

    this.dataTable = [];
    this.dataTableHead = [];
    this.rsData = [];
    this.resultados = [];
    this.arrColegio = [];
    this.codColegio = [];
    this.motivos = [];
    this.loading = false;
    this.api_error = false;
    this.dataAluRetiradosDep = false;
    this.dataAluRetiradosSnd = true;
    this.dataTable = [];
    this.rsData = [];

    if (localStorage.getItem('select_colegio')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_colegio'));
        idSelect = jsonData.id
    } 
    else if (localStorage.getItem('select_sost')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_sost'));
        idSelect = jsonData.id
    } 
    else if (localStorage.getItem('select_slep')) {
        list = JSON.parse(localStorage.getItem('ListSost'));
        jsonData = JSON.parse(localStorage.getItem('select_slep'));
        idSelect = jsonData.id
    }

    let recurso_retirados = urlServer + '/alumnos_retirados';

    if (localStorage.getItem('select_colegio')) {
      recurso = urlServer + '/colegioselector/' + idSelect;
    } 
    else if (localStorage.getItem('select_sost')) {
      recurso = urlServer + '/rbd/sostenedor/' + idSelect;
    } 
    else if (localStorage.getItem('select_slep')) {
      recurso = urlServer + '/rbd/slep/' + idSelect;
    } 
    else {
      recurso = null
    }

    let arrCodColegios = [];
    let arrIdColegios = [];
    if(recurso){
        this.IAservice.getData(recurso).then((data) => {
            Object.keys(data.data).forEach(key => {
                arrIdColegios.push(key);
                arrCodColegios.push(data.data[key]);
            });

            let ListCodColegios = arrCodColegios.toString();
            let ListIdColegios  = arrIdColegios.toString();

            params = {
                "grupo_colegios": ListCodColegios,
                "ano_escolar": this.ActualAnoEscolar,
                "tipo": "2"
            }

            this.IGservice.getDataPost(recurso_retirados, params).then((data) => {
              this.graficoMotivos(data, localStorage.getItem('cod_sostenedor'));
            })
            .catch((err) => {
              $.notify(
                {icon: 'notifications', message: err.message}, 
                {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
              );
            });
        })
        .catch((err) => {
          $.notify(
            {icon: 'notifications', message: err.message}, 
            {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
          );
        });
    }
    else{
      $.notify(
        {icon: 'notifications', message: 'Revise selección en el menú por favor.'}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
      );
    }
  }

  recargarRetirados(items: any) {
    if (!localStorage.getItem('cod_sostenedor')) { 
      this.recargaGraficoRetiradosSnd(this.rsData, items.value);
    } else {
      this.recargaGraficoRetiradosDep(this.rsData, items.value);
    }
  }

  graficoMotivos(result, cod_sostenedor){
    if( result.success ) {
      this.loading = false;
      let data = result.data;
      this.rsData = data.datos;
      this.xkeys = data.xkeys;
      this.ykeys = data.ykeys;
      this.colegios = data.colegios;
      this.recargaGraficoRetiradosSnd(this.rsData, this.ActualAnoEscolar);
      this.dataTableHead.push("Establecimiento");
      this.dataTableHead.push("Comuna");
      Object.keys(this.MESES).forEach(key => {
        this.dataTableHead.push(this.MESES[key]);
      });
      this.dataTableHead.push("Total");
      this.arrComunas = [];

      var arrColegioObj = {};
      var arrComunasObj = {};
      var cantidadColegioMes = {};
      var cod_params = {};
      var obj = {};

      Object.keys(this.colegios).forEach(key => {
        if(this.colegios[key].order!=null){
          cantidadColegioMes[this.colegios[key].order] = {};
          obj[parseInt(this.colegios[key].cod_param)] = 0;
        }
      });

      Object.keys(this.colegios).forEach(key => {
        var cod_param = parseInt(this.colegios[key].cod_param);
        if(this.colegios[key].order!=null){
          arrColegioObj[this.colegios[key].order] = this.colegios[key].nom_colegio;
          arrComunasObj[this.colegios[key].order] = this.colegios[key].comuna;
          cantidadColegioMes[this.colegios[key].order][cod_param] = this.colegios[key].total_retirados;
        }
        cod_params[cod_param] = this.colegios[key].cod_param;
          if(obj[cod_param]!=undefined){
            obj[cod_param] = parseInt(obj[cod_param]) + parseInt(this.colegios[key].total_retirados);
          }
      });

      Object.keys(cod_params).forEach(key => {
        this.motivos.push(parseInt(cod_params[key]));
      });
      this.dataTable.push(cantidadColegioMes);
      this.resultados = this.dataTable['0'];

      Object.keys(arrColegioObj).forEach(key => {
        this.arrColegio.push(arrColegioObj[key]);
        this.arrComunas.push(arrComunasObj[key]);
        this.codColegio.push(parseInt(key));
      });
      this.totalesPorMotivo = obj;

      var total = 0;
      var totalesColegio = {};
      Object.keys(cantidadColegioMes).forEach(cod_colegio => {
        var totales = parseInt(totalesColegio[cod_colegio]);
        Object.keys(cantidadColegioMes[cod_colegio]).forEach(cod_param => {
          if(totalesColegio[cod_colegio]!=undefined){
            totalesColegio[cod_colegio] = parseInt(totalesColegio[cod_colegio]) + parseInt(cantidadColegioMes[cod_colegio][cod_param]);
          }else{
            totalesColegio[cod_colegio] = parseInt(cantidadColegioMes[cod_colegio][cod_param]);
          }
        });
        total = total + totalesColegio[cod_colegio];
      });

      this.totalConsolidado = total;
      this.totalesPorColegio = totalesColegio;
      this.dataTableExcel =[];
      let tablaExcel = [];

      Object.keys(arrColegioObj).forEach(key => {
        let rowExcel = [];
        let totalColegio:number =0;
        rowExcel['Establecimiento'] = arrColegioObj[key];
        let retiradosCol = cantidadColegioMes[key];
        Object.keys(this.MESES).forEach(idx => {
          let i = parseInt(idx) + 1;
          if (retiradosCol[i]) {
            rowExcel[this.MESES[idx]] = parseInt(retiradosCol[i]);
            totalColegio += parseInt(retiradosCol[i]);
          } else {
            rowExcel[this.MESES[idx]] = '';
            totalColegio += 0;
          }
        });
        rowExcel['Total'] = totalColegio;
        tablaExcel.push(rowExcel);
      });

      let rowExcel = [];
      let rowTotal = this.rsData[0].data;
      let xTotal:number = 0;

      rowExcel['Establecimiento'] = 'Total';
      Object.keys(rowTotal).forEach(key => {
        rowExcel[this.MESES[key]] = (parseInt(rowTotal[key])>0) ? parseInt(rowTotal[key]) : '';
        xTotal += parseInt(rowTotal[key]);
      });
      rowExcel['Total'] = xTotal;
      tablaExcel.push(rowExcel);
      this.titulo_excel = "Alumnos_retirados_sostenedor_"+cod_sostenedor+"_periodo_"+this.ActualAnoEscolar
      this.dataTableExcel =tablaExcel;
    } else {
      $.notify({
        icon: 'notifications',
        message: result.message
      }, {
          type: 'danger',
          timer: 2000,
          delay: 2000,
          mouse_over: 'pause',
          placement: {
            from: 'bottom',
            align: 'right'
          }
        });
    }
    setTimeout(() => { this.loading = false}, 700);
  }

  recargaGraficoRetiradosDep(rsData, ActualAnoEscolar) {
    let meses = [];
    let data = [];
    this.dataTable = [];

    for (var key in rsData[ActualAnoEscolar]) {
      meses.push(rsData[ActualAnoEscolar][key]["MES"]);
      data.push(rsData[ActualAnoEscolar][key]["RETIRADOS"])
    }

    this.chart = new Chart({
      lang: {
        downloadPNG: "Descargar a PNG",
        downloadJPEG: "Descargar a JPEG",
        downloadSVG: "Descargar a SVG",
        downloadPDF: "Descargar a PDF",
        loading: "Cargando",
        printChart: "Imprimir",
        contextButtonTitle: 'Opciones gráfico'
      },
      chart: {
        type: 'line'
      },
      title: {
        text: 'Alumnos retirados ' + ActualAnoEscolar
      },
      yAxis: {
        title: {
          text: 'Cantidad alumnos retirados'
        }
      },
      xAxis: {
        categories: meses,
        title: {
          text: ''
        }
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          pointStart: 0
        }
      },
      series: [{
        name: 'Meses',
        data: data
      }]
    });

    let randAnoEscolar: any = this.numbreRand(2010, 2018, 2);
    let randRsData: any = this.numbreRand(1, 100, 2);
    let total = 0;

    for (var k in data) {
      total = total + data[k];
    };

    var obj = {
      anoEscolar: ActualAnoEscolar,
      retirados: data,
      total: total
    };
    this.dataTable.push(obj);
  }

  recargaGraficoRetiradosSnd(rsData, ActualAnoEscolar) {
    let meses = [];
    let data = [];
    this.dataTable = [];

    for (var key in rsData[ActualAnoEscolar]) {
      meses.push(rsData[ActualAnoEscolar][key]["MES"]);
      data.push(rsData[ActualAnoEscolar][key]["RETIRADOS"])
    }

    this.chart = new Chart({
      lang: {
        downloadPNG: "Descargar a PNG",
        downloadJPEG: "Descargar a JPEG",
        downloadSVG: "Descargar a SVG",
        downloadPDF: "Descargar a PDF",
        loading: "Cargando",
        printChart: "Imprimir",
        contextButtonTitle: 'Opciones gráfico'
      },
      title: {
        text: "",
        x: -20
      },
      subtitle: {
        text: '',
        x: -20
      },
      xAxis: {
        categories: this.MESES
      },
      yAxis: {
        min: 0,
        labels: {
          format: '{value}'
        },
        title: {
          text: "Cantidad retirados"
        },
        plotLines: [
          {
            value: 0,
            width: 1,
            color: '#808080'
          }
        ]
      },
      tooltip: {
        formatter: function() {
          return "" + this.x + "<br/>" + this.series.name + " : <strong>" + Highcharts.numberFormat(this.y, 0, ",", ".") + "</strong>";
        }
      },
      plotOptions: {
        line: {
          color: "#cb4b4b",
          events: {
              legendItemClick: function () {
                  return false; 
              }
          },
          dataLabels: {
            enabled: true,
            formatter: function() {
              return Highcharts.numberFormat(this.y, 0, ',', '.');
            }
          },
          enableMouseTracking: true
        }
      },
      series: rsData,
      credits: {
        enabled: false
      },
      chart: {
        type: 'area'
      },
    
    });

    let randAnoEscolar: any = this.numbreRand(2010, 2018, 2);
    let randRsData: any = this.numbreRand(1, 100, 2);
    let total = 0;

    for (var k in data) {
      total = total + data[k];
    };

    var obj = {
      anoEscolar: ActualAnoEscolar,
      retirados: data,
      total: total
    };
  }

  recargarAlumnosRetirados(items: any) {
    this.removeActiveTab();
    this.ActualAnoEscolar = items.value;

    if (this.aluRetirados) {
      this.alu_retirados();
    } else if (this.aluRetiradosMotivo) {
      this.alu_retirados_motivo();
    } else {
      $.notify(
        {icon: 'notifications', message: 'Opcion ingresada no es correcta'}, 
        {
          type: 'warning',
          timer: 2000,
          delay: 2000,
          mouse_over: 'pause',
          placement: {
            from: 'bottom',
            align: 'right'
          }
        }
      );
    }
  }

  numbreRand(min: number, max: number, cant: number) {
    let array = [];
    if (cant == 1) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    } else {
      for (let index = 0; index < cant; index++) {
        array.push(Math.floor(Math.random() * (max - min + 1)) + min);
      }
      return array.sort();
    }
  }

  sl2_style() {
    $(".c-btn").css("box-shadow", "none");
    $(".c-btn").css("border-top", "none");
    $(".c-btn").css("border-left", "none");
    $(".c-btn").css("border-right", "none");
    $(".c-btn").css("color", "#989898");
    $(".c-btn").css("background-color", "#ededee");
    $(".sl2-material > div >  .dropdown-list").css("position", "absolute");
  }

  removeActiveTab() {
    $("#pill1").parent().removeClass("active");
    $("#tab1").parent().removeClass("active");
    $("#tab10").parent().removeClass("active");
    $("#pill2").parent().removeClass("active");
    $("#tab2").parent().removeClass("active");
    $("#pill3").parent().removeClass("active");
    $("#tab3").parent().removeClass("active");
  }

  listAnosEscolaresSostenedor() {
    this.ListAnoEscolar = [];
    //this.ActualAnoEscolar = 0;

    let rsData = [];
    let recurso: string;
    let idSelect: string;
    let jsonData: any;
    let AnoEscolar = [];
    let codSostenedor: number;
    let opcion: number;
    let data: string;
    let currentTime = new Date()
    let ano_actual = currentTime.getFullYear();
    //AS Implementando servicio para proveer listado de Años
    this.ListAnoEscolar=this.SlcAnoEsoclar=this.Agnos.listAnosEscolares(this.ActualAnoEscolar);
    //console.log(this.ActualAnoEscolar,this.ListAnoEscolar, this.SlcAnoEsoclar)
    /*for (var _i = ano_actual; _i >= 2005; _i--) {
        this.SlcAnoEsoclar.push({ano_escolar: _i});
        this.ListAnoEscolar.push({ano_escolar: _i});
    }*/
    //AS

    /*if (localStorage.getItem('cod_sostenedor')) {
      
      let currentTime = new Date()
      let ano_actual = currentTime.getFullYear();
      codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));

      this.EvolucionMatriculaSndService.evolucionMatriculaSostenedor(codSostenedor,ano_actual).subscribe(
        result => {
          if( result.success ) {
            let jsonData = result.data;
            this.rsData = jsonData.datos;
            AnoEscolar  = jsonData.xkeys;
            let anoEsta = AnoEscolar.indexOf(ano_actual.toString());
            if (anoEsta == -1) {
              AnoEscolar.push(ano_actual.toString());
            }
            AnoEscolar = this.eliminarDuplicateInArray(AnoEscolar);

            let arrAnoEscolar = [];
            Object.keys(AnoEscolar).forEach(key => {
              arrAnoEscolar.push(parseInt(AnoEscolar[key]));
            });
            arrAnoEscolar.sort(function(a, b){return b - a});

            Object.keys(arrAnoEscolar).forEach(key => {
              var xano = {};
              xano["ano_escolar"] =  parseInt(arrAnoEscolar[key]);
              this.ListAnoEscolar.push(xano);
              this.SlcAnoEsoclar.push(xano);
              AnoEscolar.push(this.ListAnoEscolar[key].ano_escolar);
              if (this.ActualAnoEscolar) {
                if (this.ActualAnoEscolar < this.ListAnoEscolar[key].ano_escolar) {
                  this.ActualAnoEscolar = this.ListAnoEscolar[key].ano_escolar;
                }
              } else {
                this.ActualAnoEscolar = this.ListAnoEscolar[key].ano_escolar;
              }
            });
            this.SlcAnoEsoclar = this.eliminarDuplicateInArray(this.SlcAnoEsoclar);
          } else {
            $.notify(
              { icon: 'notifications', message: "No hay datos para imprimir" }, 
              {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
            );
            setTimeout(() => { this.loading = false}, 700);
          }
        },
        error => {
          setTimeout(() => { this.loading = false}, 700);
          this.api_error = true;
        }
      );
    }*/
  }

  eliminarDuplicateInArray(arreglo) {
    let uniqueNames = [];
    $.each(arreglo, function(i, el){
        if($.inArray(el, uniqueNames) === -1) uniqueNames.push(el);
    });
    return uniqueNames;
  }

  //Exporta json a excel
  exportAsXLSX():void {
    this.excelService.exportAsExcelFile(this.dataTableExcel, this.titulo_excel);
  }
}
