import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class IndicadoresGestionService {
  
  constructor(private http: HttpClient) {}

  getData(recurso) : Promise<any> {
    let url: string = `${recurso}`;
    return this.http.get(url).toPromise();
  }

}
