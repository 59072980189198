import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { urlServer } from '../url.service';

@Injectable()
export class AlumnosRetiradosSndService{
    private url:string= urlServer;

    constructor(
        public http: HttpClient
    ){}

    alumnosRetiradosSostenedor(cod_sostenedor:number,ano_escolar:number): Observable<any>{
        return this.http.get(this.url+'/sostenedor/'+cod_sostenedor+'/alumnosretirados/anoescolar/'+ano_escolar+'/tipo/1');
    }

    motivosRetiroSostenedor(cod_sostenedor:number,ano_escolar:number): Observable<any> {
        return this.http.get(this.url+'/sostenedor/'+cod_sostenedor+'/alumnosretirados/anoescolar/'+ano_escolar+'/tipo/2');
    }

    alumnosRetiradosSnd(json_param:string): Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this.http.post(this.url+'/alumnos_retirados', json_param, {headers: headers});
    }
}
