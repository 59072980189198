import { Injectable } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { Graph } from './../../models/graph.model';
import * as Highcharts from "highcharts";
declare const $: any;

@Injectable()
export class GraficasService {

  public Graph: Graph;
  public tabs:any;
  constructor() { }

  /**
   * buildGraph
   */
  public buildGraph(graph,rsData, anoEscolar, colors, categoria, tipo) {
    console.log('objeto',graph);

    let chart=new Chart({
      lang: {
        downloadPNG: "Descargar a PNG",
        downloadJPEG: "Descargar a JPEG",
        downloadSVG: "Descargar a SVG",
        downloadPDF: "Descargar a PDF",
        loading: "Cargando",
        printChart: "Imprimir",
        contextButtonTitle: 'Opciones gráfico'
      },
      chart: {
        type: 'column'
      },
      title: {
        text: graph.titulo
      },
      xAxis: {
        categories: categoria,
        min: null,
        max: null,
        title: {
          text: null
        },
        labels: {
          staggerLines:graph.linesEscalonadas
        }
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: "Porcentaje de asistencia"
        },
        labels: {
          overflow: "justify",
          format: '{value}'
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        valueSuffix: '%',
        formatter: function() {
          return "" + this.x + "<br/>" + this.series.name + " : <strong>" + Highcharts.numberFormat(this.y, 0, ",", ".")+"%</strong>";
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            shadow: true
          }
        },
        series: {
          stacking: 'normal',
          dataLabels: {
            rotation: null,
            formatter: function() {
              return this.y + "%";
            },
       
            enabled: true,
            verticalAlign: 'middle',
            align: 'center',
            inside: true
          }
        }
      },
      series: rsData,
      colors: colors
    });
    return chart;
  }
/**
 * Desactivar la propiedad activa de los tabs indicados
 */
  removeActiveTab() {
    Object.keys(this.tabs).forEach(key => {
     // console.log('recorrer',this.tabs[key]);
      $("#"+this.tabs[key]).parent().removeClass("active");
    });   

  }

  /**
   * 
   * @param arreglo 
   */
  eliminarDuplicateInArray(arreglo) {
    let uniqueNames = [];
    $.each(arreglo, function(i, el){
        if($.inArray(el, uniqueNames) === -1) uniqueNames.push(el);
    });
    return uniqueNames;
  }
}
