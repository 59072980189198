import { PuenteService } from './../../services/puente.service';
import { urlServer } from './../../services/url.service';
import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { CookieService } from 'ngx-cookie-service';

import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
//AS
import { MatDialog } from '@angular/material';
import { HttpPuenteService } from './../../services/shared/httppuente.service';
import {ChangepassmailComponent } from '../../system/changepassmail/changepassmail.component';
// import { SidebarService } from './../../services/sidebar.service';
//AS

declare var jquery: any;
declare var $: any;

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
    //AS
    class?: string;
    //AS
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}


//Menu Items
export const ROUTES: RouteInfo[] = [
    {
        path: '/system/dashboard',
        title: 'Panel de indicadores',
        type: 'link',
        icontype: 'dashboard',
    },
    {
        path: '/system/dashboardsnd',
        title: 'Dashboard-snd',
        type: 'link',
        icontype: 'dashboard'
    },
    {
        path: '/system/indicadores-academicos',
        title: 'Indicadores académicos',
        type: 'sub',
        icontype: 'school',
        collapse: 'indAcademicos',
        children: [
           {path: 'promedio-asignatura', title: 'Promedio asignatura', ab : ''},
           //{path: 'promedio-periodo', title: 'Promedio período', ab : ''},
           {path: 'rango-notas', title: 'Rango notas', ab : ''},
           //AS
           {path: 'resultado-simce', title: 'Resultado Simce', ab : ''}
           //AS
        ]
    },
    {
        path: '/system/indicadores-gestion',
        title: 'Indicadores de gestión',        
        type: 'sub',
        icontype: 'timeline',
        collapse: 'indGestion',
        children: [
           {path: 'evolucion-matricula', title: 'Evolución matrícula', ab : ''},
           {path: 'asistencia', title: 'Asistencia', ab : ''},
           {path: 'alumnos-prioritarios', title: 'Alumnos prioritarios',type: 'link', ab : ''},
           {path: 'alumnos-preferentes', title: 'Alumnos preferentes',type: 'link', ab : ''},
           {path: 'alumnos-retirados', title: 'Alumnos retirados',type: 'link', ab : ''},
           {path: 'capacidad-uso',type: 'link', title: 'Capacidad de uso', ab : ''}           
        ]
    },
    // AS Opcion de Menú para Indicadores de Uso
   {
        path: '/system/indicadores-uso',
        title: 'Indicadores de uso',
        type: 'sub',
        icontype: 'line_style',
        collapse: 'indUso',
        children: [
           {path: 'administracion', title: 'Administración', ab : ''},
           {path: 'libro-clases', title: 'Libro de clases', ab : ''}
          /* {path: 'biblioteca', title: 'Biblioteca', ab : ''},
           
           {path: 'planificacion', title: 'Planificación',type: 'link', ab : ''},
           {path: 'portal-padapo', title: 'Portal Padres y Apoderados',type: 'link', ab : ''},
           {path: 'recaudacion',type: 'link', title: 'Recaudacion', ab : ''}      */  
        ]
    },
    // AS
    {
        path: '/system/notificaciones',
        title: 'Notificaciones',
        type: 'link',
        icontype: 'comment'
    },
    {
        path: '/system/mantenedor-usuario',
        title: 'Mantenedor de usuario',
        type: 'link',
        icontype: 'group'
    }
];
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})





export class SidebarComponent implements OnInit {
    [x: string]: any;
    ListSlep = [];    
    ListSost = [];
    ListCole = [];
    // AS
    ListParentCole = [];
    ListProfilesPers= [];
    selectedSlep = [];
    selectedSost = [];
    selectedCole = [];
    settings1 = {};
    settings2 = {};
    settings3 = {};
    public urlR_tmp: any;
    token: any

    nombre_slep: string;
    nom_user: string;
    mdPerfil: any;
    slepseSelect: any;
    linkDep:string = '/#/system/dashboard';

    public menuItems: any[];
    public sin_select_aninados:boolean = false;
    public sin_select_colegios:boolean = false;

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };
    //AS
    //constructor(private sdServ: SidebarService, private cookieService: CookieService, private puente: PuenteService, private router:Router) { }
    constructor(private sdServ: HttpPuenteService, private cookieService: CookieService, private puente: PuenteService,private auth: AuthService, private router:Router, public dialog: MatDialog) {
        this.onVerificPassword();
     }

    
    ngOnInit() {

        try {
   
            this.menuItems = ROUTES.filter(menuItem => menuItem);
            this.nom_user = this.sdServ.formatName(atob(localStorage.getItem(btoa('nombre'))));
            this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));
            const token = this.cookieService.get(btoa('token'));            
            //Analisamos el perfil
            switch (this.mdPerfil.nivel) {
                
                case 0:
                    //this.sin_select_aninados = false;
                    this.sin_select_colegios = true;
                    this.urlR_tmp = urlServer + "/" + this.mdPerfil.tipo_nivel + "/" + this.mdPerfil.codigo
                  
                    this.sdServ.getDataSelect(this.urlR_tmp, this.token)
                        .then((data) => {
                            this.ListSlep = [];
                            Object.keys(data.data).forEach(key => {
                                let nombre = this.addslashes(data.data[key].nombre);
                                let option = '{ "id": ' + data.data[key].id + ', "itemName": "' + nombre + '" }'
                                this.ListSlep.push(JSON.parse(option))
                            });
                            localStorage.setItem('ListSlep', JSON.stringify(this.ListSlep))
                            setTimeout(() => {
                                this.sl2_style();
                            }, 1000);

                            
                            Object.keys(this.menuItems).forEach(key => {
                                if (this.menuItems[key].path == '/system/dashboardsnd') {
                                    this.menuItems[key].title = 'Panel de indicadores';
                                    this.menuItems[key].type = '';
                                }
                                if (this.menuItems[key].path == '/system/dashboard') {
                                    this.menuItems[key].type = 'link';
                                }
                            });
                        })
                        .catch((err) => {
                            $.notify(
                                { icon: 'notifications', message: err.message}, 
                                { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'} }
                            );
                        });

                    break;

                case 1:
                    
                    this.sin_select_aninados = false;
                    this.sin_select_colegios = true;

                    this.urlR_tmp = urlServer + "/" + this.mdPerfil.tipo_nivel + "/" + this.mdPerfil.codigo 
                    this.sdServ.getDataSelect(this.urlR_tmp, this.token)
                        .then((data) => {
                            this.nombre_slep = data.nom_slep;
                            this.ListSlep = [];
                            let nombre = this.addslashes(data.nom_slep);
                            let option = '{ "id": ' + data.id + ', "itemName": "' + nombre + '" }'
                            this.ListSlep.push(JSON.parse(option))                            
                            localStorage.setItem('ListSlep', JSON.stringify(this.ListSlep)) 

                           if (JSON.parse(localStorage.getItem('ListSlep')).length==1) {
                            localStorage.setItem('select_slep', JSON.stringify(JSON.parse(option)))
                            this.selectedSlep.push(JSON.parse(localStorage.getItem('select_slep')));
                            this.onItemSelect(JSON.parse(localStorage.getItem('select_slep')),1)
                            }
                            

                            Object.keys(this.menuItems).forEach(key => {
                                if (this.menuItems[key].path == '/system/dashboardsnd') {
                                    this.menuItems[key].title = 'Panel de indicadores';
                                    this.menuItems[key].type = '';
                                }

                                if (this.menuItems[key].path == '/system/dashboard') {                                    
                                    this.menuItems[key].type = 'link';
                                }
                            });                            

                        })
                        .catch((err) => {
                            $.notify(
                                { icon: 'notifications', message: err.message }, 
                                { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
                            );
                        });
                          setTimeout(() => {
                                this.sl2_style();
                            }, 1000); 
                           
                    this.urlR_tmp = urlServer + "/" + this.mdPerfil.tipo_nivel + "/" + this.mdPerfil.codigo + "/tree"
                    if (JSON.parse(localStorage.getItem('ListSost')) && JSON.parse(localStorage.getItem('ListSost')).length==0) {
                    this.sdServ.getDataSelect(this.urlR_tmp, this.token)
                        .then((data) => {
                            this.ListSost = [];                            
                            Object.keys(data.data).forEach(key => {
                                   this.ListCole = [];
                                   Object.keys(data.data[key].sostenedores).forEach(idSos => {                                    
                                        let nombre = this.addslashes(data.data[key].sostenedores[idSos].nom_sostenedor);
                                        let option = '{ "id": ' + idSos + ', "itemName": "' + nombre + '" }'
                                        this.ListSost.push(JSON.parse(option))
                                        // AS generar listado de colegios                                     
                                        Object.keys(data.data[key].sostenedores[idSos].colegios).forEach(keycolegio => {
                                            let nombre = this.addslashes(data.data[key].sostenedores[idSos].colegios[keycolegio].nom_colegio);
                                            let option = '{ "id": ' + data.data[key].sostenedores[idSos].colegios[keycolegio].id + ', "itemName": "' + nombre + '" }'
                                                this.ListCole.push(JSON.parse(option))
                                        });
                                        localStorage.setItem('ListCole', JSON.stringify(this.ListCole));
                                   });
                            });
                            localStorage.setItem('ListSost', JSON.stringify(this.ListSost))
                            setTimeout(() => {
                                this.sl2_style();
                            }, 1000);
                        })
                        .catch((err) => {
                            $.notify(
                                { icon: 'notifications', message: err.message }, 
                                { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
                            );
                        });
            }
                    break;

                case 2:
                    this.sin_select_aninados = false;
                    this.sin_select_colegios = true;
                    

                    //AS Agregar listado de comunas al selector del menu

                    let Urlsostenedorusuario = urlServer + "/sostenedores/" + localStorage.getItem(btoa('id_usuario')) + "/tree";
                    this.sdServ.getDataSelect(Urlsostenedorusuario, this.token).then((data) => {
                        this.ListSost = []; 
                        this.ListCole = [];                      
                            Object.keys(data.sostenedores).forEach(idSos => {   
                            //                              
                                 let nombre = this.addslashes(data.sostenedores[idSos].nom_sostenedor);
                                 //console.log('listsostenedor',nombre,data.sostenedores[idSos].id_sostenedor);
                                 let option = '{ "id": ' + data.sostenedores[idSos].id_sostenedor + ', "itemName": "' + nombre + '" }'
                                 this.ListSost.push(JSON.parse(option))
                                 // AS generar listado de colegios                                     
                               /*  Object.keys(data.data.sostenedor[idSos].colegios).forEach(keycolegio => {
                                     let nombre = this.addslashes(data.data.sostenedor[idSos].colegios[keycolegio].nom_colegio);
                                     let option = '{ "id": ' + data.data.sostenedor[idSos].colegios[keycolegio].id + ', "itemName": "' + nombre + '" }'
                                         this.ListCole.push(JSON.parse(option))
                                 });
                                 localStorage.setItem('ListCole', JSON.stringify(this.ListCole));*/
                            });
                        localStorage.setItem('ListSost', JSON.stringify(this.ListSost));
                        
                         if (JSON.parse(localStorage.getItem('ListSost')) && JSON.parse(localStorage.getItem('ListSost')).length==1) {                            
                            localStorage.setItem('select_sost', localStorage.getItem('ListSost'));
                            this.selectedSost.push(JSON.parse(localStorage.getItem('select_sost'))[0]);
                            this.onItemSelect(this.selectedSost[0],2);//localStorage.getItem('select_sost'),2)
                            }
                            
                            
                            this.linkDep = '/#/system/dashboardsnd';    
                            /* Object.keys(this.menuItems).forEach(key => {
                                if (this.menuItems[key].path == '/system/dashboard') {
                                    this.menuItems[key].type = '';
                                }
                                if (this.menuItems[key].path == '/system/dashboardsnd') {
                                    this.menuItems[key].title = 'Panel de indicadores';
                                    this.menuItems[key].type = 'link';
                                }
                            }); */



                            Object.keys(this.menuItems).forEach(key => {                                
                                if (this.menuItems[key].path == '/system/dashboardsnd') {
                                    this.menuItems[key].title = 'Panel de indicadores';
                                    this.menuItems[key].type = '';
                                }
                                if (this.menuItems[key].path == '/system/dashboard') {
                                    this.menuItems[key].type = 'link';
                                }
                            });

                        setTimeout(() => {
                            this.sl2_style();
                        }, 1000);
                    })
                    .catch((err) => {
                        $.notify(
                            { icon: 'notifications', message: err.message }, 
                            { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
                        );
                    });
                    // AS cambiando para obtener nuevos perfiles basados en sostenedores
                    
                     // AS


                   /* this.sin_select_colegios = true;
                    this.Urlsostenedor = urlServer + "/" + this.mdPerfil.tipo_nivel + "/" + this.mdPerfil.codigo
                    this.sdServ.getDataSelect(this.Urlsostenedor, this.token).then((data) => {
                        localStorage.setItem('cod_sostenedor', JSON.stringify(JSON.parse(data['cod_sostenedor'])));


                        this.urlR_tmp = urlServer + "/" + this.mdPerfil.tipo_nivel + "/" + this.mdPerfil.codigo + "/tree"
                    
                    this.sdServ.getDataSelect(this.urlR_tmp, this.token)
                        .then((data) => {
                            console.log('url',this.urlR_tmp,data);
                            this.ListSost = [];
                            
                          //  Object.keys(data.data).forEach(key => {
                                   this.ListCole = [];
                                   console.log('sostenedor',data.data); 
                                   Object.keys(data.data.sostenedor).forEach(idSos => {      
                                    console.log('sostenedor',data.data.sostenedor[idSos]);                              
                                        let nombre = this.addslashes(data.data.sostenedor.nom_sostenedor);
                                        let option = '{ "id": ' + idSos + ', "itemName": "' + nombre + '" }'
                                        this.ListSost.push(JSON.parse(option))
                                        // AS generar listado de colegios                                     
                                        Object.keys(data.data.sostenedor[idSos].colegios).forEach(keycolegio => {
                                            let nombre = this.addslashes(data.data.sostenedor[idSos].colegios[keycolegio].nom_colegio);
                                            let option = '{ "id": ' + data.data.sostenedor[idSos].colegios[keycolegio].id + ', "itemName": "' + nombre + '" }'
                                                this.ListCole.push(JSON.parse(option))
                                        });
                                        localStorage.setItem('ListCole', JSON.stringify(this.ListCole));
                                   });
                          //  });
                            localStorage.setItem('ListSost', JSON.stringify(this.ListSost));
                            
                            setTimeout(() => {
                                this.sl2_style();
                            }, 1000);
                        })
                        .catch((err) => {
                            $.notify(
                                { icon: 'notifications', message: err.message }, 
                                { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
                            );
                        });


                    })
                    .catch((err) => {
                        $.notify(
                            { icon: 'notifications', message: err.message }, 
                            { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
                        );
                    });
                    

                    

                    this.urlR_tmp = urlServer + "/" + this.mdPerfil.tipo_nivel + "/" + this.mdPerfil.codigo + "/colegios"
                    this.sdServ.getDataSelect(this.urlR_tmp, this.token)
                        .then((data) => {
                            this.ListCole = [];
                            Object.keys(data.data).forEach(key => {
                                let nombre = this.addslashes(data.data[key].nom_colegio);
                                let option = '{ "id": ' + data.data[key].id + ', "itemName": "' + nombre + '" }'
                                this.ListCole.push(JSON.parse(option))
                            });
                            localStorage.setItem('ListCole', JSON.stringify(this.ListCole))
                            //localStorage.setItem('Cod_sostenedor', JSON.stringify(this.ListCole))
                            let nombre = this.addslashes(this.mdPerfil.nombre);
                            let option = '{ "id": ' + this.mdPerfil.codigo + ', "itemName": "' + nombre + '" }'
                            localStorage.setItem('select_sost', JSON.stringify(JSON.parse(option)))
                            console.log('sostenedor',localStorage);
                            setTimeout(() => {
                                this.sl2_style();
                            }, 1000);

                            
                            this.linkDep = '/#/system/dashboardsnd';    
                            Object.keys(this.menuItems).forEach(key => {
                                if (this.menuItems[key].path == '/system/dashboard') {
                                    this.menuItems[key].type = '';
                                }
                                if (this.menuItems[key].path == '/system/dashboardsnd') {
                                    this.menuItems[key].title = 'Panel de indicadores';
                                    this.menuItems[key].type = 'link';
                                }
                            });
                        })
                        .catch((err) => {
                            $.notify(
                                { icon: 'notifications', message: err.message }, 
                                { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
                            );
                        });*/

                        //AS
                    break;

                case 3:
                    this.sin_select_colegios = true;
                    //this.urlR_tmp = urlServer + "/" + this.mdPerfil.tipo_nivel + "/" + this.mdPerfil.codigo
                    let Urlcolegiosusuario = urlServer + "/colegiosperfiles/" + localStorage.getItem(btoa('id_usuario')) + "/tree";
                    this.sdServ.getDataSelect(Urlcolegiosusuario, this.token)
                        .then((data) => {
                            
                            this.ListParentCole = []; 
                            this.ListCole = [];                            
                            Object.keys(data.colegios).forEach(keycolegio => {
                                let nombre = this.addslashes(data.colegios[keycolegio].nom_colegio);
                                let option = '{ "id": ' + data.colegios[keycolegio].id + ', "itemName": "' + nombre + '" }'
                                this.ListCole.push(JSON.parse(option))
                                let parent = '{ "id_col": ' + data.colegios[keycolegio].id + ', "id_sost": "' + data.colegios[keycolegio].id_sost+ '" }'                                                      
                                this.ListParentCole.push(JSON.parse(parent));
                            });
                            localStorage.setItem('ListCole', JSON.stringify(this.ListCole)); 
                            localStorage.setItem('ListParentCole', JSON.stringify(this.ListParentCole));
                            if (JSON.parse(localStorage.getItem('ListCole')) && JSON.parse(localStorage.getItem('ListCole')).length==1) {                            
                                localStorage.setItem('select_colegio', localStorage.getItem('ListCole'));
                                this.selectedCole.push(JSON.parse(localStorage.getItem('select_colegio'))[0]);
                                }
                            
                            setTimeout(() => {
                                this.sl2_style();
                            }, 1000);
                            Object.keys(this.menuItems).forEach(key => {                                
                                if (this.menuItems[key].path == '/system/dashboardsnd') {
                                    this.menuItems[key].title = 'Panel de indicadores';
                                    this.menuItems[key].type = '';
                                }
                                if (this.menuItems[key].path == '/system/dashboard') {
                                    this.menuItems[key].type = 'link';
                                }
                            });
                        })
                        .catch((err) => {
                            $.notify(
                                { icon: 'notifications', message: err.message }, 
                                { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
                            );
                        });
                    break;
                    case 5: // AS Para perfil personalizado
                        this.sin_select_colegios = true;
                        let Urlcolegiosusuariopersonalizado = urlServer + "/colegiosperfiles/" + localStorage.getItem(btoa('id_usuario')) + "/tree";
                        this.sdServ.getDataSelect(Urlcolegiosusuariopersonalizado, this.token)
                        .then((data) => {
                            this.ListCole = [];
                            this.ListParentCole = [];                             
                            Object.keys(data.colegios).forEach(keycolegio => {
                                let nombre = this.addslashes(data.colegios[keycolegio].nom_colegio);
                                let option = '{ "id": ' + data.colegios[keycolegio].id + ', "itemName": "' + nombre + '" }'
                                this.ListCole.push(JSON.parse(option));      
                                let parent = '{ "id_col": ' + data.colegios[keycolegio].id + ', "id_sost": "' + data.colegios[keycolegio].id_sost+ '" }'                                                      
                                this.ListParentCole.push(JSON.parse(parent));
                            });
                            localStorage.setItem('ListCole', JSON.stringify(this.ListCole));
                            localStorage.setItem('ListParentCole', JSON.stringify(this.ListParentCole));
                            if (JSON.parse(localStorage.getItem('ListCole')) && JSON.parse(localStorage.getItem('ListCole')).length==1) {                            
                                localStorage.setItem('select_colegio', localStorage.getItem('ListCole'));
                                this.selectedCole.push(JSON.parse(localStorage.getItem('select_colegio'))[0]);
                                this.onItemSelect(this.selectedCole[0],3);
                                }
                            this.auth.getProfiles(localStorage.getItem(btoa('id_persona'))).then((res) => {                                
                               const respuesta = res;
                               if (typeof respuesta.usuario !== 'undefined') {
                                // this.userProfiles = respuesta.usuario;        
                                this.ListProfilesPers = [];
                            Object.keys(respuesta.usuario).forEach(key => {
                               // console.log('gradosper',respuesta,respuesta[key].codigo)
                                let option = '{ "cod_grade": ' + respuesta.usuario[key].cod_grado + ', "tipo": "' + respuesta.usuario[key].id_perfil+ '" ,"id_subject": ' + respuesta.usuario[key].id_asignatura + '}'
                                this.ListProfilesPers.push(JSON.parse(option))
                            });
                            localStorage.setItem('ListProfilesPers', JSON.stringify(this.ListProfilesPers))
                               }                            
                             }, (err) => {
                         
                             });
                            setTimeout(() => {
                                this.sl2_style();
                            }, 1000);
                            Object.keys(this.menuItems).forEach(key => {
                               
                                if (this.menuItems[key].path == '/system/dashboardsnd') {
                                    this.menuItems[key].title = 'Panel de indicadores';
                                    this.menuItems[key].type = '';
                                }
                                if (this.menuItems[key].path == '/system/dashboard') {
                                    this.menuItems[key].type = 'link';
                                }
                            });
                        })
                        .catch((err) => {
                        
                            $.notify(
                                { icon: 'notifications', message: err.message }, 
                                { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
                            );
                        });
                    break;
            }


            if (JSON.parse(localStorage.getItem('ListSlep'))) {
                this.ListSlep = JSON.parse(localStorage.getItem('ListSlep'));
            }

            if (JSON.parse(localStorage.getItem('ListSost'))) {
                this.ListSost = JSON.parse(localStorage.getItem('ListSost'));
            }

            if (JSON.parse(localStorage.getItem('ListCole'))) {
                
                this.ListCole = JSON.parse(localStorage.getItem('ListCole'));
            }

            /* if (JSON.parse(localStorage.getItem('select_slep'))) {
               console.log('selecto',JSON.parse(localStorage.getItem('select_slep'))); 
                this.selectedSlep.push(JSON.parse(localStorage.getItem('select_slep')));
            }             
            else {
                console.log('vaciosle');
                this.selectedSlep = [];
            }  

            if (JSON.parse(localStorage.getItem('select_sost'))) {
                this.selectedSost.push(JSON.parse(localStorage.getItem('select_sost')));
            } 
            else {
                console.log('vacio');
                this.selectedSost = [];
            }

            if (JSON.parse(localStorage.getItem('select_colegio'))) {
                this.selectedCole.push(JSON.parse(localStorage.getItem('select_colegio')));
            } 
            else {
                this.selectedCole = [];
            }*/





            this.settings3 = {
                singleSelection: true,
                text: "Seleccionar slep",
                selectAllText: 'Marcar todo',
                unSelectAllText: 'Desmarcar todo',
                searchPlaceholderText: 'Buscar',
                enableSearchFilter: true,
                badgeShowLimit: 3,
                classes: "sl2-sidebar"
            };
            this.settings2 = {
                singleSelection: true,
                text: "Seleccionar",
                selectAllText: 'Marcar todo',
                unSelectAllText: 'Desmarcar todo',
                searchPlaceholderText: 'Buscar',
                enableSearchFilter: true,
                badgeShowLimit: 3,
                classes: "sl2-sidebar"
            };
            this.settings1 = {
                singleSelection: true,
                text: "Seleccionar colegio",
                selectAllText: 'Marcar todo',
                unSelectAllText: 'Desmarcar todo',
                searchPlaceholderText: 'Buscar',
                enableSearchFilter: true,
                badgeShowLimit: 3,
                classes: "sl2-sidebar"
            };
            
        
            
        } catch (error) {
            localStorage.setItem("code_error","501");
            localStorage.setItem("code_mensaje","Datos no coinciden con el sistema, por favor inicie nuevamente");
            this.router.navigateByUrl('/error_page');
        }

        
    }
    updatePS(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            let ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: true });
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    onItemSelect(item: any, tipo: any) {      
        switch (tipo) {
            case 1:
                this.selectedSost = [];
                this.ListSost = [];
                localStorage.removeItem('select_sost');
                localStorage.removeItem('ListSost');
                this.selectedCole = [];
                this.ListCole = [];
                localStorage.removeItem('select_colegio');
                localStorage.removeItem('ListCole');

                this.urlR_tmp = urlServer + "/slep/" + item.id + "/tree";

                 this.sdServ.getDataSelect(this.urlR_tmp, this.token)
                    .then((data) => {
                        Object.keys(data.data.slep.sostenedores).forEach(key => {
                            let nombre = this.addslashes(data.data.slep.sostenedores[key].nom_sostenedor);
                            let option = '{ "id": ' + data.data.slep.sostenedores[key].id + ', "itemName": "' + nombre + '" }'
                            this.ListSost.push(JSON.parse(option))
                        });
                        localStorage.setItem('ListSost', JSON.stringify(this.ListSost))
                        localStorage.setItem('select_slep', JSON.stringify(item))
                        setTimeout(() => { this.sl2_style();}, 500);
                        this.sendRequest();
                    })
                    .catch((err) => {
                        $.notify(
                            { icon: 'notifications', message: err.message }, 
                            { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
                        );
                    }); 

                break;

            case 2:
                this.selectedCole = [];
                this.ListCole = [];
                localStorage.removeItem('select_colegio');
                localStorage.removeItem('ListCole');
                
                this.Urlsostenedor = urlServer + "/sostenedor/" + item.id;
                this.sdServ.getDataSelect(this.Urlsostenedor, this.token).then((dataSost) => {
                    localStorage.removeItem('cod_sostenedor');
                    localStorage.setItem('cod_sostenedor', JSON.stringify(JSON.parse(dataSost['cod_sostenedor'])));
                });

                this.urlR_tmp = urlServer + "/sostenedor/" + item.id + "/colegios";
                //this.urlR_tmp = urlServer + "/sostenedor/" + JSON.parse(item)[0].id + "/colegios";
                this.sdServ.getDataSelect(this.urlR_tmp, this.token)
                    .then((data) => {
                        Object.keys(data.data).forEach(key => {
                            let nombre = this.addslashes(data.data[key].nom_colegio);
                            let option = '{ "id": ' + data.data[key].id + ', "itemName": "' + nombre + '" }'
                            this.ListCole.push(JSON.parse(option));
                        });
                        localStorage.setItem('ListCole', JSON.stringify(this.ListCole))
                        if (JSON.parse(localStorage.getItem('select_sost')) && JSON.parse(localStorage.getItem('select_sost')).length==1) { 
                            localStorage.removeItem('select_sost');  
                        }
                        localStorage.setItem('select_sost', JSON.stringify(item));
                        setTimeout(() => {this.sl2_style();}, 500);
                        this.sendRequest();
                    })
                    .catch((err) => {
                        $.notify(
                            { icon: 'notifications', message: err.message }, 
                            { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
                        );
                    });

                break;

            case 3:
                if (JSON.parse(localStorage.getItem('select_colegio')) && JSON.parse(localStorage.getItem('select_colegio')).length==1) { 
                    localStorage.removeItem('select_colegio');  
                }
                localStorage.setItem('select_colegio', JSON.stringify(item));
                this.sendRequest();
                break;
            // AS Perfil    
            case 4:
                    localStorage.setItem('select_perfil', JSON.stringify(item));
                    this.sendRequest();
                    break;            
        }

        setTimeout(() => {
            this.sl2_style();
        }, 1000);
    }


    OnItemDeSelect(item: any, tipo: any) {

        switch (tipo) {
            case 1:
                localStorage.removeItem('select_slep');
                localStorage.removeItem('select_sost');
                localStorage.removeItem('select_colegio');
                localStorage.removeItem('ListSost');
                localStorage.removeItem('ListCole');
                this.selectedSlep = [];
                this.selectedSost = [];
                this.selectedCole = [];

                this.ListSost = [];
                this.ListCole = [];

                break;
            case 2:
               // console.log('deselect',item,item.id);
                localStorage.removeItem('select_sost');
                localStorage.removeItem('select_colegio');
                localStorage.removeItem('ListCole');
                this.selectedSost = [];
                this.selectedCole = [];
                this.ListCole = [];

                break;
            case 3:
                localStorage.removeItem('select_colegio');
                // localStorage.removeItem('ListCole');
                // this.selectedCole = [];

                break;
        }

        let recargar: any;
        recargar = true;
        this.puente.setData([recargar]);
    }
    onSelectAll(items: any) {
    }
    onDeSelectAll(items: any) {
    }

    sl2_style() {

        $(".c-btn").css("box-shadow", "none");
        $(".c-btn").css("border-top", "none");
        $(".c-btn").css("border-left", "none");
        $(".c-btn").css("border-right", "none");
        $(".c-btn").css("color", "#989898");
        $(".sl2-sidebar > div > .dropdown-list").css("position", "relative");
        $(".sl2-sidebar > div > .dropdown-list > .list-area > .lazyContainer > span > .pure-checkbox label  ").append("<style>label::before{border-radius:30px !important;}</style>")
        $(".sl2-sidebar > div > div > div > span").each(function (index) {
            if (this.className != "fa fa-angle-down") {
                if (this.innerText.length != 42 && this.innerText.length != 0) {
                    $(this).css("width", "90%").css("overflow", "hidden"); // .css("border", "1px solid green")
                }
            }

        });
    }

    addslashes(str) {
        str = str.replace(/\\/g, '\\\\');
        str = str.replace(/\'/g, '\\\'');
        str = str.replace(/\'/g, '\\\' ');
        str = str.replace(/\"/g, '\\"');
        str = str.replace(/\0/g, '\\0');
        return str;
    }

    stripslashes(str) {
        str = str.replace(/\\'/g, '\'');
        str = str.replace(/\\"/g, '"');
        str = str.replace(/\\0/g, '\0');
        str = str.replace(/\\\\/g, '\\');
        return str;
    }

    sendRequest() {
        let recargar: any;
        recargar = true;
        this.puente.setData([recargar]);
    }

    onLogout():void{

        localStorage.removeItem(btoa('token'));
        this.cookieService.delete(btoa('token'));
        localStorage.removeItem(btoa('id_usuario'));
        localStorage.removeItem(btoa('usuario'));
        localStorage.removeItem(btoa('nombre'));
        localStorage.removeItem(btoa('perfil'));
        this.router.navigateByUrl('/');
    }
    //AS
    openDialog(){
  let config = {
    panelClass: 'bookCardDialog',
    maxWidth: '100vw',
    maxHeight: '100vh',
    height: '100%',
    width: '100%'
  };
        this.dialog.open(ChangepassmailComponent,config);
    }
    onVerificPassword(){
        if(atob(localStorage.getItem(btoa('cambio_password')))==='true'){
            this.openDialog();
        }
    }
}


//export var urlRecurso: string = this.urlR_tmp;
