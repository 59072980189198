import { Component, ElementRef, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup, AbstractControl,FormControl, ValidationErrors } from '@angular/forms';
//AS
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { User } from '../../models/user';
import { MatDialog } from '@angular/material';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-changepassmail',
  templateUrl: './changepassmail.component.html',
  styleUrls: ['./changepassmail.component.scss']
})

export class ChangepassmailComponent implements OnInit {
registerForm: FormGroup;
  userIdProfileLogged = '';
  userProfileLogged = '';
  old_password='';
  new_password='';
  new_password_revew='';
  user: User = new User();
  private nativeElement: Node;
  saveChangeDisable: Boolean = true;

  constructor(private auth: AuthService,private element: ElementRef,private router: Router,private formBuilder: FormBuilder, private cookieService: CookieService, public dialog: MatDialog) {
    this.nativeElement = element.nativeElement;
    this.userIdProfileLogged = localStorage.getItem(btoa('id_usuario'));
    this.userProfileLogged = atob(localStorage.getItem(btoa('usuario')));
    this.createForm();
   }

  ngOnInit() {
  }

  onshowPass(pass){
    
    let change = $("#"+pass);
    let botview= $("#"+pass+"_vis");
    if(change[0].type=="password"){
      change[0].type = "text";
      botview[0].innerText= "visibility_off";
    }
    else{
      change[0].type = "password";
      botview[0].innerText= "visibility";
    }
  }
  onChangePassword(): void {
    if (this.registerForm.valid) {
        // AS diferenciar parametros para el guardado de perfil personalizado          
        if(this.new_password_revew==this.new_password)
        {
           let params = {
             id_usuario: this.userIdProfileLogged,
             username:this.userProfileLogged,
             old_password:  this.old_password,
             new_password:  this.new_password
           };
         
           this.auth.updatePassword(params).then((res) => {
             $.notify({
               icon: 'notifications',
               message: `${res.message}
                          Cerrando Sesión`
             }, {
               type: 'success',
               timer: 1500,
               delay: 1000,
               mouse_over: 'pause',
               placement: {
                   from: 'bottom',
                   align: 'right'
               }
             });setTimeout(() => {
              this.closeDialog();
          }, 2000);            
                 
           }) .catch((err) => {
            $.notify(
              { icon: 'notifications', message: err.error.message }, 
              { type: 'warning', timer: 1500, delay: 1500, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
            );
          });
        }
        else{
          $.notify(
            { icon: 'notifications', message: 'Verifique nueva contraseña propuesta.' }, 
            { type: 'warning', timer: 1500, delay: 1500, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
          );
        } 
              
    }
    else {
      this.validateAllFormFields(this.registerForm);
      $.notify({
          icon: 'notifications',
          message: 'Complete los datos de ingreso .'
      }, {
              type: 'danger',
              timer: 2000,
              delay: 2000,
              mouse_over: 'pause',
              placement: {
                  from: 'bottom',
                  align: 'right'
              }
          });
  }
  }

  createForm(){
    this.registerForm = this.formBuilder.group({
      password: ['', Validators.compose([Validators.minLength(6), Validators.required])],
      password_new: ['', Validators.compose([Validators.minLength(6), Validators.required])],
      password_new_revew: ['', Validators.compose([Validators.minLength(6), Validators.required])]
      });
  }

  
  dataChanged(item){
      if(this.new_password==this.new_password_revew){
    this.saveChangeDisable = false;
    }
    else{
      this.saveChangeDisable = true;
    }
  }

  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && form.get(field).touched;
}

displayFieldCss(form: FormGroup, field: string) {
    return {
        'has-error': this.isFieldValid(form, field),
        'has-feedback': this.isFieldValid(form, field)
    };
}


validateAllFormFields(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
          this.validateAllFormFields(control);
      }
  });
}
   /*
  * Mostrar mensaje de error
  * @param: message -> string -> Mensaje a mostrar
  * return none
  */
 formErrMessage(message) {
  //this.addProfileFormWithErr = true;
  $.notify({
    icon: 'notifications',
    message: message
  }, {
    type: 'danger',
    timer: 2000,
    delay: 2000,
    mouse_over: 'pause',
    placement: {
        from: 'bottom',
        align: 'right'
    }
  });
}

/*
* Mostrar mensaje de cambio exitoso
* @param: message -> string -> Mensaje a mostrar
* return none
*/
formSuccessMessage(message) {
 // this.addProfileFormWithErr = true;
  $.notify({
    icon: 'notifications',
    message: message
  }, {
    type: 'success',
    timer: 2000,
    delay: 2000,
    mouse_over: 'pause',
    placement: {
        from: 'bottom',
        align: 'right'
    }
  });
}
//AS
closeDialog(){
  this.dialog.closeAll();
 
}

onLogout():void{

  localStorage.removeItem(btoa('token'));
  this.cookieService.delete(btoa('token'));
  localStorage.removeItem(btoa('id_usuario'));
  localStorage.removeItem(btoa('usuario'));
  localStorage.removeItem(btoa('nombre'));
  localStorage.removeItem(btoa('perfil'));
  this.router.navigateByUrl('/');
}
}