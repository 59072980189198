import { ImprimirComponent } from './imprimir/imprimir.component';
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PrincipalComponent } from './system/principal/principal.component';
import { LoginComponent } from './login/login.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { PanelComponent } from './panel/panel.component';

const appRoutes: Routes =[

    { path: '', component: LoginComponent },
    { path: '',redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'system', component: PrincipalComponent },
    { path: 'error_page', component: ErrorPageComponent },
    { path: 'panel', component: PanelComponent },
    { path: 'imprimir', component: ImprimirComponent },
    { path: '**', redirectTo: 'login' }
    
];


export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);