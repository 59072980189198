import { Component, OnInit, OnDestroy } from '@angular/core';
import { urlServer } from '../../services/url.service';
import { Chart } from 'angular-highcharts';
import { PuenteService } from '../../services/puente.service';import { Subscription } from 'rxjs/Subscription';
import { HttpPuenteService } from '../../services/shared/httppuente.service';
import { ListaAgnosService } from '../../services/shared/listaagnos.service';
import { GraficasService } from '../../services/shared/graficas.service';
import { DatatablesService } from '../../services/shared/datatables.service';
import { ExcelService } from '../../services/excel.service';

import * as Highcharts from "highcharts";
import * as jsPDF from "jspdf";
import * as html2canvas from "html2canvas";

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-resultado-simce',
  templateUrl: './resultado-simce.component.html',
  styleUrls: ['./resultado-simce.component.scss']
})
export class ResultadoSimceComponent implements OnInit, OnDestroy {

  Puente: Subscription;
  chart: Chart;
  ActualAnoEscolar: any;  
  ActualAsign: any;
  ActualGrado: any;
  mdPerfil: any;
  linkParams: any;
  tipoAgrupacion:string;
  ListIdColegios:string;
  ListCodColegios:string;
  titulo_excel:string;
  titulo_tabla:string;
  simceHistorico = false;
  simceNivel = false;
  existeData = false;  
  arrAsignaturas = [];
  arrGrados = [];
  bodyTable = [];
  dataTable = [];
  dataTableExcel = [];
  dataTableHead = [];
  getArrayGrados = [];
  getArrayGradosAsignaturas = [];
  ListAnoEscolar = [];
  ListComunasSost = [];
  rsData = [];
  rowHeadTable = [];
  SlcAnoEscolar = [];  
  public loading:boolean = true;
  public api_error:boolean = false;

  constructor(private puente: PuenteService, private IAservice: HttpPuenteService,private excelService:ExcelService,private Agnos: ListaAgnosService, private DataTables: DatatablesService, private graphService: GraficasService) {
      this.Puente = this.puente.getData().subscribe(data => {
      //this.graphService.removeActiveTab();
      let cod_solicitud=0;
      if (this.simceHistorico) {
        cod_solicitud=100;
      }
      else if (this.simceNivel) {
        cod_solicitud=101;
      }
      this.getListadoColegios(cod_solicitud);
      
      
    });
  }
  ngOnDestroy(): void {
    this.Puente.unsubscribe();
  }
  ngOnInit() {    
    this.graphService.tabs = ["pill1", "tab1", "pill2", "tab2"];
    this.ActualAnoEscolar =this.Agnos.ActualAnoEscolar;
    this.ListAnoEscolar=this.SlcAnoEscolar=this.Agnos.listAnosEscolares(this.ActualAnoEscolar);
    this.getListadoColegios(100);
    this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));
    if (sessionStorage.getItem('link_param')) {
      //this.simceHistorico = true;
      this.linkParams = sessionStorage.getItem('link_param');
      sessionStorage.removeItem('link_param');
    }
    switch (this.linkParams) {
      case 'pill2':
        this.simceNivel = true;
        break;
      default:
        this.simceHistorico = true;
        $("#pill1").parent().addClass("active");
        $("#tab1").parent().addClass("active");
    }
    this.sl2_style();
   }
  


  getListadoColegios(cod_solicitud) {
    
    let recurso: string;
    let recurso2: string;
    let idSelect: string;
    let jsonData: any;
    let data: string;    
    let opcionSelected = 'sle';

    

    if (localStorage.getItem('select_colegio')) {
      jsonData = JSON.parse(localStorage.getItem('select_colegio'));
      idSelect = jsonData.id
      recurso=recurso2 = urlServer + '/colegioselector/' + idSelect;
      opcionSelected = 'comuna';

    } 
    else if (localStorage.getItem('select_sost')) {
      jsonData = JSON.parse(localStorage.getItem('select_sost'));
      idSelect = jsonData.id
      recurso = urlServer + '/rbd/sostenedor/' + idSelect;
      recurso2 = urlServer + '/sostenedor/' + idSelect;
      opcionSelected = 'comuna';


    } else if (localStorage.getItem('select_slep')) {
      jsonData = JSON.parse(localStorage.getItem('select_slep'));
      idSelect = jsonData.id
      recurso = urlServer + '/rbd/slep/' + idSelect;
      recurso2 = urlServer + '/slep/'+idSelect+'/sostenedores';
      opcionSelected = 'sle';
    } else {
      recurso = null
      opcionSelected = '';
    }
    
    this.ListComunasSost = [];    
    this.IAservice.getData(recurso2).then((data) => {
      if (opcionSelected == 'comuna') {
        this.ListComunasSost[data['cod_sostenedor']] = data['nom_sostenedor'];
      } else if(opcionSelected == 'sle') {
        Object.keys(data.sostenedores).forEach(key => {
          this.ListComunasSost[data.sostenedores[key]['cod_sostenedor']] = data.sostenedores[key]['nom_sostenedor'];
        });
      } else {
        this.ListComunasSost = [];
      }
    })
    .catch((err) => {
      $.notify(
        {icon: 'notifications', message: err.message}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
      );
    });
    
    let arrCodColegios = [];
    let arrIdColegios = [];
    this.IAservice.getData(recurso).then((data) => {
      
      Object.keys(data.data).forEach(key => {
        arrIdColegios.push(key);
        arrCodColegios.push(data.data[key]);

      });
      this.ListCodColegios = arrCodColegios.toString();
      this.ListIdColegios  = arrIdColegios.toString();     
     this.getListaGradosAsignaturas(cod_solicitud);
    })
    .catch((err) => {
      $.notify(
        {icon: 'notifications', message: err.message}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
      );
    });
    
  }

  recargarSelectGrados(items: any) {
    
    this.ActualAnoEscolar = items.value;
    let cod_solicitud=0;
    if (this.simceHistorico) {
      this.simceHistorico=true;
      this.simceNivel=false;
      cod_solicitud=100;
    }      
    else if (this.simceNivel) {
      this.simceHistorico=false;
      this.simceNivel=true;
      cod_solicitud=101;
    }    
    this.getListadoColegios(cod_solicitud);
  }

  getListaGradosAsignaturas(cod_solicitud) { 
   // console.log(tipo);

    this.ActualGrado = 0;
    this.ActualAsign = 0;
    this.getArrayGradosAsignaturas = [];
    this.getArrayGrados = [];
    this.arrGrados = [];
    this.arrAsignaturas = [];

    this.existeData = false;
    this.chart = new Chart();    

    let recurso: string;
    let idSelect: string;
    let jsonData: any;
    let data: string;
    
    
    this.SlcAnoEscolar = this.graphService.eliminarDuplicateInArray(this.SlcAnoEscolar);
    
    if (localStorage.getItem('select_sost')) {
      jsonData = JSON.parse(localStorage.getItem('select_sost'));
      idSelect = jsonData.id
      recurso = urlServer + '/rbd/sostenedor/' + idSelect;
    } else if (localStorage.getItem('select_slep')) {
      jsonData = JSON.parse(localStorage.getItem('select_slep'));
      idSelect = jsonData.id
      recurso = urlServer + '/rbd/slep/' + idSelect;
    } else {
      recurso = null
    }

    let simce_listagradosasign_post =  '/resultado_simce';
    var jData  = '{"grupo_colegios":"'+this.ListIdColegios+'","ano_escolar":"'+this.ActualAnoEscolar+'","tipo":"'+cod_solicitud+'"}';
     
    this.IAservice.listadoGradosAsignaturasColegios(simce_listagradosasign_post,jData).subscribe(
    result => {
      if( result.success ) {   
            if(result.data.data) { 
            let jsonData = result.data.data;
            Object.keys(jsonData).forEach(cod_grado => {
              if(localStorage.getItem('ListProfilesPers'))
             {
              this.getArrayGrados.push({'cod_grado':cod_grado,'grado':jsonData[cod_grado].grado})
              let listAsig = [];
              Object.keys(jsonData[cod_grado].asignaturas).forEach(cod_asignatura => {    
                if(this.Agnos.findsubject(cod_asignatura))
                {            
                listAsig.push({'cod_asignatura':cod_asignatura, 'asignatura':jsonData[cod_grado].asignaturas[cod_asignatura]});
                this.arrAsignaturas[cod_grado+'-'+cod_asignatura] = jsonData[cod_grado].asignaturas[cod_asignatura];
                }
              });
              let aux_grado = jsonData[cod_grado].grado.split(':');
              this.arrGrados[cod_grado] = aux_grado[1];

              this.getArrayGradosAsignaturas[cod_grado] = listAsig;
            }
            else{
              this.getArrayGrados.push({'cod_grado':cod_grado,'grado':jsonData[cod_grado].grado})
              let listAsig = [];
              Object.keys(jsonData[cod_grado].asignaturas).forEach(cod_asignatura => {                
                listAsig.push({'cod_asignatura':cod_asignatura, 'asignatura':jsonData[cod_grado].asignaturas[cod_asignatura]});
                this.arrAsignaturas[cod_grado+'-'+cod_asignatura] = jsonData[cod_grado].asignaturas[cod_asignatura];
              });
              let aux_grado = jsonData[cod_grado].grado.split(':');
              this.arrGrados[cod_grado] = aux_grado[1];

              this.getArrayGradosAsignaturas[cod_grado] = listAsig;
            }
            });
          }
            else {
              $.notify(
                {icon: 'notifications', message: "No hay datos de asignaturas "}, 
                {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
              });
              setTimeout(() => { this.loading = false}, 700);
            }
      } 
      else {
        $.notify(
          {icon: 'notifications', message: "No se han obtenido datos"}, 
          {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
        });
        setTimeout(() => { this.loading = false}, 700);
      }
    },
      error => {
        setTimeout(() => { this.loading = false}, 700);
        this.api_error = true;
      }
    );   
  }

  ocultarGrafico(){
    this.existeData=false;
    this.ActualAsign=null;
  }

  recargarSelectores(tipo) {
    let cod_solicitud=0;
    this.graphService.removeActiveTab();
    if (tipo==1) {
      this.simceHistorico=true;
      this.simceNivel=false;
      cod_solicitud=100;
    }      
    else if (tipo==2) {
      this.simceHistorico=false;
      this.simceNivel=true;
      cod_solicitud=101;
    }

    this.ocultarGrafico();
    this.getListadoColegios(cod_solicitud);
  }

  recargarGrafico(items: any) {
    
    //console.log(this.simceHistorico,this.simceNivel);
    if (items.value) {
      this.ActualAsign = items.value;   
      if (this.simceHistorico) {
        this.simce_historico();
      }      
      else if (this.simceNivel) {
        this.simce_nivel();
      }
      /*else{

      }*/
    } 
  }
 
  simce_historico(){
    let jsonData: any;
    let list: any;
    let params: any;
    let tipo: any;    
    let idSelect: string;
    let recurso: string;
    this.graphService.removeActiveTab();
    $("#pill1").parent().addClass("active");
    $("#tab1").parent().addClass("active");
    this.simceNivel=false;
    this.simceHistorico=true;

  if (localStorage.getItem('select_colegio')) {
      jsonData = JSON.parse(localStorage.getItem('select_colegio'));
      idSelect = jsonData.id
      recurso = urlServer + '/colegioselector/' + idSelect;
      this.tipoAgrupacion='colegio';
      tipo = 2
      
  } 
  else if (localStorage.getItem('select_sost')) {
      jsonData = JSON.parse(localStorage.getItem('select_sost'));
      idSelect = jsonData.id
      recurso = urlServer + '/rbd/sostenedor/' + idSelect;   
      this.tipoAgrupacion='colegio';   
      tipo = 2
  } 
  else if (localStorage.getItem('select_slep')) {
      jsonData = JSON.parse(localStorage.getItem('select_slep'));
      idSelect = jsonData.id     
      recurso = urlServer + '/rbd/slep/' + idSelect; 
      this.tipoAgrupacion='comuna';
      tipo = 1
  }
  else {
    recurso = null
  }

  
  let arrCodColegios = [];
  let arrIdColegios = [];

  if(recurso){
    this.IAservice.getData(recurso).then((data) => {
    Object.keys(data.data).forEach(key => {
      arrIdColegios.push(key);
      arrCodColegios.push(data.data[key]);
  });

  let ListCodColegios = arrCodColegios.toString();
  let simce_historico_post = urlServer + '/resultado_simce';

    params = {
      "grupo_colegios": ListCodColegios,
      "cod_grado":this.ActualGrado,
      "cod_asignatura":this.ActualAsign,
      "ano_escolar": this.ActualAnoEscolar,
      "tipo": tipo
  }
  this.IAservice.getDataPost(simce_historico_post,params)
          .then((result) => {
            if( result.success ) {
              this.graficoSimceHistorico(result);
             // this.loading = true;
            } else {
              $.notify(
                {icon: 'notifications', message: "No hay datos para imprimir"}, 
                {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
              });
              setTimeout(() => { this.loading = false}, 700);
            }
          })
          .catch((err) => {
            $.notify(
              { icon: 'notifications', message: err.message }, 
              { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
            );
          });
})
.catch((err) => {
  $.notify(
    {icon: 'notifications', message: err.message}, 
    {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
  );
});
}

}

/**
 * Sumatoria en base al año para totalizar y mostrar grafica
 * @param agno 
 * @param valores 
 */
sumatoria_simce_historico(agno,valores){
  let total_agno=0;
  Object.keys(valores).forEach(key => {          
    if (!$.isEmptyObject(valores[key])) {
      Object.keys(valores[key]).forEach(keya => {        
        if(String(agno)===keya)
       {
        //console.log(agno,String(agno),keye,valores[key][keye],parseInt(valores[key][keye]));
        total_agno=total_agno+parseInt(valores[key][keya]);
       }
      });
    }
  });
  return total_agno;

}

/**
 * 
 * @param comuna 
 * @param valores 
 */
buscarComuna(comuna,valores){
  let enccomuna=false;
  Object.keys(valores).forEach(keycomuna => {//recorrer el vector de comunas             
      if(comuna===valores[keycomuna].comuna){
        enccomuna=true;
      }
  });
  return enccomuna;
}

graficoSimceHistorico(result){
  
    let jsonData = result.data;
    let AnoEscolar = jsonData.xkeys;
    let tHead = [];
    this.rsData=[];
    let tablaExcel = [];
    this.bodyTable = [];
    let nombre = "Agrupación";
    let indice=0;
    this.dataTableExcel=[];
    let dinamicKey = '';
    this.titulo_excel=`Simce_Historico_${this.ActualAnoEscolar-1}_${this.tipoAgrupacion}_`; 
        this.existeData = true;  
       // this.loading = true;
       this.dataTable = [];
      //tHead.push(nombre);
        Object.keys(AnoEscolar).forEach(key => {
          //this.rsData[indice++]=this.sumatoria_simce_historico(AnoEscolar[key],jsonData.labels);
          tHead.push(String(AnoEscolar[key]));
          
        });
       this.rowHeadTable = tHead;
       if(this.tipoAgrupacion==="comuna"){        
        let idataTAble=0;
        
        for (var i = 0; i < jsonData.comunas.length; i++) {
          let rowExcel = [];
          let obj = {};
          let rowgraph=[];
          if(this.buscarComuna(jsonData.comunas[i],jsonData.labels))
          {                       
              let row  = [];
              let agrupa  = false;
              row.push(jsonData.comunas[i]);
              rowExcel[nombre]=jsonData.comunas[i];
             Object.keys(AnoEscolar).forEach(keya => {//vector de años 
              let valor_agno=0; 
              dinamicKey = " "+String(AnoEscolar[keya])+ " ";
              Object.keys(jsonData.labels).forEach(comuna => {//recorrer el vector de comunas  
                Object.keys(jsonData.labels[comuna]).forEach(keycom => {                      
                    if((String(AnoEscolar[keya])===keycom) && jsonData.comunas[i]===jsonData.labels[comuna].comuna)
                   {
                    valor_agno=parseInt(jsonData.labels[comuna][keycom]);
                    row.push(valor_agno);
                    rowExcel[dinamicKey]=valor_agno;
                    rowgraph.push(valor_agno);
                   }                                    
                 });                
              });  
              if(valor_agno===0)    {                
               row.push('');
               rowExcel[dinamicKey]='';
              }
        });
        if(row.length>0){
        this.dataTableExcel[idataTAble]=rowExcel;
        this.dataTable[idataTAble++]=row; 
        obj["name"] = jsonData.comunas[i];
        obj["visible"] = true;
        obj["data"] = rowgraph;
        this.rsData.push(obj); 
        }
      }
    }        
      }
      else if(this.tipoAgrupacion==="colegio"){
        let idataTAble=0;
        let dinamicKey = '';
        Object.keys(jsonData.labels).forEach(colegio => {//recorrer el vector de colegios
          
          let row  = [];
          let rowExcel = [];
          let obj = {};
          let rowgraph=[];
          row.push(jsonData.labels[colegio].nom_colegio);  
          rowExcel[nombre]=jsonData.labels[colegio].nom_colegio;     
          Object.keys(AnoEscolar).forEach(keya => {//vector de años 
            
            let valor_agno=0; 
                dinamicKey = " "+String(AnoEscolar[keya])+ " ";
                Object.keys(jsonData.labels[colegio]).forEach(keyc => {
                  if(String(AnoEscolar[keya])===keyc)
               {
                valor_agno=parseInt(jsonData.labels[colegio][keyc]); 
                rowgraph.push(valor_agno);              
               }

                }); 
                if(valor_agno===0)    {                
                  row.push('');
                  rowExcel[dinamicKey]='';
                 }
                 else{
                row.push(valor_agno);
                rowExcel[dinamicKey]=valor_agno;
                 }   
          }); 
          this.dataTableExcel[idataTAble]=rowExcel;       
          this.dataTable[idataTAble++]=row; 
          obj["name"] = colegio;
          obj["visible"] = true;
          obj["data"] = rowgraph;
          this.rsData.push(obj);      
      });     
      }         
    this.drawGraficoSimceHistorico(this.rsData,AnoEscolar);
    this.loading = false;  
}

drawGraficoSimceHistorico(rsData, AnoEscolar) {

  let titulo_graph= `Período escolar ${this.ActualAnoEscolar} <br>
                     Tendencia del puntaje Simce ${this.arrGrados[this.ActualGrado]} ${this.arrAsignaturas[this.ActualGrado +'-'+this.ActualAsign]}`;
    
  this.chart = new Chart({
    lang: {
      downloadPNG: "Descargar a PNG",
      downloadJPEG: "Descargar a JPEG",
      downloadSVG: "Descargar a SVG",
      downloadPDF: "Descargar a PDF",
      loading: "Cargando",
      printChart: "Imprimir",
      contextButtonTitle: 'Opciones gráfico'
    },
    chart: {
      type: 'line'
    },
    title: {
      text: titulo_graph },
    yAxis: {
      title: {
        text: 'Puntaje'
      }
    },
    xAxis: {
      categories: AnoEscolar,
      title: {
        text: ''
      }
    },
    tooltip: {
      formatter: function() {
        return "" + this.x + "<br/>" + this.series.name + " : <strong>" + Highcharts.numberFormat(this.y, 0, ",", ".") + "</strong>";
      }
    },
    credits: {
      enabled: false
    },
    /*plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
          formatter: function() {
            return Highcharts.numberFormat(this.y, 0, ',', '.');
          }
        },
        enableMouseTracking: true
      }
    },*/
    plotOptions: {
      series: {
        pointStart: 0
      }
    },
    series: this.rsData  
        /*series: [{
          name: 'Año escolar',
          data: rsData
        }]*/
  });
}

/**
 * 
 * @param datos 
 */
flotante(datos){
  Object.keys(datos).forEach(key => {
    Object.keys(datos[key]).forEach(keyd => {
      if(keyd=="data")
      Object.keys(datos[key][keyd]).forEach(keydata => {
        datos[key][keyd][keydata]=parseFloat(datos[key][keyd][keydata]);
       
   })
  })
})
return datos;
}

tabla_simce_nivel(etiquetas,datos){
  let datos_tabla=[];
  let indice=0;
  Object.keys(etiquetas).forEach(keye => {
    let row  = [];
    let rowExcel = [];
   
    row.push(etiquetas[keye].nom_colegio,etiquetas[keye].niveles.insuficiente+'%',etiquetas[keye].niveles.elemental+'%',etiquetas[keye].niveles.adecuado+'%')
     // rowExcel.push({'Agrupación':etiquetas[keye].nom_colegio,'Nivel Insuficiente':etiquetas[keye].niveles.insuficiente,'Nivel Elemental':etiquetas[keye].niveles.elemental,'Nivel Adecuado':etiquetas[keye].niveles.adecuado})
     rowExcel['Agrupación']=etiquetas[keye].nom_colegio;
     rowExcel['Nivel Insuficiente']=etiquetas[keye].niveles.insuficiente+'%';
     rowExcel['Nivel Elemental']=etiquetas[keye].niveles.elemental+'%';
     rowExcel['Nivel Adecuado']=etiquetas[keye].niveles.adecuado+'%';
     this.dataTableExcel[indice]=rowExcel;
      datos_tabla[indice++]=row;
  
})
return datos_tabla;
}
  simce_nivel(){
    
    let jsonData: any;
    let list: any;
    let params: any;
    let tipo: any;    
    let idSelect: string;
    let recurso: string;

    let tHead = [];
    this.rsData=[];
    this.dataTable = [];
    let tablaExcel = [];
    this.bodyTable = [];
    let nombre = "Agrupación";
    let indice=0;
    this.dataTableExcel=[];
    this.titulo_excel=`Simce_nivel_${this.ActualAnoEscolar-1}_`;

    this.graphService.removeActiveTab();
    $("#pill2").parent().addClass("active");
    $("#tab2").parent().addClass("active");
    this.simceNivel=true;
    this.simceHistorico=false;
    this.existeData=true;
    tipo = 3
    if (localStorage.getItem('select_colegio')) {
      jsonData = JSON.parse(localStorage.getItem('select_colegio'));
      idSelect = jsonData.id
      recurso = urlServer + '/colegioselector/' + idSelect;
  } 
  else if (localStorage.getItem('select_sost')) {
      jsonData = JSON.parse(localStorage.getItem('select_sost'));
      idSelect = jsonData.id
      recurso = urlServer + '/rbd/sostenedor/' + idSelect;   
      this.tipoAgrupacion='colegio';   
      
  } 
  else if (localStorage.getItem('select_slep')) {
      jsonData = JSON.parse(localStorage.getItem('select_slep'));
      idSelect = jsonData.id     
      recurso = urlServer + '/rbd/slep/' + idSelect; 
      this.tipoAgrupacion='comuna';
      
  }
  else {
    recurso = null
  }

  
  let arrCodColegios = [];
  let arrIdColegios = [];

  if(recurso){
    this.IAservice.getData(recurso).then((data) => {
    Object.keys(data.data).forEach(key => {
      arrIdColegios.push(key);
      arrCodColegios.push(data.data[key]);
  });

  let ListCodColegios = arrCodColegios.toString();
  let simce_nivel_post = urlServer + '/resultado_simce';

    params = {
      "grupo_colegios": ListCodColegios,
      "cod_grado":this.ActualGrado,
      "cod_asignatura":this.ActualAsign,
      "ano_escolar": this.ActualAnoEscolar,
      "tipo": tipo
  }
  this.IAservice.getDataPost(simce_nivel_post,params)
          .then((result) => {
            if( result.success ) {
              let jsonData = result.data;
              let rsData = jsonData.datos;
              tHead.push(nombre);
              tHead.push('Nivel Insuficiente','Nivel Elemental','Nivel Adecuado');    
              this.rowHeadTable = tHead;
              this.dataTable=this.tabla_simce_nivel(jsonData.labels,rsData);
              //console.log(this.dataTable);
              this.drawGraficoSimceNivel(this.flotante(rsData), this.ActualAnoEscolar, jsonData.colors, jsonData.xkeys);
              this.loading = false;
            } else {
              $.notify(
                {icon: 'notifications', message: "No hay datos para imprimir"}, 
                {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
              });
              setTimeout(() => { this.loading = false}, 700);
            }
          })
          .catch((err) => {
            $.notify(
              { icon: 'notifications', message: err.message }, 
              { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
            );
          });
})
.catch((err) => {
  $.notify(
    {icon: 'notifications', message: err.message}, 
    {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
  );
});
}

}

  drawGraficoSimceNivel(rsData, anoEscolar, colors, categoria) {
    let linesEscalonadas = 0;
    let titulo_simce= ` Porcentaje de estudiantes en cada nivel de aprendizaje para Simce ${this.arrGrados[this.ActualGrado]} ${this.ActualAnoEscolar-1} ${this.arrAsignaturas[this.ActualGrado +'-'+this.ActualAsign]}`;
    this.titulo_tabla = `Reporte - Período escolar ${this.ActualAnoEscolar-1} - Porcentaje de estudiantes en cada nivel de aprendizaje para Simce ${this.arrGrados[this.ActualGrado]} ${this.arrAsignaturas[this.ActualGrado +'-'+this.ActualAsign]}`;
   
    linesEscalonadas = 3;

    this.chart = new Chart({
      lang: {
        downloadPNG: "Descargar a PNG",
        downloadJPEG: "Descargar a JPEG",
        downloadSVG: "Descargar a SVG",
        downloadPDF: "Descargar a PDF",
        loading: "Cargando",
        printChart: "Imprimir",
        contextButtonTitle: 'Opciones gráfico'
      },
      chart: {
        type: 'column'
      },
      title: {
        text: titulo_simce
      },
      xAxis: {
        categories: categoria,
        min: null,
        max: null,
        title: {
          text: null
        },
        labels: {
          staggerLines:linesEscalonadas
        }
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: "Porcentaje de estudiantes"
        },
        labels: {
          overflow: "justify",
          format: '{value}'
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        valueSuffix: '%',
        formatter: function() {
          return "" + this.x + "<br/>" + this.series.name + " : <strong>" + Highcharts.numberFormat(this.y, 1, ",", ".")+"%</strong>";
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            shadow: true
          }
        },
        series: {
          stacking: 'normal',
          dataLabels: {
            rotation: null,
            formatter: function() {
              return this.y + "%";
            },       
            enabled: true,
            verticalAlign: 'middle',
            align: 'center',
            inside: true
          }
        }
      },
      series: rsData,
      colors: colors
    });
  }

  exportAsXLSX():void {
    this.excelService.exportAsExcelFile(this.dataTableExcel, this.titulo_excel);
  }

  sl2_style() {
    $(".c-btn").css("box-shadow", "none");
    $(".c-btn").css("border-top", "none");
    $(".c-btn").css("border-left", "none");
    $(".c-btn").css("border-right", "none");
    $(".c-btn").css("color", "#989898");
    $(".c-btn").css("background-color", "#ededee");
    $(".sl2-material > div >  .dropdown-list").css("position", "absolute");
  }
}


