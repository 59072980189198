import { Injectable } from '@angular/core';
import { Headers, Http } from '@angular/http';
import { User } from '../models/user';
import { HttpClient } from '@angular/common/http';

import 'rxjs/add/operator/toPromise';
import { urlServer } from './url.service';

@Injectable()
export class AuthService {
  private BASE_URL: string = urlServer;
  private headers: Headers = new Headers({'Content-Type': 'application/json'});
  constructor(private http: Http, private http2: HttpClient) {}
  login(user: User): Promise<any> {
    let url: string = `${this.BASE_URL}/login`;
    return this.http.post(url, user, {headers: this.headers}).toPromise();
  }

  userLogin(user: User,token): Promise<any> {

    let url: string = `${this.BASE_URL}/usuario-datos/`+user.id_usuario;
    let headers: Headers = new Headers({
      'Content-Type': 'application/json',
      token_id: `${token}`
    });
    return this.http.get(url, {headers: headers}).toPromise();
  }

  // AS

  userLoginAll(user: User,token): Promise<any> {

    let url: string = `${this.BASE_URL}/usuario-datos-all/`+user.id_usuario;
    let headers: Headers = new Headers({
      'Content-Type': 'application/json',
      token_id: `${token}`
    });
    return this.http.get(url, {headers: headers}).toPromise();
  }

  // AS
  register(user: User): Promise<any> {
    let url: string = `${this.BASE_URL}/register`;
    return this.http.post(url, user, {headers: this.headers}).toPromise();
  }
  ensureAuthenticated(token): Promise<any> {
    let url: string = `${this.BASE_URL}/login`;
    let headers: Headers = new Headers({
      'Content-Type': 'application/json',
      token_id: `${token}`
    });
    return this.http.get(url, {headers: headers}).toPromise();
  }
  getPersonaByRun(params): Promise<any> {
    let url: string = `${this.BASE_URL}/persona-run`;
    return this.http2.get(url, {params: params}).toPromise();
  }
  registerPerson(params): Promise<any> {
    let url: string = `${this.BASE_URL}/registrar-persona-usuario`;
    return this.http2.post(url, params).toPromise();
  }
  //AS
  updatePassword(params): Promise<any> {
    let url: string ='';
    url =`${this.BASE_URL}/cambiar-password`;
    return this.http2.post(url, params).toPromise();
  }

  recoveryPass(params): Promise<any> {
    let url: string ='';
    url =`${this.BASE_URL}/resetear-password`;
    return this.http2.post(url, params).toPromise();
  }

  registerProfile(params): Promise<any> {
    
    let url: string ='';
    if(params.tipo_nivel=='personalizado'){
      url =`${this.BASE_URL}/registrar-usuario-perfil-personalizado`;
    }
    else{
     url = `${this.BASE_URL}/registrar-usuario-perfil`;
    }
    return this.http2.post(url, params).toPromise();
  }
  getProfiles(id): Promise<any> {
    let url: string = `${this.BASE_URL}/persona/${id}/usuario-perfiles`;
    return this.http2.get(url).toPromise();
  }
  deleteProfile(params): Promise<any> {
    let url: string = `${this.BASE_URL}/eliminar-usuario-perfil`;
    return this.http2.post(url, params).toPromise();
  }

  public checkRut(rut) {
    // Despejar Puntos
    var valor = rut.replace(/\./g,'');
    // Despejar Guión
    valor = valor.replace('-','');

    // Aislar Cuerpo y Dígito Verificador
    let cuerpo = valor.slice(0,-1);
    let dv = valor.slice(-1).toUpperCase();

    // Formatear RUN
    rut = cuerpo + '-'+ dv

    // Si no cumple con el mínimo ej. (n.nnn.nnn)
    if(cuerpo.length < 7) { return false; }

    // Calcular Dígito Verificador
    let suma = 0;
    let multiplo = 2;

    // Para cada dígito del Cuerpo
    for(let i=1;i<=cuerpo.length;i++) {

        // Obtener su Producto con el Múltiplo Correspondiente
        let index = multiplo * valor.charAt(cuerpo.length - i);

        // Sumar al Contador General
        suma = suma + index;

        // Consolidar Múltiplo dentro del rango [2,7]
        if(multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }

    }

    // Calcular Dígito Verificador en base al Módulo 11
    let dvEsperado = 11 - (suma % 11);

    // Casos Especiales (0 y K)
    dv = (dv == 'K')?10:dv;
    dv = (dv == 0)?11:dv;

    // Validar que el Cuerpo coincide con su Dígito Verificador
    if(dvEsperado != dv) { return false; }

    // Si todo sale bien, eliminar errores (decretar que es válido)
    return true;
  }

  public formatRut(value) {
    value = this.cleanRut(value);
    if(value.length <= 1) return value;
    
    var result = value.slice(-4,-1) + '-' + value.substr(value.length-1);
    
    for(var i = 4; i < value.length; i+=3) result = value.slice(-3-i,-i) + '.' + result;
    return result;
  }

  private cleanRut(value) {
    let out = "";
    if(typeof(value) === 'string'){
      out = value.replace(/[^0-9kK]+/g,'').toUpperCase()
    }else if(typeof( value) === 'number'){
      out = value+'';
    }
    return out
  }

}
