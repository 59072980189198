import { Subscription } from 'rxjs/Subscription';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as Chartist from 'chartist';
import { DateAdapter } from '@angular/material';
import { Chart } from 'angular-highcharts';
import { PuenteService } from '../../services/puente.service';
import { urlServer } from '../../services/url.service';
import { AsistenciaSndService } from '../../services/api-snd/asistencia.service';
import { EvolucionMatriculaSndService } from '../../services/api-snd/evolucion-matricula.service';

import * as Highcharts from "highcharts";
import * as jsPDF from "jspdf";
import * as html2canvas from "html2canvas";
import {ExcelService} from '../../services/excel.service';
//AS
import { ListaAgnosService } from '../../services/shared/listaagnos.service';
import { DatatablesService } from '../../services/shared/datatables.service';
import { HttpPuenteService } from '../../services/shared/httppuente.service';
import { GraficasService } from '../../services/shared/graficas.service';
import { Graph } from '../../models/graph.model';
//import { RangoNotasService } from './../../services/rango-notas.service';
//import { AsistenciaService } from '../../services/asistencia.service';
//AS
declare const require: any;
declare const $: any;

@Component({
  selector: 'app-asistencia',
  templateUrl: './asistencia.component.html',
  styleUrls: ['./asistencia.component.scss'],
  providers:[AsistenciaSndService, EvolucionMatriculaSndService]
})

export class AsistenciaComponent implements OnInit, OnDestroy {
  chart: Chart;
  //AS Variables para obtener datos desde servicio   
  graph:Graph;
  MES_NOM_COMPLETO = this.Agnos.MES_NOM_COMPLETO;
  MES_NOMBRES = [];//["", "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
  MESES = [];//["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
  //AS
  titulo_excel:string = 'Ejemplo';
  dataTable = [];
  dataTableExcel = [];
  //AS
 /* dataTablePrio = [];
  dataTableInteg = [];
  dataTableVuln= [];
  dataTablePref = [];*/
  //AS
  gAsistencia = false;
  gAsistenciaMensualSost = false;
  gAsistenciaMensualCol = false;
  gTotalAsistenciaMatricula = false;
  gAsistenciaMensualPrioritario = false;
  gAsistenciaMensualIntegrado = false;
  gAsistenciaMensualVulnerable = false;
  gAsistenciaMensualPreferente = false;
  gAsistenciaComparativo = false;
  gAsistMatrComparativo = false;
  spinner = false;
  color = 'red';
  mode = 'indeterminate';
  value = 50;
  data_puente: any;
  mdPerfil: any;
  Puente: Subscription;
  ActualAnoEscolar: any;
  mesSelected : any;
  SlcAnoEsoclar  = [];
  SlcMes = [];
  ListAnoEscolar = [];
  rsData = [];
  linkParams: any;

  public colegio:boolean = false;
  public loading:boolean = true;
  public api_error:boolean = false;
  public dataAsistenciaDep:boolean = false; 
  public dataAsistenciaSnd:boolean = false; 
//AS Agregando Servicio de listado de Años y Meses
    //constructor(private puente: PuenteService, private IGservice: AsistenciaService, private AsistenciaSndService:AsistenciaSndService, private EvolucionMatriculaSndService:EvolucionMatriculaSndService, private excelService:ExcelService, private IAservice: RangoNotasService,private DataTables: DatatablesService,private Agnos: ListaAgnosService) {
    constructor(private puente: PuenteService, private IGservice: HttpPuenteService, private AsistenciaSndService:AsistenciaSndService, private EvolucionMatriculaSndService:EvolucionMatriculaSndService, private excelService:ExcelService, private IAservice: HttpPuenteService,private graphService: GraficasService, private DataTables: DatatablesService,private Agnos: ListaAgnosService) {
    this.ActualAnoEscolar =this.Agnos.ActualAnoEscolar;
   // this.ActualAnoEscolar = new Date().getFullYear();   
    Object.keys(this.MES_NOM_COMPLETO).forEach(key => {
      if (parseInt(key) > 0) {
        let xmes = {};
        xmes["mes"] =  this.MES_NOM_COMPLETO[key];
        this.SlcMes.push(xmes);
      }
    });

    this.Puente = this.puente.getData().subscribe(data => {
      this.removeActiveTab();
      if (this.gAsistenciaMensualSost) {
        this.asistenciaMensualSost();
      }
      if (this.gAsistenciaMensualCol) {
        this.asistenciaMensualCol();
      }
      if (this.gTotalAsistenciaMatricula) {
        this.totalAsistenciaMatricula();
      }
      if (this.gAsistenciaMensualPrioritario) {
        this.asistenciaMensualAlumno(1);
      }
      if (this.gAsistenciaMensualIntegrado) {
        this.asistenciaMensualAlumno(2);
      }
      if (this.gAsistenciaMensualVulnerable) {
        this.asistenciaMensualAlumno(3);
      }
      if (this.gAsistenciaMensualPreferente) {
        this.asistenciaMensualAlumno(4);
      }
    });
  }

  ngOnDestroy(): void {
    this.Puente.unsubscribe();
  }

  ngOnInit() {
    //AS Obteniendo los valores de meses desde servicio
    this.MES_NOM_COMPLETO = this.Agnos.MES_NOM_COMPLETO;//["", "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    this.MES_NOMBRES = this.Agnos.MES_NOMBRES;//["", "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    this.MESES =this.Agnos.MESES;// ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
   //AS
    this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));
    if(this.mdPerfil.nivel==3 || this.mdPerfil.nivel==5){
      this.colegio=true;
    }
    else{
      this.colegio=false;
    }
    this.listAnosEscolaresSostenedor();
    
    if (sessionStorage.getItem('link_param')) {
      this.gAsistencia = false;
      this.linkParams = sessionStorage.getItem('link_param');
      sessionStorage.removeItem('link_param');
    }
    switch (this.linkParams) {
      case 'pill1':
        this.gAsistenciaMensualSost = true;
        this.asistenciaMensualSost();
        break;
      case 'pill2':
        this.gAsistenciaMensualCol = true;
        this.asistenciaMensualCol();
        break;
      case 'pill3':
        this.gTotalAsistenciaMatricula = true;
        this.totalAsistenciaMatricula();
        break;
      case 'pill4':
        this.gAsistenciaMensualPrioritario = true;
        //this.asistenciaMensualPrioritario();
          this.asistenciaMensualAlumno(1);
        break;
      case 'pill5':
        this.gAsistenciaMensualIntegrado = true;
        //this.asistenciaMensualIntegrado();
        this.asistenciaMensualAlumno(2);
        
        break;
      case 'pill6':
        this.gAsistenciaMensualVulnerable = true;
        //this.asistenciaMensualVulnerable();
        this.asistenciaMensualAlumno(3);
        break;
      case 'pill7':
        this.gAsistenciaMensualPreferente = true;
        //this.asistenciaMensualPreferente();
        this.asistenciaMensualAlumno(4);
        break;
      case 'pill8':
        this.gAsistenciaComparativo = true;
        this.asistenciaComparativo();
        break;
      default:
        this.gAsistenciaMensualSost = true;
        this.asistenciaMensualSost();
    }
    this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));
    this.sl2_style();
  }

  listAnosEscolaresSostenedor() {
    this.SlcAnoEsoclar = [];

    this.removeActiveTab();
    this.ListAnoEscolar = [];

    let rsData = [];
    let recurso: string;
    let idSelect: string;
    let jsonData: any;
    let AnoEscolar = [];
    let codSostenedor: number;
    let opcion: number;
    let data: string;
    let currentTime = new Date()
    let ano_actual = currentTime.getFullYear();
    //AS Implementando servicio para proveer listado de Años
    this.ListAnoEscolar=this.SlcAnoEsoclar=this.Agnos.listAnosEscolares(this.ActualAnoEscolar);
    //console.log(this.ListAnoEscolar,this.SlcAnoEsoclar)
    /*for (var _i = ano_actual; _i >= 2005; _i--) {
        this.SlcAnoEsoclar.push({ano_escolar: _i});
        this.ListAnoEscolar.push({ano_escolar: _i});
    }*/
    //AS

    /*
    if (localStorage.getItem('cod_sostenedor')) {


      codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));

      this.EvolucionMatriculaSndService.evolucionMatriculaSostenedor(codSostenedor,ano_actual).subscribe(
        result => {
          if (result.success) {
            let jsonData = result.data;
            this.rsData = jsonData.datos;
            AnoEscolar  = jsonData.xkeys;
            let anoEsta = AnoEscolar.indexOf(ano_actual.toString());

            if (anoEsta == -1) {
              AnoEscolar.push(ano_actual.toString());
            }
            AnoEscolar = this.eliminarDuplicateInArray(AnoEscolar);

            let arrAnoEscolar = [];
            Object.keys(AnoEscolar).forEach(key => {
              arrAnoEscolar.push(parseInt(AnoEscolar[key]));
            });
            arrAnoEscolar.sort(function(a, b){return b - a});

            Object.keys(arrAnoEscolar).forEach(key => {
              var xano = {};
              xano["ano_escolar"] =  parseInt(arrAnoEscolar[key]);
              
              this.ListAnoEscolar.push(xano);
              //this.SlcAnoEsoclar.push(xano);

              AnoEscolar.push(this.ListAnoEscolar[key].ano_escolar);

              if (this.ActualAnoEscolar) {
                if (this.ActualAnoEscolar < this.ListAnoEscolar[key].ano_escolar) {
                  this.ActualAnoEscolar = this.ListAnoEscolar[key].ano_escolar;
                }
              } else {
                this.ActualAnoEscolar = this.ListAnoEscolar[key].ano_escolar;
              }
            });

            this.SlcAnoEsoclar = this.eliminarDuplicateInArray(this.SlcAnoEsoclar);
          } else {
            $.notify(
              { icon: 'notifications', message: "No hay datos para imprimir" }, 
              {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
            );
            setTimeout(() => { this.loading = false}, 700);
          }
        },
        error => {
          setTimeout(() => { this.loading = false}, 700);
          this.api_error = true;
        }
      );
    }*/
  }

  recargarAsistencias(items: any) {

    this.removeActiveTab();
    this.ActualAnoEscolar = items.value;
        
    let dia = new Date();
    this.mesSelected = this.MES_NOM_COMPLETO[dia.getMonth()+1];

    if (this.gAsistenciaMensualSost) {
      this.asistenciaMensualSost();
    } else if (this.gAsistenciaMensualCol) {
      this.asistenciaMensualCol();
    } else if (this.gTotalAsistenciaMatricula) {
      this.totalAsistenciaMatricula();
    } else if (this.gAsistenciaMensualPrioritario) {
      //this.asistenciaMensualPrioritario();
      this.asistenciaMensualAlumno(1);
    } else if (this.gAsistenciaMensualIntegrado) {
      //this.asistenciaMensualIntegrado();
      this.asistenciaMensualAlumno(2);
    } else if (this.gAsistenciaMensualVulnerable) {
      //this.asistenciaMensualVulnerable();
      this.asistenciaMensualAlumno(3);
    } else if (this.gAsistenciaMensualPreferente) {
      //this.asistenciaMensualPreferente();
      this.asistenciaMensualAlumno(4);
    } else if (this.gAsistenciaComparativo) {
      this.asistenciaComparativo();
    } else if (this.gAsistMatrComparativo) {
      this.asistenciaMatriculaComparativo();
    }
    else {
      $.notify(
        {icon: 'notifications', message: 'Opcion ingresada no es correcta'}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom',align: 'right'} }
      );
    }
  }

  recargarMensualEstablecimiento(items: any) {
    this.removeActiveTab();
    this.mesSelected = items.value;
    if (this.gAsistenciaMensualCol) {
      this.asistenciaMensualCol();
    } else if (this.gAsistenciaComparativo) {
      this.asistenciaComparativo();
    }
    else {
      $.notify(
        {icon: 'notifications', message: 'Opcion ingresada no es correcta'}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom',align: 'right'} }
      );
    }
  }

  reloadAsistMensualAlumnos(items :any) {
    this.removeActiveTab();
    this.mesSelected = items.value;
    this.chart = new Chart();

    if (this.gAsistenciaMensualPrioritario) {
      //this.asistenciaMensualPrioritario();
      this.asistenciaMensualAlumno(1);
    } else if (this.gAsistenciaMensualIntegrado) {
      //this.asistenciaMensualIntegrado();
      this.asistenciaMensualAlumno(2);
    } else if (this.gAsistenciaMensualVulnerable) {
      //this.asistenciaMensualVulnerable();
      this.asistenciaMensualAlumno(3);
    } else if (this.gAsistenciaMensualPreferente) {
      //this.asistenciaMensualPreferente();
      this.asistenciaMensualAlumno(4);
    } else {
      $.notify(
        {icon: 'notifications', message: 'Opcion ingresada no es correcta'}, 
        {
          type: 'warning',
          timer: 2000,
          delay: 2000,
          mouse_over: 'pause',
          placement: {
            from: 'bottom',
            align: 'right'
          }
        }
      );
    }
  } 


  Gpdf() {
    this.loading = true;
    if (this.rsData.length != 0) {
      this.spinner = true;
      var divWidth = $(".highcharts-container").width() - 20;;
      var originalBodyWidth = document.body.offsetWidth;
      var originalBodyHeight = document.body.offsetHeight;
      document.body.style.width = "1920px";
      document.body.style.height = "1080px";

      $('.graphClass').highcharts().setSize("1100", false);
      $('.table').css("width","1100px");
      setTimeout(() => {
        var Doc = new jsPDF('l', 'pt', "A4");
        html2canvas(document.querySelector(".page1"), {
          onclone: function (document) {
            document.querySelector(".page1").classList.remove("col-md-8");
          }
        }).then(canvas => {
          var imgData = canvas.toDataURL('image/png');
          var width = Doc.internal.pageSize.width;
          var height = Doc.internal.pageSize.height;
          Doc.addImage(imgData, 'PNG', 0, 0);
          Doc.addPage();
          html2canvas(document.querySelector(".page2"), {
            onclone: function (document) {
              document.querySelector(".page2").style.width = "100%";
            }
          }).then(canvas => {
            var imgData = canvas.toDataURL('image/png');
            var width = Doc.internal.pageSize.width;
            var height = Doc.internal.pageSize.height;
            Doc.addImage(imgData, 'PNG', 0, 0);
            Doc.save('Indicadores de gestión.pdf')
            document.body.removeAttribute("style");
            $('.graphClass').highcharts().setSize(divWidth, false);
            $('.table').css("width","100%");
            this.spinner = false;
          });
        });
      }, 1000);
      this.loading = false;
    } else {
      $.notify(
        { icon: 'notifications', message: "No hay datos para imprimir" }, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
      );
      setTimeout(() => { this.loading = false}, 700);
    }

  }


  /* ASISTENCIA MENSUAL POR SOSTENEDOR */
  asistenciaMensualSost() {
    this.removeActiveTab();
    $("#pill1").parent().addClass("active");
    $("#tab1").parent().addClass("active");
    //$("#tab10").parent().addClass("active");
    this.gAsistenciaMensualSost = true;
    this.gAsistenciaMensualCol = false;
    this.gTotalAsistenciaMatricula = false;
    this.gAsistenciaMensualPrioritario = false;
    this.gAsistenciaMensualIntegrado = false;
    this.gAsistenciaMensualVulnerable = false;
    this.gAsistenciaMensualPreferente = false;
    this.gAsistenciaComparativo = false;
    this.gAsistMatrComparativo = false;

    let rsData = [];
    let recurso: string;
    let idSelect: string;
    let jsonData: any;
    let list: any;
    let AnoEscolar = [];
    let data:string;
    let niveles = [];
    let rsData2:string;
    //AS para recibir los vectores generados desde el servicio de Datatables
    let vectorTablaServicio=[];
    //AS
    this.dataAsistenciaDep = false;
    this.dataAsistenciaSnd = true;

     if (localStorage.getItem('select_colegio')) {
          list = JSON.parse(localStorage.getItem('ListCole'));
          jsonData = JSON.parse(localStorage.getItem('select_colegio'));
          idSelect = jsonData.id
          this.colegio=true;
      } 
      else if (localStorage.getItem('select_sost')) {
          list = JSON.parse(localStorage.getItem('ListCole'));
          jsonData = JSON.parse(localStorage.getItem('select_sost'));
          idSelect = jsonData.id
          this.colegio= false;
      } 
      else if (localStorage.getItem('select_slep')) {
          list = JSON.parse(localStorage.getItem('ListSost'));
          jsonData = JSON.parse(localStorage.getItem('select_slep'));
          idSelect = jsonData.id
          this.colegio= false;
      }

      let recurso_asistencia_post = urlServer + '/asistencia_mensual';

      if (localStorage.getItem('select_colegio')) {
        recurso = urlServer + '/colegioselector/' + idSelect;
      } 
      else if (localStorage.getItem('select_sost')) {
        recurso = urlServer + '/rbd/sostenedor/' + idSelect;
      } 
      else if (localStorage.getItem('select_slep')) {
        recurso = urlServer + '/rbd/slep/' + idSelect;
      } else {
        recurso = null
      }

      let arrCodColegios = [];
      let arrIdColegios = [];
      if(recurso){
          this.IAservice.getData(recurso).then((data) => {
              Object.keys(data.data).forEach(key => {
                  arrIdColegios.push(key);
                  arrCodColegios.push(data.data[key]);
              });

              let ListCodColegios = arrCodColegios.toString();
              let ListIdColegios  = arrIdColegios.toString();

              let params = {
                  "grupo_colegios": ListCodColegios,
                  "ano_escolar": this.ActualAnoEscolar,
                  "tipo": "2"
              }
//
              this.IGservice.getDataPost(recurso_asistencia_post, params).then((result) => {
                if( result.success ) {
                  let jsonData = result.data;
                  rsData2 = jsonData.datos;
                  //
                  this.buildGraphBarra(rsData2, this.ActualAnoEscolar, jsonData.colors, jsonData.xkeys);
                  //AS Implementar servicio de datatable
                  //this.buildTablaAsisSostenedor(jsonData.labels);
                  vectorTablaServicio=this.DataTables.buildTabla( jsonData.labels ,'asist');
                  //validar que el arreglo no venga vacio
                  this.titulo_excel = "Asistencia_mensual_por_sostenedor_"+localStorage.getItem('cod_sostenedor')+"_periodo_"+this.ActualAnoEscolar;
                  this.dataTable = vectorTablaServicio[0];
                  this.dataTableExcel = vectorTablaServicio[1];
                  //AS
                  this.loading = false;
                } else {
                  $.notify(
                    {icon: 'notifications', message: "No hay datos para imprimir"}, 
                    {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
                  });
                  setTimeout(() => { this.loading = false}, 700);
                }
              });
          })
          .catch((err) => {
            $.notify(
              {icon: 'notifications', message: err.message}, 
              {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
            );
          });
      }
      else{
        this.loading = false;
        $.notify(
          {icon: 'notifications', message: 'Revise selección en el menú por favor.'}, 
          {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
        );
      }
  }

  /* ASISTENCIA COMPARATIVO */
  asistenciaComparativo() {
    this.removeActiveTab();
    $("#pill8").parent().addClass("active");
    $("#tab8").parent().addClass("active");

    this.gAsistenciaMensualSost = false;
    this.gAsistenciaMensualCol = false;
    this.gTotalAsistenciaMatricula = false;
    this.gAsistenciaMensualPrioritario = false;
    this.gAsistenciaMensualIntegrado = false;
    this.gAsistenciaMensualVulnerable = false;
    this.gAsistenciaMensualPreferente = false;
    this.gAsistenciaComparativo = true;
    this.gAsistMatrComparativo = false;
    this.dataAsistenciaDep = true;
    this.dataAsistenciaSnd = false;
    this.rsData = [];
    this.loading = true;
    this.api_error = false;

    let rsData = [];
    let recurso: string;
    let idSelect: string;
    let jsonData: any;
    let AnoEscolar = [];
    let data:string;
    let niveles = [];
    let rsData2:string;
    let dia = new Date();
    let colores = ['#C35F5C', '#4572A7'];

    if (!this.mesSelected) {
      this.mesSelected = this.MES_NOM_COMPLETO[dia.getMonth()+1];
    }
    
    let mes_select = this.MES_NOM_COMPLETO.indexOf(this.mesSelected);

    if (localStorage.getItem('select_slep')) {
      if (!this.ActualAnoEscolar) {
        let currentTime = new Date()
        this.ActualAnoEscolar = currentTime.getFullYear();
      }

      jsonData = JSON.parse(localStorage.getItem('select_slep'));
      idSelect = jsonData.id
      recurso = urlServer + '/slep/' + idSelect + '/asistencia-comparativa/ano_escolar/'+this.ActualAnoEscolar;

      let xkeys = []; 
      let dataInasist = [];
      let dataAsist = []; 
      let rsTabla = [];

      this.IGservice.getData(recurso).then((data) => {
        Object.keys(data.data).forEach(key => {
          var AnoEscolarSelect = '0';
          if (!$.isEmptyObject(data.data[key])) {
            Object.keys(data.data[key]).forEach(zona => {
              xkeys.push(zona);
              rsData2 = data.data[key][zona];
              Object.keys(rsData2).forEach(mes => {
                if (mes_select == parseInt(mes)) {
                  dataInasist.push(rsData2[mes]['porc_inasistencia']);
                  dataAsist.push(rsData2[mes]['porc_asistencia']);
                  let obj = {
                    ASIS_POS: rsData2[mes]['asistencia_posible'],
                    asistencia:rsData2[mes]['asistencia'],
                    inasistencia:rsData2[mes]['inasistencia'],
                    mes:this.mesSelected,
                    zona:zona,
                    porc_asistencia:rsData2[mes]['porc_asistencia'],
                    porc_inasistencia:rsData2[mes]['porc_inasistencia']
                  }
                  rsTabla.push(obj);
                }
              });
            });
          }
        });

        let objInasist = {name: 'Inasistencia',data: dataInasist, visible:true};
        let objAsist = {name: 'Asistencia', data: dataAsist, visible:true };  

        this.rsData.push(objInasist);
        this.rsData.push(objAsist);
        this.buildGraphBarra(this.rsData, this.ActualAnoEscolar, colores, xkeys, 'comparativa');
        this.buildTablaAsisComparativo(rsTabla);
        this.loading = false;

      })
      .catch((err) => {
        this.loading = false;
        this.api_error = true;
        $.notify(
          { icon: 'notifications', message: err.message }, 
          { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
        );
      });
    }
  }

  /* ASISTENCIA MENSUAL POR ESTABLECIMIENTO */
  asistenciaMensualCol() {
    this.removeActiveTab();
    $("#pill2").parent().addClass("active");
    $("#tab2").parent().addClass("active");
    this.gAsistenciaMensualSost = false;
    this.gAsistenciaMensualCol = true;
    this.gTotalAsistenciaMatricula = false;
    this.gAsistenciaMensualPrioritario = false;
    this.gAsistenciaMensualIntegrado = false;
    this.gAsistenciaMensualVulnerable = false;
    this.gAsistenciaMensualPreferente = false;
    this.gAsistenciaComparativo = false;
    this.gAsistMatrComparativo = false;
    this.dataAsistenciaSnd = true;

    let rsData = [];
    let recurso: string;
    let idSelect: string;
    let jsonData: any;
    let list: any;
    let AnoEscolar = [];
    let data:string;
    let niveles = [];
    let rsData2:string;
    let dia = new Date();

    if (!this.mesSelected) {
      this.mesSelected = this.MES_NOM_COMPLETO[dia.getMonth()+1];
    }
    let mes_select = this.MES_NOM_COMPLETO.indexOf(this.mesSelected);
    let codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));

    if (this.SlcAnoEsoclar.length==0) {
      Object.keys(this.ListAnoEscolar).forEach(key => {
        let xano = {};
        xano["ano_escolar"] = this.ListAnoEscolar[key].ano_escolar;
        this.SlcAnoEsoclar.push(xano);
      });
    }

    this.SlcAnoEsoclar = this.eliminarDuplicateInArray(this.SlcAnoEsoclar);
    this.loading = true;
    this.api_error = false;

    if(localStorage.getItem('select_colegio')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_colegio'));
        idSelect = jsonData.id
    } else if (localStorage.getItem('select_sost')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_sost'));
        idSelect = jsonData.id
    } else if (localStorage.getItem('select_slep')) {
        list = JSON.parse(localStorage.getItem('ListSost'));
        jsonData = JSON.parse(localStorage.getItem('select_slep'));
        idSelect = jsonData.id
    }

    let recurso_asistencia_post = urlServer + '/asistencia_mensual';
    let tipo = "";

    if (localStorage.getItem('select_colegio')) {
      recurso = urlServer + '/colegioselector/' + idSelect;
      tipo = "4";
    } 
    else if (localStorage.getItem('select_sost')) {
      recurso = urlServer + '/rbd/sostenedor/' + idSelect;
      tipo = "4";
    } 
    else if (localStorage.getItem('select_slep')) {
      recurso = urlServer + '/rbd/slep/' + idSelect;
      tipo = "3";
    } else {
      recurso = null
    }

    let arrCodColegios = [];
    let arrIdColegios = [];
    if(recurso){
        this.IAservice.getData(recurso).then((data) => {
            Object.keys(data.data).forEach(key => {
                arrIdColegios.push(key);
                arrCodColegios.push(data.data[key]);
            });

            let ListCodColegios = arrCodColegios.toString();
            let ListIdColegios  = arrIdColegios.toString();

            let params = {
                "grupo_colegios": ListCodColegios,
                "ano_escolar": this.ActualAnoEscolar,
                "tipo": tipo,
                "mes_actual": mes_select
            }

            this.IGservice.getDataPost(recurso_asistencia_post, params).then((result) => {
              if( result.success ) {
                let jsonData = result.data;
                rsData2 = jsonData.datos;
                let series = [];
                Object.keys(rsData2).forEach(key => {
                  series[key] = new Object();
                  series[key].name = rsData2[key].name;
                  if (rsData2[key].visible !== null) {
                    series[key].visible = rsData2[key].visible;
                  }
                  series[key].data = rsData2[key].data[mes_select];
                });
                this.buildGraphBarra(series, this.ActualAnoEscolar, jsonData.colors, jsonData.xkeys);
                this.buildTablaAsisMensualCol(jsonData.labels[mes_select]);
                this.loading = false;
              } else {
                $.notify(
                  {icon: 'notifications', message: "No hay datos para imprimir"}, 
                  {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
                });
                setTimeout(() => { this.loading = false}, 700);
              }
            });
        })
        .catch((err) => {
          $.notify(
            {icon: 'notifications', message: err.message}, 
            {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
          );
        });
    }
    else{
      $.notify(
        {icon: 'notifications', message: 'Revise selección en el menú por favor.'}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
      );
    }
  }

  /* TOTAL ASISTENCIA - MATRICULA COMPARATIVO PARA SLE*/
  asistenciaMatriculaComparativo(){
    this.removeActiveTab();
    $("#pill3").parent().addClass("active");
    $("#tab3").parent().addClass("active");
    this.gAsistenciaMensualSost = false;
    this.gAsistenciaMensualCol = false;
    this.gTotalAsistenciaMatricula = false;
    this.gAsistenciaMensualPrioritario = false;
    this.gAsistenciaMensualIntegrado = false;
    this.gAsistenciaMensualVulnerable = false;
    this.gAsistenciaMensualPreferente = false;
    this.gAsistenciaComparativo = false;
    this.gAsistMatrComparativo = true;

    let rsData = [];
    let recurso: string;
    let idSelect: string;
    let jsonData: any;
    let AnoEscolar = [];
    let data:string;

    if (localStorage.getItem('select_slep')) {
      this.loading = true;
      this.api_error = false;

      if (!this.ActualAnoEscolar) {
        let currentTime = new Date()
        this.ActualAnoEscolar = currentTime.getFullYear();
      }

      if (this.SlcAnoEsoclar.length==0) {
        Object.keys(this.ListAnoEscolar).forEach(key => {
          let xano = {};
          xano["ano_escolar"] = this.ListAnoEscolar[key].ano_escolar;
          this.SlcAnoEsoclar.push(xano);
        });
      }

      this.SlcAnoEsoclar = this.eliminarDuplicateInArray(this.SlcAnoEsoclar);
      this.dataTable = [];
      jsonData = JSON.parse(localStorage.getItem('select_slep'));
      idSelect = jsonData.id
      recurso = urlServer + '/slep/' + idSelect + '/asistencia-matricula-comparativa/ano_escolar/'+this.ActualAnoEscolar;

      let xkeys = []; 
      let yAxis = []; 
      let rsTabla = [];
      let colors = [];

      colors = ["#cb4b4b", "#0B62A4", "#229900", "#ddaa00"];
      yAxis = [{index:0,labels:{format:"{value}%"}, opposite:true, title:{text: "Asistencia"}}, {index:1,title:{text: "Matrícula"}}];

      this.IGservice.getData(recurso).then((data) => {
        let matriculas = [];
        let asistencias = [];
        let rsTable = {};
        Object.keys(data.data).forEach(key => {
          if (!$.isEmptyObject(data.data[key])) {
            Object.keys(data.data[key]).forEach(zona => {
              xkeys.push(zona);
              matriculas.push(data.data[key][zona].matriculados);
              asistencias.push(data.data[key][zona].porc_asistencia);
               this.dataTable.push({
                zona: zona,
                matriculados: this.formatNumber(data.data[key][zona].matriculados),
                asistencia: this.formatNumber(data.data[key][zona].asistencia),
                porc_asistencia: data.data[key][zona].porc_asistencia,
                inasistencia: this.formatNumber(data.data[key][zona].inasistencia),
                porc_inasistencia: data.data[key][zona].porc_inasistencia,
                asistencia_posible:this.formatNumber(data.data[key][zona].asistencia_posible)
              });
            });
          }
        });
        rsData = [
          {data:matriculas, name:'Matricula', type:"column", yAxis:1,_colorIndex: 0},
          {data:asistencias, name:'Asistencia', tooltip: {valueSuffix: "%"}, type:"line", _colorIndex: 1 ,_symbolIndex: 0},
        ];
        this.buildGraphDualBarraLinea(rsData, this.ActualAnoEscolar, colors, xkeys, yAxis,'comparativa');
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
        this.api_error = true;
        $.notify(
          { icon: 'notifications', message: err.message }, 
          { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
        );
      });
      this.chart = new Chart({
        lang: {
          downloadPNG: "Descargar a PNG",
          downloadJPEG: "Descargar a JPEG",
          downloadSVG: "Descargar a SVG",
          downloadPDF: "Descargar a PDF",
          loading: "Cargando",
          printChart: "Imprimir",
          contextButtonTitle: 'Opciones gráfico'
        },
        title: {
            text: 'Total asistencia - matrícula',
            x: -20
        }
      });
    }
  } 

  /* TOTAL ASISTENCIA - MATRICULA */
  totalAsistenciaMatricula() {
    this.removeActiveTab();
    $("#pill3").parent().addClass("active");
    $("#tab3").parent().addClass("active");
    this.gAsistenciaMensualSost = false;
    this.gAsistenciaMensualCol = false;
    this.gTotalAsistenciaMatricula = true;
    this.gAsistenciaMensualPrioritario = false;
    this.gAsistenciaMensualIntegrado = false;
    this.gAsistenciaMensualVulnerable = false;
    this.gAsistenciaMensualPreferente = false;
    this.gAsistenciaComparativo = false;
    this.gAsistMatrComparativo = false;

    let rsData = [];
    let recurso: string;
    let list: any;
    let jsonData: any;
    let idSelect: string;
    let AnoEscolar = [];
    let data:string;
    let rsData2:string;
        if (!this.ActualAnoEscolar) {
      let currentTime = new Date()
      this.ActualAnoEscolar = currentTime.getFullYear();
    }

    let codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));
    if (this.SlcAnoEsoclar.length==0) {
      Object.keys(this.ListAnoEscolar).forEach(key => {
        let xano = {};
        xano["ano_escolar"] = this.ListAnoEscolar[key].ano_escolar;
        this.SlcAnoEsoclar.push(xano);
      });
    }

    this.SlcAnoEsoclar = this.eliminarDuplicateInArray(this.SlcAnoEsoclar);
    this.loading = true;
    this.api_error = false;

    if(localStorage.getItem('select_colegio')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_colegio'));
        idSelect = jsonData.id
    } else if (localStorage.getItem('select_sost')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_sost'));
        idSelect = jsonData.id
    } else if (localStorage.getItem('select_slep')) {
        list = JSON.parse(localStorage.getItem('ListSost'));
        jsonData = JSON.parse(localStorage.getItem('select_slep'));
        idSelect = jsonData.id
    }

    let recurso_asistencia_post = urlServer + '/asistencia_mensual';
    let tipo = "";
    if (localStorage.getItem('select_colegio')) {
    recurso = urlServer + '/colegioselector/' + idSelect;  
    tipo = "6";
    }
     else if (localStorage.getItem('select_sost')) {
      recurso = urlServer + '/rbd/sostenedor/' + idSelect;
      tipo = "6";
    } else if (localStorage.getItem('select_slep')) {
      recurso = urlServer + '/rbd/slep/' + idSelect;
      tipo = "5";
    } else {
      recurso = null
    }

    let arrCodColegios = [];
    let arrIdColegios = [];
    if(recurso){
      this.IAservice.getData(recurso).then((data) => {
          Object.keys(data.data).forEach(key => {
              arrIdColegios.push(key);
              arrCodColegios.push(data.data[key]);
          });

          let ListCodColegios = arrCodColegios.toString();
          let ListIdColegios  = arrIdColegios.toString();

          let params = {
              "grupo_colegios": ListCodColegios,
              "ano_escolar": this.ActualAnoEscolar,
              "tipo": tipo
          }

          this.IGservice.getDataPost(recurso_asistencia_post, params).then((result) => {
            if( result.success ) {
              let jsonData = result.data;
              rsData2 = jsonData.datos;
              this.buildGraphDualBarraLinea(rsData2, this.ActualAnoEscolar, jsonData.colors, jsonData.xkeys, jsonData.yAxis);
              this.buildTablaAsistenciaMatricula(jsonData.labels);
              this.loading = false;
            } else {
              $.notify(
                {icon: 'notifications', message: "No hay datos para imprimir"}, 
                {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
              });
              setTimeout(() => { this.loading = false}, 700);
            }
          });
      })
      .catch((err) => {
        $.notify(
          {icon: 'notifications', message: err.message}, 
          {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
        );
      });
    }
    else{
      $.notify(
        {icon: 'notifications', message: 'Revise selección en el menú por favor.'}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
      );
    }
  }

  /* ASISTENCIA MENSUAL ALUMNOS PRIORITARIOS */
  // AS asistenciaMensualPrioritario() { Modificado para dejar una sola funcion de manejo de las interfaces de alumnos  
  //AS Cambio de configuración de parametros y solicitud a API 
    asistenciaMensualAlumno(tipoalumno) {      
      let rsData = [];
      let arrCodColegios = [];
      let arrIdColegios = [];
      let niveles = [];
      let AnoEscolar = [];
      let xkeys = [];
      let diasEjeX = [];
      let recurso: string;
      let idSelect: string;      
      let data:string;      
      let rsData2:string;
      let tipo = "";
      let jsonData: any;      
      let list: any;
      let dia = new Date();            
      let recurso_asistencia_alumno = urlServer + '/asistencia_tipoalumno';
      this.removeActiveTab();
      switch (tipoalumno) {
             case 1 :
                    $("#pill4").parent().addClass("active");
                    $("#tab4").parent().addClass("active");
                    this.gAsistenciaMensualSost = false;
                    this.gAsistenciaMensualCol = false;
                    this.gTotalAsistenciaMatricula = false;
                    this.gAsistenciaMensualPrioritario = true;
                    this.gAsistenciaMensualIntegrado = false;
                    this.gAsistenciaMensualVulnerable = false;
                    this.gAsistenciaMensualPreferente = false;
                    this.gAsistenciaComparativo = false;
                    this.gAsistMatrComparativo = false;
                    this.titulo_excel = "Asistencia_mensual_alumnos_prioritarios_periodo_"+this.ActualAnoEscolar;                    
                    tipo = "1";
                    
             break;
             case 2 :
                    $("#pill5").parent().addClass("active");
                    $("#tab5").parent().addClass("active");
                    this.gAsistenciaMensualSost = false;
                    this.gAsistenciaMensualCol = false;
                    this.gTotalAsistenciaMatricula = false;
                    this.gAsistenciaMensualPrioritario = false;
                    this.gAsistenciaMensualIntegrado = true;
                    this.gAsistenciaMensualVulnerable = false;
                    this.gAsistenciaMensualPreferente = false;
                    this.gAsistenciaComparativo = false;
                    this.gAsistMatrComparativo = false; 
                    tipo = "2";                    
             break;
             case 3 :
                    $("#pill6").parent().addClass("active");
                    $("#tab6").parent().addClass("active");
                    this.gAsistenciaMensualSost = false;
                    this.gAsistenciaMensualCol = false;
                    this.gTotalAsistenciaMatricula = false;
                    this.gAsistenciaMensualPrioritario = false;
                    this.gAsistenciaMensualIntegrado = false;
                    this.gAsistenciaMensualVulnerable = true;
                    this.gAsistenciaMensualPreferente = false;
                    this.gAsistenciaComparativo = false;
                    this.gAsistMatrComparativo = false; 
                    tipo = "3";
             break;
             case 4 :
                    $("#pill7").parent().addClass("active");
                    $("#tab7").parent().addClass("active");
                    this.gAsistenciaMensualSost = false;
                    this.gAsistenciaMensualCol = false;
                    this.gTotalAsistenciaMatricula = false;
                    this.gAsistenciaMensualPrioritario = false;
                    this.gAsistenciaMensualIntegrado = false;
                    this.gAsistenciaMensualVulnerable = false;
                    this.gAsistenciaMensualPreferente = true;
                    this.gAsistenciaComparativo = false;
                    this.gAsistMatrComparativo = false; 
                    tipo = "4";
             break;
      }    
      this.loading = true;
      this.api_error = false;
      if (!this.ActualAnoEscolar) {
         let currentTime = new Date()
         this.ActualAnoEscolar = currentTime.getFullYear();
      }
      if (!this.mesSelected) {
         this.mesSelected = this.MES_NOM_COMPLETO[dia.getMonth()+1];
      }
      let mes_select = this.MES_NOM_COMPLETO.indexOf(this.mesSelected);    
      if (localStorage.getItem('select_colegio')) {
         list = JSON.parse(localStorage.getItem('ListCole'));
         jsonData = JSON.parse(localStorage.getItem('select_colegio'));
         idSelect = jsonData.id
         recurso = urlServer + '/colegioselector/' + idSelect;        
      } 
      else if (localStorage.getItem('select_sost')) {
              list = JSON.parse(localStorage.getItem('ListCole'));              
              jsonData = JSON.parse(localStorage.getItem('select_sost'));
              idSelect = jsonData.id
              recurso = urlServer + '/rbd/sostenedor/' + idSelect;
      } 
      else if (localStorage.getItem('select_slep')) {
              list = JSON.parse(localStorage.getItem('ListSost'));              
              jsonData = JSON.parse(localStorage.getItem('select_slep'));
              idSelect = jsonData.id
              recurso = urlServer + '/rbd/slep/' + idSelect;
      }  
      else {
        recurso = null
      }
      
      if (recurso) {
         this.IAservice.getData(recurso).then((data) => {
              Object.keys(data.data).forEach(key => {
                     arrIdColegios.push(key);
                     arrCodColegios.push(data.data[key]);
              });
         let ListCodColegios = arrCodColegios.toString();
         let ListIdColegios  = arrIdColegios.toString();
         let params = {
             "grupo_colegios": ListCodColegios,
             "ano_escolar": this.ActualAnoEscolar,
             "mes":mes_select,
             "tipo": tipo
         }
         this.IGservice.getDataPost(recurso_asistencia_alumno, params).then((result) => {
              if (result.success) {
                                    let jsonData = result.data;
                                    rsData2 = jsonData.datos;
                                    //Codigo tomado de la funcion que recibia del servicio apisnd descartado
                                    xkeys = jsonData.xkeys;
                                    let series = [];
                                    Object.keys(rsData2).forEach(key => {
                                           series[key] = new Object();
                                           series[key].name = rsData2[key].name;
                                           if (rsData2[key].visible !== null) {
                                               series[key].visible = rsData2[key].visible;
                                          }
                                          series[key].data = rsData2[key].data[mes_select];
                                    });
                                    Object.keys(xkeys).forEach(key => {
                                           let str = xkeys[key];
                                           let res = str.split("_");
                                           if (parseInt(res[1]) === mes_select) {
                                               diasEjeX.push(res[0]);
                                           }
                                    });
                                    diasEjeX = this.eliminarDuplicateInArray(diasEjeX);
                                    this.buildGraphLine(series, this.ActualAnoEscolar, jsonData.colors, diasEjeX);
                                    this.buildTablasAsistenciaAlumnos(jsonData.labels[mes_select],tipo);                                    
                                    this.loading = false;
              } 
              else {
                  $.notify(
                            {icon: 'notifications', message: "No hay datos para imprimir"}, 
                            {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
                  });
                  setTimeout(() => { this.loading = false}, 700);
              }
          });
      })
      .catch((err) => {
        $.notify(
          {icon: 'notifications', message: err.message}, 
          {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
        );
      });
  }

      /* if (localStorage.getItem('cod_sostenedor')) {
      let codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));
      if (this.SlcAnoEsoclar.length==0) {
        Object.keys(this.ListAnoEscolar).forEach(key => {
          let xano = {};
          xano["ano_escolar"] = this.ListAnoEscolar[key].ano_escolar;
          this.SlcAnoEsoclar.push(xano);
        });
      }
      this.SlcAnoEsoclar = this.eliminarDuplicateInArray(this.SlcAnoEsoclar);

      this.loading = true;
      this.api_error = false;
      this.AsistenciaSndService.asistenciaMensualAlumnosPrioritarios(codSostenedor,this.ActualAnoEscolar).subscribe(
        result => {
          if( result.success ) {
            let jsonData = result.data;
            rsData2 = jsonData.datos;
            xkeys = jsonData.xkeys;
            let series = [];
            Object.keys(rsData2).forEach(key => {
              series[key] = new Object();
              series[key].name = rsData2[key].name;
              if (rsData2[key].visible !== null) {
                series[key].visible = rsData2[key].visible;
              }
              series[key].data = rsData2[key].data[mes_select];
            });

            Object.keys(xkeys).forEach(key => {
              let str = xkeys[key];
              let res = str.split("_");
              if (parseInt(res[1]) === mes_select) {
                diasEjeX.push(res[0]);
              }
            });

            diasEjeX = this.eliminarDuplicateInArray(diasEjeX);
            this.buildGraphLine(series, this.ActualAnoEscolar, jsonData.colors, diasEjeX);
            this.buildTablasAsistenciaAlumnos(jsonData.labels[mes_select]);
            this.loading = false;
          } else {
            $.notify(
              {icon: 'notifications', message: "No hay datos para imprimir"}, 
              {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
            });
            setTimeout(() => { this.loading = false}, 700);
          }
        },
        error => {
          setTimeout(() => { this.loading = false}, 700);
          this.api_error = true;
        }
      );
    }*/
    // AS
  }

  /* ASISTENCIA MENSUAL ALUMNOS INTEGRADOS */
  asistenciaMensualIntegrado() {
    this.removeActiveTab();
    $("#pill5").parent().addClass("active");
    $("#tab5").parent().addClass("active");
    this.gAsistenciaMensualSost = false;
    this.gAsistenciaMensualCol = false;
    this.gTotalAsistenciaMatricula = false;
    this.gAsistenciaMensualPrioritario = false;
    this.gAsistenciaMensualIntegrado = true;
    this.gAsistenciaMensualVulnerable = false;
    this.gAsistenciaMensualPreferente = false;
    this.gAsistenciaComparativo = false;
    this.gAsistMatrComparativo = false;

    let rsData = [];
    let recurso: string;
    let idSelect: string;
    let jsonData: any;
    let AnoEscolar = [];
    let data:string;
    let niveles = [];
    let rsData2:string;
    let dia = new Date();
    let xkeys = [];
    let diasEjeX = [];

    if (!this.ActualAnoEscolar) {
      let currentTime = new Date()
      this.ActualAnoEscolar = currentTime.getFullYear();
    }

    if (!this.mesSelected) {
       this.mesSelected = this.MES_NOM_COMPLETO[dia.getMonth()+1];
    }
    let mes_select = this.MES_NOM_COMPLETO.indexOf(this.mesSelected);

    if (localStorage.getItem('cod_sostenedor')) {
      let codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));
      if (this.SlcAnoEsoclar.length==0) {
        Object.keys(this.ListAnoEscolar).forEach(key => {
          let xano = {};
          xano["ano_escolar"] = this.ListAnoEscolar[key].ano_escolar;
          this.SlcAnoEsoclar.push(xano);
        });
      }

      this.SlcAnoEsoclar = this.eliminarDuplicateInArray(this.SlcAnoEsoclar);
      this.loading = true;
      this.api_error = false;

      this.AsistenciaSndService.asistenciaMensualAlumnoIntegrado(codSostenedor,this.ActualAnoEscolar).subscribe(
        result => {
          if( result.success ) {
            let jsonData = result.data;
            rsData2 = jsonData.datos;
            xkeys = jsonData.xkeys;
            let series = [];
            Object.keys(rsData2).forEach(key => {
              series[key] = new Object();
              series[key].name = rsData2[key].name;
              if (rsData2[key].visible !== null) {
                series[key].visible = rsData2[key].visible;
              }
              series[key].data = rsData2[key].data[mes_select];
            });
            Object.keys(xkeys).forEach(key => {
              let str = xkeys[key];
              let res = str.split("_");
              if (parseInt(res[1]) === mes_select) {
                diasEjeX.push(res[0]);
              }
            });

            diasEjeX = this.eliminarDuplicateInArray(diasEjeX);

            this.buildGraphLine(series, this.ActualAnoEscolar, jsonData.colors, diasEjeX);
            //this.buildTablasAsistenciaAlumnos(jsonData.labels[mes_select]);
            this.loading = false;
          } else {
            $.notify(
              {icon: 'notifications', message: "No hay datos para imprimir"}, 
              {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
            });
            setTimeout(() => { this.loading = false}, 700);
          }
        },
        error => {
          setTimeout(() => { this.loading = false}, 700);
          this.api_error = true;
        }
      );
    }
  }

  /* ASISTENCIA MENSUAL ALUMNOS VULNERABLES */
  asistenciaMensualVulnerable() {
    this.removeActiveTab();
    $("#pill6").parent().addClass("active");
    $("#tab6").parent().addClass("active");
    this.gAsistenciaMensualSost = false;
    this.gAsistenciaMensualCol = false;
    this.gTotalAsistenciaMatricula = false;
    this.gAsistenciaMensualPrioritario = false;
    this.gAsistenciaMensualIntegrado = false;
    this.gAsistenciaMensualVulnerable = true;
    this.gAsistenciaMensualPreferente = false;
    this.gAsistenciaComparativo = false;
    this.gAsistMatrComparativo = false;

    let rsData = [];
    let recurso: string;
    let idSelect: string;
    let jsonData: any;
    let AnoEscolar = [];
    let data:string;
    let niveles = [];
    let rsData2:string;
    let dia = new Date();
    let xkeys = [];
    let diasEjeX = [];

    if (!this.ActualAnoEscolar) {
      let currentTime = new Date()
      this.ActualAnoEscolar = currentTime.getFullYear();
    }

    if (!this.mesSelected) {
       this.mesSelected = this.MES_NOM_COMPLETO[dia.getMonth()+1];
    }

    let mes_select = this.MES_NOM_COMPLETO.indexOf(this.mesSelected);
    if (localStorage.getItem('cod_sostenedor')) {
      let codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));
      if (this.SlcAnoEsoclar.length==0) {
        Object.keys(this.ListAnoEscolar).forEach(key => {
          let xano = {};
          xano["ano_escolar"] = this.ListAnoEscolar[key].ano_escolar;
          this.SlcAnoEsoclar.push(xano);
        });
      }

      this.SlcAnoEsoclar = this.eliminarDuplicateInArray(this.SlcAnoEsoclar);
      this.loading = true;
      this.api_error = false;

      this.AsistenciaSndService.asistenciaMensualAlumnoVulnerable(codSostenedor,this.ActualAnoEscolar).subscribe(
        result => {
          if( result.success ) {
            let jsonData = result.data;
            rsData2 = jsonData.datos;
            xkeys = jsonData.xkeys;
            let series = [];
            Object.keys(rsData2).forEach(key => {
              series[key] = new Object();
              series[key].name = rsData2[key].name;
              if (rsData2[key].visible !== null) {
                series[key].visible = rsData2[key].visible;
              }
              series[key].data = rsData2[key].data[mes_select];
            });
            Object.keys(xkeys).forEach(key => {
              let str = xkeys[key];
              let res = str.split("_");
              if (parseInt(res[1]) === mes_select) {
                diasEjeX.push(res[0]);
              }
            });

            diasEjeX = this.eliminarDuplicateInArray(diasEjeX);
            this.buildGraphLine(series, this.ActualAnoEscolar, jsonData.colors, diasEjeX);
            //this.buildTablasAsistenciaAlumnos(jsonData.labels[mes_select]);
            this.loading = false;
          } else {
            $.notify(
              {icon: 'notifications', message: "No hay datos para imprimir"}, 
              {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
            });
            setTimeout(() => { this.loading = false}, 700);
          }
        },
        error => {
          setTimeout(() => { this.loading = false}, 700);
          this.api_error = true;
        }
      );
    }
  }

  /* ASISTENCIA MENSUAL ALUMNOS PREFERENTES */
  asistenciaMensualPreferente() {
    this.removeActiveTab();
    $("#pill7").parent().addClass("active");
    $("#tab7").parent().addClass("active");
    this.gAsistenciaMensualSost = false;
    this.gAsistenciaMensualCol = false;
    this.gTotalAsistenciaMatricula = false;
    this.gAsistenciaMensualPrioritario = false;
    this.gAsistenciaMensualIntegrado = false;
    this.gAsistenciaMensualVulnerable = false;
    this.gAsistenciaMensualPreferente = true;
    this.gAsistenciaComparativo = false;
    this.gAsistMatrComparativo = false;

    let rsData = [];
    let recurso: string;
    let idSelect: string;
    let jsonData: any;
    let AnoEscolar = [];
    let data:string;
    let niveles = [];
    let rsData2:string;
    let dia = new Date();
    let xkeys = [];
    let diasEjeX = [];

    if (!this.ActualAnoEscolar) {
      let currentTime = new Date()
      this.ActualAnoEscolar = currentTime.getFullYear();
    }

    if (!this.mesSelected) {
       this.mesSelected = this.MES_NOM_COMPLETO[dia.getMonth()+1];
    }
    let mes_select = this.MES_NOM_COMPLETO.indexOf(this.mesSelected);
    if (localStorage.getItem('cod_sostenedor')) {
      let codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));
      if (this.SlcAnoEsoclar.length==0) {
        Object.keys(this.ListAnoEscolar).forEach(key => {
          let xano = {};
          xano["ano_escolar"] = this.ListAnoEscolar[key].ano_escolar;
          this.SlcAnoEsoclar.push(xano);
        });
      }

      this.SlcAnoEsoclar = this.eliminarDuplicateInArray(this.SlcAnoEsoclar);
      this.loading = true;
      this.api_error = false;

      this.AsistenciaSndService.asistenciaMensualAlumnoPreferente(codSostenedor,this.ActualAnoEscolar).subscribe(
        result => {
          if( result.success ) {
            let jsonData = result.data;
            rsData2 = jsonData.datos;
            xkeys = jsonData.xkeys;
            let series = [];
            Object.keys(rsData2).forEach(key => {
              series[key] = new Object();
              series[key].name = rsData2[key].name;
              if (rsData2[key].visible !== null) {
                series[key].visible = rsData2[key].visible;
              }
              series[key].data = rsData2[key].data[mes_select];
            });
            Object.keys(xkeys).forEach(key => {
              let str = xkeys[key];
              let res = str.split("_");
              if (parseInt(res[1]) === mes_select) {
                diasEjeX.push(res[0]);
              }
            });
            diasEjeX = this.eliminarDuplicateInArray(diasEjeX);

            this.buildGraphLine(series, this.ActualAnoEscolar, jsonData.colors, diasEjeX);
           // this.buildTablasAsistenciaAlumnos(jsonData.labels[mes_select]);
            this.loading = false;
          } else {
            $.notify(
              {icon: 'notifications', message: "No hay datos para imprimir"}, 
              {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
            });
            setTimeout(() => { this.loading = false}, 700);
          }
        },
        error => {
          setTimeout(() => { this.loading = false}, 700);
          this.api_error = true;
        }
      );
    }
  }

  /* FUNCIONES DE GRAFICOS */
  buildGraphBarra(rsData, anoEscolar, colors, categoria, tipo ='consolidada') {
    var linesEscalonadas = 0;
    var titulo = 'Asistencia mensual ' + anoEscolar;
    if (tipo=='comparativa') {
      linesEscalonadas = 3;
      titulo = 'Asistencia '+anoEscolar;
    }

    this.chart = new Chart({
      lang: {
        downloadPNG: "Descargar a PNG",
        downloadJPEG: "Descargar a JPEG",
        downloadSVG: "Descargar a SVG",
        downloadPDF: "Descargar a PDF",
        loading: "Cargando",
        printChart: "Imprimir",
        contextButtonTitle: 'Opciones gráfico'
      },
      chart: {
        type: 'column'
      },
      title: {
        text: titulo
      },
      xAxis: {
        categories: categoria,
        min: null,
        max: null,
        title: {
          text: null
        },
        labels: {
          staggerLines:linesEscalonadas
        }
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: "Porcentaje de asistencia"
        },
        labels: {
          overflow: "justify",
          format: '{value}'
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        valueSuffix: '%',
        formatter: function() {
          return "" + this.x + "<br/>" + this.series.name + " : <strong>" + Highcharts.numberFormat(this.y, 0, ",", ".")+"%</strong>";
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            shadow: true
          }
        },
        series: {
          stacking: 'normal',
          dataLabels: {
            rotation: null,
            formatter: function() {
              return this.y + "%";
            },
       
            enabled: true,
            verticalAlign: 'middle',
            align: 'center',
            inside: true
          }
        }
      },
      series: rsData,
      colors: colors
    });
  }

  buildGraphDualBarraLinea(rsData, anoEscolar, colors, categoria, yAxis, tipo ='consolidada') {
    var linesEscalonadas = 0;
    var rotacion = -45;

    if (tipo=='comparativa') {
      linesEscalonadas = 3;
      rotacion = 0;
    }

    this.chart = new Chart({
      lang: {
        downloadPNG: "Descargar a PNG",
        downloadJPEG: "Descargar a JPEG",
        downloadSVG: "Descargar a SVG",
        downloadPDF: "Descargar a PDF",
        loading: "Cargando",
        printChart: "Imprimir",
        contextButtonTitle: 'Opciones gráfico'
      },
      
      title: {
          text: 'Total asistencia - matrícula',
          x: -20
      },
      subtitle: {
        text: '',
        x: -20
      },
      xAxis: {
        categories: categoria,
        min: null,
        max: null,
        title: {
          text: null
        },
        labels: {
          rotation: rotacion,
          staggerLines:linesEscalonadas
        }
      },
      yAxis: yAxis,
      tooltip: {
        shared: true
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            inside: true,
            formatter: function() {
              return Highcharts.numberFormat(this.y, 0, ',', '.');
            }
          },
          enableMouseTracking: true
        },
        line: {
          dataLabels: {
            enabled: true,
            formatter: function() {
              return Highcharts.numberFormat(this.y, 0, ',', '.');
            },
            format: '{y}%'
          }
        }
      },
      colors: colors,
      series: rsData,
      credits: {
        enabled: false
      }
    });
  }

  buildGraphLine(rsData, anoEscolar, colors, categoria) {
    this.chart = new Chart({
      lang: {
        downloadPNG: "Descargar a PNG",
        downloadJPEG: "Descargar a JPEG",
        downloadSVG: "Descargar a SVG",
        downloadPDF: "Descargar a PDF",
        loading: "Cargando",
        printChart: "Imprimir",
        contextButtonTitle: 'Opciones gráfico'
      },
      title: {
          text: 'Asistencia mensual',
          x: -20
        },
        subtitle: {
          text: '',
          x: -20
        },
        xAxis: {
          categories: categoria
        },
        yAxis: {
          min: 0,
          labels: {
            format: '{value}'
          },
          title: {
            text: 'Cantidad de asistencia'
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#808080'
            }
          ]
        },
        tooltip: {
          formatter: function() {
            return "" + this.x + "<br/>" + this.series.name + " : <strong>" + Highcharts.numberFormat(this.y, 0, ",", ".") + "</strong>";
          }
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
              formatter: function() {
                return Highcharts.numberFormat(this.y, 0, ',', '.');
              }
            },
            enableMouseTracking: true
          }
        },
        colors: colors,
        series: rsData,
        credits: {
          enabled: false
        }
    });
  }

  /* FUNCIONES QUE CONSTRUYEN LAS TABLAS */
  buildTablaAsisSostenedor(rsTable) {
    this.dataTable = [];
    this.dataTableExcel = [];
    this.titulo_excel = "Asistencia_mensual_por_sostenedor_"+localStorage.getItem('cod_sostenedor')+"_periodo_"+this.ActualAnoEscolar;
    Object.keys(rsTable).forEach(key => {
      if (rsTable[key].INAS == 0) {
        rsTable[key].porc_inasistencia = 0;
      }
      this.dataTable.push({
        mes: rsTable[key].mes,
        asistencia: this.formatNumber(rsTable[key].ASIS_NET),
        porc_asistencia: rsTable[key].porc_asistencia,
        inasistencia: this.formatNumber(rsTable[key].INAS),
        porc_inasistencia: rsTable[key].porc_inasistencia,
        asistencia_posible:this.formatNumber(rsTable[key].ASIS_POS)
      });

      this.dataTableExcel.push({
        Mes: rsTable[key].mes,
        Asistencia: rsTable[key].ASIS_NET,
        Porcentaje_asistencia: rsTable[key].porc_asistencia,
        Inasistencia: rsTable[key].INAS,
        Porcentaje_inasistencia: rsTable[key].porc_inasistencia,
        Asistencia_posible:rsTable[key].ASIS_POS
      });
    });
  }


  buildTablaAsisComparativo(rsTable) {
    this.dataTable = [];
    Object.keys(rsTable).forEach(key => {
      if (!rsTable[key].ASIS_POS){
        rsTable[key].ASIS_POS = 0;
      }
      if (!rsTable[key].asistencia) {
        rsTable[key].asistencia = 0;
      }
      if (!rsTable[key].inasistencia) {
        rsTable[key].inasistencia = 0;
      }
      if (rsTable[key].INAS == 0) {
        rsTable[key].porc_inasistencia = 0;
      }
      this.dataTable.push({
        coorporacion: rsTable[key].zona,
        asistencia: this.formatNumber(rsTable[key].asistencia),
        porc_asistencia: rsTable[key].porc_asistencia,
        inasistencia: this.formatNumber(rsTable[key].inasistencia),
        porc_inasistencia: rsTable[key].porc_inasistencia,
        asistencia_posible:this.formatNumber(rsTable[key].ASIS_POS)
      });
    });
  }

  buildTablaAsisMensualCol(rsTable) {
    this.dataTable = [];
    this.dataTableExcel = [];
    this.titulo_excel = "Asistencia_mensual_colegios_periodo_"+this.ActualAnoEscolar+"_sostenedor_"+localStorage.getItem('cod_sostenedor');
    Object.keys(rsTable).forEach(key => {

      if (!rsTable[key].ASIS_POS){
        rsTable[key].ASIS_POS = 0;
      }
      if (!rsTable[key].asistencia) {
        rsTable[key].asistencia = 0;
      }
      if (!rsTable[key].inasistencia) {
        rsTable[key].inasistencia = 0;
      }
      if (rsTable[key].INAS == 0) {
        rsTable[key].porc_inasistencia = 0;
      }
      this.dataTable.push({
        colegio: rsTable[key].nom_colegio,
        asistencia: this.formatNumber(rsTable[key].asistencia),
        porc_asistencia: rsTable[key].porc_asistencia,
        inasistencia: this.formatNumber(rsTable[key].inasistencia),
        porc_inasistencia: rsTable[key].porc_inasistencia,
        asistencia_posible:this.formatNumber(rsTable[key].ASIS_POS)
      });
      this.dataTableExcel.push({
        Establecimiento: rsTable[key].nom_colegio,
        Asistencia: rsTable[key].asistencia,
        Porcentaje_asistencia: rsTable[key].porc_asistencia,
        Inasistencia: rsTable[key].inasistencia,
        Porcentaje_inasistencia: rsTable[key].porc_inasistencia,
        Asistencia_posible:rsTable[key].ASIS_POS
      });
    });
  }

  buildTablaAsistenciaMatricula(rsTable) {
    let cod_sostenedor = localStorage.getItem('cod_sostenedor');
    this.dataTable = [];
    this.dataTableExcel = [];
    this.titulo_excel = "Total_asistencia_matricula_periodo_"+this.ActualAnoEscolar+"_sostenedor_"+cod_sostenedor;

    Object.keys(rsTable).forEach(key => {
      if (!rsTable[key].asis_pos){
        rsTable[key].asis_pos = 0;
      }
      if (!rsTable[key].matricula){
        rsTable[key].matricula = 0;
      }
      if (!rsTable[key].asis_net) {
        rsTable[key].asis_net = 0;
      }
      if (!rsTable[key].inas) {
        rsTable[key].inas = 0;
      }
      if (rsTable[key].INAS == 0) {
        rsTable[key].porc_inasistencia = 0;
      }
      this.dataTable.push({
        colegio: rsTable[key].nom_colegio,
        matriculados: this.formatNumber(rsTable[key].matricula),
        asistencia: this.formatNumber(rsTable[key].asis_net),
        porc_asistencia: rsTable[key].asistencia,
        inasistencia: this.formatNumber(rsTable[key].inas),
        porc_inasistencia: rsTable[key].inasistencia,
        asistencia_posible:this.formatNumber(rsTable[key].asis_pos)
      });
      this.dataTableExcel.push({
        Establecimiento: rsTable[key].nom_colegio,
        Matriculados: rsTable[key].matricula,
        Asistencia: rsTable[key].asis_net,
        Porcentaje_asistencia: rsTable[key].asistencia,
        Inasistencia: rsTable[key].inas,
        Porcentaje_inasistencia: rsTable[key].inasistencia,
        Asistencia_posible:rsTable[key].asis_pos
      });
    });
  }

// AS Modificacion de funcion para generar tablas de asistencias Alumnos
  buildTablasAsistenciaAlumnos(rsTable,tipo){
    this.titulo_excel = ''; 
    this.dataTable = [];
    this.dataTableExcel = [];
    let mes_seleccionado = this.mesSelected;
    let cod_sostenedor = localStorage.getItem('cod_sostenedor');
    this.titulo_excel = `Asistencia_mensual_${this.DataTables.ALUMNOS[(tipo-1)]}_${mes_seleccionado.toLowerCase()}_${this.ActualAnoEscolar}`;
    if (rsTable) {
      let tablaAlumno = [];
       Object.keys(rsTable).forEach(key => {
        if (rsTable[key].fecha) {
          let asist_posible_alumno = 0;
          let asist_neta_alumno =0 ;
          if (this.gAsistenciaMensualPrioritario) {
            if (parseInt(rsTable[key].prioritario)) {
              asist_posible_alumno = parseInt(rsTable[key].asistencia_posible_prio);
              asist_neta_alumno = rsTable[key].asistencia_neta_prio;
            }
            this.dataTableExcel .push({
              Dia:rsTable[key].fecha,
              Prioritarios:asist_posible_alumno,
              Asistencia_prioritarios:asist_neta_alumno,
              Total: parseInt(rsTable[key].asistencia_posible),
              Asistencia: rsTable[key].asistencia_neta,
            });
          } else if (this.gAsistenciaMensualIntegrado) {
            if (parseInt(rsTable[key].integrado)) {
              asist_posible_alumno = parseInt(rsTable[key].asistencia_posible_int);
              asist_neta_alumno =  rsTable[key].asistencia_neta_int;
            }
            this.dataTableExcel .push({
              Dia:rsTable[key].fecha,
              Integrados:asist_posible_alumno,
              Asistencia_integrados:asist_neta_alumno,
              Total: parseInt(rsTable[key].asistencia_posible),
              Asistencia: rsTable[key].asistencia_neta,
            });
          } else if (this.gAsistenciaMensualVulnerable) {
            if (parseInt(rsTable[key].vulnerable)) {
              asist_posible_alumno = parseInt(rsTable[key].asistencia_posible_vul);
              asist_neta_alumno = rsTable[key].asistencia_neta_vul;
            }
            this.dataTableExcel .push({
              Dia:rsTable[key].fecha,
              Vulnerables:asist_posible_alumno,
              Asistencia_vulnerables:asist_neta_alumno,
              Total: parseInt(rsTable[key].asistencia_posible),
              Asistencia: rsTable[key].asistencia_neta,
            });
          } else if (this.gAsistenciaMensualPreferente) {
            if (parseInt(rsTable[key].preferente)) {
              asist_posible_alumno = parseInt(rsTable[key].asistencia_posible_pref);
              asist_neta_alumno = rsTable[key].asistencia_neta_pref;
            }
            this.dataTableExcel .push({
              Dia:rsTable[key].fecha,
              Preferentes:asist_posible_alumno,
              Asistencia_preferentes:asist_neta_alumno,
              Total: parseInt(rsTable[key].asistencia_posible),
              Asistencia: rsTable[key].asistencia_neta,
            });
          }
          //tablaAlumno.push({
            this.dataTable.push({
            dia: rsTable[key].fecha,
            asist_posible_alumno: this.formatNumber(asist_posible_alumno),
            asist_neta_alumno: asist_neta_alumno,
            total: this.formatNumber(parseInt(rsTable[key].asistencia_posible)),
            asistencia: rsTable[key].asistencia_neta,
          });
        }
      });

      

      //this.dataTable = tablaAlumno;
      
     /* if (this.gAsistenciaMensualPrioritario) {
       // this.dataTablePrio = [];
       // this.dataTablePrio = tablaAlumno;
        this.titulo_excel = "Asistencia_mensual_prioritarios_periodo_"+mes_seleccionado.toLowerCase()+"_"+this.ActualAnoEscolar+"_sostenedor_"+cod_sostenedor;
      } else if (this.gAsistenciaMensualIntegrado) {
        this.dataTableInteg = [];
        this.dataTableInteg = tablaAlumno;
        this.titulo_excel = "Asistencia_mensual_integrados_periodo_"+mes_seleccionado.toLowerCase()+"_"+this.ActualAnoEscolar+"_sostenedor_"+cod_sostenedor;
      } else if (this.gAsistenciaMensualVulnerable) {
        this.dataTableVuln = [];
        this.dataTableVuln = tablaAlumno;
        this.titulo_excel = "Asistencia_mensual_vulnerable_periodo_"+mes_seleccionado.toLowerCase()+"_"+this.ActualAnoEscolar+"_sostenedor_"+cod_sostenedor;
      } else if (this.gAsistenciaMensualPreferente) {
        this.dataTablePref = [];
        this.dataTablePref = tablaAlumno;
        this.titulo_excel = "Asistencia_mensual_preferentes_periodo_"+mes_seleccionado.toLowerCase()+"_"+this.ActualAnoEscolar+"_sostenedor_"+cod_sostenedor;
      } else {
        this.titulo_excel = '';
        this.dataTable = [];
        $.notify(
          {icon: 'notifications', message: 'Opcion ingresada no es correcta'}, 
          {
            type: 'warning',
            timer: 2000,
            delay: 2000,
            mouse_over: 'pause',
            placement: {
              from: 'bottom',
              align: 'right'
            }
          }
        );
      }*/
      // AS
    }
  }

  recargarGraficoAsistencia(items: any) {
    this.recalculaGraficoAsistencia(this.rsData, items.value, this.dataTable);
  }

  recalculaGraficoAsistencia(rsData, anoEscolar, dataTable) {
    this.chart = new Chart({
      lang: {
        downloadPNG: "Descargar a PNG",
        downloadJPEG: "Descargar a JPEG",
        downloadSVG: "Descargar a SVG",
        downloadPDF: "Descargar a PDF",
        loading: "Cargando",
        printChart: "Imprimir",
        contextButtonTitle: 'Opciones gráfico'
      },
      chart: {
        type: 'column'
      },
      title: {
        text: 'Asistencia mensual - ' + anoEscolar
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Total asistencia'
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      xAxis: {
        categories: this.MESES,
        title: {
          text: ''
        }
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        column: {
          stacking: 'percent',
          dataLabels: {
            enabled: true,
            color: 'white',
            format: '{point.percentage:.1f} %'
          }
        }
      },
      series: rsData[anoEscolar]
    });
  }

  eliminarDuplicateInArray(arreglo) {
    let uniqueNames = [];
    $.each(arreglo, function(i, el){
        if($.inArray(el, uniqueNames) === -1) uniqueNames.push(el);
    });
    return uniqueNames;
  }

  formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }

  numbreRand(min: number, max: number, cant: number) {
    let array = [];
    if (cant == 1) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    } else {
      for (let index = 0; index < cant; index++) {
        array.push(Math.floor(Math.random() * (max - min + 1)) + min);
      }
      return array.sort();
    }
  }

  //Exporta json a excel
  exportAsXLSX():void {
    this.excelService.exportAsExcelFile(this.dataTableExcel, this.titulo_excel);
  }

  sl2_style() {
    $(".c-btn").css("box-shadow", "none");
    $(".c-btn").css("border-top", "none");
    $(".c-btn").css("border-left", "none");
    $(".c-btn").css("border-right", "none");
    $(".c-btn").css("color", "#989898");
    $(".c-btn").css("background-color", "#ededee");
    $(".sl2-material > div >  .dropdown-list").css("position", "absolute");
  }

  removeActiveTab() {
    $("#pill1").parent().removeClass("active");
    $("#tab1").parent().removeClass("active");
    //$("#tab10").parent().removeClass("active");
    $("#pill2").parent().removeClass("active");
    $("#tab2").parent().removeClass("active");
    $("#pill3").parent().removeClass("active");
    $("#tab3").parent().removeClass("active");
    $("#pill4").parent().removeClass("active");
    $("#tab4").parent().removeClass("active");
    $("#pill5").parent().removeClass("active");
    $("#tab5").parent().removeClass("active");
    $("#pill6").parent().removeClass("active");
    $("#tab6").parent().removeClass("active");
    $("#pill7").parent().removeClass("active");
    $("#tab7").parent().removeClass("active");
    $("#pill8").parent().removeClass("active");
    $("#tab8").parent().removeClass("active");
    $("#pill9").parent().removeClass("active");
    $("#tab9").parent().removeClass("active");
  }
}
