import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
// import { MdIconModule, MdCardModule, MdInputModule, MdCheckboxModule, MdButtonModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SystemRoutingModule } from './system.routing';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FooterModule } from './shared/footer/footer.module';
import { FixedpluginModule } from './shared/fixedplugin/fixedplugin.module';
import { MdModule } from './md/md.module';
import { MaterialModule, AppModule } from 'app/app.module';
import { MatSelectModule, MatProgressSpinnerModule } from '@angular/material';

import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { FilterPipe } from '../filter/filter.pipe';
import { SpinnerModule } from './shared/spinner/spinner.module';

/**
 * 
 * Library highcharts
 * 
 */
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import * as boostCanvas from 'highcharts/modules/boost-canvas';
import * as boost from 'highcharts/modules/boost.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as heatmap from 'highcharts/modules/heatmap.src.js';
import * as noDataToDisplay from 'highcharts/modules/no-data-to-display.src';
import * as offlineExporting from 'highcharts/modules/offline-exporting.src';



//MODULOS
import { PrincipalComponent } from './principal/principal.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardsndComponent } from './dashboardsnd/dashboardsnd.component';
import { IndicadoresAcademicosComponent } from './indicadores-academicos/indicadores-academicos.component';
import { RangoNotasComponent } from './indicadores-academicos/rango-notas.component';
import { PromedioAsignaturaComponent } from './indicadores-academicos/promedio-asignatura.component';
import {  PromedioPeriodoComponent } from './indicadores-academicos/promedio-periodo.component'
import { IndicadoresGestionComponent } from './indicadores-gestion/indicadores-gestion.component';
import { EvolucionMatriculaComponent } from './indicadores-gestion/evolucion-matricula.component';
import { CapacidadUsoComponent } from './indicadores-gestion/capacidad-uso.component';
import { AsistenciaComponent } from './indicadores-gestion/asistencia.component';
import { AlumnosRetiradosComponent } from './indicadores-gestion/alumnos-retirados.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { MantenedorUsuarioComponent } from './mantenedor-usuario/mantenedor-usuario.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpServiceProvider } from '../services/http.service';
import { HttpClient } from '@angular/common/http';

import { NgxTinymceModule } from 'ngx-tinymce';
//AS
import { ResultadoSimceComponent } from './indicadores-academicos/resultado-simce.component';
import { AlumnosPrioritariosComponent } from './indicadores-gestion/alumnos-prioritarios.component';
import { AlumnosPreferentesComponent } from './indicadores-gestion/alumnos-preferentes.component';
import { IndicadoresUsoComponent } from './indicadores-uso/indicadores-uso.component';
import { AdministracionComponent } from './indicadores-uso/administracion.component';
import { LibroClasesComponent } from './indicadores-uso/libro-clases.component';
import { PlanificacionComponent } from './indicadores-uso/planificacion.component';
import { BibliotecaComponent } from './indicadores-uso/biblioteca.component';
import { RecaudacionComponent } from './indicadores-uso/recaudacion.component';
import { VisitasSistemaComponent } from './indicadores-uso/visitas-sistema.component';
import { PortalPadapoComponent } from './indicadores-uso/portal-padapo.component';
import { ChangePassComponent } from './changepass/changepass.component';
import { ChangepassmailComponent } from './changepassmail/changepassmail.component';
import { RecoveryPassComponent } from './recovery-pass/recovery-pass.component';

// AS


@NgModule({
  imports: [
    CommonModule,
    SystemRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SystemRoutingModule,
    MatSelectModule,
    SpinnerModule,
    MatProgressSpinnerModule,
    AngularMultiSelectModule,
    ChartModule,
    HttpClientModule,
    NgxTinymceModule.forRoot({

      baseURL: '//cdn.bootcss.com/tinymce/4.7.4/'
    }),
    
  ],
  entryComponents:[ChangePassComponent, ChangepassmailComponent,RecoveryPassComponent],
  declarations: [
    DashboardComponent,
    DashboardsndComponent,
    IndicadoresAcademicosComponent,
    IndicadoresGestionComponent,    
    PromedioAsignaturaComponent,
    RangoNotasComponent,
    //AS
    PromedioPeriodoComponent,
    ResultadoSimceComponent,
    //AS
    EvolucionMatriculaComponent,
    CapacidadUsoComponent,
    AsistenciaComponent,
    AlumnosRetiradosComponent,
    NotificacionesComponent,
    MantenedorUsuarioComponent,
    ChangePassComponent, 
    ChangepassmailComponent,    
    RecoveryPassComponent,
    FilterPipe,

    // AS Componente indicadores-uso
    AlumnosPrioritariosComponent,
    AlumnosPreferentesComponent,
    IndicadoresUsoComponent,
    AdministracionComponent,
    LibroClasesComponent,
    PlanificacionComponent,
    BibliotecaComponent,
    RecaudacionComponent,
    VisitasSistemaComponent,
    PortalPadapoComponent
    // AS
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpServiceProvider,
    multi: true,
  },
    HttpClient,
    {
      provide: HIGHCHARTS_MODULES,
      useFactory: () => [
        boostCanvas, boost, exporting, heatmap, noDataToDisplay, offlineExporting
      ]
    }
    
  ],
  schemas: [],
  exports: [
    DashboardComponent,DashboardsndComponent, ChartModule, FilterPipe
  ]
})

export class SystemModule { }
