import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { urlSnd } from '../url.service';

@Injectable()
export class ApiEvolucionMatriculaService {
    private url:string= urlSnd;

    constructor(
        public http: HttpClient
    ){}

    getMatriculas(cod_sostenedor,ano_escolar,tipo): Observable<any>{
        return this.http.get(this.url+'/sostenedor/evolucionmatricula.php?cod_sostenedor=6&ano_escolar=2017&tipo=2');
    }

}