
import { Component, OnInit, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from './../services/auth.service';
import { User } from '../models/user';
import { PasswordValidation } from '../validationforms/password-validator.component';
import { forEach } from '@angular/router/src/utils/collection';

import { CookieService } from 'ngx-cookie-service';

//AS
import { MatDialog } from '@angular/material';
import { RecoveryPassComponent } from '../system/recovery-pass/recovery-pass.component';

declare var $: any;

declare interface ValidatorFn {
    (c: AbstractControl): {
        [key: string]: any;
    };
}


@Component({
    selector: 'app-login-cmp',
    templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {
    test: string = 'just a test';
    private toggleButton: any;
    private sidebarVisible: boolean;
    private nativeElement: Node;
    user: User = new User();
    login: FormGroup;

    mdPerfil: any;
    setNivel: number;
    setKeyNivel: any;
    perfiles: any

    
    redirecPerfil:any;





    constructor(private element: ElementRef, private router: Router, private auth: AuthService, private formBuilder: FormBuilder, private cookieService: CookieService, public dialog: MatDialog) {
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
    }

    isFieldValid(form: FormGroup, field: string) {
        return !form.get(field).valid && form.get(field).touched;
    }

    displayFieldCss(form: FormGroup, field: string) {
        return {
            'has-error': this.isFieldValid(form, field),
            'has-feedback': this.isFieldValid(form, field)
        };
    }



    ngOnInit() {

       this.clearLocalStorage();

        var navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];

        setTimeout(function () {
            // after 1000 ms we add the class animated to the login/register card
            $('.card').removeClass('card-hidden');
        }, 700);

        this.login = this.formBuilder.group({
            // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
            username: [null, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
            // We can use more than one validator per field. If we want to use more than one validator we have to wrap our array of validators with a Validators.compose function. Here we are using a required, minimum length and maximum length validator.
            password: ['', Validators.required]
        });


    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    onLogin(): void {
        if (this.login.valid) {
            this.auth.login(this.user)
                .then((login) => {                    
                    this.user.id_usuario = login.json().id_usuario;                    
                    this.auth.userLogin(this.user, login.json().token)                   
                        .then((user) => {
                            
                            localStorage.setItem(btoa('id_usuario'), user.json().id_usuario);
                            localStorage.setItem(btoa('id_persona'), user.json().id);
                            localStorage.setItem(btoa('usuario'), btoa(user.json().usuario));
                            localStorage.setItem(btoa('nombre'), btoa(user.json().nombre));  
                            localStorage.setItem(btoa('cambio_password'), btoa(user.json().cambio_password));                           
                            this.cookieService.set(btoa('token'), login.json().token, 1);
                            this.perfiles = user.json().perfiles
                            Object.keys(this.perfiles).forEach(key => {
                                if(this.setNivel){
                                    // AS tomar mayor perfil
                                    if(this.setNivel >= this.perfiles[key]["nivel"] ){
                                    // AS
                                        this.setNivel = this.perfiles[key]["nivel"];
                                        this.setKeyNivel = key
                                      }
                                }else{
                                    this.setNivel = this.perfiles[key]["nivel"]
                                    this.setKeyNivel = key
                                }
                              });
                              if(this.perfiles[this.setKeyNivel]){

                                this.mdPerfil  = JSON.stringify(this.perfiles[this.setKeyNivel]);

                                localStorage.setItem(btoa('perfil'),btoa(this.mdPerfil));                 
                                // let redirecTo  = 'principal';
                                // let componente = 'DashboardComponent';

                                // if (parseInt(this.perfiles[this.setKeyNivel].nivel) == 2) {
                                //     redirecTo  = 'dashboardsnd';
                                //     componente = 'DashboardsndComponent';
                                // }

                                // let redireccionarSegunPerfil = []
                                // redireccionarSegunPerfil.push({
                                //     redirectTo: redirecTo,
                                //     componente: componente
                                // });
                                // this.redirecPerfil  = JSON.stringify(redireccionarSegunPerfil[0]);
                                // localStorage.setItem(btoa('redirecPerfil'),btoa(this.redirecPerfil));

                                if (parseInt(this.perfiles[this.setKeyNivel].nivel) == 2) {                                 
                                    this.router.navigate(['/system/dashboardsnd']);
                                } else {   
                                    this.router.navigate(['/system']);
                                }

                              }else{
                                $.notify({
                                    icon: 'notifications',
                                    message: 'Perfil no definido'
                                }, {
                                        type: 'danger',
                                        timer: 2000,
                                        delay: 2000,
                                        mouse_over: 'pause',
                                        placement: {
                                            from: 'bottom',
                                            align: 'right'
                                        }
                                    });

                                this.clearLocalStorage();
                              }

                            
                        }).catch((err) => {
                            $.notify({
                                icon: 'notifications',
                                message: 'Los datos ingresados son <b>incorrectos</b>.'
                            }, {
                                    type: 'danger',
                                    timer: 2000,
                                    delay: 2000,
                                    mouse_over: 'pause',
                                    placement: {
                                        from: 'bottom',
                                        align: 'right'
                                    }
                                });
                                this.clearLocalStorage();
                        });

                })
                .catch((err) => {
                    
                    $.notify({
                        icon: 'notifications',
                        message: err.json().message
                    }, {
                            type: 'danger',
                            timer: 2000,
                            delay: 2000,
                            mouse_over: 'pause',
                            placement: {
                                from: 'bottom',
                                align: 'right'
                            }
                        });
                });
        } else {
            this.validateAllFormFields(this.login);
            $.notify({
                icon: 'notifications',
                message: 'Complete los datos de ingreso .'
            }, {
                    type: 'danger',
                    timer: 2000,
                    delay: 2000,
                    mouse_over: 'pause',
                    placement: {
                        from: 'bottom',
                        align: 'right'
                    }
                });
        }



    }

    clearLocalStorage(){
        localStorage.removeItem(btoa('token'));
        this.cookieService.delete(btoa('token'));
        localStorage.removeItem(btoa('id_usuario'));
        localStorage.removeItem(btoa('usuario'));
        localStorage.removeItem(btoa('nombre')); 
        localStorage.removeItem(btoa('perfil'));

        localStorage.removeItem('ListSost');
        localStorage.removeItem('ListSlep');
        localStorage.removeItem('ListCole');
        localStorage.removeItem('select_slep');
        localStorage.removeItem('select_sost');
        localStorage.removeItem('select_colegio');
        localStorage.removeItem('ListProfilesPers');


    }

    // AS
    recoveryPass(){
        this.dialog.open(RecoveryPassComponent);
    }

}
