
import { urlServer } from './../../services/url.service';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { TableData } from '../md/md-table/md-table.component';
import { LegendItem, ChartType } from '../md/md-chart/md-chart.component';

import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { PuenteService } from '../../services/puente.service';
import { Chart } from 'angular-highcharts';

//AS
import { HttpPuenteService } from '../../services/shared/httppuente.service';
import { ListaAgnosService } from '../../services/shared/listaagnos.service';
// import { DashboardService } from './../../services/dashboard.service';
// import { RangoNotasService } from './../../services/rango-notas.service';
//AS

// import Highcharts = require('highcharts');
// import jsPDF = require('jspdf');
// import html2canvas = require('html2canvas');

import * as Highcharts from "highcharts";
import * as jsPDF from "jspdf";
import * as html2canvas from "html2canvas";

declare const require: any;

declare const $: any;

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {

    color = 'red';
    mode = 'indeterminate';
    value = 50;

    spinner = false;
    mdPerfil: any;
    Puente: Subscription;

    params: any;
    carga: any;
    SlcAnoEsoclar: any;
    ActualAnoEscolar: any;
    ActualMesEscolar: any;
    data_puente: any;
    chartCapacidadUso: Chart;
    chartAsisInas: Chart;

    rs_capacidad_uso: any;
    rs_asistencia: any;
    rs_matricula: any;
    rs_retirados: any;

    total_matriculados: any;
    total_retirados: any;
    //AS
    MES_NOM_COMPLETO = this.Agnos.MES_NOM_COMPLETO;// ["", "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    MESES = this.Agnos.MESES; //["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];


    //constructor(private route: ActivatedRoute, private puente: PuenteService,  private DBservice: DashboardService, private router: Router, private IAservice: RangoNotasService) {
        constructor(private route: ActivatedRoute, private puente: PuenteService,  private DBservice: HttpPuenteService, private router: Router, private IAservice: HttpPuenteService,private Agnos: ListaAgnosService) {
        this.ActualAnoEscolar = this.Agnos.ActualAnoEscolar; //new Date().getFullYear();
        this.ActualMesEscolar = this.Agnos.ActualMesEscolar; // new Date().getMonth() + 1;
        //AS
        this.Puente = this.puente.getData().subscribe(data => {
            this.cargaDashboard();
        });

        this.DBservice.getRs().subscribe(data => {
            switch (data[0]) {
                case 'capacidad_uso':
                    this.rs_capacidad_uso = data[1];
                    this.graphCapacidadUso();
                    break;
                case 'asistencia-mensual':
                    this.rs_asistencia = data[1];
                    this.graphAsisInasis();
                    break;
                case 'evolucion-matricula':
                    this.rs_matricula = data[1];
                    this.calcMatricula();
                    break;
                case 'retirados':
                    this.rs_retirados = data[1];
                    this.calcRetirados();
                    break;
                default:
                    break;
            }
        });
    }

    public ngOnInit() {
        this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));
        this.puente.getData();
        this.cargaDashboard();
    }

    ngOnDestroy(){
        this.Puente.unsubscribe();
    }

    Gpdf() {
        if (this.ActualAnoEscolar) {
            this.spinner = true;

            var divWidth = $(".highcharts-container").width() - 20;
            $("#printArea").css("width","1100px")
            document.body.style.width = "1920px";
            document.body.style.height = "1080px";
            
            $('#graphClass1').highcharts().setSize("520", false);
            $('#graphClass2').highcharts().setSize("520", false);
         
          setTimeout(() => {
            var Doc = new jsPDF('l', 'pt', "A4");
            html2canvas(document.querySelector("#printArea"), {
              onclone: function (document) {
                   
              }
            }).then(canvas => {
              var imgData = canvas.toDataURL('image/png');
              var width = Doc.internal.pageSize.width;
              var height = Doc.internal.pageSize.height;
              Doc.addImage(imgData, 'PNG', 0, 0);
              Doc.save('Inicio.pdf')
              
              document.body.removeAttribute("style");
              $('#graphClass1').highcharts().setSize(divWidth, false);
              $('#graphClass2').highcharts().setSize(divWidth, false);
              $("#printArea").css("width","100%");
            });
            this.spinner = false;
          }, 1000);
        } else {
            $.notify(
                { icon: 'notifications',message: "No hay datos para imprimir" }, 
                { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' }}
            );
        }
    
    }



    ngAfterViewInit() { }

    cargaDashboard() {
        this.rs_capacidad_uso = '';
        this.rs_asistencia = '';
        this.rs_matricula = '';
        this.rs_retirados = '';
        this.total_matriculados = '';
        this.total_retirados = '';
        let recurso_capacidad_uso_post: string;
        let recurso_matricula_post: string;
        let recurso_asistencia_post: string;
        let recurso_retirados_post: string;
        let recurso: string;
        let idSelect: string;
        let jsonData: any;
        let params: any;
        let list: any;
        this.chartCapacidadUso = null;
        this.chartAsisInas = null;

        //AS cambios para seleccion por defecto en el sidebar
        if (localStorage.getItem('select_colegio')) {
            list = JSON.parse(localStorage.getItem('ListCole'));
            jsonData = JSON.parse(localStorage.getItem('select_colegio'));
            idSelect = jsonData.id
        } 
        else if (localStorage.getItem('select_sost')) {            
            list = JSON.parse(localStorage.getItem('ListCole'));
            jsonData = JSON.parse(localStorage.getItem('select_sost'));
            idSelect = jsonData.id
        } 
        else if (localStorage.getItem('select_slep')) {
            list = JSON.parse(localStorage.getItem('ListSost'));
            jsonData = JSON.parse(localStorage.getItem('select_slep'));
            idSelect = jsonData.id
        }

        recurso_matricula_post = urlServer + '/evolucion_matricula';
        recurso_asistencia_post = urlServer + '/asistencia_mensual';
        recurso_retirados_post = urlServer + '/alumnos_retirados';
        recurso_capacidad_uso_post = urlServer + '/capacidad_uso';

        if (localStorage.getItem('select_colegio')) {
            recurso = urlServer + '/colegioselector/' + idSelect;           
        }          
        else if (localStorage.getItem('select_sost')) {
          recurso = urlServer + '/rbd/sostenedor/' + idSelect;
        } 
        else if (localStorage.getItem('select_slep')) {
          recurso = urlServer + '/rbd/slep/' + idSelect;
        }
        else {
          recurso = null
        }
        let arrCodColegios = [];
        let arrIdColegios = [];
        if(recurso){
            this.IAservice.getData(recurso).then((data) => {
                Object.keys(data.data).forEach(key => {
                    arrIdColegios.push(key);
                    arrCodColegios.push(data.data[key]);
                });

                let ListCodColegios = arrCodColegios.toString();
                let ListIdColegios  = arrIdColegios.toString();

                params = {
                    "grupo_colegios": ListCodColegios,
                    "ano_ini":  this.ActualAnoEscolar,
                    "ano_fin":  this.ActualAnoEscolar,
                    "ano_escolar": this.ActualAnoEscolar,
                    "tipo": "1",
                    "mes_actual": this.ActualMesEscolar,
                    "asociar_a": idSelect 
                }
                this.consultaDataPost(recurso_matricula_post, params, 'evolucion-matricula');
                this.consultaDataPost(recurso_asistencia_post, params, 'asistencia-mensual');
                this.consultaDataPost(recurso_retirados_post, params, 'retirados');
                this.consultaDataPost(recurso_capacidad_uso_post, params, 'capacidad_uso');
            })
            .catch((err) => {
              $.notify(
                {icon: 'notifications', message: err.message}, 
                {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
              );
            });
        }
        /* else{
            $.notify(
              {icon: 'notifications', message: 'Revise selección en el menú por favor.'}, 
              {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
            );
          } */
    }

    graphCapacidadUso() {
        let data = this.rs_capacidad_uso;
        let AnoEscolar = [];
        let porc_matricula: any;
        let porc_disponible: any;
        let rsData = [];
        var flag = false;

        if (this.rs_capacidad_uso) {
            Object.keys(data.data).forEach(key => {
                if (!$.isEmptyObject(data.data[key])) {
                    Object.keys(data.data[key]).forEach(anoEscolar => {
                        flag = true;
                        AnoEscolar.push(anoEscolar);
                        porc_matricula = (100 * data.data[key][anoEscolar].matriculados) / data.data[key][anoEscolar].capacidad
                        porc_matricula = Math.round(porc_matricula * 10) / 10;
                        porc_disponible = 100 - porc_matricula
                        porc_disponible = Math.round(porc_disponible * 10) / 10;

                        var obj = {};
                        obj["matricula"] = porc_matricula;
                        obj["disponible"] = porc_disponible;
                        rsData[anoEscolar] = obj

                    });
                }
            });
            if (flag) {
                this.capacidadUsoGraphPie(rsData, this.ActualAnoEscolar);
            }
        }
    }


    capacidadUsoGraphPie(rsData, anoEscolar) {
        if(rsData[anoEscolar]){
            this.chartCapacidadUso = new Chart({

                lang: {
                    downloadPNG: "Descargar a PNG",
                    downloadJPEG: "Descargar a JPEG",
                    downloadSVG: "Descargar a SVG",
                    downloadPDF: "Descargar a PDF",
                    loading: "Cargando",
                    printChart: "Imprimir",
                    contextButtonTitle: 'Opciones gráfico'
                },
                chart: {
                    type: 'pie',

                },
                title: {
                    text: 'Capacidad de uso ' + anoEscolar
                },

                credits: {
                    enabled: false
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                exporting: {
                    'enabled': true,
                    // buttons: {
                    //     contextButton: {
                    //         menuItems: [{
                    //             text: 'Imprimir sección completa',
                    //             onclick: this.printSeccion
                    //         }]
                    //     }
                    // }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,

                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                        }
                    }
                },
                series: [{
                    name: 'Capacidad de uso',
                    data: [{
                        name: 'Disponibles',
                        y: rsData[anoEscolar]["disponible"],
                        sliced: true,
                        selected: true,
                        color: '#1EA559'

                    }, {
                        name: 'Matriculados',
                        y: rsData[anoEscolar]["matricula"],
                        color: '#FB3948'
                    }]
                }]
            });
        }


    }

    graphAsisInasis() {
        let rsData = [];
        let AnoEscolar = [];
        let flag = false;

        if (this.rs_asistencia) {
            let data = this.rs_asistencia;
            if(data.data.data === undefined){
                Object.keys(data.data).forEach(key => {
                    Object.keys(data.data[key]).forEach(anoEscolar => {
                        flag = true;
                        AnoEscolar.push(anoEscolar);
                        let anoData = []
                        var objInasistencia = {};
                        var objAsistencia = {};
                        let inasistencias = [];
                        let asistencias = [];
                        var objTabla = [];
                        for (var k in data.data[key][anoEscolar]) {
                            var objTablaDetalle = {};
                            objTablaDetalle = {
                              mes: this.MES_NOM_COMPLETO[k],
                              inasistencia: data.data[key][anoEscolar][k][0],
                              asistencia: data.data[key][anoEscolar][k][1]
                            }
                            inasistencias.push(data.data[key][anoEscolar][k][0]);
                            asistencias.push(data.data[key][anoEscolar][k][1]);
                            objTabla.push(objTablaDetalle);
                        }
                        objInasistencia = {
                            name: "Inasistencia",
                            data: inasistencias,
                            color: "#D9444E"
                        };
                        objAsistencia = {
                            name: "Asistencia",
                            data: asistencias,
                            color: "#00649E"
                        };
                        anoData.push(objInasistencia);
                        anoData.push(objAsistencia);
                        rsData[anoEscolar] = anoData;
                    });
                });
            }
            if (flag) {
                this.recalculaGraficoAsistencia(rsData, this.ActualAnoEscolar);
            }
        }
    }



    recalculaGraficoAsistencia(rsData, anoEscolar) {
        this.chartAsisInas = new Chart({
            lang: {
                downloadPNG: "Descargar a PNG",
                downloadJPEG: "Descargar a JPEG",
                downloadSVG: "Descargar a SVG",
                downloadPDF: "Descargar a PDF",
                loading: "Cargando",
                printChart: "Imprimir",
                contextButtonTitle: 'Opciones gráfico'
            },
            chart: {
                type: 'column'
            },
            title: {
                text: 'Asistencia mensual - ' + anoEscolar
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total asistencia'
                }
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            xAxis: {
                categories: this.MESES,
                title: {
                  text: ''
                }
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                column: {
                    stacking: 'percent',
                    dataLabels: {
                        enabled: true,
                        color: 'white',
                        format: '{point.percentage:.1f} %'
                    }
                },
                series: {
                    events: {
                        legendItemClick: function () {
                            return false; // <== returning false will cancel the default action
                        }
                    }
                }
            },
            series: rsData[anoEscolar]
        });
    }    


    calcMatricula() {
        this.total_matriculados = 0;
        let AnoEscolar = [];
        let rsData = [];
        var flag = false;
        let data = [];
        if (this.rs_matricula) {
            let data = this.rs_matricula;
            Object.keys(data.data).forEach(key => {
                if(data.data[key]){
                    Object.keys(data.data[key]).forEach(anoEscolar => {
                        flag = true;
                        AnoEscolar.push(anoEscolar);
                        rsData[anoEscolar] = (data.data[key][anoEscolar].cant_matriculados);
                    });
                }
            });
            if (flag) {
                this.total_matriculados = rsData[this.ActualAnoEscolar];
            }
        }
    }

    calcRetirados() {
        this.total_retirados = 0;
        let AnoEscolar = [];
        let rsData = [];
        let data = [];
        var flag = false;
        if (this.rs_retirados) {
            let data = this.rs_retirados;
            Object.keys(data.data).forEach(key => {
                if(data.data[key]){
                    Object.keys(data.data[key]).forEach(anoEscolar => {
                        flag = true;
                        AnoEscolar.push(anoEscolar);
                        var dataobj = {};
                        for (var mes in data.data[key][anoEscolar]) {
                            if (typeof data.data[key][anoEscolar][mes] !== 'function') {

                                var obj = {};
                                obj["MES"] = this.MES_NOM_COMPLETO[mes];
                                obj["RETIRADOS"] = data.data[key][anoEscolar][mes];
                                dataobj[mes] = obj;
                                rsData[anoEscolar] = dataobj

                            }
                        }
                    });
                }
            });
            if (flag) {
                var d = new Date();
                var mesActual = d.getMonth() + 1;
                var YearActual = d.getFullYear();
                if(rsData[this.ActualAnoEscolar]){
                    if (YearActual != this.ActualAnoEscolar) {
                        var length = Object.keys(rsData[this.ActualAnoEscolar]).length;
                        mesActual = length;
                    }
                    var nom_mes = rsData[this.ActualAnoEscolar][mesActual].MES;
                    var total_mes = rsData[this.ActualAnoEscolar][mesActual].RETIRADOS;
                    this.total_retirados = total_mes + " - " + nom_mes;
                }
            }
        }

    }

    link(link: any, tab: any) {
        sessionStorage.setItem('link_param', tab);
        this.router.navigate([link]);
    }

    consultaData(recurso, params, tipo) {
        let rs: any;
        // AS this.DBservice.getData(recurso, params) 
        this.DBservice.getDataasync(recurso, params)
            .then((data) => {
                this.DBservice.setRs(data, tipo);
            })
            .catch((err) => {
                $.notify({
                    icon: 'notifications',
                    message: 'No es posible cargar los datos en este momento, consulte al administrador'
                }, {
                        type: 'warning',
                        timer: 2000,
                        delay: 2000,
                        mouse_over: 'pause',
                        placement: {
                            from: 'bottom',
                            align: 'right'
                        }
                    });
            });
        return rs;
    }

    consultaDataPost(recurso, params, tipo) {
        let rs: any;
       // AS this.DBservice.getDataPost(recurso, params) 
        this.DBservice.getDataPostasync(recurso, params)
            .then((data) => {
                this.DBservice.setRs(data, tipo);
            })
            .catch((err) => {
                $.notify({
                    icon: 'notifications',
                    message: 'No es posible cargar los datos en este momento, consulte al administrador'
                }, {
                        type: 'warning',
                        timer: 2000,
                        delay: 2000,
                        mouse_over: 'pause',
                        placement: {
                            from: 'bottom',
                            align: 'right'
                        }
                    });
            });
        return rs;
    }

}
