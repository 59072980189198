import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { urlServer } from '../url.service';

@Injectable()
export class ListadoAnosEscolaresService{
    private url:string= urlServer;

    constructor(
        public http: HttpClient
    ){}

    listarAnosEscolares(key:number, opcion:number): Observable<any>{
        if (opcion == 1) {
            return this.http.get(this.url+'/sostenedor/'+key+'/lista_ano_escolar');
        } else if (opcion == 2){
            return this.http.get(this.url+'/slep/'+key+'/lista_ano_escolar');
        } else {
            console.log('Opcion invalida');
            return ;
        }        
    }

}
