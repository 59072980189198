import { Subscription } from 'rxjs/Subscription';
import { Component, OnInit, OnDestroy  } from '@angular/core';
import { PuenteService } from '../../services/puente.service';
import { Chart } from 'angular-highcharts';
import { urlServer } from '../../services/url.service';
import { ListaAgnosService } from '../../services/shared/listaagnos.service';
import { HttpPuenteService } from '../../services/shared/httppuente.service';
import { DatatablesService } from '../../services/shared/datatables.service';
import { GraficasService } from '../../services/shared/graficas.service';
import {ExcelService} from '../../services/excel.service';
import { Graph } from '../../models/graph.model';
/*import * as Highcharts from "highcharts";
import * as jsPDF from "jspdf";
import * as html2canvas from "html2canvas";*/
declare const require: any;
declare const $: any;

@Component({
  selector: 'app-planificacion',
  templateUrl: './planificacion.component.html',
  styleUrls: ['./planificacion.component.scss']
})
export class PlanificacionComponent implements OnInit {
  chart: Chart;
  graph:Graph;
  ActualAnoEscolar: any;
  SlcAnoEscolar  = [];
  ListAnoEscolar = [];
  dataTableExcel = [];
  titulo_excel:string = 'Excel';
  linkParams: any;
  Puente: Subscription;
  public loading:boolean = true;
  public api_error:boolean = false;
  public dataAsistenciaSnd:boolean = false; 
  public indicador='uso';
  gAsistenciaMensualSost = false;
  

  constructor(private puente: PuenteService,private DataTables: DatatablesService,private Agnos: ListaAgnosService, private httppuente: HttpPuenteService,private graphService: GraficasService, private excelService:ExcelService) { 

    this.ActualAnoEscolar = this.Agnos.ActualAnoEscolar; 
    /*this.Puente = this.puente.getData().subscribe(data => {
      this.planificacionSost();
    });*/
  }

 /* ngOnDestroy(): void {
    this.Puente.unsubscribe();
  }*/

  ngOnInit() {
    this.SlcAnoEscolar=this.Agnos.listAnosEscolares(this.ActualAnoEscolar);
    //this.getlistAnosEscolares(this.ActualAnoEscolar);  Se aplica en caso de que no se cargue bien desde el oninit  
    this.gAsistenciaMensualSost = true;
    this.planificacion();

  }
  //Obtener vector con listado de Años 
  public getlistAnosEscolares ( agno : number) {
    this.SlcAnoEscolar=this.Agnos.listAnosEscolares(agno);
  }
  // path API -> sineduc/api/resources/sostenedor/planificacion_resource.php
   public planificacion(){

    let rsData = [];
    let recurso: string;
    let idSelect: string;
    let jsonData: any;
    let list: any;
    let AnoEscolar = [];
    let data:string;
    let niveles = [];
    let rsData2:string;
    this.dataAsistenciaSnd = true;
    //console.log('col:',localStorage.getItem('select_colegio'),'listcol:',localStorage.getItem('ListCole'),'selsost:',localStorage.getItem('select_sost'),'selp:',localStorage.getItem('select_slep'));
    if (localStorage.getItem('select_colegio')) {
      list = JSON.parse(localStorage.getItem('ListCole'));
      jsonData = JSON.parse(localStorage.getItem('select_colegio'));
      idSelect = jsonData.id
      recurso = null
  } else if (localStorage.getItem('select_sost')) {
      list = JSON.parse(localStorage.getItem('ListCole'));
      jsonData = JSON.parse(localStorage.getItem('select_sost'));
      idSelect = jsonData.id
      recurso = urlServer + '/rbd/sostenedor/' + idSelect;
  } else if (localStorage.getItem('select_slep')) {
      list = JSON.parse(localStorage.getItem('ListSost'));
      jsonData = JSON.parse(localStorage.getItem('select_slep'));
      idSelect = jsonData.id
      recurso = urlServer + '/rbd/slep/' + idSelect;
  }
  // Consultar ruta API
  let recurso_planificacion_post = urlServer + '/asistencia_mensual';

  if (localStorage.getItem('select_sost')) {
    recurso = urlServer + '/rbd/sostenedor/' + idSelect;
  } else if (localStorage.getItem('select_slep')) {
    recurso = urlServer + '/rbd/slep/' + idSelect;
  } else {
    recurso = null
  }
  let arrCodColegios = [];
  let arrIdColegios = [];
  console.log(recurso);
  if(recurso){
    //$("#pill1").parent().addClass("active");
   // this.DataTables.buildTabla(['1', '2', '3'],  'planificacion' );
    //Implementando Servicio de conexion http
    this.httppuente.getData(recurso).then((data) => {
        Object.keys(data.data).forEach(key => {
            arrIdColegios.push(key);
            arrCodColegios.push(data.data[key]);
        });

        let ListCodColegios = arrCodColegios.toString();
        let ListIdColegios  = arrIdColegios.toString();

        let params = {
            "grupo_colegios": ListCodColegios,
            "ano_escolar": this.ActualAnoEscolar,
            "tipo": "2"
        }

        this.httppuente.getDataPost(recurso_planificacion_post, params).then((result) => {
          if( result.success ) {
            let jsonData = result.data;
            rsData2 = jsonData.datos;
            
            this.buildGraph(rsData2, this.ActualAnoEscolar, jsonData.colors, jsonData.xkeys,'consolidada');
           // this.buildTablaAsisSostenedor(jsonData.labels);
            this.loading = false;
          } else {
            $.notify(
              {icon: 'notifications', message: "No hay datos para imprimir"}, 
              {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
            });
            setTimeout(() => { this.loading = false}, 700);
          }
        });
    })
    .catch((err) => {
      $.notify(
        {icon: 'notifications', message: err.message}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
      );
    });
}

   }

  //Exporta json a excel
  exportAsXLSX():void {
    this.excelService.exportAsExcelFile(this.dataTableExcel, this.titulo_excel);
  }

  /* FUNCIONES DE GRAFICOS */
  buildGraph(rsData, anoEscolar, colors, categoria, tipo) {
    
   // let linesEscalonadas = 0;
    //let titulo = 'Período escolar ' + anoEscolar ;
    switch (tipo) {
      case 'consolidada':      
          this.graph={
            indicador:'uso',
            tipo:'barra',
            lineasEscalonadas:0,
            titulo:'Período escolar ' + anoEscolar+ 'pru',
            subtipo:'barra'
          }
        break;
      case 'comparativa':
          this.graph={
            indicador:'uso',
            tipo:'barra',
            lineasEscalonadas:0,
            titulo:'Período escolar ' + anoEscolar+ 'p',
            subtipo:'barra'
          }
        break;
      default:
          this.graph={
            indicador:'uso',
            tipo:'barra',
            lineasEscalonadas:0,
            titulo:'Período escolar ' + anoEscolar+ 'pr',
            subtipo:'barra'
          }
    }  
    this.chart = this.graphService.buildGraph(this.graph,rsData, anoEscolar, colors, categoria, tipo);
  }

}
