import { Subscription } from 'rxjs/Subscription';
import { Component, OnInit, OnDestroy  } from '@angular/core';
import { PuenteService } from '../../services/puente.service';
import { urlServer } from '../../services/url.service';
import { Graph } from '../../models/graph.model';
import { ListaAgnosService } from '../../services/shared/listaagnos.service';
import { HttpPuenteService } from '../../services/shared/httppuente.service';

@Component({
  selector: 'app-indicadores-uso',
  templateUrl: './indicadores-uso.component.html',
  styleUrls: ['./indicadores-uso.component.scss']
})
export class IndicadoresUsoComponent implements OnInit, OnDestroy {
  Puente: Subscription;
  ActualAnoEscolar: any;
  titulo_excel:string = '';
  public loading:boolean = true;
  public datos:boolean = false;
  public api_error:boolean = false;
  dataTable      = [];
  dataTableExcel = [];
  SlcAnoEscolar  = [];
  ListAnoEscolar = [];
  constructor() { }
  ngOnDestroy(): void {
    this.Puente.unsubscribe();
  }

  ngOnInit() {
    
  }

}
