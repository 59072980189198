import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

  code_error: any
  code_mensaje: any

  constructor(private router: Router) { }

  ngOnInit() {
    setTimeout(function () {
      // after 1000 ms we add the class animated to the login/register card
      $('.card').removeClass('card-hidden');
    }, 700);
    this.code_error = localStorage.getItem("code_error")
    this.code_mensaje = localStorage.getItem("code_mensaje")

    setTimeout(() => {
      localStorage.removeItem("code_error")
      localStorage.removeItem("code_mensaje")
    }, 1000);
    setTimeout(() => {
      this.router.navigateByUrl('/login');
    }, 5000);
  }

}
