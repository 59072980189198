import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlServer } from './url.service';

@Injectable()
export class NotificationService {
  private BASE_URL: string = urlServer;
  constructor(private http: HttpClient) { }

  public getNotifications(type, id): Promise<any> {
    let url = '';
    if (id) {
      url = `${this.BASE_URL}/${type}/${id}/notificaciones`;
    } else {
      url = `${this.BASE_URL}/${type}/notificaciones`;
    }
    return this.http.get(url).toPromise();
  }

  public sendNotification(params): Promise<any> {
    const url = `${this.BASE_URL}/registro-notificacion/guardar`;
    return this.http.put(url, params).toPromise();
  }
}
