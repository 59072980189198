import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms'
import { PuenteService } from './services/puente.service';

declare var $: any;

@Component({
    selector: 'app-my-app',
    templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {

    constructor(private puente:PuenteService) {
        this.puente.isLive();
    }

    ngOnInit() {
        $.material.init();
    }
}
