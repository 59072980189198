import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class PuenteService {
  
  isLoggedIn: boolean;
  private jsonData = new Subject<any>();
  constructor(private auth: AuthService,private router: Router,private cookieService: CookieService) { }

  

  setData(data: any[]) {
    this.jsonData.next(data);
  }

  getData(): Observable<any> {
    return this.jsonData.asObservable();
  }

  isLive(){
    const token = this.cookieService.get(btoa('token'));
    if (token != undefined) {
      this.auth.ensureAuthenticated(token)
        .then((user) => {
          if (user.json().status === 'success') {
            this.isLoggedIn = true;
          }
        })
        .catch((err) => {
          if (err.json().message) {
            localStorage.removeItem(btoa('token'));
            this.cookieService.delete(btoa('token'));
            this.router.navigateByUrl('/login');
          }
        });
    } else {
      this.router.navigateByUrl('/login');
    }
  }

}
