import { Subscription } from 'rxjs/Subscription';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as Chartist from 'chartist';
import { DateAdapter } from '@angular/material';
import { Chart } from 'angular-highcharts';
import { PuenteService } from '../../services/puente.service';
import { urlServer, urlSnd } from '../../services/url.service';
import { EvolucionMatriculaSndService } from '../../services/api-snd/evolucion-matricula.service';

import * as Highcharts from "highcharts";
import * as jsPDF from "jspdf";
import * as html2canvas from "html2canvas";
import {ExcelService} from '../../services/excel.service';
//AS
import { ListaAgnosService } from '../../services/shared/listaagnos.service';
import { DatatablesService } from '../../services/shared/datatables.service';
import { HttpPuenteService } from '../../services/shared/httppuente.service';
// import { EvolucionMatriculaService } from '../../services/evolucion-matricula.service';
// import { RangoNotasService } from './../../services/rango-notas.service';
//AS
declare const require: any;
declare const $: any;

@Component({
  selector: 'app-evolucion-matricula',
  templateUrl: './evolucion-matricula.component.html',
  styleUrls: ['./evolucion-matricula.component.scss'],
  providers:[EvolucionMatriculaSndService]
})

export class EvolucionMatriculaComponent implements OnInit, OnDestroy {

  chart: Chart;
  //AS
 /* MES_NOM_COMPLETO = ["", "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  MES_NOMBRES = ["", "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
  MESES = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];*/
  //AS
  dataTable = [];
  dataTableExcel = [];
  titulo_excel:string = 'Ejemplo';
  evoMatricula = false;
  evoMatriculaCol = false;
  spinner = false;
  color = 'red';
  mode = 'indeterminate';
  value = 50;
  data_puente: any;
  mdPerfil: any;
  Puente: Subscription;
  ActualAnoEscolar: any;
  SlcAnoEsoclar = [];
  rsData = [];
  linkParams: any;
  ActualMesEscolar: any;
  numAnosDescontados: number;
  rowHeadTable = [];
  bodyTable = [];

  public colegio:boolean = false;
  public loading:boolean = true;
  public api_error:boolean = false;
  public sin_data_total_sos:boolean = false;
  public sin_data_total_col:boolean = false;
  public dataEvoMatriculaSnd:boolean = false;
  //AS Agregando Servicio de listado de Años, servicio de http se invoca servicio de IGservice pero no se usa
  //constructor(private puente: PuenteService, private IGservice: EvolucionMatriculaService, private EvolucionMatriculaSndService:EvolucionMatriculaSndService, private excelService:ExcelService, private IAservice: RangoNotasService) {
    constructor(private puente: PuenteService, private IGservice: HttpPuenteService, private EvolucionMatriculaSndService:EvolucionMatriculaSndService, private excelService:ExcelService,private DataTables: DatatablesService, private IAservice: HttpPuenteService,private Agnos: ListaAgnosService) {
      
      this.Puente = this.puente.getData().subscribe(
    data => {
      this.removeActiveTab();
        if (this.evoMatricula) {
          this.evo_matricula();
        }
        if(this.evoMatriculaCol){
          this.evo_matricula_col();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.Puente.unsubscribe();
  }

  ngOnInit() {
    
    this.numAnosDescontados = 5;
    this.ActualAnoEscolar = this.Agnos.ActualAnoEscolar; //new Date().getFullYear();
    this.ActualMesEscolar = this.Agnos.ActualMesEscolar; //new Date().getMonth() + 1;
    this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));    
    if(this.mdPerfil.nivel==3 || this.mdPerfil.nivel==5){
      this.colegio=true;
    }
    else{
      this.colegio=false;
    }
    this.removeActiveTab();

    if (sessionStorage.getItem('link_param')) {
      this.evoMatricula = false;
      this.linkParams = sessionStorage.getItem('link_param');
      sessionStorage.removeItem('link_param');
    }
    
    switch (this.linkParams) {
      case 'pill1':
        this.evoMatricula = true;
        this.evo_matricula();
        break;
      case 'pill2':
        this.evoMatriculaCol = true;
        this.evo_matricula_col();
        break;
      default:
        this.evoMatricula = true;
        this.evo_matricula();
        
    }

    this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));
    this.sl2_style();
  }

  Gpdf() {
    if (this.rsData.length != 0) {
      this.spinner = true;
      var divWidth = $(".highcharts-container").width() - 20;
      var originalBodyWidth = document.body.offsetWidth;
      var originalBodyHeight = document.body.offsetHeight;
      document.body.style.width = "1920px";
      document.body.style.height = "1080px";

      $('.graphClass').highcharts().setSize("1100", false);
      $('.table').css("width","1100px");
      setTimeout(() => {
        var Doc = new jsPDF('l', 'pt', "A4");
        html2canvas(document.querySelector(".page1"), {
          onclone: function (document) {
            document.querySelector(".page1").classList.remove("col-md-8");
          }
        }).then(canvas => {
          var imgData = canvas.toDataURL('image/png');
          var width = Doc.internal.pageSize.width;
          var height = Doc.internal.pageSize.height;
          Doc.addImage(imgData, 'PNG', 0, 0);
          Doc.addPage();

          html2canvas(document.querySelector(".page2"), {
            onclone: function (document) {
              document.querySelector(".page2").style.width = "100%";
            }
          }).then(canvas => {
            var imgData = canvas.toDataURL('image/png');
            var width = Doc.internal.pageSize.width;
            var height = Doc.internal.pageSize.height;
            Doc.addImage(imgData, 'PNG', 0, 0);
            Doc.save('Indicadores de gestión.pdf')
            document.body.removeAttribute("style");
            $('.graphClass').highcharts().setSize(divWidth, false);
            $('.table').css("width","100%");
            this.spinner = false;
          });
        });
      }, 1000);
    } else {
      this.dataEvoMatriculaSnd = false;
      $.notify(
        {icon: 'notifications',message: "No hay datos para imprimir"},
        {type: 'warning', timer: 2000,delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right'}}
      );
    }
  }

  /*EVOLUCIÓN MATRÍCULA*/
  evo_matricula() {
    this.removeActiveTab();
    this.api_error = false;
    this.sin_data_total_sos = false;
    this.loading = true;
    this.evoMatricula = true;
    this.evoMatriculaCol = false;
    this.dataTableExcel = [];

    let rsData = [];
    let recurso: string;
    let idSelect: string;
    let jsonData: any;
    let AnoEscolar = [];
    let codSostenedor: number;
    let params: any;
    let list: any;
    let tipo: any;

    $("#pill1").parent().addClass("active");
    $("#tab1").parent().addClass("active");
    $("#tab10").parent().addClass("active");

    if (localStorage.getItem('select_colegio')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_colegio'));
        idSelect = jsonData.id;
        this.colegio=true;
    } else if (localStorage.getItem('select_sost')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_sost'));
        idSelect = jsonData.id;
        this.colegio= false;
    } else if (localStorage.getItem('select_slep')) {
        list = JSON.parse(localStorage.getItem('ListSost'));
        jsonData = JSON.parse(localStorage.getItem('select_slep'));
        idSelect = jsonData.id;
        this.colegio= false;
    }

    if (localStorage.getItem('select_colegio')) {
      recurso = urlServer + '/colegioselector/' + idSelect;
      tipo = 2
    } else if (localStorage.getItem('select_sost')) {
      recurso = urlServer + '/rbd/sostenedor/' + idSelect;
      tipo = 2
    } else if (localStorage.getItem('select_slep')) {
      recurso = urlServer + '/rbd/slep/' + idSelect;
      tipo = 1
    } else {
      recurso = null
    }

    let arrCodColegios = [];
    let arrIdColegios = [];
    if(recurso){
      
      //AS Usa el servicio httppuente general y se obtiene Vector con codigo de colegios
        this.IAservice.getData(recurso).then((data) => { 
          Object.keys(data.data).forEach(key => {
              arrIdColegios.push(key);
              arrCodColegios.push(data.data[key]);
          });

          let ListCodColegios = arrCodColegios.toString();
          let ListIdColegios  = arrIdColegios.toString();

          params = {
              "grupo_colegios": ListCodColegios,
              "ano_ini": parseInt(this.ActualAnoEscolar) - this.numAnosDescontados,
              "ano_fin": this.ActualAnoEscolar,
              "tipo": tipo,
              "mes_actual": this.ActualMesEscolar,
              "asociar_a": idSelect 
          }

          codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));          
          this.EvolucionMatriculaSndService.evolucionMatriculaSnd(params).subscribe(
            result => {
              this.dataEvoMatriculaSnd = true;
              if (localStorage.getItem('select_colegio') || localStorage.getItem('select_sost')) {
                // EVOLUCIÓN MATRÍCULA - POR SOSTENEDOR
                
                this.evaluacionPorSostenedor(result, codSostenedor);
              }else{
                // EVOLUCIÓN MATRÍCULA - COMPARATIVA PARA ZONAS/COMUNAS/SOSTENEDORES DE UN SLE 
                this.rsData = [];
                this.dataTable = [];
                this.evaluacionPorComuna(result)
              }
            },
            error => {
              setTimeout(() => { this.loading = false}, 700);
              this.api_error = true;
            }
           );
        })
        .catch((err) => {
          $.notify(
            {icon: 'notifications', message: err.message},
            {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
          );
        });
    }
    else{
      this.dataEvoMatriculaSnd = false;
      $.notify(
        {icon: 'notifications', message: "No hay datos para imprimir"}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
      });
      setTimeout(() => { this.loading = false}, 700);
    }
  }

  evaluacionPorSostenedor(result, codSostenedor) {
    
    if ( result.success ) {
      
      let jsonData = result.data;
      this.rsData = jsonData.datos;
      let AnoEscolar  = jsonData.xkeys;      
      if (jsonData.datos[0].data) {
        this.rsData = jsonData.datos;
        AnoEscolar  = jsonData.xkeys;
        let dataTable = [];

        Object.keys(AnoEscolar).forEach(key => {
          dataTable.push({
            anoEscolar: AnoEscolar[key],
            cantidad: this.formatNumber(this.rsData[0].data[key])
          });

          this.dataTableExcel.push({
            Año_escolar: AnoEscolar[key],
            Cantidad_matriculados: this.rsData[0].data[key]
          });
        });
        if(this.colegio){
          this.titulo_excel = 'Evolucion_matricula_colegio_'+JSON.parse(localStorage.getItem('select_colegio')).itemName
          this.rsData[0].name=this.rsData[0].data.name='Colegio';
        }
        else{
          this.rsData[0].name=this.rsData[0].data.name='Comuna';
        this.titulo_excel = 'Evolucion_matricula_total_comuna_'+codSostenedor;
        }
        this.drawGraficoEvoMatriculaSost(this.rsData, AnoEscolar, dataTable);
        this.loading = false;
      } else {
        this.sin_data_total_sos = true;
        this.dataEvoMatriculaSnd = false;
        $.notify(
          {icon: 'notifications', message: "No hay datos para imprimir"}, 
          {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
        });
        setTimeout(() => { this.loading = false}, 700);
      }
    } 
    else {
      setTimeout(() => { this.loading = false}, 700);
    }
  }

  evaluacionPorComuna(data){
    let AnoEscolar = [];
    Object.keys(data.data).forEach(key => {
      var AnoEscolarSelect = '0';
      if (!$.isEmptyObject(data.data[key])) {
        Object.keys(data.data[key]).forEach(anoEscolar => {
          AnoEscolar.push(anoEscolar);
          this.rsData.push(data.data[key][anoEscolar].cant_matriculados);
          this.dataTable.push({
            anoEscolar: anoEscolar,
            cantidad: data.data[key][anoEscolar].cant_matriculados
          })
          this.dataTableExcel.push({
            Año_escolar: anoEscolar,
            Cantidad_matriculados: data.data[key][anoEscolar].cant_matriculados
          });
        });
        this.drawGraficosEvoMatriculaComparativa(this.rsData, AnoEscolar);
      }
    });
    this.loading = false;
  }

  /*EVOLUCION MATRICULA DETALLE POR COLEGIO PARA UN SOSTENEDOR*/
  evo_matricula_col() {
    this.removeActiveTab();
    this.sin_data_total_col = false;
    this.evoMatriculaCol = true;
    this.evoMatricula = false;
    this.titulo_excel = '';
    this.dataTableExcel = [];
    this.dataTable = [];
    this.loading = true;

    let rsData = [];
    let recurso: string;
    let idSelect: string;
    let tipo: number;
    let codSostenedor: number;
    let jsonData: any;
    let list: any;
    let AnoEscolar = [];
    let tHead = [];
    let xHead = [];
    let gColegios = [];
    let x =[];
    let tablaExcel = [];

    $("#pill2").parent().addClass("active");
    $("#tab2").parent().addClass("active");
    $("#tab20").parent().addClass("active");

    if (localStorage.getItem('select_colegio')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_colegio'));
        idSelect = jsonData.id
    } else if (localStorage.getItem('select_sost')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_sost'));
        idSelect = jsonData.id
    } else if (localStorage.getItem('select_slep')) {
        list = JSON.parse(localStorage.getItem('ListSost'));
        jsonData = JSON.parse(localStorage.getItem('select_slep'));
        idSelect = jsonData.id
    }

    if (localStorage.getItem('select_sost')) {
      recurso = urlServer + '/rbd/sostenedor/' + idSelect;
      tipo = 4
    } 
    else if (localStorage.getItem('select_slep')) {
      recurso = urlServer + '/rbd/slep/' + idSelect;
      tipo = 3
    } 
    else {
      recurso = null
    }

    let arrCodColegios = [];
    let arrIdColegios = [];
    if(recurso){
        this.IAservice.getData(recurso).then((data) => {
          Object.keys(data.data).forEach(key => {
              arrIdColegios.push(key);
              arrCodColegios.push(data.data[key]);
          });

          let ListCodColegios = arrCodColegios.toString();
          let ListIdColegios  = arrIdColegios.toString();
          let params: any;

          params = {
              "grupo_colegios": ListCodColegios,
              "ano_ini": parseInt(this.ActualAnoEscolar) - this.numAnosDescontados,
              "ano_fin": this.ActualAnoEscolar,
              "tipo": tipo,
              "mes_actual": this.ActualMesEscolar,
              "asociar_a": idSelect 
          }

          this.EvolucionMatriculaSndService.evolucionMatriculaSnd(params).subscribe(
            result => {
              this.dataEvoMatriculaSnd = true;
              this.evolucionConsolidado(result);
            },
            error => {
              setTimeout(() => { this.loading = false}, 700);
              this.api_error = true;
            }
           );
        })
        .catch((err) => {
          $.notify(
            {icon: 'notifications', message: err.message},
            {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
          );
        });
    }
    else{
      this.dataEvoMatriculaSnd = false;
      this.api_error = false;
      $.notify(
        {icon: 'notifications', message: "No hay datos para imprimir"}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
      });
      setTimeout(() => { this.loading = false}, 700);
    }
  }

  evolucionConsolidado(result){
    if( result.success ) {
      let jsonData = result.data;
      this.rsData = jsonData.datos;
      let AnoEscolar = jsonData.xkeys;
      let gColegios  = jsonData.labels;
      let tHead = [];
      let tablaExcel = [];
      this.bodyTable = [];
      let nombre = "Agrupación";

     /* if (localStorage.getItem('select_sost')) {
        nombre = "Establecimiento";
      } else if (localStorage.getItem('select_slep')) {
        nombre = "Comuna";
      }*/
      
      if (gColegios && gColegios.length != 0) {
        tHead.push(nombre);
        Object.keys(AnoEscolar).forEach(key => {
          tHead.push(String(AnoEscolar[key]));
        });
        tHead.push('Total');
        this.rowHeadTable = tHead;
        for (var i = 0; i < gColegios.length; i++) {
          let row  = [];
          let rowExcel = [];
          let dinamicKey = '';

          row.push({'valor':gColegios[i],'img':'','class_img':'material-icons','class_celda':''});
          rowExcel[nombre]=gColegios[i];

          let total = 0;
          let cantAnoAnterior = 0;
          if(this.rsData[i].data.length<AnoEscolar.length){
            for (let d= this.rsData[i].data.length; d < AnoEscolar.length; d++) {
              this.rsData[i].data.push(0);
            }
          }
          Object.keys(this.rsData[i].data).forEach(key => {
            dinamicKey = " "+AnoEscolar[key].toString()+ " ";
            if (this.rsData[i].data[key] == 0) {
              row.push({'valor':'','img':'','class_img':'material-icons','class_celda':'alinear-celda'});
              rowExcel[dinamicKey]='';
            } 
            else {
                   if (parseInt(key) == 0) {
                       row.push({'valor':this.formatNumber(this.rsData[i].data[key]),'img':'remove','class_img':'material-icons indicador-same','class_celda':'alinear-celda'});
                   }
                   else {
                          if (cantAnoAnterior > parseInt(this.rsData[i].data[key])) {
                             row.push({'valor':this.formatNumber(this.rsData[i].data[key]),'img':'trending_down','class_img':'material-icons indicador-down','class_celda':'alinear-celda'});
                          } 
                          else {
                                 if (cantAnoAnterior < parseInt(this.rsData[i].data[key])) {
                                    row.push({'valor':this.formatNumber(this.rsData[i].data[key]),'img':'trending_up','class_img':'material-icons indicador-up','class_celda':'alinear-celda'});
                                 } 
                                 else {
                                        row.push({'valor':this.formatNumber(this.rsData[i].data[key]),'img':'remove','class_img':'material-icons indicador-same','class_celda':'alinear-celda'});
                                 }
                          }
                   }
                  rowExcel[dinamicKey] = this.rsData[i].data[key];
            }
            cantAnoAnterior = this.rsData[i].data[key]; 
            total = total + this.rsData[i].data[key]; 
          });
          row.push({'valor':this.formatNumber(total),'img':'','class_img':'material-icons','class_celda':'alinear-celda'});
          rowExcel['Total'] = total;
          tablaExcel[i] = rowExcel;
          this.bodyTable[i] = row;
        }
        this.dataTableExcel = tablaExcel;
      } else {
        this.sin_data_total_col = true;
        this.dataEvoMatriculaSnd = false;
        $.notify(
          {icon: 'notifications', message: "No hay datos para imprimir"}, 
          {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
        });
        setTimeout(() => { this.loading = false}, 700);
      }
      this.drawGraficoEvoMatricula(this.rsData,AnoEscolar);
      this.loading = false;
    } else {
      setTimeout(() => { this.loading = false}, 700);
    }
  }

  drawGraficosEvoMatriculaComparativa(rsData, AnoEscolar) {
    this.chart = new Chart({
      lang: {
        downloadPNG: "Descargar a PNG",
        downloadJPEG: "Descargar a JPEG",
        downloadSVG: "Descargar a SVG",
        downloadPDF: "Descargar a PDF",
        loading: "Cargando",
        printChart: "Imprimir",
        contextButtonTitle: 'Opciones gráfico'
      },
      chart: {
        type: 'line'
      },
      title: {
        text: 'Evolución matrícula'
      },
      yAxis: {
        title: {
          text: 'Nro. Matriculados'
        }
      },
      xAxis: {
        categories: AnoEscolar,
        title: {
          text: ''
        }
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true,
            formatter: function() {
              return Highcharts.numberFormat(this.y, 0, ',', '.');
            }
          },
          enableMouseTracking: true
        }
      },
      series: [{
        name: 'Año escolar',
        data: rsData
      }]
    });
  }

  drawGraficoEvoMatriculaSost(rsData, AnoEscolar, rstable) {
    this.chart = new Chart({
      lang: {
        downloadPNG: "Descargar a PNG",
        downloadJPEG: "Descargar a JPEG",
        downloadSVG: "Descargar a SVG",
        downloadPDF: "Descargar a PDF",
        loading: "Cargando",
        printChart: "Imprimir",
        contextButtonTitle: 'Opciones gráfico'
      },
      chart: {
        type: 'line'
      },
      title: {
        text: 'Evolución matrícula'
      },
      yAxis: {
        title: {
          text: 'Nro. Matriculados'
        }
      },
      xAxis: {
        categories: AnoEscolar,
        title: {
          text: ''
        }
      },
      tooltip: {
        formatter: function() {
          return "" + this.x + "<br/>" + this.series.name + " : <strong>" + Highcharts.numberFormat(this.y, 0, ",", ".") + "</strong>";
        }
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true,
            formatter: function() {
              return Highcharts.numberFormat(this.y, 0, ',', '.');
            }
          },
          enableMouseTracking: true
        }
      },
      series: rsData
    });
    this.dataTable = rstable;
  }

  drawGraficoEvoMatricula(rsData, AnoEscolar) {
    this.chart = new Chart({
      lang: {
        downloadPNG: "Descargar a PNG",
        downloadJPEG: "Descargar a JPEG",
        downloadSVG: "Descargar a SVG",
        downloadPDF: "Descargar a PDF",
        loading: "Cargando",
        printChart: "Imprimir",
        contextButtonTitle: 'Opciones gráfico'
      },
      chart: {
        type: 'line'
      },
      title: {
        text: 'Evolución matrícula'
      },
      yAxis: {
        title: {
          text: 'Nro. Matriculados'
        }
      },
      xAxis: {
        categories: AnoEscolar,
        title: {
          text: ''
        }
      },
      tooltip: {
        formatter: function() {
          return "" + this.x + "<br/>" + this.series.name + " : <strong>" + Highcharts.numberFormat(this.y, 0, ",", ".") + "</strong>";
        }
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true,
            formatter: function() {
              return Highcharts.numberFormat(this.y, 0, ',', '.');
            }
          },
          enableMouseTracking: true
        }
      },
      series: rsData
    });
  }

  //Exporta json a excel
  exportAsXLSX():void {
    this.excelService.exportAsExcelFile(this.dataTableExcel, this.titulo_excel);
  }

  formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }

  numbreRand(min: number, max: number, cant: number) {
    let array = [];
    if (cant == 1) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    } else {
      for (let index = 0; index < cant; index++) {
        array.push(Math.floor(Math.random() * (max - min + 1)) + min);
      }
      return array.sort();
    }
  }

  sl2_style() {
    $(".c-btn").css("box-shadow", "none");
    $(".c-btn").css("border-top", "none");
    $(".c-btn").css("border-left", "none");
    $(".c-btn").css("border-right", "none");
    $(".c-btn").css("color", "#989898");
    $(".c-btn").css("background-color", "#ededee");
    $(".sl2-material > div >  .dropdown-list").css("position", "absolute");
  }

  removeActiveTab() {
    $("#pill1").parent().removeClass("active");
    $("#tab1").parent().removeClass("active");
    $("#tab10").parent().removeClass("active");
    $("#pill2").parent().removeClass("active");
    $("#tab2").parent().removeClass("active");
    $("#tab20").parent().removeClass("active");
  }
}
