import { Subscription } from 'rxjs/Subscription';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { PuenteService } from '../../services/puente.service';
import { urlServer } from '../../services/url.service';
import { ListaAgnosService } from '../../services/shared/listaagnos.service';
import { GraficasService } from '../../services/shared/graficas.service';
import { DatatablesService } from '../../services/shared/datatables.service';
import { HttpPuenteService } from '../../services/shared/httppuente.service';
import { ExcelService } from '../../services/excel.service';
import { Chart } from 'angular-highcharts';
import * as Highcharts from "highcharts";
declare const $: any;

@Component({
  selector: 'app-alumnos-prioritarios',
  templateUrl: './alumnos-prioritarios.component.html',
  styleUrls: ['./alumnos-prioritarios.component.scss']
})
export class AlumnosPrioritariosComponent implements OnInit, OnDestroy {

  chart: Chart;
  Puente: Subscription;
  ActualAnoEscolar: any;
  mdPerfil: any;
  linkParams: any;
  encabezado:string;
  titulo_excel:string = 'Ejemplo';
  SlcAnoEscolar = [];
  ListAnoEscolar = [];
  dataTable = [];
  dataTableExcel = [];
  dataTableExcelPorcAlumnos = [];
  dataTableExcelCantAlumnos = [];
  dataTableExcelPorcAlumnosNoPrio = [];
  dataTableExcelCantAlumnosNoPrio = [];
  rsData = [];
  rowHeadTablePorcAlumnos = [];
  rowBodyTablePorcAlumnos = [];
  rowHeadTablePorcAlumnosNoPrio = [];
  rowBodyTablePorcAlumnosNoPrio = [];
  colBodyTable = [];
  rowHeadTableAlumnos = [];
  rowBodyTableAlumnos= [];
  rowFootTableAlumnos = [];
  rowHeadTableAlumnosNoPrio = [];
  rowBodyTableAlumnosNoPrio= [];
  rowFootTableAlumnosNoPrio = [];
  listNivelesPorcAlumnos = [];
  listNivelesCantAlumnos = [];
  listNivelesPorcAlumnosNoPrio = [];
  listNivelesCantAlumnosNoPrio = [];
  alumnosPrio=false;
  alumnosPrioCol=false;
  alumnosPrioNivel=false;
  public colegio:boolean = false;
  public loading:boolean = true;
  public api_error:boolean = false;

  constructor(private puente: PuenteService, private IGservice: HttpPuenteService,private excelService:ExcelService,private Agnos: ListaAgnosService, private DataTables: DatatablesService, private graphService: GraficasService) { 
    
    this.Puente = this.puente.getData().subscribe(data => {
      this.graphService.removeActiveTab();
      if (this.alumnosPrio) {
        this.alumnos_prio();
      }
      if (this.alumnosPrioCol) {
        this.alumnos_prio_colegio();
      }
      if (this.alumnosPrioNivel) {
        this.alumnos_prio_nivel();
      }
    });
  }

  ngOnDestroy(): void {
    this.Puente.unsubscribe();
  }

  ngOnInit() {
    this.graphService.tabs = ["pill1", "tab1", "pill2", "tab2", "pill3", "tab3"];
    this.ActualAnoEscolar =this.Agnos.ActualAnoEscolar;
    this.ListAnoEscolar=this.SlcAnoEscolar=this.Agnos.listAnosEscolares(this.ActualAnoEscolar);
    this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));
    if(this.mdPerfil.nivel==3 || this.mdPerfil.nivel==5){
      this.colegio=true;
      
    }
    else{
      this.colegio=false;
    }
    if (sessionStorage.getItem('link_param')) {
      this.alumnosPrio = false;
      this.linkParams = sessionStorage.getItem('link_param');
      sessionStorage.removeItem('link_param');
    }
    switch (this.linkParams) {
      case 'pill2':
        this.alumnosPrioCol = true;
        this.alumnos_prio_colegio();
        break;
      case 'pill3':
        this.alumnosPrioNivel=true;
        this.alumnos_prio_nivel();
        break;
      default:
        this.alumnosPrio = true;
        this.alumnos_prio();
    }
    this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));
    this.sl2_style();
  }

    alumnosPrioritariosItem(items: any) {
    this.graphService.removeActiveTab();
    this.ActualAnoEscolar = items.value;
    if (this.alumnosPrio) {
      this.alumnos_prio();
    } else if (this.alumnosPrioCol) {
      this.alumnos_prio_colegio();
    } else if (this.alumnosPrioNivel) {
      this.loading = true;
      this.alumnos_prio_nivel();
    } else {
      $.notify(
        {icon: 'notifications', message: 'Opción ingresada no es correcta'}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause',placement: {from: 'bottom',align: 'right'}}
      );
    }
  }

  alumnos_prio() {
    this.api_error = false;
    this.graphService.removeActiveTab();

    $("#pill1").parent().addClass("active");
    $("#tab1").parent().addClass("active");

    this.alumnosPrio = true;
    this.alumnosPrioCol = false;
    this.alumnosPrioNivel = false;


    this.rsData = [];
    this.loading = false;

    let recurso: string;
    let idSelect: string;
    let jsonData: any;
    let list: any;
    let params: any;

    if (localStorage.getItem('select_colegio')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_colegio'));
        idSelect = jsonData.id
        this.colegio=true;
    } 
    else if (localStorage.getItem('select_sost')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_sost'));
        idSelect = jsonData.id
        this.colegio= false;
    } 
    else if (localStorage.getItem('select_slep')) {
        list = JSON.parse(localStorage.getItem('ListSost'));
        jsonData = JSON.parse(localStorage.getItem('select_slep'));
        idSelect = jsonData.id
        this.colegio= false;
    }

    let alumnos_prioritarios_post = urlServer + '/alumnos_prioritarios';

    if (localStorage.getItem('select_colegio')) {
      recurso = urlServer + '/colegioselector/' + idSelect;
    } 
    else if (localStorage.getItem('select_sost')) {
      recurso = urlServer + '/rbd/sostenedor/' + idSelect;
    } 
    else if (localStorage.getItem('select_slep')) {
      recurso = urlServer + '/rbd/slep/' + idSelect;
    } 
    else {
      recurso = null
    }

    let arrCodColegios = [];
    let arrIdColegios = [];
    if(recurso){
      this.IGservice.getData(recurso).then((data) => {
          Object.keys(data.data).forEach(key => {
              arrIdColegios.push(key);
              arrCodColegios.push(data.data[key]);
          });

          let ListCodColegios = arrCodColegios.toString();
          let ListIdColegios  = arrIdColegios.toString();

          params = {
              "grupo_colegios": ListCodColegios,
              "ano_escolar": this.ActualAnoEscolar,
              "tipo": "1"
          }

          this.IGservice.getDataPost(alumnos_prioritarios_post,params)
          .then((data) => {
            this.graficoConsolidado(data);
          })
          .catch((err) => {
            $.notify(
              { icon: 'notifications', message: err.message }, 
              { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
            );
          });

      })
      .catch((err) => {
        $.notify(
          {icon: 'notifications', message: err.message}, 
          {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
        );
      });
    }
    else{
      $.notify(
        {icon: 'notifications', message: 'Revise selección en el menú por favor.'}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
      );
    }
  }

  alumnos_prio_colegio() {
    this.api_error = false;
    this.graphService.removeActiveTab();

    $("#pill2").parent().addClass("active");
    $("#tab2").parent().addClass("active");

    this.alumnosPrio = false;
    this.alumnosPrioCol = true;
    this.alumnosPrioNivel = false;  
    this.rsData = [];

    let recurso: string;
    let idSelect: string;
    let data:string;
    let jsonData: any;
    let list: any;
    let params: any;
    let rsTable = [];
    let AnoEscolar = [];

    if (!this.ActualAnoEscolar) {
      let currentTime = new Date()
      this.ActualAnoEscolar = currentTime.getFullYear();
    }

    let codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));

    if (this.SlcAnoEscolar.length==0) {
      Object.keys(this.ListAnoEscolar).forEach(key => {
        let xano = {};
        xano["ano_escolar"] = this.ListAnoEscolar[key].ano_escolar;
        this.SlcAnoEscolar.push(xano);
      });
    }

    this.SlcAnoEscolar = this.graphService.eliminarDuplicateInArray(this.SlcAnoEscolar);
    this.loading = true;

    if (localStorage.getItem('select_colegio')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_colegio'));
        idSelect = jsonData.id
    } else if (localStorage.getItem('select_sost')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_sost'));
        idSelect = jsonData.id
    } else if (localStorage.getItem('select_slep')) {
        list = JSON.parse(localStorage.getItem('ListSost'));
        jsonData = JSON.parse(localStorage.getItem('select_slep'));
        idSelect = jsonData.id
    }
    let tipo = "";
    let alumnos_prioritarios_post = urlServer + '/alumnos_prioritarios';

    if (localStorage.getItem('select_sost')) {
      recurso = urlServer + '/rbd/sostenedor/' + idSelect;
      tipo = "3";
    } else if (localStorage.getItem('select_slep')) {
      recurso = urlServer + '/rbd/slep/' + idSelect;
      tipo = "2";
    } else {
      recurso = null
    }

    let arrCodColegios = [];
    let arrIdColegios = [];
    if(recurso){
      this.IGservice.getData(recurso).then((data) => {
          Object.keys(data.data).forEach(key => {
              arrIdColegios.push(key);
              arrCodColegios.push(data.data[key]);
          });

          let ListCodColegios = arrCodColegios.toString();
          let ListIdColegios  = arrIdColegios.toString();

          params = {
              "grupo_colegios": ListCodColegios,
              "ano_escolar": this.ActualAnoEscolar,
              "tipo": tipo
          }

          this.IGservice.getDataPost(alumnos_prioritarios_post,params)
          .then((result) => { 
            console.log(result);
            if( result.success && result.data.xkeys) {
              let jsonData = result.data;
              this.rsData = jsonData.datos;
              this.graphBarra(this.rsData, this.ActualAnoEscolar, jsonData.colors, jsonData.xkeys);
              this.tablaAlumnosPrioritariosCol(jsonData.labels);
              this.loading = false;
            } else {
              $.notify(
                {icon: 'notifications', message: "No hay datos para imprimir"}, 
                {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
              });
              setTimeout(() => { this.loading = false}, 700);
            }
          })
          .catch((err) => {
            $.notify(
              { icon: 'notifications', message: err.message }, 
              { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
            );
          });
      })
      .catch((err) => {
        $.notify(
          {icon: 'notifications', message: err.message}, 
          {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
        );
      });
    }
    else{
      $.notify(
        {icon: 'notifications', message: 'Revise selección en el menú por favor.'}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
      );
    }
  }

  alumnos_prio_nivel() {
    this.api_error = false;
    this.loading = true;
    this.graphService.removeActiveTab();
    $("#pill3").parent().addClass("active");
    $("#tab3").parent().addClass("active");
    this.alumnosPrio = false;
    this.alumnosPrioCol = false;
    this.alumnosPrioNivel = true;   
    let recurso: string;
    let idSelect: string;
    let data:string;
    let jsonData: any;
    let list: any;
    let params: any;
    let rsTable = [];
    let AnoEscolar = [];
    let niveles = [];
    let rsData2:string;
    
    if (!this.ActualAnoEscolar) {
      this.ActualAnoEscolar =this.Agnos.ActualAnoEscolar;
      this.ListAnoEscolar=this.SlcAnoEscolar=this.Agnos.listAnosEscolares(this.ActualAnoEscolar);
      //this.listAnosEscolaresSostenedor();
      let currentTime = new Date()
     // this.ActualAnoEscolar = currentTime.getFullYear();
    }
    
    let codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));
    if (this.SlcAnoEscolar.length==0) {
      Object.keys(this.ListAnoEscolar).forEach(key => {
        let xano = {};
        xano["ano_escolar"] = this.ListAnoEscolar[key].ano_escolar;
        this.SlcAnoEscolar.push(xano);
      });
    }
    this.SlcAnoEscolar = this.graphService.eliminarDuplicateInArray(this.SlcAnoEscolar);

    if (localStorage.getItem('select_colegio')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_colegio'));
        idSelect = jsonData.id
        this.colegio=true;
    } 
    else if (localStorage.getItem('select_sost')) {
        list = JSON.parse(localStorage.getItem('ListCole'));
        jsonData = JSON.parse(localStorage.getItem('select_sost'));
        idSelect = jsonData.id
        this.colegio= false;
    } 
    else if (localStorage.getItem('select_slep')) {
        list = JSON.parse(localStorage.getItem('ListSost'));
        jsonData = JSON.parse(localStorage.getItem('select_slep'));
        idSelect = jsonData.id
        this.colegio= false;
    }

    let alumnos_prioritarios_post = urlServer + '/alumnos_prioritarios';
    let tipo = "";
    if (localStorage.getItem('select_colegio')) {
      recurso = urlServer + '/colegioselector/' + idSelect;
      tipo = "5";
    } 
    else if (localStorage.getItem('select_sost')) {
      recurso = urlServer + '/rbd/sostenedor/' + idSelect;
      tipo = "5";
    } 
    else if (localStorage.getItem('select_slep')) {
      recurso = urlServer + '/rbd/slep/' + idSelect;
      tipo = "4";
    } 
    else {
      recurso = null
    }

    let arrCodColegios = [];
    let arrIdColegios = [];
    if(recurso){
      
      this.IGservice.getData(recurso).then((data) => {
          Object.keys(data.data).forEach(key => {
              arrIdColegios.push(key);
              arrCodColegios.push(data.data[key]);
          });

          let ListCodColegios = arrCodColegios.toString();
          let ListIdColegios  = arrIdColegios.toString();

          params = {
              "grupo_colegios": ListCodColegios,
              "ano_escolar": this.ActualAnoEscolar,
              "tipo": tipo
          }

          this.IGservice.getDataPost(alumnos_prioritarios_post,params)
          .then((result) => {
            if( result.success && result.data.xkeys) {
              jsonData = result.data;
              rsData2 = jsonData.datos;
              niveles = jsonData.labels.niveles;
              rsTable = jsonData.labels;
              if(localStorage.getItem('ListProfilesPers'))
             { 
               let nivelesvalores=null;              
              nivelesvalores=this.Agnos.nivelespersonalizado(rsData2,jsonData.xkeys);
              this.graphBarra(nivelesvalores[1], this.ActualAnoEscolar, jsonData.colors, nivelesvalores[0]);
              
              this.tablaAlumnosPrioritariosNiveles(rsTable, nivelesvalores[0]);
              this.loading = false;
             }
              else{              
              this.graphBarra(rsData2, this.ActualAnoEscolar, jsonData.colors, jsonData.xkeys);
              this.tablaAlumnosPrioritariosNiveles(rsTable, niveles);
              this.loading = false;
              }
            } 
            else {
              $.notify(
                {icon: 'notifications', message: "No hay datos para imprimir"}, 
                {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
              });
              setTimeout(() => { this.loading = false}, 700);
            }
          })
          .catch((err) => {
            $.notify(
              { icon: 'notifications', message: err.message }, 
              { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
            );
          });

      })
      .catch((err) => {
        $.notify(
          {icon: 'notifications', message: err.message}, 
          {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
        );
      });
    }
    else{
      $.notify(
        {icon: 'notifications', message: 'Revise selección en el menú por favor.'}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
      );
    }
  }

  graficoConsolidado(result) {
    if( result.success ) {
      let jsonData = result.data;
      var obj = {};
      obj["matricula"] = jsonData.datos[1][1];
      obj["disponible"] = jsonData.datos[0][1];
      this.rsData[this.ActualAnoEscolar] = obj
      this.graphPie(this.rsData, this.ActualAnoEscolar);
      this.loading = false;
    } else {
      $.notify(
        {icon: 'notifications', message: "No hay datos para imprimir"}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
      });
      setTimeout(() => { this.loading = false}, 700);
    }
  }

  graphPie(rsData, anoEscolar) {
    this.loading = true;
    if (rsData.length>0) {
      this.chart = new Chart({
        lang: {
          downloadPNG: "Descargar a PNG",
          downloadJPEG: "Descargar a JPEG",
          downloadSVG: "Descargar a SVG",
          downloadPDF: "Descargar a PDF",
          loading: "Cargando",
          printChart: "Imprimir",
          contextButtonTitle: 'Opciones gráfico'
        },
        chart: {
          type: 'pie'
        },
        title: {
          text: 'Alumnos prioritarios ' + anoEscolar
        },
        credits: {
          enabled: false
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.y}</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.y}'
            }
          }
        },
        series: [{
          name: 'Alumnos prioritarios',
          data: [{
            name: 'Prioritarios',
            y: rsData[anoEscolar]["disponible"],
            sliced: true,
            selected: true,
            color: '#1EA559'

          }, {
            name: 'No Prioritarios',
            y: rsData[anoEscolar]["matricula"],
            color: '#FB3948'
          }]
        }]
      });

      this.dataTable = [];
      this.dataTableExcel =[];
      let codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));

      for (var k in rsData) {
        if (parseInt(k)>0) {
          this.dataTable.push({
            anoEscolar: k,
            disponible: rsData[k]["disponible"] + '%',
            matricula: rsData[k]["matricula"] + '%',
          });

          this.titulo_excel = `Alumnos_prioritarios_periodo_${this.ActualAnoEscolar}`;
          this.dataTableExcel.push({
            Año_escolar: k,
            Disponible: rsData[k]["disponible"] + '%',
            Matriculados: rsData[k]["matricula"] + '%',
          });
        }
      };
      this.loading = false;
    } else {
      $.notify({
        icon: 'notifications',
        message: "No hay datos para imprimir"
      },
      {
        type: 'warning',
        timer: 2000,
        delay: 2000,
        mouse_over: 'pause',
        placement: {
          from: 'bottom',
          align: 'right'
        }
      });
      this.loading = false;
    }
  }

  graphBarra(rsData, anoEscolar, colors, categoria, tipo = 'consolidado') {
    this.loading = true;
    let linesEscalonadas = 0;
    var rotacionDataLabels = -90;
    if (tipo == 'comparativo') {
      rotacionDataLabels = null;
    }

    this.chart = new Chart({
      lang: {
        downloadPNG: "Descargar a PNG",
        downloadJPEG: "Descargar a JPEG",
        downloadSVG: "Descargar a SVG",
        downloadPDF: "Descargar a PDF",
        loading: "Cargando",
        printChart: "Imprimir",
        contextButtonTitle: 'Opciones gráfico'
      },
      chart: {
        type: 'column'
      },
      title: {
        text: `Alumnos prioritarios ${anoEscolar}`
      },
      xAxis: {
        categories: categoria,
        min: null,
        max: null,
        title: {
          text: null
        },
        labels: {
          staggerLines:linesEscalonadas
        }
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: "Porcentaje de alumnos prioritarios"
        },
        labels: {
          overflow: "justify",
          format: '{value}'
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        valueSuffix: '%',
        formatter: function() {
          return "" + this.x + "<br/>" + this.series.name + " : <strong>" + Highcharts.numberFormat(this.y, 0, ",", ".")+"%</strong>";
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            shadow: true
          }
        },
        series: {
          stacking: 'normal',
          dataLabels: {
            rotation: null,//rotacionDataLabels,
            formatter: function() {
              return this.y + "%";
              /*if (tipo == 'consolidado') {
                return this.series.name + ": <strong>" + this.y + "%</strong> ";
              } else {
                return "% uso: <strong>" + this.y + "%</strong> ";
              }*/
            },
            enabled: true,
            verticalAlign: 'middle',
            align: 'center',
            inside: true,
          }
        }
      },
      series: rsData,
      colors: colors
    });
    this.loading = false;
  }

  tablaAlumnosPrioritariosCol(rsTable) {
    if (rsTable['0']) {
      let codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));
      this.titulo_excel = `Alumnos Prioritarios`;
      this.dataTable = [];
      this.dataTableExcel=[];

      Object.keys(rsTable).forEach(key => {
        if (key != 'colegios') {
          let total_prioritarios:number;
          let total_noprioritarios:number;
          let cod_colegio:number;
          let nom_colegio:string;
          let porc_prioritarios:number;
          let porc_noprioritarios:number;
          let cant_matricula_disp:number;
          let cant_matricula_disp_sin_formato:number;

          total_prioritarios = parseInt(rsTable[key].total_prioritarios);
          total_noprioritarios = parseInt(rsTable[key].total_no_prioritarios);
          cod_colegio = parseInt(rsTable[key].cod_colegio);
          nom_colegio  = rsTable[key].nom_colegio?rsTable[key].nom_colegio:rsTable[key].comuna;
          porc_prioritarios = parseInt(rsTable[key].porc_prioritarios);
          porc_noprioritarios = parseInt(rsTable[key].porc_no_prioritarios);
          cant_matricula_disp = this.DataTables.formatNumber(total_noprioritarios - total_prioritarios);
          cant_matricula_disp_sin_formato = total_noprioritarios - total_prioritarios;

          this.dataTable.push({
            colegio: nom_colegio,
            total_prioritarios: this.DataTables.formatNumber(total_prioritarios),
            porc_prioritarios: porc_prioritarios + '%',
            total_noprioritarios: this.DataTables.formatNumber(total_noprioritarios),
            porc_noprioritarios: porc_noprioritarios + '%',
          });

          this.dataTableExcel.push({
            Agrupacion: nom_colegio,
            Total_prioritarios: total_prioritarios,
            Porc_prioritarios: porc_prioritarios + '%',
            Total_no_prioritarios: total_noprioritarios,
            Porc_no_prioritarios: porc_noprioritarios + '%',
          });
        }
      });
    
    } else {
      $.notify(
        {icon: 'notifications', message: "No hay datos para imprimir"}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
      });
    }
  }

  tablaAlumnosPrioritariosNiveles(rsTable, niveles) {
    this.loading = true;
    this.rowHeadTablePorcAlumnos = [];
    this.rowBodyTablePorcAlumnos = [];
    this.rowHeadTablePorcAlumnosNoPrio = [];
    this.rowBodyTablePorcAlumnosNoPrio = [];
    this.colBodyTable = [];   //General 
    this.rowHeadTableAlumnos = [];
    this.rowBodyTableAlumnos = [];
    this.rowHeadTableAlumnosNoPrio = [];
    this.rowBodyTableAlumnosNoPrio = [];

    this.listNivelesPorcAlumnos = [];
    this.listNivelesPorcAlumnosNoPrio = [];
    this.listNivelesCantAlumnos = [];
    this.listNivelesCantAlumnosNoPrio = [];
    this.rowFootTableAlumnos= [];
    this.dataTableExcelPorcAlumnos = [];
    this.dataTableExcelPorcAlumnosNoPrio = [];
    this.dataTableExcelCantAlumnos = [];
    this.dataTableExcelCantAlumnosNoPrio= [];

    let tHeadPorcAlumnosPrio = [];
    let tHeadPorcAlumnosNoPrio = [];
    let tHeadAlumnos  = [];
    let tFootAlumnos  = [];
    let tHeadAlumnosNoPrio  = [];
    let tFootAlumnosNoPrio  = [];

    let totalNivelesAlumnos = [];
    let totalNivelesAlumnosNoPrio = [];
    let arrNivelCol = []; //General 

    if (niveles) {
      
     // rsTable[0]['nom_colegio']?tHeadPorcAlumnosPrio.push('Establecimientosssss'):tHeadPorcAlumnosPrio.push('Comunasssss');
      tHeadPorcAlumnosPrio.push('Agrupación');
      tHeadPorcAlumnosNoPrio.push('Agrupación');
      tHeadAlumnos.push('Agrupación');
      tFootAlumnos.push('Total');
      tHeadAlumnosNoPrio.push('Agrupación');
      tFootAlumnosNoPrio.push('Total');

      Object.keys(niveles).forEach(key => {
        
        tHeadPorcAlumnosPrio.push(niveles[key]);
        tHeadPorcAlumnosNoPrio.push(niveles[key]);
        tHeadAlumnos.push(niveles[key]);
        tHeadAlumnosNoPrio.push(niveles[key]);
        totalNivelesAlumnos[niveles[key]] = totalNivelesAlumnos[niveles[key]] = 0;
        totalNivelesAlumnosNoPrio[niveles[key]] = totalNivelesAlumnosNoPrio[niveles[key]] = 0;
        this.listNivelesPorcAlumnos.push(niveles[key]);
        this.listNivelesPorcAlumnosNoPrio.push(niveles[key]);
        this.listNivelesCantAlumnos.push(niveles[key]);
        this.listNivelesCantAlumnosNoPrio.push(niveles[key]);
        arrNivelCol[niveles[key]] = '';
      });
      this.listNivelesCantAlumnos.push('Total');
      this.listNivelesCantAlumnosNoPrio.push('Total');
      tHeadAlumnos.push('Total');
      tHeadAlumnosNoPrio.push('Total');
      totalNivelesAlumnos['Total'] = totalNivelesAlumnos['Total']=0; 
      totalNivelesAlumnosNoPrio['Total'] = totalNivelesAlumnosNoPrio['Total']=0; 
      this.rowHeadTablePorcAlumnos = tHeadPorcAlumnosPrio;
      this.rowHeadTablePorcAlumnosNoPrio = tHeadPorcAlumnosNoPrio;
      this.rowHeadTableAlumnos  = tHeadAlumnos;
      this.rowHeadTableAlumnosNoPrio  = tHeadAlumnosNoPrio;
      let arrColegio = [];
      let arrColegioNoPrio = [];
      let arrColegioPorcPrio = [];
      let arrColegioPorcNoPrio = [];

      Object.keys(rsTable).forEach(key => {
        if (key != 'niveles') {
          let porcAlumnosCol = [];
          let porcAlumnosColNoPrio = [];
          let AlumnosCol = [];
          let AlumnosColNoPrio = [];
          let totalPrioColegio = 0;
          let totalNoPrioColegio = 0;
          porcAlumnosCol = arrNivelCol.slice();
          porcAlumnosColNoPrio = arrNivelCol.slice();
          AlumnosCol = arrNivelCol.slice();
          AlumnosColNoPrio = arrNivelCol.slice();
          let colegio = rsTable[key]['nom_colegio']?rsTable[key]['nom_colegio']:rsTable[key]['comuna'];
         // this.encabezado=rsTable[key]['nom_colegio']?'Establecimiento':'Comuna';
          arrColegio.push({rbd:parseInt(key),colegio:colegio});
         /* arrColegioNoPrio.push({rbd:parseInt(key),colegio:colegio});
          arrColegioPorcPrio.push({rbd:parseInt(key),colegio:colegio});
          arrColegioPorcNoPrio.push({rbd:parseInt(key),colegio:colegio});*/
          Object.keys(rsTable[key]['grados']).forEach(nivel => {
            if(this.DataTables.buscarNivel(nivel,niveles)){
            let cant_alu_prio:number;
            let cant_alu_no_prio:number;
            let porcentajeprio:number;
            let porcentajenoprio:number;
            cant_alu_prio     = parseInt(rsTable[key]['grados'][nivel]['total_prioritarios']);
            cant_alu_no_prio = parseInt(rsTable[key]['grados'][nivel]['total_no_prioritarios']);
            porcentajeprio   = parseInt(rsTable[key]['grados'][nivel]['porc_prioritarios']);
            porcentajenoprio   = parseInt(rsTable[key]['grados'][nivel]['porc_no_prioritarios']);
            totalPrioColegio = totalPrioColegio + cant_alu_prio;
            totalNoPrioColegio = totalNoPrioColegio + cant_alu_no_prio;
            porcAlumnosCol[nivel] = porcentajeprio+'%';
            porcAlumnosColNoPrio[nivel] = porcentajenoprio+'%';
            AlumnosCol[nivel] = this.DataTables.formatNumber(cant_alu_prio);
            AlumnosColNoPrio[nivel] = this.DataTables.formatNumber(cant_alu_no_prio);

            totalNivelesAlumnos[nivel] = cant_alu_prio + totalNivelesAlumnos[nivel];
            totalNivelesAlumnosNoPrio[nivel] = cant_alu_no_prio + totalNivelesAlumnosNoPrio[nivel];
            }
          });

          AlumnosCol['Total'] = this.DataTables.formatNumber(totalPrioColegio);
          AlumnosColNoPrio['Total'] = this.DataTables.formatNumber(totalNoPrioColegio);
          totalNivelesAlumnos['Total'] = totalPrioColegio + totalNivelesAlumnos['Total'];
          totalNivelesAlumnosNoPrio['Total'] = totalNoPrioColegio + totalNivelesAlumnosNoPrio['Total'];
          this.rowBodyTablePorcAlumnos[parseInt(key)] = porcAlumnosCol;
          this.rowBodyTablePorcAlumnosNoPrio[parseInt(key)] = porcAlumnosColNoPrio;
          this.rowBodyTableAlumnos[parseInt(key)]  = AlumnosCol;
          this.rowBodyTableAlumnosNoPrio[parseInt(key)]  = AlumnosColNoPrio;
        }

      });
      this.colBodyTable =  arrColegio;
      this.rowFootTableAlumnos  = totalNivelesAlumnos;
      this.rowFootTableAlumnosNoPrio  = totalNivelesAlumnosNoPrio;

      Object.keys(this.colBodyTable).forEach(key=>{
        let rbd  = this.colBodyTable[key].rbd;
        let rowExcelCantAlumnos = [];
        let rowExcelCantAlumnosNoPrio = [];
        let rowExcelPorcPrio = [];
        let rowExcelPorcNoPrio = [];
        rowExcelPorcNoPrio['Agrupacion'] =rowExcelPorcPrio['Agrupacion'] = rowExcelCantAlumnos['Agrupacion'] =rowExcelCantAlumnosNoPrio['Agrupacion'] = this.colBodyTable[key].colegio;
        Object.keys(this.listNivelesPorcAlumnos).forEach(idx=>{
          let rowHead = this.listNivelesPorcAlumnos[idx];
          let porcentajeprio = this.rowBodyTablePorcAlumnos[rbd][rowHead];
          let index = " "+rowHead;
          rowExcelPorcPrio[index] = porcentajeprio;
        });
        this.dataTableExcelPorcAlumnos[key] = rowExcelPorcPrio;

        Object.keys(this.listNivelesPorcAlumnosNoPrio).forEach(idx=>{
          let rowHead = this.listNivelesPorcAlumnosNoPrio[idx];
          let porcentajenoprio = this.rowBodyTablePorcAlumnosNoPrio[rbd][rowHead];
          let index = " "+rowHead;
          rowExcelPorcNoPrio[index] = porcentajenoprio;
        });
        this.dataTableExcelPorcAlumnosNoPrio[key] = rowExcelPorcNoPrio;

        //rowExcelCantAlumnos['Establecimiento'] =  this.colBodyTable[key].colegio;
        Object.keys(this.listNivelesCantAlumnos).forEach(idx=>{
          let rowHead  = this.listNivelesCantAlumnos[idx];
          let cantidad = this.rowBodyTableAlumnos[rbd][rowHead];
          let index = " "+rowHead;
          if (cantidad) {
            rowExcelCantAlumnos[index] = parseInt(cantidad.replace('.',''));
          } else {
            rowExcelCantAlumnos[index] = '';
          }
        });
        this.dataTableExcelCantAlumnos[key] = rowExcelCantAlumnos;
      
      let rowalumnosprio = [];
      rowalumnosprio['Agrupacion'] = 'Total';
      Object.keys(this.rowFootTableAlumnos).forEach(idx=>{
        let total = parseInt(this.rowFootTableAlumnos[idx]);
        let index = " "+idx;
        rowalumnosprio[index] = total;
      });
      this.dataTableExcelCantAlumnos.push(rowalumnosprio);

     // rowExcelCantAlumnosNoPrio['Establecimiento'] =  this.colBodyTable[key].colegio; 
      Object.keys(this.listNivelesCantAlumnosNoPrio).forEach(idx=>{
        let rowHead  = this.listNivelesCantAlumnosNoPrio[idx];
        let cantidad = this.rowBodyTableAlumnosNoPrio[rbd][rowHead];
        let index = " "+rowHead;
        if (cantidad) {
          rowExcelCantAlumnosNoPrio[index] = parseInt(cantidad.replace('.',''));
        } else {
          rowExcelCantAlumnosNoPrio[index] = '';
        }
      });
      this.dataTableExcelCantAlumnosNoPrio[key] = rowExcelCantAlumnosNoPrio;
    });

    let rowalumnosnoprio = [];
    rowalumnosnoprio['Agrupacion'] = 'Total';
    Object.keys(this.rowFootTableAlumnosNoPrio).forEach(idx=>{
      let total = parseInt(this.rowFootTableAlumnosNoPrio[idx]);
      let index = " "+idx;
      rowalumnosnoprio[index] = total;
    });
    this.dataTableExcelCantAlumnosNoPrio.push(rowalumnosnoprio);
    } else {
      $.notify(
        {icon: 'notifications', message: "No hay datos para imprimir"}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
      });
    }
    this.loading = false;
  }

 
  //Exporta json a excel
  exportAsXLSX(opcion):void {
    let titulo = '';
    switch (opcion) {
      case 1:
        this.excelService.exportAsExcelFile(this.dataTableExcel, this.titulo_excel);
        break;
      case 2:
        titulo = `Alumnos_prioritarios_nivel_periodo_${ this.ActualAnoEscolar }`;
        this.excelService.exportAsExcelFile(this.dataTableExcelCantAlumnos, titulo);
        break;
      case 3:
        titulo = `Alumnos_prioritarios_nivel_porcentaje_periodo_${ this.ActualAnoEscolar }`;
        this.excelService.exportAsExcelFile(this.dataTableExcelPorcAlumnos, titulo);
        break;
        case 4:
        titulo = `Alumnos_noprioritarios_nivel_periodo_${ this.ActualAnoEscolar }`;
        this.excelService.exportAsExcelFile(this.dataTableExcelCantAlumnosNoPrio, titulo);
        break;
        case 5:
        titulo = `Alumnos_noprioritarios_nivel_porcentaje_periodo_${ this.ActualAnoEscolar }`;
        this.excelService.exportAsExcelFile(this.dataTableExcelPorcAlumnosNoPrio, this.titulo_excel);
        break;
      default:
        $.notify(
          {icon: 'notifications', message: 'Si usted llego aquí es que ha modificado las opciones permitidas ..'}, 
          {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
        );
    }
  }

  sl2_style() {
    $(".c-btn").css("box-shadow", "none");
    $(".c-btn").css("border-top", "none");
    $(".c-btn").css("border-left", "none");
    $(".c-btn").css("border-right", "none");
    $(".c-btn").css("color", "#989898");
    $(".c-btn").css("background-color", "#ededee");
    $(".sl2-material > div >  .dropdown-list").css("position", "absolute");
  }

}
