

import { Component, OnInit, OnDestroy } from '@angular/core';
import * as Chartist from 'chartist';
import { urlServer } from '../../services/url.service';
import { Chart } from 'angular-highcharts';
import { PuenteService } from '../../services/puente.service';import { Subscription } from 'rxjs/Subscription';
import { IndicadoresAcademicosNotasSndService } from '../../services/api-snd/indicadores-academicos-notas.services';
import { ListadoAnosEscolaresService } from '../../services/api-snd/get-anos-escolares.service';
//AS
import { ListaAgnosService } from '../../services/shared/listaagnos.service';
import { HttpPuenteService } from '../../services/shared/httppuente.service';
import { ExcelService } from '../../services/excel.service';
// import { RangoNotasService } from './../../services/rango-notas.service';
//import { GetGradosAsignaturasService } from '../../services/api-snd/get-grados-asignaturas.service';
//AS
import * as Highcharts from "highcharts";
import * as jsPDF from "jspdf";
import * as html2canvas from "html2canvas";


declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-rango-notas',
  templateUrl: './rango-notas.component.html',
  styleUrls: ['./rango-notas.component.scss'],
  providers:[IndicadoresAcademicosNotasSndService, ListadoAnosEscolaresService]
 //AS providers:[IndicadoresAcademicosNotasSndService, ListadoAnosEscolaresService, GetGradosAsignaturasService]
})

export class RangoNotasComponent implements OnInit,OnDestroy {

  dataTable = [];
  dataTableHead = [];
  dataTableExcel = [];
  chart: Chart;

  color = 'red';
  mode = 'indeterminate';
  value = 50;
  mdPerfil:any;
  spinner = false;

  itemList = [];
  selectedItems1 = [];
  settings1 = {};
  

  ListAnoEscolar = [];
  ListComunasSost = [];
  
  itemAgrupadoPor:string;
  titulo_excel='Rango_notas';
  arrGrados = [];
  arrAsignaturas = [];

  getArrayGrados = [];
  getArrayGradosAsignaturas = [];
  
  ActualAnoEscolar: any;
  
  ActualGrado: any;
  ActualAsign: any;

  ListCodColegios:string;
  ListIdColegios:string;

  SlcAnoEsoclar = [];
  SlcGrados = [];
  rsData = [];
  headerTbl = [];
  existeData = false;

  Puente: Subscription;

  public loading:boolean = true;
  public api_error:boolean = false;

    // AS constructor(private puente: PuenteService, private IAservice: RangoNotasService, private IndicadoresAcademicosNotasSndService:IndicadoresAcademicosNotasSndService, private ListadoAnosEscolaresService: ListadoAnosEscolaresService, private GetGradosAsignaturasService: GetGradosAsignaturasService) {
    constructor(private puente: PuenteService, private IAservice: HttpPuenteService,private excelService:ExcelService, private IndicadoresAcademicosNotasSndService:IndicadoresAcademicosNotasSndService,private Agnos: ListaAgnosService, private ListadoAnosEscolaresService: ListadoAnosEscolaresService) {
    this.Puente = this.puente.getData().subscribe(data => {
     
      this.getListadoColegios();
    });
  }

  ngOnInit() {
    this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));
    this.getListadoColegios();

    this.sl2_style();
  }

  ngOnDestroy() {
    this.Puente.unsubscribe();
  }
  
  Gpdf() {
    if (this.rsData.length != 0) {
      this.spinner = true;
      var divWidth = $(".highcharts-container").width() - 20;
      var originalBodyWidth = document.body.offsetWidth;
      var originalBodyHeight = document.body.offsetHeight;
      document.body.style.width = "1920px";
      document.body.style.height = "1080px";

      $('.graphClass').highcharts().setSize("1100", false);
      $('.table').css("width", "1100px");
      setTimeout(() => {
        var Doc = new jsPDF('l', 'pt', "A4");
        html2canvas(document.querySelector(".page1"), {
          onclone: function (document) {
            document.querySelector(".page1").classList.remove("col-md-8");
          }
        }).then(canvas => {
          var imgData = canvas.toDataURL('image/png');
          var width = Doc.internal.pageSize.width;
          var height = Doc.internal.pageSize.height;
          Doc.addImage(imgData, 'PNG', 0, 0);
          Doc.addPage();

          html2canvas(document.querySelector(".page2"), {
            onclone: function (document) {
              document.querySelector(".page2").style.width = "100%";
            }
          }).then(canvas => {
            var imgData = canvas.toDataURL('image/png');
            var width = Doc.internal.pageSize.width;
            var height = Doc.internal.pageSize.height;
            Doc.addImage(imgData, 'PNG', 0, 0);
            Doc.save('Indicadores académicos.pdf')
            document.body.removeAttribute("style");
            this.spinner = false;

            $('.graphClass').highcharts().setSize(divWidth, false);
            $('.table').css("width", "100%");
          });
        });

      }, 1000);
    } else {
      $.notify({
        icon: 'notifications',
        message: "No hay datos para imprimir"
      }, {
          type: 'warning',
          timer: 2000,
          delay: 2000,
          mouse_over: 'pause',
          placement: {
            from: 'bottom',
            align: 'right'
          }
        });
    }

  }

  sl2_style() {

    $(".c-btn").css("box-shadow", "none");
    $(".c-btn").css("border-top", "none");
    $(".c-btn").css("border-left", "none");
    $(".c-btn").css("border-right", "none");
    $(".c-btn").css("color", "#989898");
    $(".c-btn").css("background-color", "#ededee");
    $(".sl2-material > div >  .dropdown-list").css("position", "absolute");
  }

  onItemSelect(item: any) {
  }
  OnItemDeSelect(item: any) {
  }
  onSelectAll(items: any) {
  }
  onDeSelectAll(items: any) {
  }


  getListadoColegios() {
    
    let recurso: string;
    let recurso2: string;
    let idSelect: string;
    let jsonData: any;
    let data: string;
    
    let opcionSelected = 'sle';

    if (localStorage.getItem('select_colegio')) {
      jsonData = JSON.parse(localStorage.getItem('select_colegio'));
      idSelect = jsonData.id
      recurso=recurso2 = urlServer + '/colegioselector/' + idSelect;
      opcionSelected = 'colegio';
    }     
    else if (localStorage.getItem('select_sost')) {
      jsonData = JSON.parse(localStorage.getItem('select_sost'));
      idSelect = jsonData.id
      recurso = urlServer + '/rbd/sostenedor/' + idSelect;
      recurso2 = urlServer + '/sostenedor/' + idSelect;
      opcionSelected = 'comuna';
    } 
    else if (localStorage.getItem('select_slep')) { 
      jsonData = JSON.parse(localStorage.getItem('select_slep'));
      idSelect = jsonData.id
      recurso = urlServer + '/rbd/slep/' + idSelect;
      recurso2 = urlServer + '/slep/'+idSelect+'/sostenedores';
      opcionSelected = 'sle';
    } 
    else {
      recurso = recurso2 = null
      opcionSelected = '';
    }
    
    if(recurso){
    this.ListComunasSost = [];
    this.IAservice.getData(recurso2).then((data) => {
      if (opcionSelected == 'comuna') {
        this.ListComunasSost[data['cod_sostenedor']] = data['nom_sostenedor'];
      } 
      else if(opcionSelected == 'sle') {
        Object.keys(data.sostenedores).forEach(key => {
          this.ListComunasSost[data.sostenedores[key]['cod_sostenedor']] = data.sostenedores[key]['nom_sostenedor'];
        });
      } 
      else {
        this.ListComunasSost = [];
      }
    })
    .catch((err) => {
      $.notify(
        {icon: 'notifications', message: err.message}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
      );
    });

    let arrCodColegios = [];
    let arrIdColegios = [];
    this.IAservice.getData(recurso).then((data) => {
      Object.keys(data.data).forEach(key => {
        arrIdColegios.push(key);
        arrCodColegios.push(data.data[key]);

      });
      this.ListCodColegios = arrCodColegios.toString();
      this.ListIdColegios  = arrIdColegios.toString();
      this.getListaAnosEscolares();

    })
    .catch((err) => {
      $.notify(
        {icon: 'notifications', message: err.message}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
      );
    });
  }
  else{
    $.notify(
      {icon: 'notifications', message: 'Revise selección en el menú por favor.' }, 
      {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
    );
  }
      
  }


  getListaAnosEscolares() {

    this.ListAnoEscolar = [];
    this.ActualAnoEscolar = 0;
    this.SlcAnoEsoclar = [];
    

    let rsData = [];
    let recurso: string;
    let idSelect: string;
    let jsonData: any;
    let AnoEscolar = [];
    let codSostenedor: number;
    let opcion: number;
    let data: string;
    let idPadre: string;
    let codigo:number;
    let tipo:number;

    if (localStorage.getItem('select_colegio')) {
      let colegRs = JSON.parse(localStorage.getItem('select_colegio'));      
      idSelect = colegRs['id'];
      codigo = JSON.parse(localStorage.getItem('select_sost')) ? JSON.parse(localStorage.getItem('select_sost')).id:this.IAservice.idsostParent(idSelect);
      this.itemAgrupadoPor = ' colegio de ';
      tipo = 1;
    }  
    else if (localStorage.getItem('select_sost')) {
      let codSostenedor = parseInt(localStorage.getItem('cod_sostenedor'));
      let sostRs = JSON.parse(localStorage.getItem('select_sost'));
      codigo = parseInt(sostRs['id']);
      this.itemAgrupadoPor = ' comuna de '+this.ListComunasSost[codSostenedor];
      tipo = 1;
    }  
    else if (localStorage.getItem('select_slep')) {
      let slepRs = JSON.parse(localStorage.getItem('select_slep'));
      codigo = parseInt(slepRs['id']);
      this.itemAgrupadoPor = 'SLE '+slepRs['itemName'].charAt(0).toUpperCase() + slepRs['itemName'].slice(1).toLowerCase();
      tipo = 2;
    }
     
    //   
    let currentTime = new Date()
    let ano_actual = currentTime.getFullYear();

    this.ListadoAnosEscolaresService.listarAnosEscolares(codigo,tipo).subscribe(
      result => {//verificar validaciones para cuando es success pero con data null && result.data
        
        if( result.success && result.data[codigo].length != undefined) {

            let jsonData = result.data;
            AnoEscolar = jsonData[codigo].split(",")
            AnoEscolar = this.eliminarDuplicateInArray(AnoEscolar);

            let arrAnoEscolar = [];
            Object.keys(AnoEscolar).forEach(key => {
              arrAnoEscolar.push(parseInt(AnoEscolar[key]));
            });
            arrAnoEscolar.sort(function(a, b){return b - a});
            
            Object.keys(arrAnoEscolar).forEach(key => {
              var xano = {};
              xano["ano_escolar"] =  parseInt(arrAnoEscolar[key]);
              this.ListAnoEscolar.push(xano);

              this.SlcAnoEsoclar.push(xano);
              
              AnoEscolar.push(this.ListAnoEscolar[key].ano_escolar);

              if (this.ActualAnoEscolar) {
                if (this.ActualAnoEscolar < this.ListAnoEscolar[key].ano_escolar) {
                  this.ActualAnoEscolar = this.ListAnoEscolar[key].ano_escolar;
                }
              } else {
                this.ActualAnoEscolar = this.ListAnoEscolar[key].ano_escolar;
              }
            });

            this.SlcAnoEsoclar = this.eliminarDuplicateInArray(this.SlcAnoEsoclar);

            this.loading = false;

            this.getListaGradosAsignaturas();
            
          } else {
            $.notify(
              { icon: 'notifications', message: "No hay datos para imprimir" }, 
              {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
            );
            setTimeout(() => { this.loading = false}, 700);
          }
      },
      error => {
          setTimeout(() => { this.loading = false}, 700);
          this.api_error = true;
        }
      );  
  } 
   
  ocultarGrafico(){
    this.existeData=false;
    this.ActualAsign=null;
  }

  getListaGradosAsignaturas() { 

    this.ActualGrado = 0;
    this.ActualAsign = 0;
    this.getArrayGradosAsignaturas = [];
    this.getArrayGrados = [];
    this.arrGrados = [];
    this.arrAsignaturas = [];

    this.existeData = false;
    this.chart = new Chart();
    this.dataTable = [];

    let recurso: string;
    let idSelect: string;
    let jsonData: any;
    let data: string;
    
    this.SlcAnoEsoclar = this.eliminarDuplicateInArray(this.SlcAnoEsoclar);
    
    if (localStorage.getItem('select_colegio')) {
      jsonData = JSON.parse(localStorage.getItem('select_colegio'));
      idSelect = jsonData.id
      recurso = urlServer + '/colegioselector/' + idSelect;
    } 
    if (localStorage.getItem('select_sost')) {
      jsonData = JSON.parse(localStorage.getItem('select_sost'));
      idSelect = jsonData.id
      recurso = urlServer + '/rbd/sostenedor/' + idSelect;
    } 
    else if (localStorage.getItem('select_slep')) {
      jsonData = JSON.parse(localStorage.getItem('select_slep'));
      idSelect = jsonData.id
      recurso = urlServer + '/rbd/slep/' + idSelect;
    } 
    else {
      recurso = null
    }

    var jData  = '{"lista_colegios":"'+this.ListIdColegios+'","ano_escolar":"'+this.ActualAnoEscolar+'"}';
    let rango_notas_grados_post='/colegios/grados_asignaturas';
    this.IAservice.listadoGradosAsignaturasColegios(rango_notas_grados_post,jData).subscribe(
    result => {
      if( result.success ) {
        let jsonData = result.data;
        Object.keys(jsonData).forEach(cod_grado => {
          if(localStorage.getItem('ListProfilesPers'))
             {
          if(this.Agnos.findgrade(cod_grado))
             {
          this.getArrayGrados.push({'cod_grado':cod_grado,'grado':jsonData[cod_grado].grado})
          let listAsig = [];
          Object.keys(jsonData[cod_grado].asignaturas).forEach(cod_asignatura => {
            if(this.Agnos.findsubject(cod_asignatura))
            {
            listAsig.push({'cod_asignatura':cod_asignatura, 'asignatura':jsonData[cod_grado].asignaturas[cod_asignatura]});
            this.arrAsignaturas[cod_grado+'-'+cod_asignatura] = jsonData[cod_grado].asignaturas[cod_asignatura];
            }
          });

          let aux_grado = jsonData[cod_grado].grado.split(':');
          this.arrGrados[cod_grado] = aux_grado[1];
          this.getArrayGradosAsignaturas[cod_grado] = listAsig;
        }
      }
      else{
        this.getArrayGrados.push({'cod_grado':cod_grado,'grado':jsonData[cod_grado].grado})
          let listAsig = [];
          Object.keys(jsonData[cod_grado].asignaturas).forEach(cod_asignatura => {            
            listAsig.push({'cod_asignatura':cod_asignatura, 'asignatura':jsonData[cod_grado].asignaturas[cod_asignatura]});
            this.arrAsignaturas[cod_grado+'-'+cod_asignatura] = jsonData[cod_grado].asignaturas[cod_asignatura];
          });

          let aux_grado = jsonData[cod_grado].grado.split(':');
          this.arrGrados[cod_grado] = aux_grado[1];
          this.getArrayGradosAsignaturas[cod_grado] = listAsig;
      }

        });

        this.rangoNotas();

      } else {
        $.notify(
          {icon: 'notifications', message: "No hay datos para imprimir"}, 
          {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
        });
        setTimeout(() => { this.loading = false}, 700);
      }
    },
      error => {
        setTimeout(() => { this.loading = false}, 700);
        this.api_error = true;
      }
    );
   
  }
  
  recargarSelectGrados(items: any) {
    this.ActualAnoEscolar = items.value;
    this.getListaGradosAsignaturas();
  }




  rangoNotas() {
    
    let recurso: string;
    let recurso_grado: string;
    let idSelect: string;
    let jsonData: any;
    let AnoEscolar = [];
    let rowHead = {};
    this.dataTableHead = [];
    this.dataTable = [];
    this.dataTableExcel=[];

    this.loading   = false;
    this.api_error = false;

    // AS

    rowHead = {
      agrupa  : 'Agrupación',
      rango_1 : 'entre 1 y 1.9',
      rango_2 : 'entre 2 y 2.9',
      rango_3 : 'entre 3 y 3.9',
      rango_4 : 'entre 4 y 4.9',
      rango_5 : 'entre 5 y 5.9',
      rango_6 : 'entre 6 y 7'
    }
    /*if (localStorage.getItem('select_sost')) {
      rowHead = {
        agrupa  : 'Agrupación',
        rango_1 : 'entre 1 y 1.9',
        rango_2 : 'entre 2 y 2.9',
        rango_3 : 'entre 3 y 3.9',
        rango_4 : 'entre 4 y 4.9',
        rango_5 : 'entre 5 y 5.9',
        rango_6 : 'entre 6 y 7'
      }

    }  else if (localStorage.getItem('select_slep')) {
      rowHead = {
        agrupa  : 'Agrupación',
        rango_1 : 'entre 1 y 1.9',
        rango_2 : 'entre 2 y 2.9',
        rango_3 : 'entre 3 y 3.9',
        rango_4 : 'entre 4 y 4.9',
        rango_5 : 'entre 5 y 5.9',
        rango_6 : 'entre 6 y 7'
      }

    }*/

    // AS
    
    if (this.ActualAsign) {

      let dataTipo = 0;
       if (localStorage.getItem('select_colegio')) {
        dataTipo = 2;
      } 
      else if (localStorage.getItem('select_sost')) {
        dataTipo = 2;
      }
      else if (localStorage.getItem('select_slep')) {
        dataTipo = 1;
      }

      var jData  = '{"grupo_colegios":"'+this.ListCodColegios+'","cod_grado":"'+this.ActualGrado+'","cod_asignatura":"'+this.ActualAsign+'","ano_escolar":"'+this.ActualAnoEscolar+'", "tipo": "'+dataTipo+'"}';

      this.IndicadoresAcademicosNotasSndService.rangoNotasSnd(jData).subscribe(
        result => {
          if( result.success && result.data.success) {
            this.existeData = true;

            let jsonData  = result.data;
            let colores   = result.data['colors'];
            let tablaData = result.data['labels'];
            
            this.rsData   = result.data['datos'];
            
            let i = 0;
            let rsSerie = [];
            while (i < this.rsData.length) {
              rsSerie[i] = {};
              rsSerie[i].name = this.rsData[i].name;

              let j = 0;
              let auxData = this.rsData[i].data;
              let midata = [];
              let k = 0;
              midata[k] = parseInt(auxData[j].data);

              rsSerie[i].data = midata;
              rsSerie[i]._colorIndex = i;
              i++;
            }

            this.dataTableHead.push(rowHead);
            let array_indices=[]=Object.values(rowHead);
            Object.keys(tablaData).forEach(cod => {
              let rs = tablaData[cod]['grados'][this.ActualGrado]['asignaturas'][this.ActualAsign];
              let row = {};
              let rowexcel = {};
              let nombreAgrupa:string;
              
              nombreAgrupa = tablaData[cod]['nom_agrupa'];

              // if (localStorage.getItem('select_sost')) {
              //   nombreAgrupa = tablaData[cod]['nom_agrupa'];
              // } else if (localStorage.getItem('select_slep')) {
              //   nombreAgrupa = this.ListComunasSost[cod];
              // }  
              
              row = {
                agrupa  : nombreAgrupa,
                rango_1 : '',
                rango_2 : '',
                rango_3 : '',
                rango_4 : '',
                rango_5 : '',
                rango_6 : ''
              }  
              rowexcel['Agrupación']=  nombreAgrupa;
              
              let indice_rango:number=1;
              Object.keys(rs).forEach(data => {
                
                if ( data != 'nombre') {
                  row[data] = rs[data];
                  rowexcel[array_indices[indice_rango++]]=  rs[data];
                }
              })
              this.dataTable.push(row);
              this.dataTableExcel.push(rowexcel);
            });
            this.drawGraphBarra(rsSerie, this.ActualAnoEscolar, colores, ["Rango de notas"], '');

          } else {
            $.notify(
              {icon: 'notifications', message: "No hay datos para imprimir"}, 
              {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }
            });
            setTimeout(() => { this.loading = false}, 700);
          }
        },
        error => {
          setTimeout(() => { this.loading = false}, 700);
          this.api_error = true;
        }
      );
    }
  }





  drawGraphBarra(rsData, anoEscolar, colors, categoria, tipo = 'consolidado') {

    let titulo_graph = 'Distribución notas '+this.itemAgrupadoPor+', periodo escolar ' + anoEscolar +'.<br>'+this.arrGrados[this.ActualGrado]+' - '+this.arrAsignaturas[this.ActualGrado +'-'+this.ActualAsign];

    this.loading = true;
    var rotacionDataLabels = -90;
    if (tipo == 'comparativo') {
      rotacionDataLabels = null;
    }


    this.chart = new Chart({
      lang: {
        downloadPNG: "Descargar a PNG",
        downloadJPEG: "Descargar a JPEG",
        downloadSVG: "Descargar a SVG",
        downloadPDF: "Descargar a PDF",
        loading: "Cargando",
        printChart: "Imprimir",
        contextButtonTitle: 'Opciones gráfico'
      },
      chart: {
        type: "column"
      },
      title: {
        text: titulo_graph
      },
      xAxis: {
        categories: categoria,
        min: null,
        max: null,
        title: {
          text: null
        },
        labels: {
          rotation: 0
        }
      },
      yAxis: {
        min: 0,
        max: null,
        title: {
          text: 'Millares'
        },
        labels: {
          overflow: "justify",
          format: '{value}'
        }
      },
      tooltip: {
        valueSuffix: '',
        formatter: function() {
          return "" + this.x + "<br/>" + this.series.name + " : <strong>" + Highcharts.numberFormat(this.y, 0, ",", ".") + '' + "</strong>";
        }
      },      
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            shadow: true
          }
        },
        series: {
          dataLabels: {
            rotation: 0,
            formatter: function() {
              return this.series.name + ": <strong>" + this.y + '' + "</strong> ";
            },
            format: '{y}',
            enabled: true,
            verticalAlign: 'middle',
            align: 'center',
            inside: true
          }
        }
      },
      credits: {
        enabled: false
      },
      series: rsData,
      colors: colors
    });
    this.loading = false;
  }

  

  recargarRangoNotas(items: any) {
    if (items.value) {
      this.ActualAsign = items.value;
      this.rangoNotas();
    } else {
      console.log('no llegue');
    }
  }

  
 


  eliminarDuplicateInArray(arreglo) {
    let uniqueNames = [];
    $.each(arreglo, function(i, el){
        if($.inArray(el, uniqueNames) === -1) uniqueNames.push(el);
    });
    return uniqueNames;
  }


  formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }


  numbreRand(min: number, max: number, cant: number) {
    let array = [];
    if (cant == 1) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    } else {
      for (let index = 0; index < cant; index++) {
        array.push(Math.floor(Math.random() * (max - min + 1)) + min);
      }
      return array.sort();
    }
  }

  MaysPrimera(string) {
    var cadena = new String(string);
    cadena = cadena.toLowerCase();
    cadena = cadena.charAt(0).toUpperCase() + cadena.slice(1);
    return cadena;
  }

  camelize(str) {

    str = str.toLowerCase();
    return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g,
      function (s) {
        return s.toUpperCase();
      });

  }

  addslashes(str) {
    str = str.replace(/\\/g, '\\\\');
    str = str.replace(/\'/g, '\\\'');
    str = str.replace(/\"/g, '\\"');
    str = str.replace(/\0/g, '\\0');
    return str;
  }

  stripslashes(str) {
    str = str.replace(/\\'/g, '\'');
    str = str.replace(/\\"/g, '"');
    str = str.replace(/\\0/g, '\0');
    str = str.replace(/\\\\/g, '\\');
    return str;
  }

  //Exporta json a excel
  exportAsXLSX():void {    
    this.excelService.exportAsExcelFile(this.dataTableExcel, this.titulo_excel);
  }

}
