import { Component, OnInit, OnDestroy } from '@angular/core';
import { urlServer } from '../../services/url.service';
import { Chart } from 'angular-highcharts';
import { PuenteService } from '../../services/puente.service';import { Subscription } from 'rxjs/Subscription';
import { HttpPuenteService } from '../../services/shared/httppuente.service';
import { ListaAgnosService } from '../../services/shared/listaagnos.service';
import { GraficasService } from '../../services/shared/graficas.service';
import { DatatablesService } from '../../services/shared/datatables.service';
import { ExcelService } from '../../services/excel.service';


declare var $: any;

@Component({
  selector: 'app-promedio-periodo',
  templateUrl: './promedio-periodo.component.html',
  styleUrls: ['./promedio-periodo.component.scss']
})
export class PromedioPeriodoComponent implements OnInit, OnDestroy  {
  
  Puente: Subscription;
  chart: Chart;
  ActualAnoEscolar: any; 
  ActualGrado: any;
  ActualPeriodo: any;
  mdPerfil: any;
  linkParams: any;
  tipoAgrupacion:string;
  ListIdColegios:string;
  ListCodColegios:string;
  titulo_excel:string;
  titulo_tabla:string;
  titulo_grafico:string='';
  headerTbl = [];
  SlcAnoEscolar = [];
  SlcAnoEscolarOrden = [];
  SlcGrados = [];
  Periodos=[];
  dataTable = [];
  dataTableExcel=[];
  rsData = [];
  
    // AS
  existeData = false;
  constructor(private puente: PuenteService, private IAservice: HttpPuenteService,private excelService:ExcelService,private Agnos: ListaAgnosService, private DataTables: DatatablesService, private graphService: GraficasService) { 
    
    this.Puente = this.puente.getData().subscribe(data => {
      this.selectoresPromedioPeriodo();
    });
  }
  ngOnInit() {
    this.ActualAnoEscolar =this.Agnos.ActualAnoEscolar;
    this.Periodos=this.Agnos.listPeriodosEscolares();
    this.ActualPeriodo=this.Periodos[0].id;
   // console.log(this.ActualPeriodo,this.Periodos,this.Periodos[0].id)
    this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));
    this.selectoresPromedioPeriodo();
    this.sl2_style();
  }
  ngOnDestroy() {
    this.Puente.unsubscribe();
  }

  selectoresPromedioPeriodo() { 
    let rsData = [];
    let recurso: string;
    let recurso_grado: string;
    let idSelect: string;
    let jsonData: any;
    let AnoEscolar = [];    
    if (localStorage.getItem('select_colegio')) {
      jsonData = JSON.parse(localStorage.getItem('select_colegio'));
      idSelect = jsonData.id
      recurso_grado = urlServer + '/colegio/' + idSelect + '/grados';
    } 
    else if (localStorage.getItem('select_sost')) {
      jsonData = JSON.parse(localStorage.getItem('select_sost'));
      idSelect = jsonData.id
      recurso_grado = urlServer + '/sostenedor/' + idSelect + '/grados';
    } 
    else if (localStorage.getItem('select_slep')) {
      jsonData = JSON.parse(localStorage.getItem('select_slep'));
      idSelect = jsonData.id
      recurso_grado = urlServer + '/slep/' + idSelect + '/grados';
    } 
    else{
      recurso_grado = null;
    }
    /* else {
      recurso_grado = urlServer + '/dep/' + this.mdPerfil.codigo +'/grados';
    } */
      
      if(recurso_grado){

    this.dataTable = [];
    this.rsData = [];
    this.ActualAnoEscolar = 0;
    this.ActualGrado = '';
    this.SlcAnoEscolar = [];
    this.SlcAnoEscolarOrden = [];
    this.existeData = false;
    
    this.IAservice.getData(recurso_grado)
      .then((data) => {      
        Object.keys(data.data).forEach(key => {
          Object.keys(data.data[key]).forEach(anoEscolar => {
            //AnoEscolar.push(anoEscolar);

            var gradoObj = [];
            for (var cod_grado in data.data[key][anoEscolar]) {
             
             // verificar que grado esté entre los asignados al usuario logueado
             if(localStorage.getItem('ListProfilesPers'))
             {
             if(this.Agnos.findgrade(cod_grado))
             {
              var obj = {
                id: cod_grado,
                nom_grado: data.data[key][anoEscolar][cod_grado]
              }
              gradoObj.push(obj)
            }
            }
            else{
              var obj = {
                id: cod_grado,
                nom_grado: data.data[key][anoEscolar][cod_grado]
              }
              gradoObj.push(obj)
            }
          }
            this.SlcGrados[anoEscolar] = gradoObj;           
            if (this.ActualAnoEscolar) {
              if (this.ActualAnoEscolar < anoEscolar) {
                this.ActualAnoEscolar = anoEscolar;
              }
            } 
            else {
              this.ActualAnoEscolar = anoEscolar;
            }
            var xano = {};
            xano["ano_escolar"] = anoEscolar;
            this.SlcAnoEscolar.push(xano);
          });
          if (this.SlcGrados[this.ActualAnoEscolar]) {
            this.ActualGrado = this.SlcGrados[this.ActualAnoEscolar][0].id;           
          }
        });
        this.SlcAnoEscolarOrden=this.Agnos.yearsOrder(this.SlcAnoEscolar);
        this.datosPromedioAsignaturaPeriodo();
      })
      .catch((err) => {
        $.notify(
          {icon: 'notifications', message: err.message}, 
          {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
        );
      });
    }
    else{
      $.notify(
        {icon: 'notifications', message: 'Revise selección en el menú por favor.'}, 
        {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
      );
    }
  }

  recargarPromedioPeriodoAno(items: any) {
    this.ActualAnoEscolar = items.value;
    this.datosPromedioAsignaturaPeriodo();
  }

  recargarPromedioPeriodoGrado(items: any) {
    this.ActualGrado = items.value;
    this.datosPromedioAsignaturaPeriodo();
  }

  recargarPromedioPeriodo(items: any) {
    this.ActualPeriodo = items.value;
    this.datosPromedioAsignaturaPeriodo();
  }

  numbreRand(min: number, max: number, cant: number) {
    let array = [];
    if (cant == 1) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    } else {
      for (let index = 0; index < cant; index++) {
        array.push(Math.floor(Math.random() * (max - min + 1)) + min);
      }
      return array.sort();
    }
  }

  MaysPrimera(string) {
    var cadena = new String(string);
    cadena = cadena.toLowerCase();
    cadena = cadena.charAt(0).toUpperCase() + cadena.slice(1);
    return cadena;
  }

  camelize(str) {
    str = str.toLowerCase();
    return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g,
      function (s) {
        return s.toUpperCase();
      });
  }


  graficoPromedioPeriodo(){
    this.chart = new Chart({
      lang: {
        downloadPNG: "Descargar a PNG",
        downloadJPEG: "Descargar a JPEG",
        downloadSVG: "Descargar a SVG",
        downloadPDF: "Descargar a PDF",
        loading: "Cargando",
        printChart: "Imprimir"
      },
      chart: {
        type: 'line'
      },
      title: {
        text: 'Promedios por asignatura'
      },
      yAxis: {
        title: {
          text: 'Promedio'
        }
      },
      xAxis: {
        categories: this.headerTbl,
        title: {
          text: ''
        }
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          pointStart: 0
        }
      },
      series: this.rsData
    });
  }

  

  datosPromedioAsignaturaPeriodo() {
    let rsData = [];
    let recurso: string;
    let list: any;
    let idSelect: string;
    let idPadre: string;
    let jsonData: any;
    let AnoEscolar = [];
    this.dataTable = [];
    this.dataTableExcel=[];
    this.rsData = [];
    this.titulo_grafico='período ';
    if (localStorage.getItem('select_colegio')) {
      list = JSON.parse(localStorage.getItem('ListCole'));
      jsonData = JSON.parse(localStorage.getItem('select_colegio'));
      idSelect = jsonData.id
      
      idPadre = JSON.parse(localStorage.getItem('select_sost')) ? JSON.parse(localStorage.getItem('select_sost')).id:this.IAservice.idsostParent(idSelect);
      recurso = urlServer + '/colegio/' + idSelect + '/promedio-asignatura';
    } 
    else if (localStorage.getItem('select_sost')) {
      list = JSON.parse(localStorage.getItem('ListCole'));
      jsonData = JSON.parse(localStorage.getItem('select_sost'));
      idSelect = jsonData.id
      recurso = urlServer + '/sostenedor/' + idSelect + '/promedio-asignatura';
    } 
    else if (localStorage.getItem('select_slep')) {
      list = JSON.parse(localStorage.getItem('ListSost'));
      jsonData = JSON.parse(localStorage.getItem('select_slep'));
      idSelect = jsonData.id
      recurso = urlServer + '/slep/' + idSelect + '/promedio-asignatura';
    } 
    else {
      list = JSON.parse(localStorage.getItem('ListSlep'));
      recurso = urlServer + '/dep/' + this.mdPerfil.codigo +'/promedio-asignatura';
    }

    this.dataTable = [];
    this.headerTbl = [];
    let params: any;
    let tipo = "";
    let promedio_asignatura_periodo_post = '';//urlServer + '/promedio_asignatura_periodo';

    if (localStorage.getItem('select_colegio')) {
      recurso = urlServer + '/colegioselector/' + idSelect;
      tipo = "4";
    } 
    else if (localStorage.getItem('select_sost')) {
      recurso = urlServer + '/rbd/sostenedor/' + idSelect;
      tipo = "4";
    } 
    else if (localStorage.getItem('select_slep')) {
      recurso = urlServer + '/rbd/slep/' + idSelect;
      tipo = "3";
    } 
    else {
      recurso = null
    }

    
    if (this.ActualPeriodo==1 || this.ActualPeriodo==2) {
      this.titulo_grafico= `${this.titulo_grafico} ${this.ActualPeriodo}`;
      this.titulo_excel=`Promedio_período_${this.ActualPeriodo}_${this.ActualAnoEscolar}_`;
      promedio_asignatura_periodo_post = urlServer + '/promedio_asignatura_periodo';
    } 
    else if (this.ActualPeriodo==3) {
      this.titulo_excel=`Promedio_período_final_${this.ActualAnoEscolar}_`;
      this.titulo_grafico= `${this.titulo_grafico} final`;
      promedio_asignatura_periodo_post = urlServer + '/promedio_notas_asignatura';
    } 
    else {
      promedio_asignatura_periodo_post = null
    }
    let arrCodColegios = [];
    let arrIdColegios = [];
    if (this.ActualGrado != '' && this.ActualAnoEscolar != 0 && recurso) {
      //if(recurso){
       // if(tipo != "5"){
          this.IAservice.getData(recurso).then((data) => {
            Object.keys(data.data).forEach(key => {
                arrIdColegios.push(key);
                arrCodColegios.push(data.data[key]);
            });

            let ListCodColegios = arrCodColegios.toString();
            
            params = {
                "grupo_colegios": ListCodColegios,
                "ano_escolar": this.ActualAnoEscolar,
                "tipo": tipo,
                "asociar_a": idPadre?idPadre:idSelect,
                "cod_grado": this.ActualGrado,
                "periodo": this.ActualPeriodo
            }

            this.IAservice.getDataPost(promedio_asignatura_periodo_post, params)
              .then((data) => {
                list = data.data['establecimientos'];                
                Object.keys(data.data).forEach(key => {
                  //console.log('llave',key);
                  if (data.data[key] && key != "establecimientos") {
                    var listpromedio = {};
                    this.existeData = true;
                    Object.keys(data.data[key]).forEach(cod_asignatura => {
                      let obj = {};
                      if(localStorage.getItem('ListProfilesPers'))
                      {
                      if(this.Agnos.findsubject(cod_asignatura))
                      {
                                            
                      obj["name"] = this.MaysPrimera(data.data[key][cod_asignatura]["asignatura"]);
                      
                      if (parseInt(cod_asignatura)==14 || parseInt(cod_asignatura)==5 || parseInt(cod_asignatura)==11224 || parseInt(cod_asignatura)==15 ) {
                        obj["visible"] = true;
                      } else {
                        obj["visible"] = false;
                      }

                      var dataCol = [];
                      Object.keys(data.data[key][cod_asignatura]["promedios"]).forEach(id => {
                        Object.keys(list).forEach(k => {
                          
                          if (list[k].id == id) {
                            dataCol.push(data.data[key][cod_asignatura]["promedios"][id]);
                            if (this.headerTbl.indexOf(this.camelize(list[k].itemName)) === -1) {
                              this.headerTbl.push(this.camelize(list[k].itemName));
                            }
                          }
                        });
                      });
                      obj["data"] = dataCol;
                      this.rsData.push(obj);
                    }
                  }
                    else{
                      obj["name"] = this.MaysPrimera(data.data[key][cod_asignatura]["asignatura"]);
                      
                      if (parseInt(cod_asignatura)==14 || parseInt(cod_asignatura)==5 || parseInt(cod_asignatura)==11224 || parseInt(cod_asignatura)==15 ) {
                        obj["visible"] = true;
                      } else {
                        obj["visible"] = false;
                      }

                      var dataCol = [];
                      Object.keys(data.data[key][cod_asignatura]["promedios"]).forEach(id => {
                        Object.keys(list).forEach(k => {
                          
                          if (list[k].id == id) {
                            dataCol.push(data.data[key][cod_asignatura]["promedios"][id]);
                            if (this.headerTbl.indexOf(this.camelize(list[k].itemName)) === -1) {
                              this.headerTbl.push(this.camelize(list[k].itemName));
                            }
                          }
                        });
                      });
                      obj["data"] = dataCol;
                      this.rsData.push(obj);
                    }
                    });
                  }                 
                });                
                let objexcel = [];
                
                  Object.keys(data.data['establecimientos']).forEach(kest => {
                    let datafila= [];
                    datafila["Agrupación"]= data.data['establecimientos'][kest].itemName;
                    Object.keys(this.rsData).forEach(krs => {
                      datafila[this.rsData[krs].name]=this.rsData[krs].data[kest];
                    });
                    objexcel[kest]=(datafila);
              });
              this.dataTableExcel=objexcel;
                this.graficoPromedioPeriodo();

            })
            .catch((err) => {
              $.notify(
                {icon: 'notifications', message: 'No hay datos para Graficar'},//err.message}, 
                {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
              );
            });
          })
          .catch((err) => {
            $.notify(
              {icon: 'notifications', message: err.message}, 
              {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
            );
          });
     /* }
      else{
        arrCodColegios.push(idSelect);
        let ListCodColegios = arrCodColegios.toString();
         params = {
             "grupo_colegios": ListCodColegios,
             "ano_escolar": this.ActualAnoEscolar,
             "tipo": tipo,
             "asociar_a": idSelect,
             "cod_grado": this.ActualGrado,
             "periodo": this.ActualPeriodo
         }

         this.IAservice.getDataPost(promedio_asignatura_periodo_post, params)
           .then((data) => {
             list = data.data['establecimientos'];                
             Object.keys(data.data).forEach(key => {
               if (data.data[key] && key != "establecimientos") {
                 var listpromedio = {};
                 this.existeData = true;
                 Object.keys(data.data[key]).forEach(cod_asignatura => {                      
                   let obj = {};                      
                   obj["name"] = this.MaysPrimera(data.data[key][cod_asignatura]["asignatura"]);
                   
                   if (parseInt(cod_asignatura)==14 || parseInt(cod_asignatura)==5 || parseInt(cod_asignatura)==11224 || parseInt(cod_asignatura)==15 ) {
                     obj["visible"] = true;
                   } else {
                     obj["visible"] = false;
                   }

                   var dataCol = [];
                   Object.keys(data.data[key][cod_asignatura]["promedios"]).forEach(id => {
                     Object.keys(list).forEach(k => {
                       
                       if (list[k].id == id) {
                         dataCol.push(data.data[key][cod_asignatura]["promedios"][id]);
                         if (this.headerTbl.indexOf(this.camelize(list[k].itemName)) === -1) {
                           this.headerTbl.push(this.camelize(list[k].itemName));
                         }
                       }
                     });
                   });
                   obj["data"] = dataCol;
                   this.rsData.push(obj);
                 });
               }
              
             });
             
             let objexcel = [];
             
               Object.keys(data.data['establecimientos']).forEach(kest => {
                 let datafila= [];
                 datafila["Agrupación"]= data.data['establecimientos'][kest].itemName;
                 Object.keys(this.rsData).forEach(krs => {
                   datafila[this.rsData[krs].name]=this.rsData[krs].data[kest];
                 });
                 objexcel[kest]=(datafila);
           });
           this.dataTableExcel=objexcel;
             this.graficoPromedioPeriodo();

         })
         .catch((err) => {
           $.notify(
             {icon: 'notifications', message: 'No hay datos para Graficar'},//err.message}, 
             {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
           );
         });
      }*/
    } else {
      $.notify(
        { icon: 'notifications', message: 'No existe configuración previa para mostrar' }, 
        { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }}
      );
    }
  }

  //Exporta json a excel
  exportAsXLSX():void {    
    this.excelService.exportAsExcelFile(this.dataTableExcel, this.titulo_excel);
  }

  sl2_style() {

    $(".c-btn").css("box-shadow", "none");
    $(".c-btn").css("border-top", "none");
    $(".c-btn").css("border-left", "none");
    $(".c-btn").css("border-right", "none");
    $(".c-btn").css("color", "#989898");
    $(".c-btn").css("background-color", "#ededee");
    $(".sl2-material > div >  .dropdown-list").css("position", "absolute");
  }

}
