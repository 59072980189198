import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { PuenteService } from '../../services/puente.service';
import { Subscriber } from 'rxjs/Subscriber';
import { Subscription } from 'rxjs/Subscription';
declare var jquery: any;
declare var $: any;
import * as _ from 'lodash';
import { NotificationService } from '../../services/notification.service';
import { timeout } from 'q';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.scss']
})
export class NotificacionesComponent implements OnDestroy {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  public nomChat: any;
  public arrDestinatarios: any[] = [];
  public arrDestinatariosFilter: any[] = [];
  public searchString: string;
  public notificationMessage: string;
  listOfWhoToNotify: any[] = [];
  listTitle: String = '';
  puenteSub: Subscription;
  notificaciones: any[] = [];
  notificacionesFiltered: any[] = [];
  grupos: { id_slep: any; id_sostenedor: any; id_colegio: any; };
  showNotificationBox: Boolean = true;
  mdPerfil: any;

  constructor(
    private puente: PuenteService,
    private notificationService: NotificationService
  ) {
    this.refreshNotificationBox();
    this.puenteSub = this.puente.getData().subscribe(data => {
      this.listOfWhoToNotify = [];
      this.arrDestinatarios = [];
      this.nomChat = '';
      this.refreshNotificationBox();
    });
    
  }
 
  ngOnDestroy() {
    this.puenteSub.unsubscribe();
  }


  /*
  * Actualiza los destinatarios dependiendo de la selección del navbar
  * @param: none
  * return none
  */
  refreshNotificationBox(onlyNotifications = false) {
    let jsonData = [];
    let type = '';
    let typeSelected = '';
    this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));

    if (localStorage.getItem('select_colegio')) {
      type = 'colegio';
      typeSelected = JSON.parse(localStorage.getItem('select_colegio'));
      this.listTitle = 'Cursos';
      jsonData = [];
      this.showNotificationBox = false;
      return false;
    } else if (localStorage.getItem('select_sost')) {
      type = 'sostenedor';
      typeSelected = JSON.parse(localStorage.getItem('select_sost'));
      this.listTitle = 'Colegios';
      jsonData = JSON.parse(localStorage.getItem('ListCole'));
    } else if (localStorage.getItem('select_slep')) {
      type = 'slep';
      typeSelected = JSON.parse(localStorage.getItem('select_slep'));
      this.listTitle = 'Sostenedores';
      jsonData = JSON.parse(localStorage.getItem('ListSost'));
    } else {
      type = 'dep/'+this.mdPerfil.codigo;
      typeSelected = null;
      this.listTitle = 'Slep';
      jsonData = JSON.parse(localStorage.getItem('ListSlep'));
    }
    this.showNotificationBox = true;
    const id = typeSelected && typeSelected['id'] ? typeSelected['id'] : typeSelected;
    this.getNotifications(type, id);
    if (!onlyNotifications){
      this.listOfWhoToNotify = jsonData;
    }
  }

  /*
  * Inicialización de los grupos
  * @param: none
  * return none
  */
  initGroups() {
    const colegio = JSON.parse(localStorage.getItem('select_colegio'));
    const sostenedor = JSON.parse(localStorage.getItem('select_sost'));
    const slep = JSON.parse(localStorage.getItem('select_slep'));
    const usuario = localStorage.getItem(btoa('id_usuario'));
    return {
      id_slep:  slep ? slep.id : null,
      id_sostenedor:  sostenedor ? sostenedor.id : null,
      id_colegio: colegio ? colegio.id : null,
      id_usuario: usuario
    }
  }

  /*
  * Agregar los destinatarios
  * @param: id => id del destinatario
  * return none
  */
  addDest(id) {
    const index = this.arrDestinatarios.indexOf(id);
    let arrnom = '';

    if (index !== -1) {
      this.arrDestinatarios.splice(index, 1);
    } else {
      this.arrDestinatarios.push(id);
    }

    _.forEach(this.arrDestinatarios, (value) => {
      arrnom += _.find(this.listOfWhoToNotify, {id: value}).itemName + ', ';
    });

    this.nomChat = arrnom.substring(0, arrnom.length - 2);
  }

  /*
  * Filtro de notificaciones a mostrar
  * @param: none
  * return [array] => Notificaciones a mostrar
  */
  filterNotification() {
    this.notificacionesFiltered = _.filter(this.notificaciones, (value) => {
      if (!this.arrDestinatarios.length) {
        return true;
      }
      return this.arrDestinatarios.indexOf(value.tipo_id) >= 0 ? true : false;
    });
  }


  /*
  * Envío de notificaciones
  * @param: none
  * return none
  */
  sendNotification() {
    if (this.notificationMessage === '' || this.notificationMessage === undefined) {
      this.formErrMessage('Debe escribir un mensaje en la notificación.');
      return false;
    }

    if (!this.arrDestinatarios.length) {
      this.formErrMessage('Debe seleccionar al menos un destinatario');
      return false;
    }

    const params = this.initGroups();
    params['mensaje'] = this.notificationMessage;
    params['data'] = this.arrDestinatarios;

    this.notificationService.sendNotification(params).then(res => {
      this.notificationMessage = '';
      this.refreshNotificationBox(true);
      this.formSuccessMessage('Notificación enviada exitosamente.');
    }, (err) => {
      this.formErrMessage(err.mensaje);
    });

  }

  /*
  * Trae las notificaciones
  * @param: none
  * return none
  */
  getNotifications(type, id) {
    this.notificationService.getNotifications(type, id).then(res => {
      this.notificaciones = res.data;
      this.notificacionesFiltered = this.notificaciones ? this.notificaciones : [];
      this.filterNotification();
      // TODO: Hacer el llamado del scroll una vez que carguen todos los elementos.
      setTimeout(() => {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      }, 100);
    }, (err) => {
      this.formErrMessage(err.mensaje);
    });
  }

  /*
  * Mostrar mensaje de error
  * @param: message -> string -> Mensaje a mostrar
  * return none
  */
  formErrMessage(message) {
    $.notify({
      icon: 'notifications',
      message: message
    }, {
      type: 'danger',
      timer: 2000,
      delay: 2000,
      mouse_over: 'pause',
      placement: {
          from: 'bottom',
          align: 'right'
      }
    });
  }

  /*
  * Mostrar mensaje de cambio exitoso
  * @param: message -> string -> Mensaje a mostrar
  * return none
  */
  formSuccessMessage(message) {
    $.notify({
      icon: 'notifications',
      message: message
    }, {
      type: 'success',
      timer: 2000,
      delay: 2000,
      mouse_over: 'pause',
      placement: {
          from: 'bottom',
          align: 'right'
      }
    });
  }
}
