import { PromedioAsignaturaService } from './../../services/promedio-asignatura.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as Chartist from 'chartist';
import { urlServer } from '../../services/url.service';
import { Chart } from 'angular-highcharts';
import { PuenteService } from '../../services/puente.service';
//AS
import { HttpPuenteService } from '../../services/shared/httppuente.service';
//import { AlumnosRetiradosService } from '../../services/alumnos-retirados.service';
//import { RangoNotasService } from './../../services/rango-notas.service';
//AS

import { Subscription } from 'rxjs/Subscription';
import * as Highcharts from "highcharts";
import * as jsPDF from "jspdf";
import * as html2canvas from "html2canvas";
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-promedio-asignatura',
  templateUrl: './promedio-asignatura.component.html',
  styleUrls: ['./promedio-asignatura.component.scss'],
  providers:[PromedioAsignaturaService]
})
export class PromedioAsignaturaComponent implements OnInit,OnDestroy {

  dataTable = [];
  chart: Chart;

  color = 'red';
  mode = 'indeterminate';
  value = 50;
  mdPerfil:any;
  spinner = false;


  itemList = [];
  selectedItems1 = [];
  settings1 = {};

  ActualAnoEscolar: any;
  ActualGrado: any;
  SlcAnoEsoclar = [];
  SlcGrados = [];
  rsData = [];
  headerTbl = [];
  existeData = false;
  Puente: Subscription;


   //AS constructor(private puente: PuenteService, private IAservice: PromedioAsignaturaService, private IAserviceNotas: RangoNotasService) {
    constructor(private puente: PuenteService, private IAservice: PromedioAsignaturaService, private IAserviceNotas: HttpPuenteService) {
    this.ActualAnoEscolar = new Date().getFullYear();
    this.Puente = this.puente.getData().subscribe(data => {
      this.graphNotasAsignatura();
    });
  }

  ngOnInit() {
    this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));
    this.graphNotasAsignatura();

    this.sl2_style();

  }

  ngOnDestroy() {
    this.Puente.unsubscribe();
  }
  
  Gpdf() {
    if (this.rsData.length != 0) {
      this.spinner = true;
      var divWidth = $(".highcharts-container").width() - 20;;
      var originalBodyWidth = document.body.offsetWidth;
      var originalBodyHeight = document.body.offsetHeight;
      document.body.style.width = "1920px";
      document.body.style.height = "1080px";

      $('.graphClass').highcharts().setSize("1100", false);
      $('.table').css("width", "1100px");
      setTimeout(() => {
        var Doc = new jsPDF('l', 'pt', "A4");
        html2canvas(document.querySelector(".page1"), {
          onclone: function (document) {
            document.querySelector(".page1").classList.remove("col-md-8");
          }
        }).then(canvas => {
          var imgData = canvas.toDataURL('image/png');
          var width = Doc.internal.pageSize.width;
          var height = Doc.internal.pageSize.height;
          Doc.addImage(imgData, 'PNG', 0, 0);
          Doc.addPage();

          html2canvas(document.querySelector(".page2"), {
            onclone: function (document) {
              document.querySelector(".page2").style.width = "100%";
            }
          }).then(canvas => {
            var imgData = canvas.toDataURL('image/png');
            var width = Doc.internal.pageSize.width;
            var height = Doc.internal.pageSize.height;
            Doc.addImage(imgData, 'PNG', 0, 0);
            Doc.save('Indicadores académicos.pdf')
            document.body.removeAttribute("style");
            this.spinner = false;

            $('.graphClass').highcharts().setSize(divWidth, false);
            $('.table').css("width", "100%");
          });
        });

      }, 1000);
    } else {
      $.notify({
        icon: 'notifications',
        message: "No hay datos para imprimir"
      }, {
          type: 'warning',
          timer: 2000,
          delay: 2000,
          mouse_over: 'pause',
          placement: {
            from: 'bottom',
            align: 'right'
          }
        });
    }

  }

  sl2_style() {

    $(".c-btn").css("box-shadow", "none");
    $(".c-btn").css("border-top", "none");
    $(".c-btn").css("border-left", "none");
    $(".c-btn").css("border-right", "none");
    $(".c-btn").css("color", "#989898");
    $(".c-btn").css("background-color", "#ededee");
    $(".sl2-material > div >  .dropdown-list").css("position", "absolute");
  }

  onItemSelect(item: any) {
  }
  OnItemDeSelect(item: any) {
  }
  onSelectAll(items: any) {
  }
  onDeSelectAll(items: any) {
  }


  graphNotasAsignatura() {
    let rsData = [];
    let recurso: string;
    let recurso_grado: string;
    let idSelect: string;
    let jsonData: any;
    let AnoEscolar = [];

    
    if (localStorage.getItem('select_colegio')) {
      jsonData = JSON.parse(localStorage.getItem('select_colegio'));
      idSelect = jsonData.id
      recurso_grado = urlServer + '/colegio/' + idSelect + '/grados';
    } else if (localStorage.getItem('select_sost')) {
      jsonData = JSON.parse(localStorage.getItem('select_sost'));
      idSelect = jsonData.id
      recurso_grado = urlServer + '/sostenedor/' + idSelect + '/grados';
    } else if (localStorage.getItem('select_slep')) {
      jsonData = JSON.parse(localStorage.getItem('select_slep'));
      idSelect = jsonData.id
      recurso_grado = urlServer + '/slep/' + idSelect + '/grados';
    } else {
      recurso_grado = urlServer + '/dep/' + this.mdPerfil.codigo +'/grados';
    }

    this.dataTable = [];
    this.rsData = [];
    this.ActualAnoEscolar = 0;
    this.ActualGrado = '';
    this.SlcAnoEsoclar = []
    let params: any;

    this.IAservice.getData(recurso_grado, params)
      .then((data) => {
        Object.keys(data.data).forEach(key => {
          Object.keys(data.data[key]).forEach(anoEscolar => {
            AnoEscolar.push(anoEscolar);

            var gradoObj = [];
            for (var cod_grado in data.data[key][anoEscolar]) {
              var obj = {
                id: cod_grado,
                nom_grado: data.data[key][anoEscolar][cod_grado]
              }
              gradoObj.push(obj)
            }
            this.SlcGrados[anoEscolar] = gradoObj;

            if (this.ActualAnoEscolar) {
              if (this.ActualAnoEscolar < anoEscolar) {
                this.ActualAnoEscolar = anoEscolar;
              }
            } else {
              this.ActualAnoEscolar = anoEscolar;
            }

            var xano = {};
            xano["ano_escolar"] = anoEscolar;

            this.SlcAnoEsoclar.push(xano);

          });
          if (this.SlcGrados[this.ActualAnoEscolar]) {
            this.ActualGrado = this.SlcGrados[this.ActualAnoEscolar][0].id;
          }

        });

        this.recalculaGraficoAcademico();

      })
      .catch((err) => {
        $.notify(
          {icon: 'notifications', message: err.message}, 
          {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
        );
      });
  }


  recargarGraficoAcademicoAno(items: any) {
    this.ActualAnoEscolar = items.value;
    this.recalculaGraficoAcademico();
  }

  recargarGraficoAcademicoGrado(items: any) {
    this.ActualGrado = items.value;
    this.recalculaGraficoAcademico();
  }

  recalculaGraficoAcademico() {
    let rsData = [];
    let recurso: string;
    let list: any;
    let idSelect: string;
    let jsonData: any;
    let AnoEscolar = [];
    this.dataTable = [];
    this.rsData = [];

    if (localStorage.getItem('select_colegio')) {
      list = JSON.parse(localStorage.getItem('ListCole'));
      jsonData = JSON.parse(localStorage.getItem('select_colegio'));      
      idSelect = jsonData.id   
      recurso = urlServer + '/colegio/' + idSelect + '/promedio-asignatura';
    } else if (localStorage.getItem('select_sost')) {
      
      list = JSON.parse(localStorage.getItem('ListCole'));
      jsonData = JSON.parse(localStorage.getItem('select_sost'));
      idSelect = jsonData.id
      recurso = urlServer + '/sostenedor/' + idSelect + '/promedio-asignatura';
    } else if (localStorage.getItem('select_slep')) {
      list = JSON.parse(localStorage.getItem('ListSost'));
      jsonData = JSON.parse(localStorage.getItem('select_slep'));
      idSelect = jsonData.id
      recurso = urlServer + '/slep/' + idSelect + '/promedio-asignatura';
    } else {
      list = JSON.parse(localStorage.getItem('ListSlep'));
      recurso = urlServer + '/dep/' + this.mdPerfil.codigo +'/promedio-asignatura';
    }

    var params = {
      nivel: this.ActualGrado,
      ano_escolar: this.ActualAnoEscolar
    };
    

    let randAnoEscolar: any = this.numbreRand(2010, 2018, 5);
    let randRsData: any = this.numbreRand(500, 5000, 5);
    this.dataTable = [];

    this.headerTbl = [];
    var listcategories = [];
    var dataGraph = [];
    var notas_colegio = []
    var notas_colegio_asig = {};
    var list_asignaturas = {};

    let paramsDos: any;
    let tipo = "";
    let promedio_notas_asignatura_post = urlServer + '/promedio_notas_asignatura';

    if (localStorage.getItem('select_colegio')) {
      recurso = urlServer + '/rbd/colegio/' + idSelect;
      tipo = "5";
    } else
    if (localStorage.getItem('select_sost')) {
      recurso = urlServer + '/rbd/sostenedor/' + idSelect;
      tipo = "4";
    } else if (localStorage.getItem('select_slep')) {
      recurso = urlServer + '/rbd/slep/' + idSelect;
      tipo = "3";
    } else {
      recurso = null
    }

    let arrCodColegios = [];
    let arrIdColegios = [];
    if (this.ActualGrado != '' && this.ActualAnoEscolar != 0) {
      if(recurso){
          this.IAserviceNotas.getData(recurso).then((data) => {
            Object.keys(data.data).forEach(key => {
                arrIdColegios.push(key);
                arrCodColegios.push(data.data[key]);
            });

            let ListCodColegios = arrCodColegios.toString();
            let ListIdColegios  = arrIdColegios.toString();

            paramsDos = {
                "grupo_colegios": ListCodColegios,
                "ano_escolar": this.ActualAnoEscolar,
                "tipo": tipo,
                "asociar_a": idSelect,
                "cod_grado": this.ActualGrado
            }

            this.IAservice.getDataPost(promedio_notas_asignatura_post, paramsDos)
              .then((data) => {
                list = data.data['establecimientos'];
                Object.keys(data.data).forEach(key => {
                  if (data.data[key] && key != "establecimientos") {
                    var listpromedio = {};
                    Object.keys(data.data[key]).forEach(cod_asignatura => {

                      this.existeData = true;
                      var obj = {};
                      obj["name"] = this.MaysPrimera(data.data[key][cod_asignatura]["asignatura"]);

                      if (parseInt(cod_asignatura)==14 || parseInt(cod_asignatura)==5 || parseInt(cod_asignatura)==11224 || parseInt(cod_asignatura)==15 ) {
                        obj["visible"] = true;
                      } else {
                        obj["visible"] = false;
                      }

                      var dataCol = [];
                      Object.keys(data.data[key][cod_asignatura]["promedios"]).forEach(id => {
                        Object.keys(list).forEach(k => {
                          if (list[k].id == id) {
                            dataCol.push(data.data[key][cod_asignatura]["promedios"][id]);
                            if (this.headerTbl.indexOf(this.camelize(list[k].itemName)) === -1) {
                              this.headerTbl.push(this.camelize(list[k].itemName));
                            }
                          }
                        });
                      });
                      obj["data"] = dataCol;
                      this.rsData.push(obj);
                    });
                  }
                });
                this.chart = new Chart({
                  lang: {
                    downloadPNG: "Descargar a PNG",
                    downloadJPEG: "Descargar a JPEG",
                    downloadSVG: "Descargar a SVG",
                    downloadPDF: "Descargar a PDF",
                    loading: "Cargando",
                    printChart: "Imprimir"
                  },
                  chart: {
                    type: 'line'
                  },
                  title: {
                    text: 'Notas por asignatura'
                  },
                  yAxis: {
                    title: {
                      text: 'Promedio'
                    }
                  },
                  xAxis: {
                    categories: this.headerTbl,
                    title: {
                      text: ''
                    }
                  },
                  credits: {
                    enabled: false
                  },
                  plotOptions: {
                    series: {
                      pointStart: 0
                    }
                  },
                  series: this.rsData
                });
            })
            .catch((err) => {
              $.notify(
                {icon: 'notifications', message: err.message}, 
                {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
              );
            });
          })
          .catch((err) => {
            $.notify(
              {icon: 'notifications', message: err.message}, 
              {type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right'}}
            );
          });
      }
    } else {
      $.notify(
        { icon: 'notifications', message: 'No existe configuración previa para mostrar' }, 
        { type: 'warning', timer: 2000, delay: 2000, mouse_over: 'pause', placement: {from: 'bottom', align: 'right' }}
      );
    }
  }

  numbreRand(min: number, max: number, cant: number) {
    let array = [];
    if (cant == 1) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    } else {
      for (let index = 0; index < cant; index++) {
        array.push(Math.floor(Math.random() * (max - min + 1)) + min);
      }
      return array.sort();
    }
  }

  MaysPrimera(string) {
    var cadena = new String(string);
    cadena = cadena.toLowerCase();
    cadena = cadena.charAt(0).toUpperCase() + cadena.slice(1);
    return cadena;
  }

  camelize(str) {

    str = str.toLowerCase();
    return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g,
      function (s) {
        return s.toUpperCase();
      });

  }

  addslashes(str) {
    str = str.replace(/\\/g, '\\\\');
    str = str.replace(/\'/g, '\\\'');
    str = str.replace(/\"/g, '\\"');
    str = str.replace(/\0/g, '\\0');
    return str;
  }

  stripslashes(str) {
    str = str.replace(/\\'/g, '\'');
    str = str.replace(/\\"/g, '"');
    str = str.replace(/\\0/g, '\0');
    str = str.replace(/\\\\/g, '\\');
    return str;
  }

}
