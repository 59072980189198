/**
 * Creado por Angris Salazar
 * Servicio para proveer listado de Años,meses a ser mostrado en los distintos componentes con selectores
 */
import { Injectable } from '@angular/core';
import { Periodo } from '../../models/periodo.model';

@Injectable()

export class ListaAgnosService {

  public ActualAnoEscolar =new Date().getFullYear();
  public ActualMesEscolar = new Date().getMonth() + 1;
  public MES_NOM_COMPLETO = ["", "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  public MES_NOMBRES = ["", "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
  public MESES = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
  public Periodos: Periodo[] = [];
  constructor() {}

  public listAnosEscolares(agno : number) {

    //let SlcAnoEscolar  = [];
    let ListAnoEscolar = []; 
    for (var _i = agno; _i >= 2005; _i--) {
        ListAnoEscolar.push({ano_escolar: _i});
    }
    return(ListAnoEscolar);    
  }
/**
 * Generar listado de Meses para mostrar en Selector
 */
  public listMeses() {
    let ListMeses = []; 

    for (let _i = 0; _i <= (this.MES_NOM_COMPLETO.length)-2; _i++) {      
       ListMeses.push({nom_mes: this.MES_NOM_COMPLETO[_i+1]});
   }
   return(ListMeses); 

  }
/**
 * 
 * 
 */
  public listPeriodosEscolares() {
    this.Periodos=[];
    this.Periodos.push({ id:"1",nom_periodo:"1er Período"})
    this.Periodos.push({ id:"2",nom_periodo:"2do Período"})
    this.Periodos.push({ id:"3",nom_periodo: "Período final"})
    return(this.Periodos);    
  }

  /**
   * Obtener grados que se deben mostrar en selector para perfil personalizado
   */
  public findgrade(grade){
    let find=false;
    if(localStorage.getItem('ListProfilesPers'))
    {
      let grades=JSON.parse(localStorage.getItem('ListProfilesPers'))
    Object.keys(grades).forEach(key => {
      if(grade==grades[key].cod_grade && grades[key].tipo==7){
        find=true;
      }
    });
    
  }
  return find;
  }

  /**
   * Obtener grados que se deben mostrar en selector para perfil personalizado
   */
  public findsubject(subject){
    let find=false;
    // tener claro lo que voy a comparar 
    if(localStorage.getItem('ListProfilesPers'))
    {
      let subjects=JSON.parse(localStorage.getItem('ListProfilesPers'))
      
    Object.keys(subjects).forEach(key => {
      if(subject==subjects[key].id_subject && subjects[key].tipo==8){
        find=true;
      }
    });
    //quitar para cuando haya valores de asignaturas para comparar
    //find=true; 
  }
  return find;
  }

  /**
   * Obtener los niveles para mostrar en perfil personalizado
   */
  public nivelespersonalizado(valores,niveles){   
    let totalesalumno=[];
    let parcialesalumno=[];
    let nivelesreducidos=[];
    let nivelespersonalizados=[];
    for(let i=0,j=0;i<niveles.length;i++){
      let nivelseparado=niveles[i].split('(');
      let nivelcomparar= nivelseparado[1].slice(0,-1)+nivelseparado[0];
      if(this.findgrade(nivelcomparar)){
        totalesalumno[j]=valores[0].data[i];
        parcialesalumno[j]=valores[1].data[i];
        nivelesreducidos[j++]=niveles[i];
      }
    }
    valores[0].data=totalesalumno;
    valores[1].data=parcialesalumno;
    nivelespersonalizados[0]=nivelesreducidos;
    nivelespersonalizados[1]=valores;
    return nivelespersonalizados;
  }

  /**
   * Ordenar vector de años que se deben mostrar en selector
   */
  public yearsOrder(years){
    let yearsorder=[]
    for(let i=years.length-1,j=0;i>0;i--,j++){
      yearsorder[j]=years[i];
    }
    return yearsorder;
  }
}
