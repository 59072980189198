import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { urlServer } from '../url.service';

@Injectable()
export class IndicadoresAcademicosNotasSndService{
    private url:string= urlServer;

    constructor(public http: HttpClient){}

    rangoNotasSnd(json_param:string): Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this.http.post(this.url+'/rango_notas', json_param, {headers: headers});
    }

    promedioNotasSnd(json_param:string): Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this.http.post(this.url+'/promedio_notas_asignatura', json_param, {headers: headers});
    }
   
}

