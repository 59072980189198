import { Injectable } from '@angular/core';

@Injectable()
export class DatatablesService {

  public ALUMNOS = ["prioritarios", "integrados", "vulnerables", "preferentes"];
  constructor() { }

  public formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }

  public buildTabla(rsTable,tipo){

    let dataTable = [];
    let dataTableExcel = [];
    Object.keys(rsTable).forEach(key => {
      if (rsTable[key].INAS == 0) {
        rsTable[key].porc_inasistencia = 0;
      }
      dataTable.push({
        mes: rsTable[key].mes,
        asistencia: this.formatNumber(rsTable[key].ASIS_NET),
        porc_asistencia: rsTable[key].porc_asistencia,
        inasistencia: this.formatNumber(rsTable[key].INAS),
        porc_inasistencia: rsTable[key].porc_inasistencia,
        asistencia_posible:this.formatNumber(rsTable[key].ASIS_POS)
      });

      dataTableExcel.push({
        Mes: rsTable[key].mes,
        Asistencia: rsTable[key].ASIS_NET,
        Porcentaje_asistencia: rsTable[key].porc_asistencia,
        Inasistencia: rsTable[key].INAS,
        Porcentaje_inasistencia: rsTable[key].porc_inasistencia,
        Asistencia_posible:rsTable[key].ASIS_POS
      });
    });
    return([dataTable,dataTableExcel]);

  }

  buscarNivel(valor,niveles){
    let find=false;
    for(let i=0,j=0;i<niveles.length;i++){
    if(valor==niveles[i]){
    find=true;
    }
  }
  return find;
}

}
