import { Subscription } from 'rxjs/Subscription';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as Chartist from 'chartist';
import { DateAdapter } from '@angular/material';

import { Chart } from 'angular-highcharts';
import { PuenteService } from '../../services/puente.service';
import { IndicadoresGestionService } from '../../services/indicadores-gestion.service';
import { urlServer } from '../../services/url.service';

import * as Highcharts from "highcharts";
import * as jsPDF from "jspdf";
import * as html2canvas from "html2canvas";


declare const require: any;

declare const $: any;


@Component({
  selector: 'app-indicadores-gestion',
  templateUrl: './indicadores-gestion.component.html',
  styleUrls: ['./indicadores-gestion.component.scss'],
})



export class IndicadoresGestionComponent implements OnInit, OnDestroy {


  chart: Chart;
  MES_NOM_COMPLETO = ["", "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  MES_NOMBRES = ["", "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
  MESES = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
  dataTable = [];

  evoMatricula = false;
  aluRetirados = false;
  gAsistencia = false;
  capacUso = false;

  spinner = false;
  color = 'red';
  mode = 'indeterminate';
  value = 50;

  data_puente: any;
  mdPerfil: any;
  Puente: Subscription;

  ActualAnoEscolar: any;
  SlcAnoEsoclar = [];
  rsData = [];
  linkParams: any;

  constructor(private puente: PuenteService, private IGservice: IndicadoresGestionService) {

    this.Puente = this.puente.getData().subscribe(data => {
      this.removeActiveTab();
      if (this.evoMatricula) {
        this.evo_matricula();
      } else if (this.aluRetirados) {
        this.alu_retirados();
      } else if (this.gAsistencia) {
        this.asistencia();
      } else if (this.capacUso) {
        this.capac_uso();
      }

    });

  }

  ngOnDestroy(): void {
    this.Puente.unsubscribe();
  }



  ngOnInit() {
    this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));
    this.removeActiveTab();

    if (sessionStorage.getItem('link_param')) {
      this.evoMatricula = false;
      this.aluRetirados = false;
      this.gAsistencia = false;
      this.capacUso = false;
      this.linkParams = sessionStorage.getItem('link_param');
      sessionStorage.removeItem('link_param');
    }

    switch (this.linkParams) {
      case 'pill1':
        this.evoMatricula = true;
        this.evo_matricula();
        break;
      case 'pill2':
        this.aluRetirados = true;
        this.alu_retirados();
        break;
      case 'pill3':
        this.gAsistencia = true;
        this.asistencia();
        break;
      case 'pill4':
        this.capacUso = true;
        this.capac_uso();
        break;
      default:
        this.evoMatricula = true;
        this.evo_matricula();
    }

    this.mdPerfil = JSON.parse(atob(localStorage.getItem(btoa('perfil'))));

    this.sl2_style();

  }

  Gpdf() {
    if (this.rsData.length != 0) {
      this.spinner = true;
      var divWidth = $(".highcharts-container").width() - 20;;
      var originalBodyWidth = document.body.offsetWidth;
      var originalBodyHeight = document.body.offsetHeight;
      document.body.style.width = "1920px";
      document.body.style.height = "1080px";


      $('.graphClass').highcharts().setSize("1100", false);
      $('.table').css("width","1100px");
      setTimeout(() => {
        var Doc = new jsPDF('l', 'pt', "A4");
        html2canvas(document.querySelector(".page1"), {
          onclone: function (document) {
            //document.querySelector(".page1").style.transform = "scale(1.5)";
            // document.querySelector(".page1").classList.add("col-md-10");
            document.querySelector(".page1").classList.remove("col-md-8");
            //document.querySelector(".page1").classList.remove("col-md-offset-2");
            // document.querySelector(".page1").ownerDocument.defaultView.innerHeight = "595px";
            // document.querySelector(".page1").ownerDocument.defaultView.innerWidth = "841px";

          }
        }).then(canvas => {
          var imgData = canvas.toDataURL('image/png');
          var width = Doc.internal.pageSize.width;
          var height = Doc.internal.pageSize.height;
          Doc.addImage(imgData, 'PNG', 0, 0);
          Doc.addPage();

          html2canvas(document.querySelector(".page2"), {
            onclone: function (document) {
              document.querySelector(".page2").style.width = "100%";
            }
          }).then(canvas => {
            var imgData = canvas.toDataURL('image/png');
            var width = Doc.internal.pageSize.width;
            var height = Doc.internal.pageSize.height;
            Doc.addImage(imgData, 'PNG', 0, 0);
            Doc.save('Indicadores de gestión.pdf')
            document.body.removeAttribute("style");
            // document.body.style.width = originalBodyWidth + "px";
            // document.body.style.height = originalBodyHeight + "px";

            $('.graphClass').highcharts().setSize(divWidth, false);
            $('.table').css("width","100%");
            this.spinner = false;
          });
        });

      }, 1000);
    } else {
      $.notify({
        icon: 'notifications',
        message: "No hay datos para imprimir"
      }, {
          type: 'warning',
          timer: 2000,
          delay: 2000,
          mouse_over: 'pause',
          placement: {
            from: 'bottom',
            align: 'right'
          }
        });
    }

  }
  // Gpdf() {
  //   if(this.rsData.length != 0){
  //     var Doc = new jsPDF('l', 'cm', 'A4');
  //     html2canvas(document.querySelector(".page1")).then(canvas => {
  //       var imgData = canvas.toDataURL('image/png');
  //       var width = Doc.internal.pageSize.width;
  //       var height = Doc.internal.pageSize.height;
      
  //       Doc.addImage(imgData, 'PNG', 0,0, width );
  //       Doc.addPage();
  //       html2canvas(document.querySelector(".page2")).then(canvas => {
  //         var imgData = canvas.toDataURL('image/png');
          
  //         var width = Doc.internal.pageSize.width;
  //         var height = Doc.internal.pageSize.height;
        
  //         Doc.addImage(imgData, 'PNG', 0,0, width);
  //         Doc.save('Indicadores de gestión.pdf')
    
  //       });
  
  //     });
  //   }else{
  //     $.notify({
  //       icon: 'notifications',
  //       message: "No hay datos para imprimir"
  //     }, {
  //         type: 'warning',
  //         timer: 2000,
  //         delay: 2000,
  //         mouse_over: 'pause',
  //         placement: {
  //           from: 'bottom',
  //           align: 'right'
  //         }
  //       });
  //   }

  // }

  alu_retirados() {
    this.removeActiveTab();
    $("#pill2").parent().addClass("active");
    $("#tab2").parent().addClass("active");
    this.aluRetirados = true;
    this.evoMatricula = false;
    this.gAsistencia = false;
    this.capacUso = false;
    let rsData = [];
    let recurso: string;
    let idSelect: string;
    let jsonData: any;
    let AnoEscolar = [];


    if (localStorage.getItem('select_colegio')) {
      jsonData = JSON.parse(localStorage.getItem('select_colegio'));
      idSelect = jsonData.id
      recurso = urlServer + '/colegio/' + idSelect + '/retirados';
    } else if (localStorage.getItem('select_sost')) {
      jsonData = JSON.parse(localStorage.getItem('select_sost'));
      idSelect = jsonData.id
      recurso = urlServer + '/sostenedor/' + idSelect + '/retirados';
    } else if (localStorage.getItem('select_slep')) {
      jsonData = JSON.parse(localStorage.getItem('select_slep'));
      idSelect = jsonData.id
      recurso = urlServer + '/slep/' + idSelect + '/retirados';
    } else {
      recurso = urlServer + '/dep/' + this.mdPerfil.codigo +'/retirados';
    }

    let ArrMeses = [];
    let ArrDataAno = [];

    this.dataTable = [];
    this.rsData = [];
    this.ActualAnoEscolar = 0;
    this.SlcAnoEsoclar = []


    this.IGservice.getData(recurso).then((data) => {
      Object.keys(data.data).forEach(key => {
        Object.keys(data.data[key]).forEach(anoEscolar => {
          AnoEscolar.push(anoEscolar);

          var dataobj = {};
          for (var mes in data.data[key][anoEscolar]) {
            if (typeof data.data[key][anoEscolar][mes] !== 'function') {

              var obj = {};
              obj["MES"] = this.MES_NOMBRES[mes];
              obj["RETIRADOS"] = data.data[key][anoEscolar][mes];
              dataobj[mes] = obj;
              this.rsData[anoEscolar] = dataobj

            }
          }



          if (this.ActualAnoEscolar) {
            if (this.ActualAnoEscolar < anoEscolar) {
              this.ActualAnoEscolar = anoEscolar;
            }
          } else {
            this.ActualAnoEscolar = anoEscolar;
          }

          var xano = {};
          xano["ano_escolar"] = anoEscolar;

          this.SlcAnoEsoclar.push(xano);


        });
      });

      this.recargaGraficoRetirados(this.rsData, this.ActualAnoEscolar);
    })
      .catch((err) => {

        $.notify({
          icon: 'notifications',
          message: err.message
        }, {
            type: 'warning',
            timer: 2000,
            delay: 2000,
            mouse_over: 'pause',
            placement: {
              from: 'bottom',
              align: 'right'
            }
          });
      });
  }

  recargarRetirados(items: any) {
    this.recargaGraficoRetirados(this.rsData, items.value);
  }

  recargaGraficoRetirados(rsData, ActualAnoEscolar) {

    let meses = [];
    let data = [];
    this.dataTable = [];

    for (var key in rsData[ActualAnoEscolar]) {
      meses.push(rsData[ActualAnoEscolar][key]["MES"]);
      data.push(rsData[ActualAnoEscolar][key]["RETIRADOS"])
    }

    this.chart = new Chart({
      lang: {
        downloadPNG: "Descargar a PNG",
        downloadJPEG: "Descargar a JPEG",
        downloadSVG: "Descargar a SVG",
        downloadPDF: "Descargar a PDF",
        loading: "Cargando",
        printChart: "Imprimir",
        contextButtonTitle: 'Opciones gráfico'
      },
      chart: {
        type: 'area'
      },
      title: {
        text: 'Alumnos retirados ' + ActualAnoEscolar
      },
      yAxis: {
        title: {
          text: 'Cantidad alumnos retirados'
        }
      },
      xAxis: {
        categories: meses,
        title: {
          text: ''
        }
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          pointStart: 0
        }
      },
      series: [{
        name: 'Meses',
        data: data
      }]
    });


    let randAnoEscolar: any = this.numbreRand(2010, 2018, 2);
    let randRsData: any = this.numbreRand(1, 100, 2);

    let total = 0;
    for (var k in data) {
      total = total + data[k];
    };

    var obj = {
      anoEscolar: ActualAnoEscolar,
      retirados: data,
      total: total
    };


    this.dataTable.push(obj);
  }



  evo_matricula() {
    this.removeActiveTab();
    $("#pill1").parent().addClass("active");
    $("#tab1").parent().addClass("active");
    this.evoMatricula = true;
    this.aluRetirados = false;
    this.gAsistencia = false;
    this.capacUso = false;

    this.ActualAnoEscolar = 0;
    let rsData = [];
    let recurso: string;
    let idSelect: string;
    let jsonData: any;
    let AnoEscolar = [];

    if (localStorage.getItem('select_colegio')) {
      jsonData = JSON.parse(localStorage.getItem('select_colegio'));
      idSelect = jsonData.id
      recurso = urlServer + '/colegio/' + idSelect + '/evolucion-matricula';
    } else if (localStorage.getItem('select_sost')) {
      jsonData = JSON.parse(localStorage.getItem('select_sost'));
      idSelect = jsonData.id
      recurso = urlServer + '/sostenedor/' + idSelect + '/evolucion-matricula';
    } else if (localStorage.getItem('select_slep')) {
      jsonData = JSON.parse(localStorage.getItem('select_slep'));
      idSelect = jsonData.id
      recurso = urlServer + '/slep/' + idSelect + '/evolucion-matricula';
    } else {
      recurso = urlServer + '/dep/' + this.mdPerfil.codigo +'/evolucion-matricula';
    }
    this.rsData = [];
    this.dataTable = [];
    this.IGservice.getData(recurso).then((data) => {
      Object.keys(data.data).forEach(key => {
        Object.keys(data.data[key]).forEach(anoEscolar => {
          AnoEscolar.push(anoEscolar);
          this.rsData.push(data.data[key][anoEscolar].cant_matriculados);
          this.dataTable.push({
            anoEscolar: anoEscolar,
            cantidad: data.data[key][anoEscolar].cant_matriculados
          })


          if (this.ActualAnoEscolar) {
            if (this.ActualAnoEscolar < anoEscolar) {
              this.ActualAnoEscolar = anoEscolar;
            }
          } else {
            this.ActualAnoEscolar = anoEscolar;
          }


        });
      });

      this.chart = new Chart({
        lang: {
          downloadPNG: "Descargar a PNG",
          downloadJPEG: "Descargar a JPEG",
          downloadSVG: "Descargar a SVG",
          downloadPDF: "Descargar a PDF",
          loading: "Cargando",
          printChart: "Imprimir",
          contextButtonTitle: 'Opciones gráfico'
        },
        chart: {
          type: 'line'
        },
        title: {
          text: 'Evolución matricula'
        },
        yAxis: {
          title: {
            text: 'Nro. Matriculados'
          }
        },
        xAxis: {
          categories: AnoEscolar,
          title: {
            text: ''
          }
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            pointStart: 0
          }
        },
        series: [{
          name: 'Año escolar',
          data: this.rsData
        }]
      });

    })
      .catch((err) => {

        $.notify({
          icon: 'notifications',
          message: err.message
        }, {
            type: 'warning',
            timer: 2000,
            delay: 2000,
            mouse_over: 'pause',
            placement: {
              from: 'bottom',
              align: 'right'
            }
          });
      });

  }

  asistencia() {
    this.removeActiveTab();
    $("#pill3").parent().addClass("active");
    $("#tab3").parent().addClass("active");
    this.gAsistencia = true;
    this.evoMatricula = false;
    this.aluRetirados = false;
    this.capacUso = false;
    let rsData = [];
    let recurso: string;
    let idSelect: string;
    let jsonData: any;
    let AnoEscolar = [];

    this.dataTable = [];
    this.rsData = [];
    this.ActualAnoEscolar = 0;
    this.SlcAnoEsoclar = []


    if (localStorage.getItem('select_colegio')) {
      jsonData = JSON.parse(localStorage.getItem('select_colegio'));
      idSelect = jsonData.id
      recurso = urlServer + '/colegio/' + idSelect + '/asistencia-mensual';
    } else if (localStorage.getItem('select_sost')) {
      jsonData = JSON.parse(localStorage.getItem('select_sost'));
      idSelect = jsonData.id
      recurso = urlServer + '/sostenedor/' + idSelect + '/asistencia-mensual';
    } else if (localStorage.getItem('select_slep')) {
      jsonData = JSON.parse(localStorage.getItem('select_slep'));
      idSelect = jsonData.id
      recurso = urlServer + '/slep/' + idSelect + '/asistencia-mensual';
    } else {
      recurso = urlServer + '/dep/' + this.mdPerfil.codigo +'/asistencia-mensual';
    }


    this.IGservice.getData(recurso).then((data) => {
      Object.keys(data.data).forEach(key => {
        Object.keys(data.data[key]).forEach(anoEscolar => {
          AnoEscolar.push(anoEscolar);
          let anoData = []
          var objInasistencia = {};
          var objAsistencia = {};
          let inasistencias = [];
          let asistencias = [];
          var objTabla = [];
          for (var k in data.data[key][anoEscolar]) {
            var objTablaDetalle = {};
            objTablaDetalle = {
              mes: this.MES_NOM_COMPLETO[k],
              inasistencia: data.data[key][anoEscolar][k][0],
              asistencia: data.data[key][anoEscolar][k][1]
            }
            inasistencias.push(data.data[key][anoEscolar][k][0]);
            asistencias.push(data.data[key][anoEscolar][k][1]);

            objTabla.push(objTablaDetalle);
          }

          this.dataTable[anoEscolar] = objTabla;

          objInasistencia = {
            name: "Inasistencia",
            data: inasistencias,
            color: "#D9444E"
          };
          objAsistencia = {
            name: "Asistencia",
            data: asistencias,
            color: "#00649E"
          };

          anoData.push(objInasistencia);
          anoData.push(objAsistencia);


          this.rsData[anoEscolar] = anoData;

          var xano = {};
          xano["ano_escolar"] = anoEscolar;

          this.SlcAnoEsoclar.push(xano);
          if (this.ActualAnoEscolar) {
            if (this.ActualAnoEscolar < anoEscolar) {
              this.ActualAnoEscolar = anoEscolar;
            }
          } else {
            this.ActualAnoEscolar = anoEscolar;
          }


        });
      });
      this.recalculaGraficoAsistencia(this.rsData, this.ActualAnoEscolar, this.dataTable);
    })
      .catch((err) => {

        $.notify({
          icon: 'notifications',
          message: err.message
        }, {
            type: 'warning',
            timer: 2000,
            delay: 2000,
            mouse_over: 'pause',
            placement: {
              from: 'bottom',
              align: 'right'
            }
          });
      });
  }

  recargarGraficoAsistencia(items: any) {
    this.recalculaGraficoAsistencia(this.rsData, items.value, this.dataTable);
  }

  recalculaGraficoAsistencia(rsData, anoEscolar, dataTable) {
    this.chart = new Chart({
      lang: {
        downloadPNG: "Descargar a PNG",
        downloadJPEG: "Descargar a JPEG",
        downloadSVG: "Descargar a SVG",
        downloadPDF: "Descargar a PDF",
        loading: "Cargando",
        printChart: "Imprimir",
        contextButtonTitle: 'Opciones gráfico'
      },
      chart: {
        type: 'column'
      },
      title: {
        text: 'Asistencia mensual - ' + anoEscolar
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Total asistencia'
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      xAxis: {
        categories: this.MESES,
        title: {
          text: ''
        }
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        column: {
          stacking: 'percent',
          dataLabels: {
            enabled: true,
            color: 'white',
            format: '{point.percentage:.1f} %'
          }
        }
      },
      series: rsData[anoEscolar]
    });

  }

  capac_uso() {
    this.removeActiveTab();
    $("#pill4").parent().addClass("active");
    $("#tab4").parent().addClass("active");
    this.capacUso = true;
    this.evoMatricula = false;
    this.aluRetirados = false;
    this.gAsistencia = false;

    this.rsData = [];
    let recurso: string;
    let idSelect: string;
    let jsonData: any;
    let AnoEscolar = [];
    this.ActualAnoEscolar = 0;
    this.SlcAnoEsoclar = []

    let porc_matricula: any;
    let porc_disponible: any;


    if (localStorage.getItem('select_colegio')) {
      jsonData = JSON.parse(localStorage.getItem('select_colegio'));
      idSelect = jsonData.id
      recurso = urlServer + '/colegio/' + idSelect + '/capacidad-uso';
    } else if (localStorage.getItem('select_sost')) {
      jsonData = JSON.parse(localStorage.getItem('select_sost'));
      idSelect = jsonData.id
      recurso = urlServer + '/sostenedor/' + idSelect + '/capacidad-uso';
    } else if (localStorage.getItem('select_slep')) {
      jsonData = JSON.parse(localStorage.getItem('select_slep'));
      idSelect = jsonData.id
      recurso = urlServer + '/slep/' + idSelect + '/capacidad-uso';
    } else {
      recurso = urlServer + '/dep/' + this.mdPerfil.codigo +'/capacidad-uso';
    }

    this.IGservice.getData(recurso).then((data) => {
      Object.keys(data.data).forEach(key => {
        if (!$.isEmptyObject(data.data[key])) {
          Object.keys(data.data[key]).forEach(anoEscolar => {
            AnoEscolar.push(anoEscolar);

            porc_matricula = (100 * data.data[key][anoEscolar].matriculados) / data.data[key][anoEscolar].capacidad
            porc_matricula = Math.round(porc_matricula * 10) / 10;
            porc_disponible = 100 - porc_matricula
            porc_disponible = Math.round(porc_disponible * 10) / 10;

            var obj = {};
            obj["matricula"] = porc_matricula;
            obj["disponible"] = porc_disponible;
            this.rsData[anoEscolar] = obj

            var xano = {};
            xano["ano_escolar"] = anoEscolar;

            this.SlcAnoEsoclar.push(xano);


            if (this.ActualAnoEscolar) {
              if (this.ActualAnoEscolar < anoEscolar) {
                this.ActualAnoEscolar = anoEscolar;
              }
            } else {
              this.ActualAnoEscolar = anoEscolar;
            }

          });
          this.recalculaGraphPie(this.rsData, this.ActualAnoEscolar);

        }
      });



    })
      .catch((err) => {

        $.notify({
          icon: 'notifications',
          message: err.message
        }, {
            type: 'warning',
            timer: 2000,
            delay: 2000,
            mouse_over: 'pause',
            placement: {
              from: 'bottom',
              align: 'right'
            }
          });
      });

  }

  recargarGraficoCapacidad(items: any) {
    this.recalculaGraphPie(this.rsData, items.value);
  }

  recalculaGraphPie(rsData, anoEscolar) {
    this.chart = new Chart({
      lang: {
        downloadPNG: "Descargar a PNG",
        downloadJPEG: "Descargar a JPEG",
        downloadSVG: "Descargar a SVG",
        downloadPDF: "Descargar a PDF",
        loading: "Cargando",
        printChart: "Imprimir",
        contextButtonTitle: 'Opciones gráfico'
      },
      chart: {
        type: 'pie'
      },
      title: {
        text: 'Capacidad de uso ' + anoEscolar
      },

      credits: {
        enabled: false
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          }
        }
      },
      series: [{
        name: 'Capacidad de uso',
        data: [{
          name: 'Disponibles',
          y: rsData[anoEscolar]["disponible"],
          sliced: true,
          selected: true,
          color: '#1EA559'

        }, {
          name: 'Matriculados',
          y: rsData[anoEscolar]["matricula"],
          color: '#FB3948'
        }]
      }]
    });

    this.dataTable = [];

    for (var k in rsData) {
      this.dataTable.push({
        anoEscolar: k,
        disponible: rsData[k]["disponible"] + '%',
        matricula: rsData[k]["matricula"] + '%',
      });
    };
  }


  numbreRand(min: number, max: number, cant: number) {
    let array = [];
    if (cant == 1) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    } else {
      for (let index = 0; index < cant; index++) {
        array.push(Math.floor(Math.random() * (max - min + 1)) + min);
      }
      return array.sort();
    }
  }

  sl2_style() {

    $(".c-btn").css("box-shadow", "none");
    $(".c-btn").css("border-top", "none");
    $(".c-btn").css("border-left", "none");
    $(".c-btn").css("border-right", "none");
    $(".c-btn").css("color", "#989898");
    $(".c-btn").css("background-color", "#ededee");
    $(".sl2-material > div >  .dropdown-list").css("position", "absolute");
  }

  removeActiveTab() {
    $("#pill2").parent().removeClass("active");
    $("#tab2").parent().removeClass("active");
    $("#pill1").parent().removeClass("active");
    $("#tab1").parent().removeClass("active");
    $("#pill3").parent().removeClass("active");
    $("#tab3").parent().removeClass("active");
    $("#pill4").parent().removeClass("active");
    $("#tab4").parent().removeClass("active");
  }

}
