import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { urlServer } from '../url.service';

@Injectable()
export class CapacidadSndService{
    private url:string= urlServer;

    constructor(
        public http: HttpClient
    ){}

    capacidadTotalSostenedor(cod_sostenedor:number,ano_escolar:number): Observable<any>{
        return this.http.get(this.url+'/sostenedor/'+cod_sostenedor+'/capacidad/ano_escolar/'+ano_escolar);
    }

    capacidadTotalEstablecimiento(cod_sostenedor:number,ano_escolar:number): Observable<any>{
        return this.http.get(this.url+'/sostenedor/'+cod_sostenedor+'/capacidad/ano_escolar/'+ano_escolar+'/tipo/1');
    }

    capacidadTotalGrado(cod_sostenedor:number,ano_escolar:number): Observable<any>{
        return this.http.get(this.url+'/sostenedor/'+cod_sostenedor+'/capacidad/ano_escolar/'+ano_escolar+'/tipo/2');
    }

    capacidadUsoSnd(json_param:string): Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this.http.post(this.url+'/capacidad_uso', json_param, {headers: headers});
    }

}
