import { Component, ElementRef, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup, AbstractControl,FormControl, ValidationErrors } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
declare var jquery: any;
declare var $: any;
@Component({
  selector: 'app-recovery-pass',
  templateUrl: './recovery-pass.component.html',
  styleUrls: ['./recovery-pass.component.scss']
})
export class RecoveryPassComponent implements OnInit {
  recoveryForm: FormGroup;
  private nativeElement: Node;
  username='';
  recoveryPassDisable: Boolean = true;

  constructor(private auth: AuthService,private element: ElementRef,private router: Router,private formBuilder: FormBuilder, private cookieService: CookieService, public dialog: MatDialog) { 
    this.nativeElement = element.nativeElement;
    this.createForm();
  }

  ngOnInit() {
  }

  createForm(){
    this.recoveryForm = this.formBuilder.group({
      'username': ['',[Validators.required,Validators.pattern("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?")]]
      });
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
        'has-error': this.isFieldValid(form, field),
        'has-feedback': this.isFieldValid(form, field)
    };
}

isFieldValid(form: FormGroup, field: string) {
  return !form.get(field).valid && form.get(field).touched;
}

validateAllFormFields(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
          this.validateAllFormFields(control);
      }
  });
}

onRecoveryPass(){
  if (this.recoveryForm.valid) {
    // AS diferenciar parametros para el guardado de perfil personalizado          
       
       let params = {
         username:this.username,
       };
     
       this.auth.recoveryPass(params).then((res) => {
         $.notify({
           icon: 'notifications',
           message: `${res.message}. 
                     Revise su correo por favor.`
         }, {
           type: 'success',
           timer: 1500,
           delay: 1000,
           mouse_over: 'pause',
           placement: {
               from: 'bottom',
               align: 'right'
           }
         });setTimeout(() => {
          this.closeDialog();
      }, 2000);            
             
       }) .catch((err) => {
        $.notify(
          { icon: 'notifications', message: err.error.message }, 
          { type: 'warning', timer: 1500, delay: 1500, mouse_over: 'pause', placement: { from: 'bottom', align: 'right' } }
        );
      });
     
          
}
else {
  this.validateAllFormFields(this.recoveryForm);
  $.notify({
      icon: 'notifications',
      message: 'Complete los datos de ingreso .'
  }, {
          type: 'danger',
          timer: 2000,
          delay: 2000,
          mouse_over: 'pause',
          placement: {
              from: 'bottom',
              align: 'right'
          }
      });
}
}
  closeDialog(){
    this.dialog.closeAll();    
  }

}
